.pd__product__price__block {
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 16px -15px;
  justify-content: space-between;

  @media screen and (max-width:992px) {
    margin: 16px 0px;
    padding-left: 0px;
  }
  @media screen and (max-width:480px) {
    margin: 4px 0px 0px;
  }

  .pd__product__price__offer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    @media screen and (max-width:992px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 0px;
    }

    .pd__total__price__block {
      padding: 0px 0px 0px 0px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      // max-width: 230px;
      @media screen and (max-width:992px) {
        max-width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
      }

      @media screen and (max-width:480px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .inner_price_section {
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        .text3{
          color: #7D7D7D;
          font-weight: 700;
          font-size: 20px;
          position: relative;
          display: inline-block;
          width: max-content;

          &::after {
            content: " ";
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: #7D7D7D;
            transform: translateY(-10px) rotate(-5deg);
            bottom: 0;
            left: 0;
        }
        }
        .heading4 {
          font-size: 25px;
          line-height: 1;

          @media screen and (max-width:1366px) {
            font-size: 23px;
          }

          @media screen and (max-width:992px) {
            font-size: 20px;
          }
          @media screen and (max-width:480px) {
            font-size: 18px;
          }
          @media screen and (max-width:320px) {
            font-size: 16px;
          }
        }
      }
      

      .save-amount-box {
        padding: 6px 12px;
        background-color: #FF9D73;
        border-radius: 20px;
        color: #ffffff;
        border: 1.3px solid #FE6321;
        margin: 0px 10px 0px 0px;

        @media screen and (max-width:992px) {
          margin: 0px 6px 0px 6px;
          padding: 5px 9px;
          width: max-content;
        }

        @media screen and (max-width:480px) {
          margin: 0px;
        }

        .pd__save__price__button{
          color: #ffffff;
          font-weight: 700;
          font-size: 17px;
          line-height: 1.1;
          margin: 0px !important;

          @media screen and (max-width:992px) {
            font-size: 14px;
            line-height: 1.1;
          }
        }

      }

      .offer__persentage__box {
        height: 36px;
        width: 36px;
        padding: 3px 5px;
        border-radius: 50%;
        background-color: #DC3A1A;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        .heading6 {
          color: #ffffff;
          font-size: 15px;
          font-weight: 600;
          text-align: center;

          @media screen and (max-width:992px) {
            font-size: 12px;
          }
        }

        @media screen and (max-width:992px) {
          height: 30px;
          width: 30px;
        }
        @media screen and (max-width:480px) { margin-top: 5px; }
      }
    }

  }

  .pd__or__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;

    .pd__mid__line {
      height: 1px;
      width: 100%;
      background-color: #dbdbdb;
      z-index: -1;
      transform: translateY(-20px);
    }

    .pd__or__text__block {
      background-color: #f6f6f6;
      width: fit-content;
      padding: 10px;
      border-radius: 50%;

      .pd__or__text {
        font-size: 14px;
        font-family: 'SSTbold';
        font-weight: bold;
        margin: 0px;
      }
    }
  }

  .pd__product__monthly__price__block {
    padding: 0px 0px 0px 16px;
    text-align: right;

    .pd__know__more {
      color: #003cb4;
      font-size: 15px;
    }
  }

}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .pd__product__price__block {
    .pd__or__block {
      .pd__or__text__block {
        padding: 5px 10px;
      }
    }
  }

}

/*-------------------------- Arabic view End ------------------------*/