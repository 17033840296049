.bundle__card__area{
  min-height: 28px;
  .bundle__card {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00008b;
    color: #fff;
    padding: 8px 10px;
    border-radius: 5px;
    z-index: 2;
    position: relative;
    font-size: 12px;
    max-width: 115px;
    letter-spacing: .1rem;
    line-height: 1;
    
    @media(max-width:767px) {
      padding: 6px 8px;
      font-size: 10px;
    }
  }
}
.disableBundled{
    background: #a7a7a7!important;
    cursor: not-allowed!important;
    color: black!important;
}
