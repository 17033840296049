@media(max-width:991px) {
    .mb__cart__page {
        .product__avilable__offers__block .product__offer__block .product__offer__icon {
            width: 20px;
            margin: 0px 16px 0px 0px;
        }
        .main__recommand__product__block{
            background: #fff;
            padding-top: 20px;
        }
        .product__offer__block {
            display: flex;
            align-items: center;
            margin: 10px 0px 10px 0px;
            flex: 0 0 100%;
            .product__offer__icon {
              width: 20px;
              margin: 0px 12px 0px 0px;
            }
        
            .product__tamara_offer__icon {
              width: 20px;
              margin: 0px 12px 0px 0px;
              height: 15px;
              width: 50px;
              margin-left: 30px;
            }
        
            .tamara-product-widget {
              line-height: 1.4 !important;
              font-size: 13px !important;
              padding: 16px 80px 16px 16px !important;
        
              &:empty {
                display: none;
              }
        
              @media screen and (max-width: 1280px) {
                font-size: 12px !important;
                padding: 16px 60px 16px 16px !important;
              }
        
              @media screen and (max-width: 992px) {
                padding: 16px 110px 16px 16px !important;
              }
        
              @media screen and (max-width: 480px) {
                font-size: 13px !important;
                font-weight: normal;
                padding: 16px 85px 16px 16px !important;
              }
        
              .tamara-logo {
                width: 40px !important;
                height: auto !important;
        
                @media screen and (max-width: 992px) {
                  width: 70px !important;
                }
        
                @media screen and (max-width: 480px) {
                  width: 52px !important;
                }
              }
            }
        
            .installment__offer__box {
              width: 100%;
            }
            #TabbyPromo {
              @media screen and (max-width:992px) {
                width: 100%;
              }
        
              .styles__tabby-promo-snippet_font_inherit--b7b35 {}
        
              .styles__tabby-promo-snippet__content--88401 {
                .styles__tabby-promo-snippet__text--f30af {
                  span {
                    line-height: 1.4 !important;
                    font-size: 13px !important;
                  }
                }
              }
            }
        
            .styles__tabby-promo-snippet--54518.styles__tabby-promo-snippet_font_inherit--b7b35 .product__offer__text__box {
              display: flex;
              align-items: center;
        
              .product__offertype {
                margin: 0px;
                font-size: 14px;
                font-family: 'SSTbold';
                font-weight: bold;
        
                .product__offerText {
                  margin: 0px 10px 0px 0px;
                  font-size: 14px;
                  font-family: "SSTregular" !important;
                  font-weight: 400;
                }
              }
        
              .product__termsAndConditions__link {
                margin: 0px;
                font-size: 14px;
                font-family: 'SSTbold';
                font-weight: bold;
                text-decoration: none;
                color: #003cb4;
              }
            }
          }            
        .mb__sc__fixed__btn {
            background: #DC3A1A 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            padding: 10px 30px;
            position: fixed;
            bottom: 0;
            width: 100%;
            left: 0;
            right: 0;
            align-items: center;
            color: #fff !important;
        }
        .shopping__cart__page__container{
            background: #f5f5f5;
            .shopping__cart__page__block{

                
                .mb__cart__emptybox {
                    margin-bottom: 30px;
                    text-align: center;
                    img{
                        width: 85%;
                        max-width: 180px;
                        margin: 0px auto;
                    }
                    .text3{
                        margin: 15px 0px !important;
                        text-align: center;
                    }
                }
            }
        }
        .breadcrumbs__container {
            // padding-top: 16px;
        }

        .sc__product__middle__block {
            .heading4 {
                margin-top: 30px !important;
                font-size: 18px;
            }
        }

        .protection__plan__block .selected__plan__block .remove__plan p {
            background-image: url("./../../assets/Icon/delete.svg");
            font-size: 0;
            height: 22px;
            width: 24px;
            background-size: contain;
            margin-left: auto !important;
            cursor: pointer;
            background-repeat: no-repeat;
        }

        .sc__product__block {

            .sc__product__left__block,
            .sc__product__middle__block,
            .sc__product__right__block {
                width: 100%;
            }

            .sc__product__image__block {
                background-color: transparent !important;
                border: none !important;
                width: 100%;
                height: auto;
                padding: 0;
            }
        }

        .sc__product__right__block {
            background: #F2F2F2;
            align-items: center !important;
            padding: 10px 20px !important;
            margin: 0 auto;
            width: 94% !important;
            border-radius: 5px;
            overflow: hidden;
        }
        .protection__plan__block .heading5{
            font-size: 16px;
        }
      
    }

}
@media(max-width:767px){
    .sc__product__block .sc__offers__discounts__block .sc__form__block .sc__form__field {
        width: 100%;
    }
    .sc__product__block .sc__offers__discounts__block .sc__form__block .sc__form__field:last-child {
        width: 110px;
    }
    .shopping__cart__page__container .shopping__cart__page__block{
        padding-top: 0;
    }
    .sc__page__title .text1 {
        font-size: 15px;
    }
    
}


/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {

    .mb__cart__page{

        .product__offer__block {
            .tamara-product-widget {
              padding: 16px 16px 16px 80px !important;
      
              @media screen and (max-width: 1280px) {
                padding: 16px 16px 16px 60px !important;
              }
      
              @media screen and (max-width: 992px) {
                padding: 16px 16px 16px 110px !important;
              }
      
              @media screen and (max-width: 480px) {
                padding: 16px 16px 16px 85px !important;
              }
            }
          }      
    }
    

}

/*-------------------------- Arabic view End ------------------------*/