.infinite-scroll-component {
  overflow: unset!important;
}
.plp__filter__product__container {
  position: relative;
  .plp__filter__product__title__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9f9;
    padding: 15px 5%;

    .plp__filter__product__sortby__grid__button {
      display: flex;
      align-items: center;

      .plp__sortby__select__field {
        cursor: pointer;
        border: 1px solid #000000;
        width: 180px;
        padding: 8px 10px;
        border-radius: 3px;
        background: #ffffff 0% 0% no-repeat padding-box;
        margin: 0px 0px 0px 10px;
        font-size:15px;
        .plp__sortby__options {
          width: 100%;
          color: #808080;
          font-family: 'SSTregular';
          font-size: 16px;
        }
      }
      .plp__grid__button {
        display: flex;
        .plp__grid__button {
          // height: 50px;
          // width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 0px 0px 10px;
          background: #ffffff;
          border: 1px solid #727272;
          border-radius: 3px;
          padding: 8px;
          .grid__icon {
          }
          cursor: pointer;
        }
        .plp__grid__button__active {
          cursor: pointer;
          // height: 50px;
          // width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 0px 0px 10px;
          padding: 8px;
          background: #ffffff;
          border: 1px solid #ff4f04;
          border-radius: 3px;
          .grid__icon {
          }
        }
        .plp__list__button {
          cursor: pointer;
          // height: 50px;
          // width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          margin: 0px 0px 0px 10px;
          background: #ffffff;
          border: 1px solid #727272;
          border-radius: 3px;
          .list__icon {
          }
        }
        .plp__list__button__active {
          cursor: pointer;
          // height: 50px;
          // width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 0px 0px 10px;
          background: #ffffff;
          border: 1px solid #ff4f04;
          border-radius: 3px;
          padding: 8px;
          .list__icon {
          }
        }
      }
    }
  }
  .plp__filter__product__block {
    margin: 0px auto;
    .plp__filter__block {
      background: #ffffff;
      padding: 0px;
      box-shadow: -1px 1px 3px -1px #00000029;
    }
    .plp__product__block {
      background-color: #f5f4f4;
      padding: 15px 25px;
      .heading3{
        word-break: break-all;
      }
      .plp__inner__product__block{
        .PLP_dsk_noProduct_box{
          min-height: 33vh;
          display: flex;
          align-items: center;
          h1 {
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .plp__filter__product__container{
    .plp__filter__product__title__block{
      .plp__filter__product__sortby__grid__button {
        .plp__grid__button .plp__list__button{
          margin: 0px 0px;
        }
        .plp__sortby__select__field{
          margin: 0px 10px 0px 10px;
          font-size: 12px;
        }
      }        
    }
  } 
}

/*-------------------------- Arabic view End ------------------------*/
