.loginModel__container {
  margin: 0px 0px 0px 0px;
  width: 100%;
  padding: 0px;
  height: fit-content;
  .loginModel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid #D1D1D1;
    background-color: #ffffff;
    p.heading3{
      line-height: 1;
    }
    .cancel__button {
      cursor: pointer;
      &:hover{
        filter: invert(1) sepia(0%) saturate(7500%) hue-rotate(217deg) brightness(108%) contrast(108%);
      }
    }    
  }
  .heading7{
    margin-top: 18px !important;
  }
  .loginModel__content {
    padding: 15px 0px 0px;
    background-color: #ffffff;
    .main__form__field__block {
      margin: 0px 0px 10px;

      .field__itembox{
        padding: 20px 10px 6px;
        border: 1px solid #979797AB;
        background-color: #ffffff;
        border-radius: 12px;
        height: 56px;
        position: relative;
        
        .field__labelbox{
          position: absolute;
          width: auto;
          height: 17px;
          display: flex;
          left: 12px;
          top: 50%;
          transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          transition: all .4s ease-in-out;
          -webkit-transition: all .4s ease-in-out;
          .heading7{
            color: #22303D;
            font-size: 17px;
            line-height: 1;
            margin: 0px !important;
            font-family: 'SSTmedium';
            font-weight: 500;
            @media screen and (max-width:480px) {
              font-size: 15px;
            }
          }
        }
        input.form__field{
          width: 100%;
          border: none;
          background-color: transparent !important;
          position: relative;
          z-index: 1;
          &.focus_in,:focus {
            outline: none;
            box-shadow: none;
            & + .field__labelbox{
              top: 14px;
              .heading7{
                font-size: 13px;
                color: #6D7278;
                font-weight: 500;
                font-family: 'SSTmedium';
                margin: 0px !important;
              }
            }
            & + a + .field__labelbox{
              top: 14px;
              .heading7{
                font-size: 13px;
                color: #6D7278;
                font-weight: 500;
                font-family: 'SSTmedium';
                margin: 0px !important;
              }
            }            
          }
          &:focus{
            outline: none;
            box-shadow: none;
            & + .field__labelbox{
              top: 14px;
              .heading7{
                font-size: 13px;
                color: #6D7278;
                font-weight: 500;
                font-family: 'SSTmedium';
                margin: 0px !important;
              }
            }
            & + a + .field__labelbox{
              top: 14px;
              .heading7{
                font-size: 13px;
                color: #6D7278;
                font-weight: 500;
                font-family: 'SSTmedium';
                margin: 0px !important;
              }
            }  
          }
          &:focus-visible{
            outline: none;
            box-shadow: none;
          }
          &:-webkit-autofill {
            background-color: #ffffff !important;
            -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
            -webkit-text-fill-color: #333;
          }
          &:-webkit-autofill:focus {
            background-color: #ffffff !important;
            -webkit-box-shadow: 0 0 0 50px white inset;/*your box-shadow*/
            -webkit-text-fill-color: #333;
          }
        }
        a { 
          display: flex; 
          position: absolute;
          width: 23px;
          height: auto;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          z-index: 1;
          cursor: pointer;
        }
      }


      .form__label {
      }
      .field__block {        
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .form__field {
          border: 1px solid #c8c8c8;
          border-radius: 4px;
          padding: 6px 15px;
          width: 100%;
          outline: none;          
          font-size: 16px;
          letter-spacing: 0.3px;

          &:focus {
            background-color: #ffffff;
            border-color: #333333;
          }
        }
        a { 
          display: flex; 
          position: absolute;
          width: 23px;
          height: auto;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .invalid__message {
        color: red;
        margin: 2px 0px 0px;
        font-size: 13px;
      }
    }
    .remember__and__forget__password__block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;
      .main__remember__block {
        width: 60%;
        @media screen and (max-width:767px){ width: 50%; }
        .remember__block {
          display: flex;
          align-items: flex-start;
          .inner__Remember__block {
            width: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 2px 10px 0px 0px;
            .check__icon {
              display: block;
            }
            .check__icon__disable {
              display: none;
            }
          }
          .Remember__label {
            font-size: 15px;
          }
          .policy__link {
            font-size: 16px;
            color: #003cb4;
            text-decoration: none;
          }
        }
      }
      .forgot__password__block {
        .forgot__password__text {
          color: #5A68EC;
          text-decoration: none;
          cursor: pointer;
          font-size: 15px;
          transition: all .3s ease-in-out;
          -webkit-transition: all .3s ease-in-out;
          &:hover{
            color: #2c3ee7;
          }
        }
      }
    }
    
    .signin__button {
      background: #DC3A1A 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border-radius: 5px;
      color: white;
      outline: none;
      border: none;
      width: 100%;
      font-size: 16px;
      font-family: 'SSTbold';
      font-weight: bold;
      line-height: 1;
      padding: 11px 15px;
      &:hover{
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
    }
    .or__block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      margin: 20px 0px;
      .mid__line {
        height: 1px;
        width: 100%;
        background-color: #e4e4e4;
        z-index: 0;
        transform: translateY(-20px);
      }
      .or__text__block {
        background-color: #f6f6f6;
        width: fit-content;
        padding: 8px;
        border-radius: 50%;
        z-index: 1;
        .or__text {
          font-size: 12px;
         font-family: 'SSTbold';
font-weight: bold;
          margin: 0px;
        }
      }
    }
    .getOtp__button {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border: 2px solid #000000;
      border-radius: 5px;
      color: black;
      outline: none;
      width: 100%;
      font-size: 16px;
      font-family: 'SSTbold';
      font-weight: bold;
      padding: 7px 10px;
      margin: 0px 0px 10px;
      &:hover{
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
      &:disabled{
        cursor: not-allowed;
        transform: none;
        box-shadow: 0px 3px 6px #00000005;
        background: #707070;
      }
    }
    .google__signin__button {
      background: #ff3d2b 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border-radius: 5px;
      color: white;
      outline: none;
      border: none;
      width: 100%;
      font-size: 15px;
      font-family: "SSTregular";
      padding: 10px 15px;
      margin: 0px 0px 10px;
      display: flex;
      align-items: center;
      .signin__button__icon {
        margin: 0px 20px 0px 0px;
        height: 22px;
      }      
      &:hover{
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
    }
    .facebook__signin__button {
      background: #2c5a98 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border-radius: 5px;
      color: white;
      outline: none;
      border: none;
      width: 100%;
      font-size: 15px;
      font-family: "SSTregular";
      padding: 10px 15px;
      margin: 0px 0px 10px;
      display: flex;
      align-items: center;
      .signin__button__icon {
        margin: 0px 20px 0px 0px;
        height: 22px;
      }
      &:hover{
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
    }
    .apple__signin__button {
      background: #101010 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border-radius: 5px;
      color: white;
      outline: none;
      border: none;
      width: 100%;
      font-size: 15px;
      font-family: "SSTregular";
      padding: 10px 15px;
      margin: 0px 0px 10px;
      display: flex;
      align-items: center;
      .signin__button__icon {
        margin: 0px 20px 0px 0px;
        height: 22px;
      }
      &:hover{
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
    }
    .loginModel__footer {
      padding: 10px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      .footer__text {
        font-size: 14px;
       font-family: 'SSTbold';
font-weight: bold;
        margin: 0px;
      }
      .footer__link__text {
        color: #ff4f04;
        font-size: 15px;
        font-family: 'SSTbold';
        font-weight: bold;
        margin: 0px 0px 0px 5px;
        cursor: pointer;
        &:hover{
          color: #cdcdcd;
        }
      }
    }
  }
}


/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {
 
  .loginModel__container {
    .loginModel__content{
      .main__form__field__block {
        .field__block{
          a{
            right: auto;
            left: 12px;
          }
        } 
        .field__itembox {
          .field__labelbox{
            left: auto;
            right: 12px;
            .heading7{
              font-family: 'SSTArabicMedium' !important;
            }
          }
          a{
            right: auto;
            left: 12px;
          }
          .form__field[name="password"],
          .form__field[name="username"]{
            direction: ltr;
            text-align: right;
          }
        }
      }
       
      .remember__and__forget__password__block {
        .main__remember__block {
          width: 50%;
          @media screen and (max-width:767px){ width: 40%; }

          .remember__block {
            .inner__Remember__block{
              margin: 2px 0px 0px 10px;
            }
          }
        }
      }
      .or__block {
        .or__text__block{
          padding: 8px 13px;
        }
      }
      .getOtp__button,.or__text__block .or__text {
        font-family: 'SSTArabicBold';
      }
    } 
  }

}
/*-------------------------- Arabic view End ------------------------*/

.main__policy__check__block {
  display: flex;
  gap: 16px;
}