.ship__green__step__block {
    padding-bottom: 20px;

    ul {
        list-style: none;
        margin: 0;
        align-items: center;
        justify-content: center;
        padding: 0;

        li {
            width: 25%;
            text-align: center;
            position: relative;
            z-index: 1;
            @media screen and (max-width:992px) {
                
            }
            @media screen and (max-width:580px) {
                height: 95px;
            }


            &.active {
                fill: none;
                border: none !important;

                label {
                    color: #26A541;
                }

                .circle {
                    background: #26A541;

                    &:before {
                        background: #26A541;
                        @media screen and (max-width:580px) {
                            top: 45%;
                        }
                    }

                    &::after {
                        border-color: #26A541;
                        @media screen and (max-width:580px) {
                            top: 43%;
                        }
                    }
                }
            }

            label {
                font-weight: 600;
                color: #C8C8C8;
                font-size: 16px;
            }

            span.ship__date {
                color: #202020;
                // padding-bottom: 20px;
                display: block;
                min-height: 23px;
                @media screen and (max-width:580px) {
                    min-height: 18px;
                }
            }

            .circle {
                height: 27px;
                width: 27px;
                background: #F2F2F2;

                margin: 10px auto;
                border-radius: 100%;
                // border: 1px solid #f2f2f2;
                z-index: 0;

                &::after {
                    position: absolute;
                    height: 40px;
                    width: 40px;
                    border: 2px solid #f2f2f2;
                    content: "";
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    border-radius: 100%;
                    background: #fff;
                    z-index: -1;
                    @media screen and (max-width:580px) {
                        top: 44% !important;
                    }
                }

                &:before {
                    position: absolute;
                    content: "";
                    height: 4px;
                    width: 100%;
                    background-color: #F2F2F2;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    z-index: -1;

                    @media screen and (max-width:580px) {
                        top: 45%;
                    }

                }
            }
        }
    }
}

@media(max-width:991px) {
    .ship__green__step__block ul li .circle {
        height: 14px;
        width: 14px;
    }

    .ship__green__step__block ul li .circle::after {
        height: 25px;
        width: 25px;
        top: 51%;
    }

    .ship__green__step__block ul li span.ship__date,.ship__green__step__block ul li label {
        font-size: 12px;
    }
    .orderDetails__container .orderDetails__block .orderDetails__inner__block .od__order__track__map__block .od__order__track__title__block .od__order__track__title__text{
        flex-wrap: wrap;        
    }
    .od__order__track__title__text .heading4{
        font-size: 19px;
    }
    .mo__product__block__new .mo__product__middle__block{
        margin-top: 20px;
    }
    .orderDetails__container .orderDetails__block .orderDetails__inner__block .od__order__track__map__block .od__order__track__title__block{
        width: 100%;
        padding: 10px;
    }
}
@media(max-width:375px) {
    .ship__green__step__block ul li .circle {
        height: 0;
        width: 0;
    }
    .ship__green__step__block ul li span.ship__date{
        margin-top: 10px;
    }
    .ship__green__step__block ul li {
        width: 100%;
        text-align: left;
    }
    .ship__green__step__block ul{
        flex-wrap: wrap;
    }
    .ship__green__step__block ul li .circle:before{
        height: 100%;
        width: 2px;
    }
    .myOrders__container .myOrders__block{
        padding: 0;
    }
}