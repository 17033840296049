.notify__modal{
    .modal-dialog{
        top: 50%;
        transform: translateY(-50%) !important;
    }
    .modal-header{
        padding: 15px 20px;
        border-bottom: none;
        position: relative;
        display: flex;
        justify-content: flex-end;  

        @media screen and (max-width:580px){
            padding: 15px 15px;
        }
        .cancel__button{ cursor: pointer;}          
    }
    .modal-body{
        padding: 1rem 6rem 6rem;

        @media screen and (max-width:1280px){
            padding: 1rem 5rem 5rem;
        }
        @media screen and (max-width:992px){
            padding: 1rem 4rem 4rem;
        }
        @media screen and (max-width:580px){
            padding: 1rem 2.5rem 2.5rem;
        }
        .heading3{
            text-align: center;
            font-size: 26px;
            margin-bottom: 5px !important;

            @media screen and (max-width:992px){ font-size: 24px; }

            @media screen and (max-width:580px){ font-size: 22px; }
        }
        .heading6 {

            @media screen and (max-width:580px){ font-size: 16px; }
        }
        .text3{
            color: #8c8e91;

            @media screen and (max-width:580px){ font-size: 14px; }
        }
        .notify__login__icon, .notify__success__icon {
            font-size: 9rem;
            color: #dc3a1a;

            @media screen and (max-width:992px){ font-size: 7rem; }

            @media screen and (max-width:580px){ font-size: 5rem; }
        }
        .main__form__field__block{
            width: 100%;
            margin-bottom: 25px;
            display: inline-block;
            .form__field, .profile__mobile__form__field {
                width: 100%;
                border: 1px solid #c8c8c8;
                font-size: 16px;
                padding: 7px 15px;
                font-family: "SSTregular";
                border-radius: 5px;
            }
            .profile__mobile__form__field{
                padding-left: 50px;
            }

            .react-tel-input {
                .selected-flag {
                    .flag {
                        @media screen and (max-width:580px) {
                            left: 50%;
                            transform: translate(-50%,-50%);
                            margin-top: 0;
                        }
                        .arrow {
                            @media screen and (max-width:580px) {
                                position: relative;
                                top: 50%;
                                /* margin-top: -2px; */
                                left: 50%;
                                transform: translate(-50%,-50%);    
                                background-color: transparent;
                                border-top: none;
                                border: none;
                            }                        
                        }
                    }
                    
                }
            }
            
        }

        .notify__add__btn{
            background-color: #dc3a1a;
            color: #ffffff;
            text-decoration: none;
            font-size: 21px;
            font-weight: 600;
            transition: all 0.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;
            &:hover{                
                opacity: 1;
                -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
            }
            @media screen and (max-width:580px){
                font-size: 17px;
                line-height: 1.2;
            }
        }
        .notify__close{
            background-color: #8c8e91;
            color: #ffffff;
            text-decoration: none;
            font-size: 21px;
            font-weight: 600;
            transition: all 0.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;
            &:hover{
                opacity: 1;
                -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
            }
            @media screen and (max-width:580px){
                font-size: 17px;
                line-height: 1.2;
            }
        }
    }
    
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

    .notify__modal .modal-body {
        .main__form__field__block P{
            .profile__mobile__form__field{
                padding-left: 15px;
                padding-right: 50px;
            }
        }
    }

}

/*-------------------------- Arabic view End ------------------------*/