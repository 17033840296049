.pd__productOne__block {
    width: 220px !important;
    margin: 10px 15px 10px 0px;
    background-color: #ffffff;
    text-decoration: none;
    color: #000000;
    padding: 10px 10px;
    transition: all 0.3s;
    position: relative;

    @media screen and (max-width:580px) {
        width: 200px !important;
        margin: 0px 0px 10px 0px;
        padding: 8px;
    }
  &:hover{
    box-shadow: 0px 0px 9px #d9d9d9;
  }

  .Probox__discount__labelBox{
    position: absolute;
    max-width: 85%;
    height: auto;
    left: 0px;
    top: 0px;
    z-index: 2;
  }
  .product__pics__area{
    position: relative;
    .productOne__image__block {
      width: 200px;
      height: 200px;
      overflow: hidden;
      margin: 0px auto;
  
      @media screen and (max-width:580px) {
        width: 200px;
        max-width: 100%;
        height: 160px;
      }
      .productOne__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
        @media screen and (max-width:580px) {
          max-height: 140px;
          max-width: max-content;
          margin: 0px auto;
        }
      }
    }
    .soldOutLable {
      position: absolute;
      width: 100%;
      background: hsla(0,0%,85.1%,.76);
      height: 30px;
      top: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 21px;
      color: rgba(0,0,0,.72);
      text-transform: uppercase;
      @media screen and (max-width:480px){
        top: 35%;
      }
    }
    .cardLabel {
      display: flex;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      z-index: 2;
      img {
        border-radius: 0 !important;
        max-width: 25% !important;
        max-height: 25% !important;
      }
  
      &.left_top {
        justify-content: flex-start;
        align-items: flex-start;
      }
      &.center_top {
        justify-content: center;
        align-items: flex-start;        
      }
      &.right_top {
        justify-content: flex-end;
        align-items: flex-start;
      }
      &.left_middle {
        justify-content: flex-start;
        align-items: center;        
      }
      &.center_middle {
        justify-content: center;
        align-items: center;
        img {
          max-height: 50% !important;
          max-width: 50% !important;
        }
      }
      &.right_middle {
        justify-content: flex-end;
        align-items: center;        
      }
      &.left_bottom {
        justify-content: flex-start;
        align-items: flex-end;
      }
      &.center_bottom {
        justify-content: center;
        align-items: flex-end;        
      }
      &.right_bottom {
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
  }
  
  .productOne__name__favourite {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 8px 0px 0px 0px;
    
    .productOne__name {
      font-family: 'SSTbold';
      font-weight: bold;
      margin: 0px;
      // height: 55px;
      // overflow: hidden;
      text-decoration: none;
      color: #000000;
      font-size: 14px;
      line-height: 1.3;
      margin-bottom: 8px;
    }
    .productOne__favourite__icon {
      cursor: pointer;
      display: block;
      @media screen and (max-width:580px) {
        width: 20px;
      }
    }
    .productOne__favourite__icon__disable {
      display: none;
    }
  }
  .rating__block {
    padding: 0px;
    margin: 0px 0px 10px;
    .bGVcoD {
      padding: 0px;
    }
  }
  .prize__block {
    display: flex;
    margin-bottom: 8px;
    min-height: 40px;    
    flex-direction: column;    
    justify-content: flex-end;
    span {
      vertical-align: text-top;      
      // &:first-child { margin-right: 8px !important; }
      // &:last-child { margin-left: 0px !important; margin: 0px !important; }
      &.text4{
        position: relative;
        width: fit-content;
        line-height: 1.2;
        color: #333333 !important;

        &::after{
          content: " ";
          display: block;
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #666666;
          -webkit-transform: translateY(-8px) rotate(-6deg);
          transform: translateY(-8px) rotate(-6deg);            
          bottom: 0;
          left: 0;
        }
      }
      &.heading7 {
        color: #000000;
      }
    }
    .old__prize {
      font-family: "SSTregular";
      font-size: 18px;
      text-decoration-line: line-through;
      color: #c8c8c8;
    }
    .new__prize {
      font-family: 'SSTbold';
      font-weight: bold;
      font-size: 18px;      
    }
  }
  .addToCart__button {
    border-radius: 5px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'SSTbold';
    font-weight: bold;
    letter-spacing: 0.85px;
    padding: 8px 10px;
    background-color: #DC3A1A;    
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    .addToCart__icon{
      margin: 0px 10px 0px 0px; 
      width: 19px;
      @media screen and (max-width:1420px) {
        width: 18px;
      }
      @media screen and (max-width:1280px) {
        width: 16px;
        margin: 0px 8px 0px 0px; 
      }
      @media screen and (max-width:580px) {
        margin: 0px 8px 0px 0px;
        width: 15px;
      }
    }
    .spinner-border{
      margin-left: 10px !important;
      @media screen and (max-width:1420px) {
        margin-left: 8px !important;
        width: 0.8rem;
        height: 0.8rem;
      }  
      @media screen and (max-width:1280px) {
        margin-left: 6px !important;
        width: 0.7rem;
        height: 0.7rem;
      }     
    }
    @media screen and (max-width:1366px) {
        font-size: 14px;
    }
    @media screen and (max-width:580px) {
      padding: 8px 10px;
      font-size: 13px;      
    }
    &:hover,&:focus {
      -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
      box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);      
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      opacity: 0.8;
    }
    .btn__spinner{
      min-height: 22.5px;
      @media screen and (max-width:1366px) {
        min-height: 21px;
      }
      @media screen and (max-width:580px) {
        
      }
    }
    // &:focus {
    //   -webkit-box-shadow: 0 5px 5px -4.8px rgb(32 32 32 / 60%);
    //   box-shadow: 0 5px 5px -4.8px rgb(32 32 32 / 60%);      
    //   transform: translateY(0px);
    //   -webkit-transform: translateY(0px);
    //   transition: all 0.2s ease-in-out;
    //   -webkit-transition: all 0.2s ease-in-out;
    // }
  }
  .outofStock__button{
    border-radius: 5px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'SSTbold';
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0.85px;
    padding: 8px 12px;
    background-color: #a7a7a7;    
    text-transform: uppercase;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    @media screen and (max-width:1366px) {
        font-size: 14px;
    }
    @media screen and (max-width:580px) {
      padding: 8px 10px;
      font-size: 13px;      
    }
  }
}
.size__button__block {
  margin: 0px 0px 5px 0px;
  min-height: 54px;

  .size__button__listing {
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    padding: 10px 0px;
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    align-items: center;
    span{
      display: block;
      margin: 0px 8px 0px 0px;
    }
    button{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000000;
        border-radius: 3px;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
    }

    .size__button__active {
      font-size: 12px;
      font-family: 'SSTmedium';
      border-radius: 3px;
      color: #ffffff;
      padding: 6px 8px;
      // line-height: 1.26;
      // background-color: #000000;
      border: 1px solid #000000;
      outline: none;
      border: none;
      margin: 0px 8px 0px 0px;
      z-index: 2;
      position: relative;
      &:before{
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        z-index: -1;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
    .size__button {
      font-size: 12px;
      border: 1px solid #707070;
      border-radius: 3px;
      padding: 6px 8px;
      background-color: #ffffff;
      outline: none;
      margin: 0px 8px 0px 0px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }      
}
@media(max-width:767px) {
  .size__button__block .size__button__listing .size__button__active {
      padding: 6px 5px;}
  .size__button__block .size__button__listing .size__button {
      padding: 6px 5px;}
  }
.notifyMe__button{
  border-radius: 5px;
  color: #ffffff;
  font-size: 15px;
  font-family: 'SSTbold';  
  text-transform: uppercase;
  letter-spacing: 0.85px;
  padding: 8px 15px;
  line-height: 1.5;                
  background-color: #FE8B21;  
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  
  &:hover,&:focus {
    -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
    box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    opacity: 0.8;
  }  
  
  @media screen and (max-width:1366px) {
      font-size: 14px;
  }
  @media screen and (max-width:580px) {
    padding: 8px 10px;
    font-size: 13px;      
  }
  // @media screen and (max-width:480px) {
  //   font-size: 13px;
  //   padding: 5px 10px;
  //   line-height: 1;
  //   letter-spacing: 0.5px;
  // }

  img {
    margin: 0px 8px 0px 0px;
    height: 14px;
    width: auto;
    vertical-align: sub;     
    
    @media screen and (max-width:480px) {
      margin: 0px 4px 0px 0px;
      height: 13px;
    }
  }
  .btn__spinner{
    min-height: 22.5px;
    @media screen and (max-width:1366px) {
      min-height: 21px;
    }
    @media screen and (max-width:580px) {
      
    }
  }
}
.productOne__block {
  width: 260px;
  margin: 0px 25px 0px 0px;
  background-color: #ffffff;
  text-decoration: none;
  color: #000000;

  .product__pics__area{
    .productOne__image__block {
      width: 200px;
      height: 200px;
      overflow: hidden;
      .productOne__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
  }

  .productOne__name__favourite {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px 0px 0px 0px;
    margin-bottom: 8px;
    p{
      width: calc(100% - 0px);
      vertical-align: top;
      padding-right: 20px;
      font-size: 14px !important;
    }
    .productOne__name {
     font-family: 'SSTbold';
      font-weight: bold;
      font-size: 14px;
      line-height: 1.3;
      margin: 0px;
      min-height: 70px;
      overflow: hidden;
      text-decoration: none;
      color: #000000;
    }
    .productOne__favourite__icon {
      display: block;
      cursor: pointer;
      width: 20px;
      &.empty_icon{
        opacity: 0.5;
      }
    }
    .productOne__favourite__icon__disable {
      display: none;
    }
  }
  .rating__block {
    padding: 0px;
    .bGVcoD {
      padding: 0px;
    }
  }
  .prize__block {
    display: flex;
    .old__prize {
      font-family: "SSTregular";
      font-size: 18px;
      text-decoration-line: line-through;
      color: #c8c8c8;
    }
    .new__prize {
     font-family: 'SSTbold';
font-weight: bold;
      font-size: 18px;

      margin: 0px 0px 0px 5px;
    }
  }
  .addToCart__button {
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
    font-family: 'SSTbold';
    font-weight: bold;
    letter-spacing: 0.85px;
    padding: 20px 0px;
    background-color: #DC3A1A;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    .addToCart__icon{
      margin: 0px 20px 0px 0px; 
      @media screen and (max-width:1420px) {
        width: 17px;
      }
      @media screen and (max-width:1280px) {
        width: 16px;
        margin: 0px 8px 0px 0px; 
      }
    }
    .spinner-border{
      margin-left: 10px !important;
      @media screen and (max-width:1420px) {
        margin-left: 8px !important;
        width: 0.8rem;
        height: 0.8rem;
      }
    }
    @media screen and (max-width:1420px) {
      width: 18px;
    }
    @media screen and (max-width:1280px) {
      width: 16px;
      margin: 0px 8px 0px 0px; 
    }    
  }
  .outofStock__button{
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
   font-family: 'SSTbold';
    font-weight: bold;
    letter-spacing: 0.85px;
    padding: 20px 0px;
    background-color: #cecece;
    text-align: center;
    text-transform: uppercase;
  }
}



.productOne__block:last-child {
  margin: 0px 16px 0px 0px;
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  
  .pd__productOne__block{
    .prize__block{
      align-items: flex-start;
      .heading7{
        margin: 0px 5px 0px 0px;
      }
      span{
        // &:first-child { margin-right: 0px !important; margin-left: 8px !important; }
        
      }
      
    }
    .productOne__name__favourite {
      .productOne__name{
        font-family: 'SSTArabicBold';
      }
    }
    .addToCart__button,.outofStock__button,
    .notifyMe__button,.pre-order {
      font-family: 'SSTArabicBold';
      font-weight: bold;
      font-size: 14px;
    }
    .addToCart__button {
      flex-direction: row-reverse;
      .addToCart__icon {
          margin: 0px 0px 0px 10px;     
          @media screen and (max-width:480px){
            margin: 0px 0px 0px 6px;
          }           
      }
    }    
    .notifyMe__button,.outofStock__button {
      flex-direction: row-reverse;
      @media screen and (max-width:1200px) {
        font-size: 14px;
      }
      img{
        margin: 0px 0px 0px 8px;
        
        @media screen and (max-width:480px){
          margin: 0px 0px 0px 6px;
        }
      }                  
    }

    .product__delivery__option__area {
      direction: rtl;
      .product__delivery__itembox {
        direction: rtl;
      }
    }
  } 
}

/*-------------------------- Arabic view End ------------------------*/
