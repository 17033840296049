.allCategory {
  background-color: #f5f4f4;
  padding: 0 12px 0px;

  .productSeven__block .productSeven__header__block .productSeven__quality__favourite__block {
    justify-content: end;

    img.productSeven__quality__icon {
      margin-right: 10px;
    }
  }

  .section__title {
    font-size: 26px !important;
  }

  .productSeven__block .productSeven__header__block {
    background: transparent;
  }

  .productSeven__block .productSeven__size__button__block {
    padding: 10px 0px 10px 0px;
    border-top: 1px solid #D9D9D9;
    border-bottom: 1px solid #D9D9D9;
  }

  .productSeven__block .productSeven__series {
    display: none;
  }

  .carousel__container .section__title {
    text-align: left;
    font-size: 40px;
  }

  .carousel__block {
    height: auto !important;
  }

  .main__product__container__block{
    background-color: transparent !important;
    @media screen and (max-width:576px) {
      padding: 0px !important;
    }
    .slick-slider{
      padding: 15px 10px !important;
      @media screen and (max-width:576px) {
        padding: 10px 0px !important;
      }
      .slick-list{
        .slick-track{
          display: flex;          
          align-items: flex-start;
          position: relative;
          @media screen and (max-width:992px) {
            padding: 0px 10px;
          }
          .slick-slide{
            width: 260px;
            flex: 0 0 260px;
            max-width: 260px;
            margin: 10px 15px 10px 0px;
            
            @media screen and (max-width:992px) {
                width: 250px;
                flex: 0 0 250px;
                max-width: 250px;
            }
            @media screen and (max-width:576px) {
                width: 244px;
                flex: 0 0 244px;
                max-width: 244px;
            }

            .productNine__image__slider{
              .slick-slider{
                padding: 0px !important;
                .slick-list{
                  .slick-track{
                    .slick-slide{
                      width: 100%;
                      flex: 0 0 100%;
                      max-width: 100%;
                      margin: 0px;
                    }
                  }
                }
              }
            }
            
          }
        }
      }
      .left__arrow {
        @media screen and (max-width:576px) {
          left: -4.5% !important;  
        }        
      }
      .right__arrow{
        @media screen and (max-width:576px) {
          right: -4.5% !important; 
        }
      }
    }
  }

  .main__bestSellers__block {
    width: 1420px;
    padding: 0px;
    margin: 0px auto;
    .bestSellers__block{
      .productNine__block{        
        margin: 10px 20px 10px 0px;
        flex: 0 0 23.9%;
        width: 23.9%;
        @media screen and (max-width:1200px){
          flex: 0 0 25.9%;
          width:25.9%;
        }
        @media screen and (max-width:1050px){
          flex: 0 0 28.9%;
          width:28.9%;
        }
        @media screen and (max-width:992px){
          flex: 0 0 31.9%;
          width:31.9%;
        }
        @media screen and (max-width:850px){
          flex: 0 0 37.9%;
          width:37.9%;
        }
        @media screen and (max-width:700px){
          flex: 0 0 48.5%;
          width:48.5%;
        }
        @media screen and (max-width:580px){
          flex: 0 0 59.9%;
          width:59.9%;
        }
        @media screen and (max-width:450px){
          flex: 0 0 90.9%;
          width:90.9%;
        }
      }
    }

  }

  .main__budgetTv__block {
    width: 1420px;
    padding: 0px;
    margin: 0px auto;
    margin-top: 50px;

    &:last-child:empty {
      display: none;
    }
    .category__banner__container{
      margin: 0px;
      padding-bottom: 49px;
    }
    .budgetTV__block{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      overflow-x: hidden;
      scroll-behavior: smooth;
      position: relative;
      .productNine__block{        
        margin: 10px 20px 10px 0px;
        flex: 0 0 23.9%;
        width: 23.9%;
        @media screen and (max-width:1200px){
          flex: 0 0 25.9%;
          width:25.9%;
        }
        @media screen and (max-width:1050px){
          flex: 0 0 28.9%;
          width:28.9%;
        }
        @media screen and (max-width:992px){
          flex: 0 0 31.9%;
          width:31.9%;
        }
        @media screen and (max-width:850px){
          flex: 0 0 37.9%;
          width:37.9%;
        }
        @media screen and (max-width:700px){
          flex: 0 0 48.5%;
          width:48.5%;
        }
        @media screen and (max-width:580px){
          flex: 0 0 59.9%;
          width:59.9%;
        }
        @media screen and (max-width:450px){
          flex: 0 0 90.9%;
          width:90.9%;
        }
      }
    }

  }

  .three__ad__banner__container {
    width: 1420px;
    padding: 0px;
    margin: 0px auto;

    // margin-bottom: 49px;
    .three__ad__banner__block {


      .section__title {
        font-family: 'SSTbold';
        font-weight: bold;
        font-size: 40px;
        text-align: left;
      }

      .inner__three__ad__banner__block {
        padding: 0px 15px;

        .ad__banner__block {
          .ad__banner__image {
            width: 100%;
            border-radius: 5px;
            @media(max-width:576px){
              max-height: 260px;
            }
          }
        }
      }
    }
  }
  .productNine__block {
    .productNine__content {
      .delivery-time-area{
        // @media screen and (max-width:768px) {
        //   display: flex;
        //   flex-direction: row;
        //   flex-wrap: wrap;
        //   align-items: flex-end;
        //   min-height: 52px;
        // }
        // .text4,.heading7{
        //   @media screen and (max-width:992px) {
        //     font-size: 11px;
        //   }
        //   @media screen and (max-width:768px) {
        //     display: inline-flex;            
        //     align-items: self-end;
        //     height: 14px;
        //   }
        // }
      } 
    }
  }
}

@media screen and (max-width: 1440px) {

  .main__bestSellers__block,
  .main__budgetTv__block,
  .category__screensize__block,
  .three__ad__banner__container {
    width: 90% !important;
    max-width: 90%;
  }
}

@media(max-width:992px) {

  .main__bestSellers__block,
  .main__budgetTv__block,
  .category__screensize__block,
  .three__ad__banner__container {
    // width: 90% !important;
    min-width: 100%;
  }
}

@media(max-width:991px) {
  .allCategory .section__title {
    font-size: 24px !important;
  }

  .allCategory .main__budgetTv__block {
    margin-top: 20px;
  }

  .allCategory .three__ad__banner__container .three__ad__banner__block .inner__three__ad__banner__block {
    padding: 0 15px;
  }
}

@media(max-width:767px) {
  .allCategory .section__title {
    font-size: 20px !important;
  }
  
}

/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {

  .allCategory {
    .main__budgetTv__block,.main__bestSellers__block {
      .budgetTV__block,.bestSellers__block {
        .productNine__block {
          margin: 10px 0px 10px 20px;          
          .productNine__content {
            .productNine__image__slider{
              .slick-prev{
                left: -25px;
              }
              .slick-next{
                right: -25px;
                left: auto;
              }
            }
          }
        }
      }
    }
    .main__product__container__block {
      .slick-slider {
        .slick-list {
          .slick-track {
            .slick-slide {
              direction: rtl;
            }
          }
        }
      }
    }
  }
  
  
  .category__screensize__block {
    .section__title{
      text-align: right;
    }
  }
  .allCategory {
    .carousel__container{
      .section__title{
        text-align: right;
        font-family: 'SSTbold';
      }
    }
    .three__ad__banner__container {
      .three__ad__banner__block {
        .section__title{
          text-align: right;
        }
      }
    } 
  }
  

}

/*-------------------------- Arabic view End ------------------------*/
.skeleton-loader-comp {
  margin: 0 auto;
  width: calc(100% - 30px);

  .skeleton-card-container {
    display: flex;
    gap: 25px;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
  }
}