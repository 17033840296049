$mobileBreakpoint: 1200px;

.wrapper {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3), 0 0 10px 0 rgba(0, 0, 0, 0.055);

  .inner {
    display: flex;
    width: 100%;
    max-width: 730px;
    border-radius: 12px;
    background-color: #fff;
    padding: 32px;
    flex-direction: column;
    overflow: hidden;
    max-height: 95%;
    overflow-y: scroll;
    gap: 15px;

    .header {
      flex: 1;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;

      .title {
        flex: 1;
        font-size: 22px;
        align-items: left;
        margin: 0 10px;
        font-weight: 700;
      }
    }

    .messageWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .sectionWrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .charts {
        display: flex;
        flex-direction: row;
        gap: 15px;

        .chart {
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
            font-size: 14px;
            font-weight: unset;
          }
        }
      }
    }

    .bottomWrapper {
      display: flex;
      flex-direction: row;
      flex: 1;
      gap: 10px;

      .howItWorks {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .noteOne {
          display: flex;
          font-size: 14px;
          flex-direction: row;

          img {
            margin: 0 5px;
          }
        }
      }
    }
  }

  .example {
    display: flex;
    flex-direction: column;
    flex: 0.5;

    .product {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 10px 0;
      padding: 10px;
      border-radius: 4px;
      border: solid 1px #27272a;
      justify-content: space-between;
      gap: 10px;

      .top {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;

        .currency {
          font-size: 14px;
        }

        span {
          font-size: 10px;
          color: #c5c5c5;
        }

        .productImage {
          border-radius: 4px;
        }

        .totalPrice {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
      }

      .bottom {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .summary {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .installmentPrice {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
          }

          span {
            font-size: 12px;
          }

          span:nth-child(2) {
            font-size: 12px;
            font-weight: unset;
            color: #c5c5c5;
          }
        }
      }
    }

    .breakdown {
      display: flex;
      flex-direction: column;

      span {
        font-size: 15px;
      }

      .installment {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

        .scheduledDay {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 14px;
    }

    .payments {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: row;
      gap: 10px;
    }
  }

  span {
    font-size: 18px;
    font-weight: 500;
  }

  p {
    font-size: 14px;
  }

  .dot {
    height: 10px;
    width: 10px;
    background-color: #27272a;
    border-radius: 50%;
    display: inline-block;
  }

  .dottedBorder {
    border: 1px solid #fff;
    background-color: #27272a;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }

  .dotEmptyLine {
    height: 6px;
    width: 6px;
    margin: 1px 2px;
    background-color: #27272a;
    border-radius: 50%;
    display: inline-block;

    &.future {
      background-color: #d8d8d8;
    }
  }

  table {
    border: 1px solid;
    border-radius: 4px;
    font-size: 15px;
    padding: 10px;
    width: 100%;
    border-spacing: 0 0.5rem;

    tr > td:first-child {
      text-align: left;

      [dir='rtl'] & {
        text-align: right;
      }
    }

    tr > th:first-child {
      text-align: left;

      [dir='rtl'] & {
        text-align: right;
      }
    }

    tr td:nth-of-type(2) {
      text-align: right;

      [dir='rtl'] & {
        text-align: left;
      }
    }

    tr th:nth-of-type(2) {
      text-align: right;

      [dir='rtl'] & {
        text-align: left;
      }
    }
  }
}

@media only screen and (max-width: ($mobileBreakpoint - 1)) {
  .wrapper {
    .inner {
      max-height: unset;
      display: flex;
      flex-direction: column;
      gap: 20px;

      .bottomWrapper {
        flex-direction: column;
        .example {
          margin-top: 10px;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      span {
        font-size: 10px;
      }

      .payments {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
      }
    }
  }
}
