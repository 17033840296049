.productTwo__block:hover {
  text-decoration: none;
  color: #000000;
}
.productTwo__block {
  width: 260px;
  margin: 10px 30px 10px 0px;
  background-color: #ffffff;
  text-decoration: none;
  color: #000000;
  padding: 5px;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 9px #d9d9d9;
  }

  .productTwo__header__block {
    background-color: #f1f4f9;
    padding: 14px;
    margin: 0px 0px 10px;
    width: 100%;
    .productTwo__quality__favourite__block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      .productTwo__quality__icon {
        width: 22px;
      }

      .productTwo__favourite__icon {
        display: block;
        cursor: pointer;
      }
      .productTwo__favourite__icon__disable {
        display: none;
      }
    }
    .productTwo__image__block {
      width: 272px;
      height: 252px;
      max-width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .productTwo__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
      }
    }
    .productTwo__compare__block {
      display: flex;
      align-items: center;
      margin: 10px 0px 0px;
      .productTwo__compare__input__check {
        accent-color: #000000;
        height: 15px;
        width: 15px;
        border-radius: 10px;
        margin: 0px 10px 0px 0px;
        // border: 15px solid #303030;
        text-transform: uppercase;
      }
      .productTwo__compare__text {
        font-size: 14px;
        font-family: "SSTregular";
        margin: 0px;
      }
    }
  }

  .productTwo__name {
    font-family: "SSTbold";
    font-weight: bold;
    font-size: 14px;
    line-height: 1.3;
    margin: 0px 0px 10px 0px;
    height: 55px;
    line-height: 1.3;
    text-decoration: none;
    color: #000000;
  }
  .productTwo__series {
    font-family: "SSTregular";
    font-size: 14px;
    margin: 0px 0px 10px 0px;
    color: #808080;
    letter-spacing: 0.5px;
  }

  .rating__block {
    padding: 0px;
    margin: 0px 0px;
    .bGVcoD {
      padding: 0px;
    }
  }
  .productTwo__size__button__block {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px 10px 0px;

    button {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000000;
        border-radius: 3px;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
    }
    .productTwo__size__button__active {
      font-size: 12px;
      font-family: "SSTmedium";
      border-radius: 3px;
      color: #ffffff;
      padding: 6px 8px;
      border: 1px solid #000000;
      outline: none;
      // border: none;
      z-index: 2;
      position: relative;
      &:before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        z-index: -1;
      }
    }
    .productTwo__size__button {
      font-size: 12px;
      font-family: "SSTmedium";
      border: 1px solid #707070;
      border-radius: 3px;
      padding: 6px 8px;
      background-color: #ffffff;
      outline: none;
    }
  }
  .prize__block {
    display: flex;
    align-items: flex-end;
    .old__prize {
      font-family: "SSTregular";
      font-size: 18px;
      text-decoration-line: line-through;
      color: #c8c8c8;
    }
    .new__prize {
      font-family: "SSTbold";
      font-weight: bold;
      font-size: 18px;

      margin: 0px 0px 0px 5px;
    }
  }
  .addToCart__button {
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    font-family: "SSTbold";
    font-weight: bold;
    letter-spacing: 0.85px;
    padding: 8px 15px;
    background-color: #dc3a1a;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;

    @media screen and (max-width:580px) {
      padding: 8px 10px;
    }
    .addToCart__icon {
      margin: 0px 10px 0px 0px;
      width: 17px;
    }
    &:hover,
    &:focus {
      -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
      box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, 0.6);
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      opacity: 0.8;
    }
  }
  .outofStock__button {
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    font-family: "SSTbold";
    font-weight: bold;
    letter-spacing: 0.85px;
    padding: 8px 15px;
    background-color: #a7a7a7;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    @media screen and (max-width:580px) {
      padding: 8px 10px;
    }
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .productTwo__block{
    .productTwo__header__block{
      .productTwo__compare__block{
        .productTwo__compare__input__check{
          margin: 0px 0px 0px 10px;
        }
      } 
    } 
    .prize__block{
      .heading7{
        margin: 0px 5px 10px 0px;
      }
    }
    .addToCart__button,.outofStock__button,
    .notifyMe__button,.pre-order {
      font-family: 'SSTArabicBold';
      font-weight: bold;
      font-size: 14px;
    }
    .addToCart__button{
      .addToCart__icon{
        margin: 0px 0px 0px 10px;
      }
    }
  } 

}

/*-------------------------- Arabic view End ------------------------*/
