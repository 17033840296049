.user__credit__page__container {
    padding: 0px 12px 40px;
    min-height: 65vh;
    background-color: #f5f5f5;
    .user__credit__main__block {
        padding: 15px;
        width: 1420px;
        margin: 0px auto;
        background-color: #ffffff;

        @media screen and (max-width: 1450px) {
            width: 90% !important;
            max-width: 90% !important;
        }
        @media screen and (max-width: 992px) {
            width: 100% !important;
            max-width: 100% !important;
        }

        .user__credit__header{
            border-bottom: 1px solid #cdcdcd;
            padding: 10px 0px;
            margin-bottom: 20px;
        }
        .user__wallet__area{
            background-color: #dc3a1a;
            border-radius: 6px;
            padding: 16px 10px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 15px 0px;

            @media screen and (max-width:1200px) {
                padding: 12px 10px;
            }
            @media screen and (max-width:460px) {
                flex-direction: column;
                align-items: center;
            }
            .user__wallet__labelBox{
                flex: 0 0 40%;
                max-width: 40%;
                min-width: 450px;
                display: flex;
                align-items: center;

                @media screen and (max-width:992px) {
                    min-width:220px;
                }
                @media screen and (max-width:460px) {
                    justify-content: center;
                }
                svg {
                    color: #ffffff;
                    width: 35px;
                    height: 35px;

                    @media screen and (max-width:1200px) {
                        width: 26px;
                        height: 26px;
                    }
                    @media screen and (max-width:992px) { 
                        width: 22px;
                        height: 22px;
                    }
                }
                .user__wallet__labelText{
                    color: #ffffff;
                    display: inline-block;
                    margin: 0px;
                    font-size: 25px;
                    font-weight: 600;
                    margin-left: 5px;
                    @media screen and (max-width:1200px) { font-size: 23px; }
                    @media screen and (max-width:992px) {  font-size: 20px; }
                    @media screen and (max-width:460px) { justify-content: center; }
                    @media screen and (max-width:320px) { font-size: 18px; }
                }
            }
            .user__wallet__balanceAmount{
                .heading4{
                    color: #ffffff;
                    text-align: right;

                    @media screen and (max-width:1200px) { font-size: 23px !important; }
                    @media screen and (max-width:992px) {  font-size: 20px !important; }
                    @media screen and (max-width:320px) { font-size: 18px !important; }
                }
            }
        }

        .user__credit__listbox {
            .user__credit__itembox {
                padding: 10px 0px;
                border-top: 1px dashed #8c8c8c;

                .user__credit__timeArea{
                    text-align: center;
                    color: #b7b7b7;
                    font-size: 15px; 
                    margin-bottom: 0px;   
                    font-weight: 500;
                }
                .user__credit__details{
                    display: flex;
                    flex-direction: row;              
                    
                    @media screen and (max-width:580px) {
                        flex-wrap: wrap;
                        justify-content: flex-end;
                    }

                    .user__credit__info__itembox{
                        display: flex;
                        flex-direction: column;
                        flex: 0 0 20%;
                        max-width: 20%;
                        padding: 10px;
                        justify-content: flex-start;

                        @media screen and (max-width:992px) { 
                            flex: 0 0 21.5%;
                            max-width: 21.5%;
                            padding: 8px;
                        }
                        @media screen and (max-width:580px) {
                            flex: 0 0 37%;
                            max-width: 40%;
                        }
                        @media screen and (max-width:442px) {
                            flex: 0 0 34%;
                            max-width: 36%;
                        }
                        @media screen and (max-width:390px) {
                            flex: 0 0 33%;
                            max-width: 34%;
                        }
                        @media screen and (max-width:320px) {
                            flex: 0 0 48%;
                            max-width: 48%;
                        }
                        &:first-child{
                            flex: 0 0 12%;
                            max-width: 12%;
                            min-width: 160px;

                            @media screen and (max-width:992px) { 
                                flex: 0 0 12%;
                                max-width: 12%;
                                min-width: 100px;
                            }                            
                            @media screen and (max-width:580px) {
                                flex: 0 0 14%;
                                max-width: 14%;
                                min-width: 100px;
                            }
                            @media screen and (max-width:320px) {
                                flex: 0 0 100%;
                                max-width: 100%;
                                min-width: 100px;
                                align-items: center;
                            }
                        }
                        .credit_iconBox {
                            width: 80px;
                            height: 80px;
                            flex: 0 0 80px;
                            max-width: 80px;
                            border-radius: 6px;
                            position: relative;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.credit_cashtype{ background-color: #595959; }
                            &.credit_ordertype{ background-color: #dc3a1a; }

                            @media screen and (max-width:1200px) { 
                                width: 60px;
                                height: 60px;
                                flex: 0 0 60px;
                                max-width: 60px;
                            }

                            @media screen and (max-width:580px) { 
                                width: 50px;
                                height: 50px;
                                flex: 0 0 50px;
                                max-width: 50px;
                            }
                            img{
                                width: 40px;
                                height: 65px;
                                margin: 0px auto;
                                @media screen and (max-width:1200px) { 
                                    width: 30px;
                                    height: 55px;
                                }
                                @media screen and (max-width:992px) { 
                                    width: 26px;
                                    height: 50px;
                                }
                            }                            

                        }
                        label{
                            font-size: 18px;
                            color: #565656;
                            @media screen and (max-width:580px) {
                                font-size: 16px;
                                line-height: 1.1;
                            }
                            @media screen and (max-width:320px) {
                                font-size: 14px;
                                line-height: 1.1;
                            }
                        }
                        a.credit__orderNo__btn {
                            text-decoration: none;
                            color: #0091ff;
                            font-size: 15px;
                            font-weight: 500;
                            display: inline-block;
                            width: max-content;
                            &:hover{
                                color: #000000;
                            }
                        }
                        .heading7{
                            color: #1b860a;
                            @media screen and (max-width:580px) {
                                line-height: 1.1;
                            }
                        }
                    }

                }
            }
        }


    }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

    .user__credit__page__container {
        .user__credit__main__block {
            .user__wallet__area {
                .user__wallet__labelBox {
                    .user__wallet__labelText {
                        margin-left: 0px;
                        margin-right: 5px; 
                    }
                }
            }
            .user__credit__listbox {
                .user__credit__itembox {                    
                }
            }
        }
    }

}

/*-------------------------- Arabic view end ------------------------*/
