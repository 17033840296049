.discount__offer__labelbox {
    padding: 6px;
    background-color: #dc3a1a;
    color: #ffffff;
    text-align: center;
    border-radius: 6px;
    position: absolute;
    max-width: 25%;
    height: auto;
    left: 0px;
    top: 0px;
    z-index: 2;

    @media screen and (max-width:1430px) { max-width: 35%; }
    @media screen and (max-width:480px) { max-width: 27%; }
    @media screen and (max-width:280px) { max-width: 28%; }
    h6 {
        font-weight: bold;
        margin: 0px;
        line-height: 1;
    }
}
.mobile__product__detail__page{
    .discount__offer__labelbox {
        left: 15px;
        z-index: 2;
        @media screen and (max-width:280px) {
            left: 2px;
        }
        h6 { 
            font-size: 15px; 
            @media screen and (max-width:480px) {
                font-size: 14px;
            }
            @media screen and (max-width:280px) { font-size: 13px; }
        }
    }
}