.carousel__container {
    .carousel__block {
      width: 100%;
      padding: 0px;
      position: relative;
    }
    .section__title {
        font-size: 24px;
    }
    .arrow__block {
      z-index: 10;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1230px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      .arrow {
        filter: invert(1);
        cursor: pointer;
      }
      .arrow__disable {
          visibility: hidden;
      }
      .left__arrow {
        transform: translateX(-200%);
      }
      .right__arrow {
      }
    }
    .top__trending__block {
      // position: relative;
      display: flex;
      align-items: center;
      overflow-x: scroll;
      scroll-behavior: smooth;
      // transition: all 0.2s ease-in;
    }
  }
  
  // .carousel__container {
  //   .carousel__block {
  //     // width: 1190px;
  //     padding: 0px;
  //   }
  //   .section__title {
  //   }
  //   .inner__clients__block {
  //     // position: relative;
  //     // display: flex;
  //     // align-items: center;
  //     .arrow {
  //     }
  //     .left__arrow {
  //     }
  //     .product__block {
  //         width: 255px;
  //         margin: 0px 16px 0px 0px ;
  //         background-color: #303030;
  //       .product__image__block {
  //           height: 255px;
  //         .product__image {
  //           height: 100%;
  //           object-fit: cover;
  //         }
  //       }
  //       .product__name__favourite {
  //         .product__name {
  //         }
  //         .product__favourite__icon {
  //           display: block;
  //         }
  //         .product__favourite__icon__disable {
  //           display: none;
  //         }
  //       }
  //     }
  //   }
  // }
  