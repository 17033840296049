.product__highlight__container {
  display: flex;
  background: #f1f1f1;
  min-height: 380px;
  align-items: center;
  .product__highlight__block {
    // background: white;
    text-align: center;
    align-items: center;
    margin: auto;

    .product__highlight__image__block {
      width: 150px;
      height: 150px;
      background: white;
      border-radius: 50%;
      /* align-items: center; */
      /* text-align: center; */
      margin: auto;
      .product__highlight__image {
        width: 100px;
        height: 150px;
      }
    }
  }
}
