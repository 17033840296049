.bundleModal__comman {

    .modal-content {
        background-color: #f7f7fa;
        border-radius: 10px;

        .modal-header {
            border-bottom: none;
            padding: 1rem 1.7rem;
            @media screen and (max-width:480px) {
                padding: 1rem 1.1rem;
            }
            .modal-title {
                font-size: 17px;
            }

            .btn-close {
                font-size: 9px;
                border: 1px solid #777;
                border-radius: 50%;
                padding: 5px;
                margin: -0.5rem -0.1rem -0.5rem auto;
                @media screen and (max-width:992px) {
                    padding: 5px 6px;
                    margin-right: -15px;
                }
                @media screen and (max-width:480px) {
                    margin-right: -8px;
                }
            }
        }

        .modal-body {
            padding: 1rem 1.7rem;
            @media screen and (max-width:480px) {
                padding: 1rem 1.1rem;
            }
            .bundleCity {
                &.store__city__selection{
                    min-height: 200px;
                }
                .bundle_city_wrapper {
                    width: auto;
                    height: auto;

                    .search__input__block {
                        margin-bottom: 15px;

                        input {
                            border: none;
                            padding: 10px 10px;
                            font-size: 16px;
                            width: 100%;
                            border-radius: 8px;
                        }
                    }

                    .items {
                        padding: 15px;
                        background-color: #ffffff;
                        max-height: 200px;
                        overflow: scroll;
                        overflow-x: hidden;
                        border-radius: 8px;

                        @media screen and (max-width:480px) {
                            padding: 0px;
                        }

                        .loaction-item-box {
                            display: flex;
                            flex: 0 0 100%;
                            max-width: 100%;
                            justify-content: space-between;
                            align-items: center;
                            margin: 8px 2px;
                            padding: 2px 13px;
                            cursor: pointer;

                            @media screen and (max-width:480px) {
                                padding: 2px 10px;
                            }
                            label {
                                span {
                                    font-size: 16px;
                                    line-height: 1.3;
                                    color: #000000;
                                    font-weight: 500;
                                    @media screen and (max-width:480px) {
                                        font-size: 14px;
                                    }
                                }
                            }

                            // input[type='radio'] {
                            //     height: 18px;
                            //     width: 17px;
                            //     accent-color: #dc3a1a;
                            //     &::after{
                            //         content: " ";
                            //         display: block;
                            //         width: 50%;
                            //         height: 50%;
                            //         max-width: 9px;
                            //         max-height: 9px;
                            //         position: absolute;
                            //         left: 50%;
                            //         top: 50%;
                            //         transform: translate(-50%,-50%);
                            //         padding: 0px;
                            //     }
                            //     @media screen and (max-width:480px) {
                            //         height: 15px;
                            //         width: 15px;
                            //     }
                                
                            // }

                            input{
                                height: 14px;
                                width: 14px;
                                accent-color: #dc3a1a;

                                &::after{
                                    content: " ";
                                    display: block;
                                    width: 50%;
                                    height: 50%;
                                    max-width: 9px;
                                    max-height: 9px;
                                    position: absolute;
                                    left: 50%;
                                    top: 50%;
                                    transform: translate(-50%,-50%);
                                    padding: 0px;
                                }
                            }
                        }
                    }
                }
            }
            .socialShareModal{
                margin: 0px 0px 25px;
                .social__Share__btn{
                    padding: 7px 12px;
                    border: none;
                    display: inline-flex;
                    align-items: center;
                    margin-right: 10px;
                    font-size: 16px;
                    color: #ffffff;
                    border-radius: 8px;                    
                    opacity: 0.6;
                    transition: all .3s ease-in-out;
                    -webkit-transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    text-decoration: none;
                    cursor: pointer;

                    @media screen and (max-width:992px) {
                        margin-bottom: 12px;
                    }
                    &:hover{ opacity: 1; }

                    &.facebook{ background-color: #3b5998; }
                    &.whatsapp{ background-color: #25d366; }
                    &.twitter{ background-color: #55acee; }
                    &.messenger{ background-color: #1d72f2; }
                    &.pinterest{ background-color: #e00; }

                    svg {
                        margin-right: 6px;
                    }
                }
            }
        }

        .modal-footer {
            border-top: none;
            padding: 1rem 1.7rem;
            @media screen and (max-width:480px) {
                padding: 1rem 1.1rem;
            }
            .btn-primary {
                color: #fff;
                background-color: #dc3a1a;
                border-color: #dc3a1a;
                &:focus{
                    box-shadow: 0 0 0 0.25rem #dc3a1a75;
                }
                
            }
        }
    }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

    .bundleModal__comman {
        .modal-content {
            .modal-header {
                .btn-close{
                    margin: -0.5rem auto -0.5rem -0.1rem;
                }
            }
            .modal-body {
                .socialShareModal{     
                    .social__Share__btn{
                        margin-right: 0px !important;
                        margin-left: 10px;
                        svg {
                            margin-right: 0px;
                            margin-left: 6px;
                        }
                    }
                }
            }
        } 
    }
    
}

/*-------------------------- Arabic view End ------------------------*/