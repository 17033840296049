.inner__password__block {
  max-width: 600px;
  padding: 16px;
  .main__form__field__block {
    margin: 0px 0px 10px;
    .form__label {
    }
    .field__block {      
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .form__field {        
        width: 100%;
        outline: none;
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        padding: 6px 15px;
        font-size: 16px;
        letter-spacing: 0.3px;
        &:focus {
          background-color: #ffffff;
          border-color: #333333;
        }
      }
      a {
          display: flex;
          position: absolute;
          width: 23px;
          height: auto;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
      }
    }
    .invalid__message {
      color: red;
      margin: 5px 0px 0px;
      font-size: 14px;
      line-height: 1.1;
    }
  }
  .profile__form__button__block {
    margin: 20px 0px 0px;
    .form__save__button {
      text-align: center;
      font-size: 14px;
     font-family: 'SSTbold';
font-weight: bold;
      border-radius: 5px;
      min-width: 150px;
      color: #ffffff;
      padding: 10px 25px;
      background-color: #dc3a1a;
      margin: 0px 12px 10px 0px;
      outline: none;
      border: none;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      &:hover,&:focus{
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
      .btn__spinner{
        min-height: 21px;        
      }
    }
    .form__cancel__button {
      cursor: pointer;
      text-align: center;
      min-width: 150px;
      font-size: 14px;
     font-family: 'SSTbold';
font-weight: bold;
      border: 1px solid #707070;
      border-radius: 5px;
      padding: 10px 25px;
      color: #808080;
      background-color: #ffffff;
      margin: 0px 12px 10px 0px;
      outline: none;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      &:hover,&:focus{
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
    }
  }
}



/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {
  
  .inner__password__block .profile__form__button__block .form__cancel__button,
  .inner__password__block .profile__form__button__block .form__save__button{
      margin: 0px 0px 10px 12px;
      font-family: 'SSTArabicBold';
  }
  .inner__password__block .main__form__field__block .field__block a {
    right: auto;
    left: 12px;
  }

}

/*-------------------------- Arabic view End ------------------------*/