.mo__product__block__new{
  background-color: #ffffff;
  width: 100%;
  height: fit-content;
  padding: 15px 20px;
  margin: 0px;
  border-bottom: 1px solid #000;
  .order__detail__list{
    margin-bottom: 0px;
    @media(max-width:1360px){
      width: 100%;
      flex-wrap: nowrap;
    }
    @media(max-width:992px){
      width: 100%;
      flex-wrap: wrap;
    }
    li{
      width: 35%;
      margin-right: 10px;
      @media(max-width:1360px){
        width: 100%;
        flex-wrap: wrap;
      }
      @media(max-width:992px){
        width: 110px;
        word-wrap: break-word;  
      }
      .heading7{
        margin-right: 10px !important;
        color: #000 !important;
      }
      &:last-child{ margin-right: 0px; }

    }
  }
  .mo__product__left__block {
    padding: 0px 15px 0px;

    .mo__product__image__block {
      width: 100%;
      height: 172px;
      padding: 10px;

      background-color: #e9f3f3;
      border: 1px solid #c8c8c8;
      .mo__product__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .mo__product__middle__block {
    padding: 0px 20px 0px;
    .mo__product__company__logo {
      margin: 0px 0px 16px;
    }
    .mo__order__details__row {
      margin-bottom: 10px;
    }
    ul.align-align-items-lg-start.list-unstyled.order__detail__list.d-flex {
      margin-bottom: 5px;
  }
  }

  .mo__product__right__block {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 0px 20px;
    .mo__order__detail__button {
      text-align: center;
      font-size: 14px;
      font-family: 'SSTbold';
      font-weight: bold;
      border-radius: 3px;
      min-width: fit-content;
      color: #ffffff;
      padding: 8px 15px;
      background-color: #dc3a1a;
      margin: 0px 0px 8px 0px;
      outline: none;
      border: none;
      letter-spacing: 0.3px;
      cursor: pointer;
      &:hover,&:focus {
        -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);      
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
    }
    .write__reviewBox{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img{
        width: 19px;
        height: 19px;
        @media (max-width:740px) { width: 14px; height: 14px; }
      }
      .write_Review__link{
        font-size: 15px;
        @media (max-width:740px) { font-size: 13px; }
      }
    }
  }
  .write_Review__link {
    color: #000000;
    margin: 0px 0px 0px 10px;
  }
}
.orderDetails__container .col-lg-9{
  width: 100%;
  margin-bottom: 20px;
}

@media(max-width:576px){
  .myOrders__container .mo__product__block__new .order__detail__list {
    width: 100%;
    flex-wrap: inherit;
    // overflow-x: auto;
}

}
@media(max-width:991px){
  .myOrders__container .mo__product__block__new .order__detail__list .text3{
    font-size: 14px;
  }
  .mo__product__block__new .mo__product__middle__block .heading6{
    line-height: 1.4;
  }
}
