.checkout__page__container {
  padding: 0px 12px;
  background-color: #f5f5f5;

  .super__coin__block .super__coin__text__block .super__coin__title {
    font-size: 14px;
  }

  .super__coin__block .super__coin__text__block .super__coin__condition {
    font-size: 12px;
  }

  .checkout__page__block {
    padding: 0px 0px;
    width: 1420px;
    margin: 0px auto;

    .checkout__page__inner__block {
      padding: 0px 0px;
      width: 100%;
      margin: 0px auto;

      .checkout__left__block {
        padding: 0px;
        margin: 0px 0px 50px 0px;

        @media (max-width: 1100px) {
          margin: 0px 0px 0px 0px;
        }

        .checkout__login__main__block {
          width: 100%;
          display: flex;
          align-items: flex-start;
          padding: 12px 0px;
          margin: 0px;
          display: flex;
          align-items: center;

          .checkout__signin__button {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            cursor: pointer;

            .done__icon {
              position: absolute;
              top: 82%;
              left: 50%;
              transform: translateX(-50%);
              width: 18px;
            }
          }

          .checkout__middle__line__block {
            height: 1px;
            background-color: #818181;
          }

          .checkout__delivery__button {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: default;
            img {
                height: 22px;
            }           
            .done__icon {
              position: absolute;
              top: 82%;
              left: 50%;
              transform: translateX(-50%);
              width: 18px;
            }
          }

          .checkout__payment__button {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: default;

            .done__icon {
              position: absolute;
              top: 70%;
              left: 60px;
            }
          }
        }

        .user__delivery__address__block__disable {
          display: none;
        }

        .user__delivery__address__block {
          background-color: #ffffff;
          box-shadow: 0px 3px 6px #0000000a;
          margin: 20px 0px;
          border: 1px solid #C7C7C7;
          .delivery__address__block {
            padding: 16px 0px 0px;

            .delivery__address__option__area{
              padding-bottom: 18px;
              padding-top: 10px;
              border-bottom: 1px solid #acacac;
              .delivery__option__item{
                .delivery__option__btn{
                  user-select: none;
                  cursor: pointer;
                  padding: 8px 10px;
                  border: 2px solid #dbdbdb;
                  border-radius: 6px;
                  box-shadow: 0px 2px 6px #dbdbdb;
                  display: flex;
                  align-items: center;
                  font-size: 18px;
                  label {
                    cursor: pointer;
                  }
                  .delivery__input__check{
                    margin-right: 10px;
                    width: 18px;
                    height: 18px;                    
                    accent-color: #000000;
                  }
                  img{ 
                    margin-right: 6px; 
                    width: 24px;
                  }
                  &.active{
                    border-color: #dc3a1a;
                    color: #ffffff;
                    background-color: #dc3a1a;
                    img{
                      // filter: invert(28%) sepia(87%) saturate(1745%) hue-rotate(349deg) brightness(90%) contrast(99%);
                      filter: brightness(0) invert(1);
                    }
                    input[type="radio"] {
                      accent-color: #dc3a1a;
                    }
                  }
                }
              }
            }
            .delivery__address__title__block {
              display: flex;
              align-items: center;
              padding: 0px 16px;

              &.select__address__block{
                padding: 18px 16px 0px;
              }
            }

            .checkout__address__add__area{
              display: flex;
              flex-direction: row;

              .address__select__block {
                padding: 0px 0px;
                margin: 20px 0px;
                border-bottom: 0;        
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;   
                flex:0 0 90%;
                max-width: calc(100% - 120px);   

                .address__block {
                  padding: 0px 16px;

                  .selected__address__inner__block,.address__inner__block {                                    
                    box-shadow: 0px 3px 6px #00000029;
                    border: 2px solid #d1d1d1;
                    padding: 15px;
                    margin-bottom: 20px;
                    border-radius: 15px;
                    cursor: pointer;
                    
                    .user__address__info__area{
                      display: inline-block;
                      width: 75%;
                      max-width: calc(100% - 80px);
                      padding-right: 5px;
                    }
                    .address__action__area{
                      display: inline-block;
                      width: 70px;
                      vertical-align: top;

                      .inner__address__button__block {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .edit__button,
                        .delete__button {
                          text-align: center;
                          font-size: 14px;
                          font-family: 'SSTbold';
                          font-weight: bold;
                          border-radius: 5px;                        
                          color: #000000;
                          padding: 0px;
                          width: 30px;
                          // background-color: #c8c8c8;
                          // margin: 0px 0px 10px 0px;
                          outline: none;
                          border: none;
                          letter-spacing: 0.3px;
                          cursor: pointer;
                          text-transform: uppercase;
                          &:hover{
                            transition: all 0.3s ease-in-out;
                            box-shadow: 0px 1px 4px #d9d9d9;
                          }
                        }
                      }
                      .address__tag {
                        width: 100%;
                        text-align: center;
                        padding: 5px 10px !important;
                        background: #ff9b04;
                        border: 1px solid #ff9b04;
                        border-radius: 8px;
                        margin: 5px 0px 5px;                      
                        line-height: 15px !important;
                        transition: all 0.3s ease-in-out;
                        span{
                          font-size: 12px !important;
                          color: #ffffff;
                        }
                        &:hover{
                          box-shadow: 0px 1px 4px #d9d9d9;
                        }
                      }
                    }
                    
                    .white__address__tag {
                      width: fit-content;
                      padding: 5px 10px !important;
                      background: #ffffff;
                      border: 1px solid #ffffff;
                      border-radius: 3px;
                      margin: 0px 0px 10px;
                      height: 30px;
                      line-height: 15px !important;
                      span{
                        font-size: 12px !important;
                      }
                    }

                    .full__address {
                      width: 90%;
                      min-height: 75px;
                    }

                    .address__button__block {
                      margin: 20px 0px 0px;

                      .delivery__button {
                        text-align: center;
                        font-size: 14px;
                        font-family: 'SSTbold';
                        font-weight: bold;
                        border-radius: 5px;
                        width: 100%;
                        color: #ffffff;
                        padding: 10px 10px;
                        background-color: #dc3a1a;
                        margin: 0px ;
                        outline: none;
                        border: none;
                        letter-spacing: 0.3px;
                        cursor: pointer;
                        text-transform: uppercase;
                      }

                      
                    }
                  }
                  .selected__address__inner__block {
                    border: 2px solid #000000;
                  }

                  // .address__inner__block {
                  //   background: #ffffff;
                  //   box-shadow: 0px 3px 6px #00000029;
                  //   border: 2px solid #d1d1d1;
                  //   padding: 20px 30px;
                  
                  //   .address__tag {
                  //     width: fit-content;
                  //     padding: 5px 10px !important;
                    
                  //     background: #eeeeee;
                  //     border: 1px solid #eeeeee;
                  //     border-radius: 3px;
                  //     margin: 0px 0px 10px;
                  //     height: 30px;
                  //     line-height: 15px !important;
                  //     span{
                  //       font-size: 12px !important;
                  //     }
                  //   }

                  //   .white__address__tag {
                  //     width: fit-content;
                  //     padding: 5px 10px !important;
                  //     background: #ffffff;
                  //     border: 1px solid #ffffff;
                  //     border-radius: 3px;
                  //     margin: 0px 0px 10px;
                  //     height: 30px;
                  //     line-height: 15px !important;
                  //     span{
                  //       font-size: 12px !important;
                  //     }
                  //   }

                  //   .full__address {
                  //     width: 90%;
                  //     height: 50px;
                  //   }

                  //   .address__button__block {
                  //     margin: 20px 0px 0px;

                  //     .delivery__button {
                  //       text-align: center;
                  //       font-size: 16px;
                  //       font-family: 'SSTbold';
                  //       font-weight: bold;
                  //       border-radius: 5px;
                  //       width: 100%;
                  //       color: #ffffff;
                  //       padding: 10px 15px;
                  //       background-color: #dc3a1a;
                  //       margin: 0px 12px 10px 0px;
                  //       outline: none;
                  //       border: none;
                  //       letter-spacing: 0.3px;
                  //       cursor: pointer;
                  //       text-transform: uppercase;
                  //     }

                  //     .inner__address__button__block {
                  //       display: flex;
                  //       align-items: center;
                  //       justify-content: space-between;

                  //       .edit__button,
                  //       .delete__button {
                  //         text-align: center;
                  //         font-size: 16px;
                  //         font-family: 'SSTbold';
                  //         font-weight: bold;
                  //         border-radius: 5px;
                  //         width: 49%;
                  //         color: #000000;
                  //         padding: 10px 25px;
                  //         background-color: #c8c8c8;
                  //         margin: 0px 0px 10px 0px;
                  //         outline: none;
                  //         border: none;
                  //         letter-spacing: 0.3px;
                  //         cursor: pointer;
                  //         text-transform: uppercase;
                  //       }
                  //     }
                  //   }
                  // }
                }                
              }

              .add__new__address__block {
                padding: 10px 16px;
                display: flex;
                justify-content: center;
                flex: 0 0 120px;
                max-width: 120px;
                align-items: center;
    
                .location__button {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  cursor: pointer;
                  text-align: center;
                  min-width: fit-content;
                  font-size: 14px;
                  font-family: 'SSTbold';
                  font-weight: bold;
                  border: none;
                  border-radius: 5px;              
                  color: #000000;
                  background-color: transparent;
                  outline: none;
                  letter-spacing: 0.3px;
    
                  .location__icon {
                    margin: 0px 7px 0px 0px;
                    width: 20px;
                  }
                  &:hover{
                    color: #dc3a1a;
                    .heading7{
                      color: #dc3a1a !important;
                    }
                  }

                }
              }

            }
            .view__more__address__block{
              display: flex;
              justify-content: center;
              button{
                margin-bottom: 12px;
                &:hover{
                  text-decoration: underline;
                  text-decoration-color: #818181;
                }
              }
            }            

          }

          .store__pickup__mainarea{
            width: 60%;
            margin: 20px auto;
            padding: 15px;

            .invalid__message {
              color: red;
              margin: 0px 0px 5px 0px;
              font-size: 16px;
            }
            .store__pickup__heading{
              display: flex;
              flex-direction: row;   
              justify-content: space-between;   
              align-items: flex-start;

              .heading4 {
                display: inline-block;
                margin-bottom: 0px !important;
              }
              .css-b62m3t-container{                
                width: 250px;
              }
              .search__store__availablebox {
                width: 35%;
                input, button{
                  border-color: #6c757d;
                  &:focus{
                    box-shadow: none;
                    border-color: #6c757d;
                  }
                }
                button {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
              }
            }
            .store__pickup__options__box{
              max-height: 370px;
              overflow: scroll;
              overflow-x: hidden;
              padding-right: 5px;
              margin-bottom: 20px;

              .store__pickup__itembox{
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                .store__pickup__select__area{
                  width: 40px;
                  display: flex;
                  align-items: center;
                  justify-self: center;
                  input {
                    width: 17px;
                    height: 17px;
                    accent-color: #ed4b2b;
                  }
                }
                .pickup__store__infobox{
                  width: 98%;
                  max-width: calc(100% - 40px);
                  background-color: #ffffff;
                  border-left: 4px solid #e33124;
                  border-radius: 6px;
                  box-shadow: 0px 1px 2px #cdcdcd;
                  padding: 12px 0px 12px 15px;
                  .product__store__status{
                    margin-bottom: 5px;
                    // color: #e33124;
                  }
                  .pickup__store__location{
                    font-size: 12px;
                    color: #8994a6;
                    display: block;
                    width: 100%;
                  }
                  .pickup__store__time{
                    font-size: 12px;
                    color: #8994a6;
                  }
                  .store__opening__hours__btn{
                    background-color: #dc3a1a;
                    padding: 8px 10px;
                    color: #fff;
                    font-size: 15px;
                    border:none;
                    &:hover{
                      box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                      transform: translateY(-2px);
                      transition: all 0.2s ease-in-out;
                      -webkit-transition: all 0.2s ease-in-out;
                    }
                  }
                }
              }
            }
          }

          

          .view__more__address__button {
            display: flex;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            text-align: center;
            min-width: fit-content;
            font-size: 14px;
            font-family: 'SSTbold';
            font-weight: bold;
            border-radius: 5px;
            border: none;
            color: #000000;
            background-color: #ffffff;
            outline: none;
            letter-spacing: 0.3px;
          }

          .delivery__selcetion__pickup__store {
            padding: 20px 5px;
            margin: 0px;
            
            .delivery__preferences__block {
              .delivery__preferences__title__block {}
              
            }
            .delivery__selection__block {
              .delivery__selection {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 20px 0px 0px 0px;

                .delivery__selection__form__block {
                  display: flex;
                  align-items: center;

                  .delivery__input__check {
                    accent-color: #ff4f04;
                    height: 17px;
                    width: 17px;
                    border-radius: 10px;
                    margin: 0px 16px 0px 0px;
                    // border: 17px solid #303030;
                    text-transform: uppercase;
                  }

                  .delivery__selection__text {
                    margin: 0px;
                  }
                }

                .delivery__price__block {
                  width: 30%;
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;

                  .delivery__price {
                    text-align: right;
                    margin: 0px;
                  }
                }
              }
            }
            .delivery__pickup__store {}

            .continue__button__block {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0px 10px;
             
              .continue___button {
                text-decoration: none;
                border-radius: 5px;
                color: #ffffff;
                font-size: 16px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 8px 20px;
                background-color: #dc3a1a;
                text-align: center;
                text-transform: uppercase;
                width: fit-content;
                border: none;
                margin: 0px;
                // height: 44px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.4s ease-in-out;
                -webkit-transition: all 0.4s ease-in-out;

                &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: #cdcdcd;
                    border-radius: 4px;
                    transform: scaleX(0);
                    transform-origin: 0 50%;
                    transition-property: transform;
                    transition-duration: 0.3s;
                    transition-timing-function: ease-out;       
                }
                &:hover{
                  box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                  transform: translateY(-2px);
                  -webkit-transform: translateY(-2px);                  
                }
                &:focus{
                  color: #000000;
                  box-shadow: 0 3px 3px -4.8px rgb(32 32 32 / 60%);
                  transform: translateY(0px);
                  -webkit-transform: translateY(0px);    
                  transition: all 0.3s ease-in-out;
                  -webkit-transition: all 0.3s ease-in-out;            
                  &:before{
                    transform: scaleX(1);
                    -webkit-transform: scaleX(1);
                  }
                }
                .btn__spinner{
                  min-width: 86.15px;
                  min-height: 22px;     
                }
              }
            }
          }

          .checkout__page__horizontal__line {
            border-bottom: 1px solid #acacac;
            margin: 0px;
          }
        }

        .user__payment__block__disable {
          display: none;
        }

        .user__payment__block {
          background-color: #ffffff;
          box-shadow: 0px 3px 6px #0000000a;
          margin: 0px 0px;
          padding: 15px 15px;
          border: 1px solid #C7C7C7;
          margin-top: 20px;

          .payment__title__block {
            display: flex;
            align-items: center;
            padding: 0px;
            margin: 0px 0px 20px;
            img{ width: 21px; }
          }
          .payment__block {
            padding: 0px 1px 12px;
            .text4{
              font-weight: 500;
              font-family: 'SSTmedium';
              text-transform: uppercase;
            }            
            .card__block {
              display: flex;
  
              .card {
                  /* object-fit: cover; */
                  width: auto !important;
                  height: 20px !important;
                  margin: 0px 5px 0px 0px;
              }
          }
          .tamara__logobox{
            
            .card { 
              background-color: unset;
              margin: 0px; 
              width: 100%;              
              border: none;
            }
          }
            .payment__form__block {
              display: flex;
              align-items: center;
              padding: 10px 30px 10px 10px;
              min-width: 185px;
              background: #f5f5f5 0% 0% no-repeat padding-box;
              // border-radius: 5px;
              border: 1px solid #c7c7c7;
              margin-bottom: 15px;
              justify-content: space-between;
              cursor: pointer;
              .payment__input__check {
                cursor: pointer;
                accent-color: #ff4f04;
                height: 17px;
                width: 17px;
                border-radius: 10px;
                margin: 0px 16px 0px 0px;
                // border: 17px solid #303030;
                text-transform: uppercase;
              }

              .payment__selection__text {
                margin: 0px;
                width: 100%;
              }
              img{
                width: auto;
                max-height: 28px;
                max-width: 80px;
              }
              &.mokafa{
                img{
                  max-width: 55px;
                }
              }
            }
            .payment__form__main__block {
              margin: 0px 0px 10px;

             

              .payment__detail__form__block {
                padding: 10px 15px 10px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #eeeeee;
                border-radius: 5px;
                position: relative;

                .payment_process_loading{
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  left: 0px;
                  top:0px;
                  background-color: #ffffffb8;
                  z-index: 3;
                }
                .address__content__block {
                  .payment__card__block {
                    display: flex;
                    width: 100%;
                    justify-content: flex-start;
                    flex-direction: column;

                    .payment__form__field__row {
                      width: 100%;
                      display: flex;
                      align-items: flex-end;
                      // margin: 10px;
                      &.mezza__text{
                        .field__block{
                          img{
                            max-height: 36px;
                          }
                        }
                        
                      }
                      .month__year__form__field__block {
                        // width:100%
                        margin: 0px 12px 0px -12px;
                      }

                      .main__form__field__block {                       
                        position: relative;
                        padding-bottom: 18px;
                        
                        input:focus,
                        textarea:focus {
                          outline: none !important;
                          background: none !important;
                        }

                        .card__holder__field:focus {}

                        .field__block {
                          width: 100%;

                          .form__field {
                            width: 100%;
                            padding: 8px 10px;
                            // background: #ffffff 0% 0% no-repeat padding-box;
                            border: 1px solid #c8c8c8;
                            border-radius: 4px;
                            font-size: 15px;                            
                          }
                          
                          /* Chrome, Safari, Edge, Opera */
                          input::-webkit-outer-spin-button,
                          input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                          }
                          /* Firefox */
                          input[type=number] {
                            -moz-appearance: textfield;
                          }
                          .apply_btn{
                            background-color: #dc3a1a;
                            color: #fff;
                            transition: all 0.4s ease-in-out;
                            -webkit-transition: all 0.4s ease-in-out;
                            -moz-transition: all 0.4s ease-in-out;
                            &:hover{
                              opacity: 0.8;
                            }
                            .btn__spinner{
                              min-height: 24px;
                            }
                          }
                        }

                        .invalid__message {
                          color: red;
                          margin: 5px 0px 0px;
                          line-height: 1;
                          font-size: 14px;
                          position: absolute;
                          width: 100%;
                          height: auto;
                          bottom: 3px;
                        }
                      }

                      .btn.btn__primary__orange{
                        padding: 8px 10px;
                      }
                    }
                  }
                }
              }
              .payment__detail__form__block.tamara__paybox {
                border: none;
                padding: 0px;

                .tamara__installments__label{
                  width: 70%;
                  text-align: center;
                  font-size: 15px;
                  color: #b863f3;
                }
                .tamara__installments__progressbox {
                  padding: 10px 3%;
                  border: 1px solid #6dc7be;
                  border-radius: 10px;
                  .tamara__logo__pic{ 
                    margin-bottom: 15px; 
                    width: 140px;
                  }
                  ul{
                    margin: 0px auto 25px;
                    padding: 0px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    position: relative;
                    width:80%;

                    &:before {
                      content: " ";
                      display: block;
                      position: absolute;
                      height: 4px;
                      width: 85%;
                      background-color: #6dc7be;
                      left: 50%;
                      top: 48%;
                      transform: translate(-50%);
                    }

                    li {
                      list-style-type: none;
                      text-align: center;

                      .tamara_amt_box {
                        font-size: 15px;
                        font-family: 'SSTbold';
                        color: #6dc7be;
                        margin-bottom: 0px;
                      }
                      .circle-box{
                        height: 20px;
                        width: 20px;
                        background-color: #6dc7be;
                        border-radius: 50%;
                        margin: 5px auto;
                      }
                      .tamara_date_label{
                        font-size: 14px;
                      }
                    }
                  }
                }
              }

            }
            .visa__notes__box{
              align-items: center;
              img{ margin-top: 0px; }
            }
          }

          .continue__button__block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .btn{
              display: block;
              height: 50px !important;
              padding: 5px;
              &:hover , &:focus {
                background-color: #dc3a1a;
                color: #ffffff;
              }
              &[disabled]{
                  background-color: #707070;
                  cursor: not-allowed;
                  border-color: #707070;
              }
            }
            .continue___button__link {
              text-decoration: none;

              .continue___button {
                border-radius: 5px;
                color: #ffffff;
                font-size: 16px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 10px 60px;
                background-color: #dc3a1a;
                text-align: center;
                text-transform: uppercase;
                width: fit-content;
                border: none;
                margin: 0px;
                height: 64px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            .pd__apple__buy__btn {
              display: block;
              height: 50px !important;
              padding: 5px;
              width: 100%;
              border-radius: 5px;
              color: #ffffff;
              font-size: 16px;
              font-family: 'SSTbold';
              font-weight: bold;
              letter-spacing: 0.85px;
              padding: 10px 20px;
              background-color: #000000;
              text-align: center;
              border: none;
              text-decoration: none;
              svg {
                width: 15px;
                height: 15px;
                margin-right: 5px;
              }
              &:disabled{
                background-color: #707070;
                opacity: 0.65;
              }
            }
          }
        }

        .order__summary__block {
          background-color: #ffffff;
          box-shadow: 0px 3px 6px #0000000a;
          padding: 16px 0px;

          .order__summary__title__block {
            display: flex;
            align-items: center;
            padding: 0px 16px;
            margin: 0px 0px 10px 0px;
          }
        }
      }

      .checkout__right__block {
        padding: 0px 0px 0px 20px;

        .checkout__package__summary__block {
          box-shadow: 0px 3px 6px #0000000a;
          background-color: #ffffff;

          .checkout__os__title {
            font-size: 24px;
            font-family: 'SSTbold';
            font-weight: bold;
            padding: 20px;
            border-bottom: 1px solid rgb(224, 224, 224);
            margin: 0px;
          }

          .checkout__os__detail__block {
            padding: 10px 20px 20px;

            .checkout__os__detail__inner__block {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid rgb(224, 224, 224);
              padding: 20px 0px;

              &:last-child {
                border-bottom: 0;
              }

              .checkout__os__detail__title {
                margin: 0px;
                font-size: 16px;
                font-family: "SSTregular";
              }

              .checkout__os__detail__price {
                margin: 0px;
                font-size: 16px;
                font-family: 'SSTbold';
                font-weight: bold;
              }
            }

            .checkout__os__detail__total__order__block {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 30px 0px;

              .checkout__os__detail__total__order {
                font-size: 18px;
                font-family: 'SSTbold';
                font-weight: bold;
                margin: 0px;
              }
            }
          }
        }
      }
    }
  }
}



@media(max-width:1500px){
  .address__inner__block,.selected__address__inner__block {
    padding: 20px 10px !important;
  }
  .checkout__page__container .address__button__block button{
    padding: 10px 5px !important;
    font-size: 14px !important;
  }
  .checkout__page__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .checkout__page__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}

@media screen and (max-width: 1200px) {
  .checkout__right__block {
    padding: 0px !important;
    margin: 10px 0px;
  }
}

@media screen and (max-width: 992px) {
  .checkout__page__block {
    width: 100% !important;
  }
}

.address__popup__container__disable {
  display: none;
}

.address__popup__container {
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0px;
  width: 100%;
  max-width: 100%;
  z-index: 18;
  height: 100vh;
  max-height: 100vh;
  // overflow-y: scroll;
  background-color: #02020250;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
}


// new order summary right sec start
.checkout__right__block {
  margin-top: 68px;
  @media screen and (max-width:1200px) {
      margin-top: 40px;
  }
  @media screen and (max-width:767px) {
      margin-top: 20px;
  }
  .checkout__bottom__text{
    color: #C4C4C4;
    font-size: 12px;
    padding: 0 5px;
    @media(max-width:992px){
      margin:10px 30px;
    }
  }
}

.checkout__summary__block {
  border: 1px solid #C7C7C7;
  background: #fff;
  padding: 20px 15px;
  margin-bottom: 20px;
  @media(max-width:992px){
    margin:10px 20px;
  }
  // .heading7
  hr {
    /* border: 1px solid #C7C7C7; */
    background: transparent;
    margin: 0 -14px;
    opacity: 1;
    border: 1px dashed #D2D2D2;
  }
  

  ul {
    li {
      margin: 12px 0;
      justify-content: space-between;
     
      .sum__points {
        color: #22D400;
        font-size: 14px;
        font-weight: 600;
      }

      input[type="radio"]{
        margin: 10px 10px 0px 0px;
        cursor: pointer;
        z-index: 1;
        height: 16px;
        width: 16px;
        max-width: 16px;
        max-height: 16px;
        & + p {
          width: 66%;
          max-width: 65%;
        }
      }
      img {
        width: auto;
        max-height: 28px;
        max-width: 80px;
      }
      p {
        position: relative;
        color: #1B2630 !important;
        font-weight: 500;
        margin: 0;

        .sum__eq {
          font-size: 12px;
        }

        &.summary__pro__name {
          width: calc(100% - 100px) !important;
          padding-left: 35px;
          padding-right: 5px !important;
          font-size: 15px;
          display: flex;
          flex-direction: column;
          .service{
            font-weight: 500;
            color: #898686;
          }
        }

        &.summary__pro__price {
          width: 120px;
          text-align: right;

          &.summary__discount_price {
            font-weight: 500;
            font-size: 13px;
            color: #ff5005 !important;
          }
        }

        .order__count {
          display: inline-block;
          position: absolute;
          color: #8D8D8D !important;
          // margin-right: 10px;
          width: 30px;
          font-size: 14px;
          left: 0;
        }
        .pd__mb__cashback {
          display: flex;
          margin: 10px 0px;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: -0.768px;
          color: #168F00 !important;
          font-weight: 500;

          p {
            margin: 0px 5px;
            font-weight: 500;
            color: #168F00 !important;
          }            
        }
        .btn-link{
          padding: 0px 8px;
          line-height: 1.1;
          text-decoration: none;
          text-transform: capitalize;
          color: #dc3a1a;
          font-weight: 500;
          cursor: pointer;
          &:hover{
            text-decoration: underline;
          }
          &:focus{
            outline: unset;
            box-shadow: unset;
          }
        }
      }     
      label{
        img {
          width: auto;
          max-height: 28px;
          max-width: 95px;
        }
      }
      &.discount_info_row{
        position: relative;
        .discount_remove_spinner {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0px;
          top: 0px;
          background-color: #ffffffb8;
          text-align: center;
          z-index: 2;
        }
        button{
          &:focus{
            box-shadow: unset;
          }
        }
      }

    &.summary_cashback {
      p {
        color: #168F00 !important;
      }
    }

    }
    .loyalty__points__block{
      ul{
        margin: 0px;
        padding: 0px;
        li{
          input[type="radio"]{
            &::before{
              left: 50%;
            }
          }
        }
      }
      
    }
  }

  .checkout__coupon__block {
    .cart_coupon_code_inputbox{
      position: relative;
      margin-bottom: 15px;
      display: flex;
      flex: 0 0 100%;
      align-items: center;

      .cart_coupon_spinnerbox{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        background-color: #ffffffb8;
        z-index: 10;
      }
      .remove_coupen_code_btn{
        img {
          cursor: pointer;
        }
      }
      .input-group {
        position: relative;
        width: 93%;
        z-index: 1;
        button#button-addon2 {
          background: transparent;
          border: 0;
          position: absolute;
          right: 10px;
          top: 50%;
         font-size: 13px;
          transform: translateY(-50%);
          color: #000;
          font-weight: 600;
          z-index: 3;
        }
  
        input.form-control {
          background: #FFDCA7;
          border: 1px solid #1B26303B;
          border-radius: 0;
          padding-right: 60px;
          &:focus {
            background: #d8d8d7;
            box-shadow: none;
            border-color: #000;
          }
        }
      }  
    }
    

  }
}
.mezza__text .btn__primary__orange{
  height: 40px;
    padding: 5px;
    margin-top: 32px;
    @media screen and (max-width:480) {
      
    }
}
// new order summary right sec start

@media(max-width:1460px){
  .checkout__page__container .checkout__page__block .checkout__page__inner__block .checkout__left__block .checkout__login__main__block .heading5{
    font-size: 14px;
  }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  

  .checkout__page__container {
    .checkout__page__block {
      .checkout__page__inner__block {
        .checkout__left__block {

          .checkout__login__main__block {
            .checkout__delivery__button,.checkout__signin__button,.checkout__payment__button {
              img{ 
                width: 22px; 
                @media screen and (max-width:1280px) {  
                  width: 17px;
                }
              }
              .heading6{
                margin-left: 0px !important;
                margin-right: 5px !important;
                font-size: 17px;
                
                @media screen and (max-width:1450px) {  font-size: 14px;   }
                @media screen and (max-width:1199px) {  font-size: 16px;   }
              }
            }
            .checkout__signin__button{
              padding-left: 0px;
            }
          }
                    
          .user__delivery__address__block {
            .delivery__address__block{
              .delivery__address__option__area{
                .delivery__option__item{
                  .delivery__option__btn.active,.delivery__option__btn {
                    input[type=radio]{
                      margin-right: 0px;
                      margin-left: 10px;
                    }
                    img{
                      margin-right: 0px;
                      margin-left: 6px;
                    }
                  } 
                } 
              } 
              .delivery__selcetion__pickup__store {
                display: flex;
                justify-content: flex-end;
                .delivery__preferences__block {                  
                }
                .delivery__selection__block {
                  .delivery__selection {
                    .delivery__selection__form__block {
                      .delivery__input__check{   
                        margin: 0px 0px 0px 16px; 
                      }
                    }
                  }
                }
              }
            }            
            .store__pickup__mainarea {
              .store__pickup__heading {
                .search__store__availablebox{
                  flex-direction: row-reverse;
                }
              }
              .store__pickup__options__box {
                .store__pickup__itembox {
                  .pickup__store__infobox {
                    padding: 12px 15px 12px 0px;
                    .pickup__store__location{

                    }
                    .store__opening__hours__btn{
                      float: left;
                    }
                    .product__store__status{
                      float: unset;
                    }
                  }
                }
              }
            }

            
          }
          .user__payment__block {
            .payment__title__block{
              p{
                margin-left: 0px !important;
                margin-right: 10px !important;
              }              
            }
            .continue__button__block {
              .btn{
                font-family: 'SSTArabicBold';
              }
              .pd__apple__buy__btn {
                display: flex;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
              }
            }
            .payment__block {
              .text4{
                font-family: 'SSTArabicMedium';
              }
              .payment__form__block{
                margin-left: 0px;
                margin-right: 10px;
                .payment__input__check{
                  margin: 0px 0px 0px 16px;
                }
              }
              .payment__form__main__block{
                .payment__detail__form__block {
                  .payment__card__block{
                    .main__form__field__block.pay__promoCode{
                      .form__field{
                        direction: ltr;
                        text-align: right;
                      }
                    }
                    .payment__form__field__row.mezza__text,
                    .payment__card__block.mezza__text {
                      .main__form__field__block{
                        input{
                          direction: ltr;
                          text-align: right;
                        }
                      }
                    }
                    .payment__form__field__row {
                      .main__form__field__block {
                        .field__block {
                          .form__field[name="phone"],
                          .form__field[name="cardNumber"] {
                            direction: ltr;
                            text-align: right;
                          }
                        }
                      }
                    }
                  }
                }
                
              }
            }
          }
        }
        .checkout__right__block {
            margin-top: 69px;
            padding: 0px 20px 0px 0px !important;

            .checkout__summary__block {
              ul {
                li {

                  .sum__points{ text-align: left; }

                  p:last-child{ text-align: left; }
                }
              }
            }
        }
      }
    }
  }
/* for mobile \/ */

  .checkout__right__block {
      
      .checkout__summary__block {
        ul {
          li {

            .sum__points{ text-align: left; }

            p:last-child{ text-align: left; }
          }
        }
      }
  }

  
}
.bin-note{
  color: rgb(0 130 160);
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 13px;
    text-align: center;
}
/*-------------------------- Arabic view end ------------------------*/