.contact__page {

    .mb_back_section{
        padding: 14px 15px;
        .mb__contact__back__btn{
            text-decoration: none;
            color: #5A68EC;
            font-weight: 500;
        }
    }
    .contact__container{
        padding: 15px;
        width: 1420px;
        margin: 3rem auto 5rem;
        background-color: #ffffff;
        border-radius: 13px;
        box-shadow: 0px 0px 80.2977px 40.1488px rgba(0, 0, 0, 0.03);

        @media screen and (max-width:1450px) {
            width: 90% !important;
        }
        @media screen and (max-width:992px) {
            width: 92% !important;
            padding: 10px;
            margin: 1rem auto 4rem;
        }

        .contact__info_section{
            background-color: #000000;
            padding: 2.5rem;
            border-radius: 13px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            @media screen and (max-width:1366px) {
                padding: 2.2rem;
            }
            @media screen and (max-width:992px) {
                text-align: center;
                padding: 1.2rem;
            }
            h2 {
                color: #ffffff;
                font-size: 36px;
                @media screen and (max-width:1366px) {
                    font-size: 2.1rem;
                }
                @media screen and (max-width:1100px) {
                    font-size: 1.7rem;
                }
            }
            .disc_for_mob{
                color: #c9c9c9;
                @media screen and (max-width:992px) {
                    font-size: 12px;
                    font-weight: normal;
                }
            }
            .contact__data__area {
                .contact__data__itembox{
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 3rem;
                    @media screen and (max-width:992px) {
                        flex-direction: column;
                        margin-bottom: 1.5rem;
                    }
                    .contact__iconBox{
                        margin-right: 15px;
                        @media screen and (max-width:1366px) { margin-right: 10px; }
                        @media screen and (max-width:1280px) { margin-right: 8px; }
                        img{
                            width: 32px;
                            @media screen and (max-width:1366px) { width: 29px; }
                            @media screen and (max-width:1280px) { width: 25px; }
                        }
                    }
                    .contact__link{
                        color: #ffffff;
                        font-size: 20px;
                        font-weight: normal;
                        text-decoration: none;
                        
                        &.call{
                            text-decoration: underline;
                            @media screen and (max-width:992px) {
                                font-size: 18px;
                            }
                        }
                        @media screen and (max-width:1366px) {
                            font-size: 18px;
                        }
                        @media screen and (max-width:992px) {
                            font-size: 13px;
                        }
                    }
                }
            }
            .social__contact__area{
                
                ul {
                    margin: 0px;
                    padding: 0px;           
                    display: flex;
                    align-items: center;

                    @media screen and (max-width:992px) {
                        justify-content: center;
                    }
                    li{
                        list-style-type: none;
                        margin-right: 30px;
                        display: inline-block;
                        &:last-child{
                            margin-right: 0px;
                        }
                        a {
                            padding: 3px 6px;
                            background-color: #1B1B1B;
                            border-radius: 50%;
                            color: #ffffff;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 35px;
                            width: 35px;
                            img{
                                width: 20px;
                            }
                        }
                    }
                }
            }
        }

        .contact__form__section{
            padding: 2.5rem;
            background-color: #ffffff;
            @media screen and (max-width:1366px) {
                padding: 2.2rem;
            }
            @media screen and (max-width:992px) {
                padding: 1.8rem;
            }
            form{
                display: flex;
                flex-direction: column;
                flex: 0 0 100%;
                max-width: 100%;

            }
            .form__itembox{
                margin-bottom: 1.8rem;
                position: relative;
                @media screen and (max-width:992px) { margin-bottom: 1.2rem; }

                .form-label{
                    color: #000000;
                    font-size: 16px;
                    font-weight: 500;
                    font-family: "SSTmedium";
                }
                .form-control,.form-select {
                    border: 1px solid rgba(151, 151, 151, 0.668788);
                    font-size: 18px;
                    padding: 10px;
                    border-radius: 13px;
                }
                .select_custombox{
                    position: relative;
                    width: 100%;
                    &::before{
                        content: " ";
                        display: block;
                        position: absolute;
                        height: 17px;
                        width: 17px;
                        border-top: 9px solid #000000;
                        border-bottom: 6px solid transparent;
                        border-left: 9px solid transparent;
                        border-right: 9px solid transparent;
                        right: 12px;
                        top: 58%;
                        transform: translateY(-52%);
                        @media screen and (max-width:1280px) {
                            height: 15px;
                            width: 15px;
                            border-top: 8px solid #000000;
                            border-bottom: 6px solid transparent;
                            border-left: 7px solid transparent;
                            border-right: 7px solid transparent;
                            right: 14px;
                        }
                    }
                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;                        
                        text-indent: 1px;
                        text-overflow: '';
                        position: relative;
                        background-color: transparent;   
                        background-image: unset !important;

                        &::-ms-expand {
                            display: none;
                            -webkit-appearance: none;
                            -moz-appearance: none;                            
                        }                 
                    }
                }
                .invalid__message{
                    margin: 0px;
                    position: absolute;
                    width: 100%;
                    height: auto;
                    font-size: 15px;
                    letter-spacing: 0.6px;
                    @media screen and (max-width:992px) {
                        font-size: 14px;
                    }
                }
            }
            .send_message_btn{
                padding: 10px 20px;
                min-width: 250px;
                max-width: 95%;
                font-size: 20px;
                line-height: 1.4;
                background-color: #000000;
                border-radius: 6px;
                font-weight: 500;
                color: #ffffff;
                display: block;
                margin-left: auto;
                margin-top: 3rem;
                box-shadow: 0px 0px 18.7361px rgba(0, 0, 0, 0.12);
                @media screen and (max-width:992px) {
                    width: 100%;
                    max-width: 100%;
                    border-radius: 3px;
                    margin-top: 1rem;
                    font-size: 17px;
                }
                @media screen and (max-width:480px) {
                    font-size: 15px;
                }
            }
        }
    }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

    
    .contact__page {
        .mb_back_section {
            .mb__contact__back__btn{
                svg {
                    transform: rotateY(180deg);
                }
            }
        }
        .contact__container {
            .contact__info_section {
                .contact__data__area {
                    .contact__data__itembox {
                        .contact__iconBox{
                            margin-right: 0px;
                            margin-left: 15px;
                            @media screen and (max-width:1366px) { margin-left: 10px; }
                            @media screen and (max-width:1280px) { margin-left: 8px; }
                        }
                    }
                }
                .social__contact__area {
                    ul {
                        li{
                            margin-left: 30px;
                            margin-right: 0px;
                            &:last-child{
                                margin-left: 0px;
                            }
                        }
                    }
                }
            }
            .contact__form__section {
                .form__itembox {
                    .select_custombox {
                        &::before{
                            right: auto;
                            left: 12px;
                        }
                    }
                }
            }
        }
    }

}

/*-------------------------- Arabic view End ------------------------*/