.subscribeWrapper {
  margin: 0 0 15px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .subscribeSection {
    background: #F4F4F4;
    border-radius: 7px;
    width: 100%;
  }

  @media screen and (max-width: 992px) {
    margin: 10px 0;
  }
}

.subscribeWrapperPDP {
  @extend .subscribeWrapper;

  .subscribeSection {

    border: 1px solid #d6d6d3;
  }
}