.productFive__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    padding: 5px;
    cursor: pointer;
    &:hover{
      box-shadow: 0px 0px 9px #d9d9d9;
    }
    @media screen and (max-width:992px) {
      padding: 10px 10px;
      margin: 10px 15px 10px 0px;
    }
    @media screen and (max-width:767px) {
      padding: 8px;
      .plp__tamara__box {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
    }

    a {
      width: 100%;
      .productFive__image__block {
        height: 140px;
        // width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 0px 20px;
        .productFive__image {
          // height: 100%;
          width: 90%;
          object-fit: cover;
          //   margin: 0px 0px 10px 0px;
  
          @media screen and (max-width:992px) {
            max-height: 140px;
            max-width: max-content;
          }
        }
      }
    }
    p.heading6{
      text-align: left !important;
      min-height: 40px;
    }
  

  .prize__block {
    margin-bottom: 8px;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    span {
      vertical-align: text-top;
      // &:first-child { margin-right: 8px !important; }
      // &:last-child { margin-left: 0px !important; margin: 0px !important; }
      &.text3{
        margin-bottom: 0px !important;
        position: relative;
        width: fit-content;
        line-height: 1.2;
        &::after{
          content: " ";
          display: block;
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #666666;
          -webkit-transform: translateY(-8px) rotate(-6deg);
          transform: translateY(-8px) rotate(-6deg);            
          bottom: 0;
          left: 0;
        }
      }
    }

    .old__prize {
      font-family: "SSTregular";
      font-size: 18px;
      text-decoration-line: line-through;
      color: #c8c8c8;
    }
    .new__prize {
      font-family: 'SSTbold';
      font-weight: bold;
      font-size: 18px;      
    }
  }
  .addToCart__button {
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    font-family: 'SSTbold';
    font-weight: bold;
    letter-spacing: 0.85px;
    padding: 8px 10px;
    background-color: #DC3A1A;    
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    width: 100%;
    
    .addToCart__icon {
      margin: 0px 10px 0px 0px; 
      width: 17px;
    }
    @media screen and (max-width:767px) {      
      font-size: 13px;
      width: 100%;
      .addToCart__icon{
        margin: 0px 8px 0px 0px; 
        width: 15px;
      }
    }
    @media screen and (max-width:580px) {
      padding: 8px 10px;
      font-size: 13px;      
    }
    &:hover,&:focus {
      -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
      box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);      
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      opacity: 0.8;
    }
    // &:focus {
    //   -webkit-box-shadow: 0 5px 5px -4.8px rgb(32 32 32 / 60%);
    //   box-shadow: 0 5px 5px -4.8px rgb(32 32 32 / 60%);      
    //   transform: translateY(0px);
    //   -webkit-transform: translateY(0px);
    //   transition: all 0.2s ease-in-out;
    //   -webkit-transition: all 0.2s ease-in-out;
    // }
  }
  .outofStock__button{
    border-radius: 5px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'SSTbold';
    font-weight: bold;
    letter-spacing: 0.85px;
    padding: 8px 15px;
    background-color: #a7a7a7;    
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
    width: 100%;
    line-height: 1.2;
    @media screen and (max-width:767px) {      
      font-size: 13px;
    }
    @media screen and (max-width:580px) {
      padding: 8px 10px;
      font-size: 13px;      
    }
  }
  .notifyMe__button{
    border-radius: 5px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'SSTbold';  
    text-transform: uppercase;
    letter-spacing: 0.85px;
    padding: 8px 15px;
    line-height: 1.5;                
    background-color: #FE8B21;  
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    width: 100%;

    @media screen and (max-width:767px) {      
      font-size: 13px;     
      width: 100%;
    }
    @media screen and (max-width:580px) {
      padding: 8px 10px;
      font-size: 13px;      
    }
    &:hover,&:focus {
      -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
      box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      opacity: 0.8;
    }
  }
}
  @media(max-width:1200px){
    .productFive__image__block {
      // height: 100px !important;
    }
  }


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .productFive__block {
    .addToCart__button,.outofStock__button,
    .notifyMe__button,.pre-order {
      font-family: 'SSTArabicBold';
      font-weight: bold;      
    }
    .addToCart__button {
      .addToCart__icon{
        margin-left: 8px;
        margin-right: 0px;
      }
    }
  }

}

/*-------------------------- Arabic view End ------------------------*/