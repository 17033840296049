.product__details__page__container {
  padding: 12px 12px 0px;
  position: relative;

  .product__details__page__block {
    padding: 0px 0px;
    width: 1420px;
    margin: 0px auto;

    .product__details__left__block__new {
      padding: 0px;
      margin: 0px;
      display: flex;
      flex-direction: column;

      .accordian__block {
        padding-right: 10px;
        margin-right: 10px;
        width: 97%;
        -webkit-transition: all 2s ease-in-out;
        transition: all 2s ease-in-out;

        &:first-child {
          margin-top: 40px;
        }

        &:last-child {
          margin-bottom: 40px;
        }

      }


      .products__details__inner__block {
        width: 100%;
        padding: 0px;
        margin: 0px;
        height: 100%;

        .product__carousel__main__block {
          padding: 0px;
          margin: 0px;

          .product__carousel__block {
            padding: 0px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            position: sticky;
            top: 120px;
            left: 0px;

            .carousel.activeCarousel {
              .carousel_zoomBox {
                // width: 428px;
                max-width: 100%;
                max-height: max-content;
                // height: 500px;

                // img{
                //   height: auto !important;
                //   width: 100% !important;
                // }
                img.carousel_zoom_img {
                  &+div {
                    background-color: #ffffff;

                    img {
                      max-width: 900px;
                      height: auto !important;
                      max-height: 1000px;
                    }
                  }
                }
              }

            }

            .carousel__dots__main__block {
              .carousel__dots__block {
                .slick-slider {
                  .slick-list {
                    overflow: scroll;
                    max-height: 93px;
                    overflow-x: hidden;
                    overflow-y: hidden;

                    .slick-track {
                      display: flex;
                      flex-wrap: nowrap;

                      .slick-slide {
                        margin-right: 6px;
                      }
                    }

                  }
                }
              }
            }

            .pd__social__media__compare__block {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
              margin-bottom: 40px;

              .pd__social__media__icon__block {
                display: flex;
                align-items: center;

                .pd__share__text {
                  font-size: 16px;
                  font-family: 'SSTbold';
                  font-weight: bold;
                  margin: 0px 20px 0px 0px;
                }

                .pd__social__media__icon {
                  margin: 0px 10px 0px 0px;
                  width: 25px;

                  @media(max-width:1200px) {
                    margin: 0px 5px 0px 0px;

                  }
                }
              }

              .pd__compare__block {
                display: flex;
                align-items: center;
                cursor: pointer;

                .pd__compare__input__check {
                  accent-color: #000000;
                  height: 16px;
                  width: 16px;
                  border-radius: 10px;
                  margin: 0px 10px 0px 0px;
                  // border: 16px solid #303030;
                  text-transform: uppercase;
                  cursor: pointer;
                }

                .pd__compare__text {
                  font-size: 15px;
                  font-family: "SSTregular";
                  margin: 0px;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .product__details__block {
          padding: 0px 23px;
          position: sticky;
          top: 120px;
          left: 0px;
          height: max-content;

          .delivery__wrapper {
            margin: 8px 10px 0 10px;
          }

          .soldOutLable {
            text-align: center;
            font-size: 16px;
            padding: 5px;
            color: #e9555b;
            font-weight: 700;
            text-transform: uppercase;
            border-bottom: 1px solid #c8c9ca;
            margin-bottom: 5px;
            margin-top: 10px;
          }

          .store__pickup__location__listing {
            .locationCard {
              padding: 15px;
              border: 1px solid #e7e7e7;
            }

            .location__show__more__button {
              border: none;
              padding: 8px 10px;
              width: 100%;
              text-align: center;
              font-family: "SSTbold";
              color: #000000;
              font-size: 15px;
              background-color: unset;
              text-decoration: underline;
            }
          }

          .pd__product__company__logo {
            margin: 0px 0px 16px;
            width: 90px;
          }

          .rating__and__sku__area {
            margin: 15px -15px;

            .product__sku__box {
              display: flex;
              align-items: baseline;
              flex-direction: row;

              .heading4 {
                margin-right: 10px;
                min-width: 60px;
              }

              .text3 {
                margin-left: 4px !important;
              }

              .pro__sku__copy {
                font-size: 16px;
                font-weight: 500;
                color: #5A68EC;
                text-decoration: underline;
                text-decoration-color: #5A68EC;
                margin-left: 10px;
                text-decoration: underline;
              }
            }
          }

          .product__details__titlebox {

            .pd__favourite__button,
            .pd__share__button {
              padding: 11px 14px;
              font-size: 18px;
              font-family: "SSTregular";
              letter-spacing: 0.18px;
              border: none;
              border-radius: 3px;
              background-color: #ECECEC;
              float: right;
              margin-left: 6px;

              @media screen and (max-width:1366px) {
                padding: 10px 12px;
              }

              @media screen and (max-width:1366px) {
                padding: 9px 10px;
              }

              .pd__favourite__icon,
              .sahre-icon-pic {
                display: block;
                width: auto;
                max-height: 22px;

                @media screen and (max-width:1366px) {
                  max-height: 19px;
                }
              }

              .pd__favourite__icon__disable {
                display: none;
              }

              &:hover {
                box-shadow: 0px 1px 6px -1px #787878;
              }

            }

            .product__namebox {
              width: 81%;
              max-width: calc(100% - 110px);

              .testfreaks-badge {
                &:nth-child(3) {
                  display: none !important;
                }
              }

              .heading5 {
                @media screen and (max-width:1360px) {
                  font-size: 18px;
                }

                @media screen and (max-width:1024px) {
                  font-size: 16px;
                  line-height: 1.3;
                }
              }
            }
          }

          .pd__category__favourite__button__block {
            margin: 10px 0px 10px 0px;
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;

            .pd__category__button {
              padding: 8px 18px;
              margin: 0px 14px 10px 0px;
              font-size: 15px;
              line-height: 1;
              font-family: "SSTregular";
              letter-spacing: 0.18px;
              border: 1px solid #707070;
              border-radius: 3px;
              background-color: #ffffff;
              transition: all 0.2s;

              &:hover {
                background: #dc3a1a;
                border-color: #dc3a1a;
                color: #fff;
              }
            }

          }

          .loyalty__points__area {
            row-gap: 5px;

            .loyalty__item__box {
              padding: 16px 12px;
              background-color: #F8F8F8;
              border-radius: 15px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 100%;

              img {
                width: auto;
                max-height: 29px;
                max-width: 90px;

                @media screen and (max-width:1280px) {
                  max-width: 75px;
                  width: auto;
                  max-height: 26px;
                }
              }

              .loyalty__contentbox {
                color: #000000;
                text-align: right;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                p {
                  font-size: 13px;
                  margin-bottom: 0px;
                  line-height: 1.2;
                }

                span {
                  font-size: 11px;
                  font-weight: 500;
                }

                @media screen and (max-width:1100px) {
                  margin-top: 9px;
                }
              }

              @media screen and (max-width:1100px) {
                flex-direction: column;
                min-height: 95px;
              }
            }
          }

          .pd__prod__size__install__area {            
            .pd__size__Option__area {
              .heading6 {
                font-size: 14px;
              }
            }

            .pd__install__info__area {
              .heading6 {
                font-size: 14px;
                margin-bottom: 5px;
              }

              .install__option__area {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;

                .install__Ans {
                  display: inline-flex;
                  flex: 0 0 50%;
                  max-width: 50%;

                  input[type="radio"] {
                    accent-color: #dc3a1a;
                    cursor: pointer;

                    &:after {
                      content: '';
                      display: block;
                      width: 50%;
                      height: 38%;
                    }
                  }

                  label {
                    font-size: 14px;
                    vertical-align: top;
                    margin-left: 4px;
                    font-weight: 700;
                    color: #000000;
                    cursor: pointer;
                    margin-left: 5px;
                  }
                }

                .install__message__box {
                  display: flex;
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin-top: 6px;
                  justify-content: space-between;

                  .change_button {
                    color: #5A68EC;
                    cursor: pointer;
                  }

                  span {
                    font-size: 15px;
                    font-weight: 600;
                    color: #dc3a1a;
                  }
                }
              }

              input[type="checkbox"] {
                width: 16px;
                height: 16px;
                accent-color: #dc3a1a;
              }

              strong {
                font-size: 14px;
                vertical-align: top;
                margin-left: 4px;
              }
            }
          }

          .super__coin__itembox {
            margin-bottom: 15px;

            .heading7 {
              text-align: right;
            }

            .sum__points {
              color: #000000;
              font-size: 16px;
              text-align: right;
              font-family: 'SSTregular';

              strong {
                font-family: 'SSTbold';
              }
            }

            .sum__eq {
              font-size: 12px;
              text-align: right;
            }
          }

          .pd__block__bottom__line {
            margin: 10px 0px !important;
          }


          .pd__unlock__membership__block {
            border: 1px solid #707070;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 16px;

            .pd__unlock__membership {
              display: flex;
              align-items: center;

              .pd__icon__block {
                width: 18px;
                margin: 0px 15px 0px 0px;

                // .pd__unlock__icon {}
              }

              .pd__unlock__membership__text {
                width: 85%;
                margin: 0px;
                font-size: 14px;
              }
            }

            .pd__gret__right__arrow__icon {
              width: 10px;
            }
          }


          .pd__warranty__size__block {
            .pd__warranty__block {
              display: flex;
              align-items: center;
              margin: 0px 0px 10px;

              .pd__warranty__title {
                margin: 0px;
                padding: 0px;
                font-size: 15px;
                font-family: "SSTregular";
              }

              .pd__warranty__text {
                padding: 0px;
                margin: 0px;
                font-size: 15px;
                font-family: "SSTregular";
              }
            }

            .pd__size__block {
              display: flex;
              align-items: flex-start;
              margin: 0px 0px 15px;
              font-size: 16px;
              font-family: "SSTregular";

              .pd__size__title {
                margin: 0px;
                padding: 0px;
              }

              .pd__size__button__block {
                padding: 0px;

                button {
                  position: relative;

                  &:before {
                    content: "";
                    position: absolute;
                    z-index: -1;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: #FE6321;
                    border-radius: 10px;
                    -webkit-transform: scaleX(0);
                    transform: scaleX(0);
                    -webkit-transform-origin: 50%;
                    transform-origin: 50%;
                    -webkit-transition-property: transform;
                    transition-property: transform;
                    -webkit-transition-duration: 0.3s;
                    transition-duration: 0.3s;
                    -webkit-transition-timing-function: ease-out;
                    transition-timing-function: ease-out;
                  }
                }

                .pd__size__button__active {
                  font-size: 14px;
                  border-radius: 10px;
                  color: #ffffff;
                  padding: 15px 15px;
                  background-color: #FE6321;
                  font-family: "SSTmedium";
                  // background-color: #000000;
                  border: none;
                  margin: 0px 6px 8px 0px;
                  outline: none;
                  z-index: 2;
                  position: relative;

                  &:before {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                    z-index: -1;
                  }
                }

                .pd__size__button {
                  font-size: 14px;
                  border: none;
                  font-weight: 700;
                  color: #000000;
                  border-radius: 10px;
                  padding: 15px 15px;
                  font-family: "SSTbold";
                  background-color: #F7F7F7;
                  margin: 0px 6px 8px 0px;
                  outline: none;
                }
              }
            }
          }

          .pd__delivery__block {
            .pd__form__block {
              width: 100%;
              display: flex;
              align-items: center;
              margin: 12px 0px;

              .pd__form__field {
                width: 70%;

                .pd__input__field {
                  width: 100%;
                  border: 1px solid #c8c8c8;
                  border-radius: 4px 0px 0px 4px;
                  outline: none;
                  padding: 8px 5px 8px 15px;
                  font-size: 14px;
                  font-family: "SSTregular";
                }

                input[type="number"]::-webkit-inner-spin-button,
                input[type="number"]::-webkit-outer-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                .pd__input__button {
                  border-radius: 0px 4px 4px 0px;
                  color: #ffffff;
                  padding: 8.2px 20px;
                  background-color: #dc3a1a;
                  width: fit-content;
                  outline: none;
                  border: none;
                  font-size: 15px;
                  font-family: 'SSTbold';
                  font-weight: bold;

                  &:hover {
                    opacity: 0.8;
                  }

                  &:focus {
                    background-color: #000000;
                    box-shadow: 2px 0px 4px #a9a6a6;
                  }
                }
              }
            }
          }

          .pd__Allbuttons__area {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            .pd__bundle__button__block {
              padding: 0px 10px 0px 0px;

              .pd__bundle__button {
                border-radius: 10px;
                color: #ffffff;
                font-size: 15px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 10px 15px;
                background-color: #c11111;
                border: unset;
                text-align: center;
                text-transform: uppercase;
                cursor: pointer;

                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                  box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
                  transform: translateY(-2px);
                  -webkit-transform: translateY(-2px);
                  transition: all 0.2s ease-in-out;
                  -webkit-transition: all 0.2s ease-in-out;
                }
              }
            }

            .pd__buy__button__block {

              .pd__buy__button {
                border-radius: 10px;
                color: #ffffff;
                font-size: 15px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 10px 12px;
                background-color: #000000;
                border: unset;
                text-align: center;
                text-transform: uppercase;
                cursor: pointer;
                // line-height: 1.4;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                  box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
                  transform: translateY(-2px);
                  -webkit-transform: translateY(-2px);
                  transition: all 0.2s ease-in-out;
                  -webkit-transition: all 0.2s ease-in-out;
                }

                .btn__spinner {
                  min-height: 21px;

                  @media screen and (max-width:1366px) {}

                  @media screen and (max-width:580px) {}
                }

                @media screen and (max-width:1366px) {
                  font-size: 14px;                  
                }

                &.pd__apple__buy__btn{

                  svg {
                    margin: 0px 3px;
                    @media screen and (max-width:1280px) {
                      height: 13px;
                      width: 13px;
                    }
                  }
                  &:disabled{
                    background-color: #707070;
                    opacity: 0.65;
                  }

                  @media screen and (max-width:1420px) {
                    font-size: 14px;
                    padding: 10px 7px;
                  }
                  @media screen and (max-width:1150px) {
                    font-size: 13px;
                    padding: 10px 6px;
                  }
                }
              }

              

              @media screen and (max-width:1280px) {}
            }

            .pd__addToCart__button__block {
              padding: 0px 0px 0px 10px;

              .pd__addToCart__button {
                border-radius: 10px;
                color: #ffffff;
                font-size: 15px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 10px 15px;
                background-color: #dc3a1a;
                border: unset;
                text-align: center;
                text-transform: uppercase;
                cursor: pointer;
                position: relative;
                width: 100%;

                @media screen and (max-width:1366px) {
                  font-size: 14px;
                }

                @media screen and (max-width:1280px) {
                  padding: 10px 12px;
                }

                .pd__addToCart__icon {
                  margin: 0px 12px 0px 0px;
                  height: 16px;
                  vertical-align: sub;

                  @media screen and (max-width:1350px) {
                    margin: 0px 10px 0px 0px;
                  }
                }

                .spinner-border {
                  margin-left: 10px !important;
                  margin-right: 0px !important;

                  @media screen and (max-width:1450px) {
                    margin-left: 8px !important;
                    width: 0.8rem;
                    height: 0.8rem;
                  }

                  @media screen and (max-width:1280px) {
                    margin-left: 6px !important;
                    width: 0.7rem;
                    height: 0.7rem;
                  }
                }

                &:before {
                  content: "";
                  position: absolute;
                  z-index: -1;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background: #f17e67;
                  border-radius: 5px;
                  -webkit-transform: scaleX(0);
                  transform: scaleX(0);
                  -webkit-transform-origin: 0 50%;
                  transform-origin: 0 50%;
                  -webkit-transition-property: transform;
                  transition-property: transform;
                  -webkit-transition-duration: 0.3s;
                  transition-duration: 0.3s;
                  -webkit-transition-timing-function: ease-out;
                  transition-timing-function: ease-out;
                }

                &:hover {
                  -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                  box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
                  transform: translateY(-2px);
                  -webkit-transform: translateY(-2px);
                  transition: all 0.2s ease-in-out;
                  -webkit-transition: all 0.2s ease-in-out;
                }

                &:focus {
                  // color: #000000;
                  -webkit-box-shadow: 0 3px 3px -4.8px rgb(32 32 32 / 60%);
                  box-shadow: 0 3px 3px -4.8px rgb(32 32 32 / 60%);
                  transform: translateY(0px);
                  -webkit-transform: translateY(0px);
                  transition: all 0.2s ease-in-out;
                  -webkit-transition: all 0.2s ease-in-out;

                  &:before {
                    -webkit-transform: scaleX(1);
                    transform: scaleX(1);
                  }

                  .addToCart__icon {
                    // filter:brightness(0) invert(0);
                  }
                }

                .btn__spinner {
                  min-height: 22.5px;

                  @media screen and (max-width:1366px) {
                    min-height: 21px;
                  }

                  @media screen and (max-width:580px) {}
                }
              }

              .outofStock__button {
                border-radius: 10px;
                color: #ffffff;
                font-size: 15px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 10px 15px;
                background-color: #a7a7a7;
                /*#666666*/
                border: none;
                text-align: center;
                text-transform: uppercase;
                cursor: default;
                position: relative;
                width: 100%;

                @media screen and (max-width:1366px) {
                  font-size: 14px;
                }
              }

              .notifyMe__button {
                border: none;
                border-radius: 10px;
                color: #ffffff;
                font-size: 15px;
                font-family: 'SSTbold';
                text-transform: uppercase;
                letter-spacing: 0.85px;
                padding: 10px 14px;
                line-height: 1.5;
                background-color: #FE8B21;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0px;


                @media screen and (max-width:1366px) {
                  font-size: 14px;
                }

                @media screen and (max-width:1080px) {
                  padding: 8px 8px;
                }

                @media screen and (max-width:480px) {
                  font-size: 13px;
                  padding: 8px 8px;
                }

                img {
                  margin: 0px 10px 0px 0px;
                  height: 16px;
                  vertical-align: sub;

                  @media screen and (max-width:1200px) {
                    margin: 0px 8px 0px 0px;
                  }

                  @media screen and (max-width:1080px) {
                    height: 15px;
                  }
                }
              }

            }

            .field__block {
              border: 1px solid #000000;
              border-radius: 10px;
              padding: 7px 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              appearance: none;
              -webkit-appearance: none;
              z-index: 2;
              background-color: transparent;
              height: 10%;

              .form__field {
                width: 100%;
                outline: none;
                border: none;
                font-size: 16px;
                letter-spacing: 0.3px;
                text-align: center;
              }

              /* Chrome, Safari, Edge, Opera */
              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              /* Firefox */
              input[type=number] {
                -moz-appearance: textfield;
              }
            }

          }

          .pd__common__main__block {
            margin: 15px 0px 15px;
          }
        }
      }

      .exp__rd__block {
        padding: 20px 0px;

        .exp__rd__title {
          font-size: 24px;
          font-family: 'SSTbold';
          font-weight: bold;
          letter-spacing: 0.24px;
          line-height: 1.2;
          // margin: 0px 0px 20px;
        }

        .exp__rd__main__block {
          .exp__rd__left__block {}

          .exp__rd__package__block {

            .exp__rd__package__inner__block {
              background-color: #f3f3f3;
              padding: 20px 15px 10px;
              height: 100%;

              .exp__rd__package__saving__block {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 0px 0px 10px;

                .package__saving__text {
                  margin: 0px 20px 0px 0px;
                }

                .package__saving__price {
                  margin: 0px;
                }
              }

              .exp__rd__package__total__block {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                margin: 0px 0px 25%;

                .package__total__text {
                  margin: 0px 0px 0px 0px;
                  max-width: 60%;
                }

                .package__total__price {
                  margin: 0px;
                }
              }

              .exp__rd__addToCart__button {
                border-radius: 5px;
                color: #ffffff;
                font-size: 14px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 10px 9px;
                background-color: #dc3a1a;
                text-align: center;
                text-transform: uppercase;
                margin: 0px 0px 10px;
                cursor: pointer;

                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 4px 4px -4.8px rgb(32 32 32 / 60%);
                  box-shadow: 0 4px 4px -4.8px rgba(32, 32, 32, .6);
                  transform: translateY(-2px);
                  -webkit-transform: translateY(-2px);
                  transition: all 0.2s ease-in-out;
                  -webkit-transition: all 0.2s ease-in-out;
                }

                @media (max-width:1200px) {
                  width: 250px;
                  margin-left: auto;
                }

                .exp__rd__addToCart__icon {
                  margin: 0px 7px 0px 0px;
                  width: 17px;
                  vertical-align: sub;
                }
              }

              .exp__rd__package__link__text {
                text-align: center;

                @media (max-width:1200px) {
                  text-align: right;
                }

                .exp__rd__package__link {
                  width: 100%;
                  margin: 0px;
                  color: #003cb4;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }

    .product__details__right__block {
      // border-left: 1px solid #eeeeee;
      padding: 0px 15px 0px 15px;
      height: fit-content;

      .pd__related__product__section {
        padding: 15px 5px 15px;
        border: 1px solid #DBDBDB;
        border-radius: 15px;

        .pd__mb__block__title {
          font-size: 20px;
          font-family: 'SSTbold';
          font-weight: bold;
          color: #000000;
          letter-spacing: 0.24px;
          line-height: 24px;
          padding: 0px 15px 15px;
          border-bottom: 1px solid #E4E4E4;
          margin-bottom: 0px;

          @media screen and (max-width:1366px) {
            font-size: 18px;
          }
        }

        .pd__mb__product__block {

          padding: 15px 5px 15px;
          border-bottom: 1px solid #E4E4E4;
          margin: 0;

          &:last-child {
            border-bottom: none;
          }

          .pd__mb__product__image__block {
            position: relative;
            height: max-content;

            // height: 100%;
            // width: 100%;
            .pd__mb__product__image {
              height: 50%;
              width: 100%;
              object-fit: contain;
            }

            .soldOutLable {
              position: absolute;
              width: 100%;
              background: hsla(0, 0%, 85.1%, 0.76);
              top: 50%;
              left: 0px;
              transform: translateY(-50%);
              padding: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: 700;
              font-size: 13px;
              line-height: 1.1;
              color: rgba(0, 0, 0, 0.72);
              text-transform: uppercase;
              text-decoration: none;
              text-align: center;

              @media screen and (max-width:1220px) {
                padding: 5px 2px;
                font-size: 12px;
              }
            }
          }

          .pd__mb__product__detail__block {
            .pd__mb__product__name {
              text-decoration: none;
              color: #000000;
              display: block;
              margin-bottom: 10px;

              p {
                @media screen and (max-width:1280px) {
                  font-size: 14px;
                }
              }
            }

            .product__delivery__option__area {
              @media screen and (max-width:1480px) {
                flex-direction: column;
              }

              .product__delivery__itembox {
                padding: 5px 4px;

                @media screen and (max-width:1480px) {
                  margin-bottom: 5px;
                }

                &:last-child {
                  margin-bottom: 0px;
                }

                img {
                  margin-right: 4px;
                }

                span {
                  @media screen and (max-width:1600px) {
                    font-size: 9px;
                  }
                }
              }
            }

            .mb__pd__related__pricebox {
              display: flex;
              flex-direction: row;

              @media screen and (max-width:1480px) {
                flex-direction: column;
              }

              span {
                font-size: 14px;

                @media screen and (max-width:1500px) {
                  font-size: 13px;
                }
              }

              .text4 {
                color: #C8C8C8 !important;
                font-weight: normal;

                @media screen and (max-width:1480px) {
                  margin-left: 0px !important;
                }
              }
            }

            .heading7,
            .text4 {
              display: block;
              line-height: 1.1;
              font-family: 'ArialBold';
              font-weight: bold;
            }

            .pd__mb__product__oldPrice {
              margin: 0px 10px 0px 10px;
            }

          }

          .compare__cart_box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 6px;
            align-items: center;
            flex-wrap: wrap;

            @media screen and (max-width:1366px) {
              padding: 6px 10px;
            }

            .pd__compare__block {
              display: flex;
              align-items: center;
              margin: 0px 0px 0px;

              .pd__compare__input__check {
                accent-color: #000000;
                height: 15px;
                width: 15px;
                border-radius: 10px;
                margin: 0px 8px 0px 0px;
                // border: 15px solid #303030;
                text-transform: uppercase;
                cursor: pointer;

                @media screen and (max-width:1280px) {
                  height: 13px;
                  width: 13px;
                }
              }

              .pd__compare__text {
                font-size: 14px;
                font-family: "SSTregular";
                margin: 0px;
                cursor: pointer;
              }
            }

            .pd__relatedPro__item__buttonBox {
              text-align: right;

              @media screen and (max-width:1360px) {
                margin-left: auto;
              }

              .pd__related__action__btn {
                padding: 7px 10px;
                color: #ffffff;
                font-size: 12px;
                font-weight: bold;
                border-radius: 20px;
                text-transform: uppercase;
                border: none;
                line-height: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                text-align: center;

                img {
                  height: 14px;
                  margin-right: 6px;
                }

                &:hover {
                  box-shadow: 0 1px 2px #666666;
                }

                .spinner-border {
                  &.ms-3 {
                    margin-left: 7px !important;
                  }
                }
              }

              .pd__addToCart__button {
                border-radius: 20px;
                background-color: #dc3a1a;
                position: relative;
                margin-bottom: 0px;
                min-width: 115px;

                @media screen and (max-width:1360px) {}

                img {
                  width: 14px;

                  @media screen and (max-width:1360px) {
                    width: 13px;
                  }

                  // & + .spinner-border{
                  //   position: absolute;
                  //   height: 20px;
                  //   width: 20px;
                  //   background-color: #ffffff45;
                  //   border-radius: 50%;
                  //   left: 0px;
                  //   top: 0px;
                  //   margin: 5px !important;
                  //   padding: 0;
                  //   &.ms-3{
                  //     margin-left: 5px !important;
                  //   }
                  //   @media screen and (max-width:1360px){ 
                  //     height: 17px;
                  //     width: 17px;
                  //   }
                  // }
                }

                .btn__spinner {
                  min-width: 95.35px;
                  min-height: 14px;

                  @media screen and (max-width:1360px) {
                    min-width: 92px;
                  }

                  @media screen and (max-width:1280px) {}

                  @media screen and (max-width:580px) {}
                }
              }
              .outofStock__button{
                background-color: #a7a7a7;
                cursor: unset;
                line-height: 1.12;
                &:hover{
                  box-shadow: none;
                }
              }
              .pre-order {
                padding: 7px 10px;
                border-radius: 20px;
                background-color: #F3A100 !important;
                min-width: 104px;

                @media screen and (max-width:1360px) {
                  padding: 5px 8px;
                  font-size: 14px;
                }

                img {
                  width: 14px;

                  @media screen and (max-width:1360px) {
                    width: 13px;
                  }
                }
                .btn__spinner {
                  min-width: 83px;
                  min-height: 14px;

                  @media screen and (max-width:1360px) {
                    min-width: 83px;
                  }
                }
              }

              .notifyMe__button {
                background-color: #FE8B21;
                color: #ffffff;
                border-radius: 20px;
              }

              .pd__buy__button {
                background-color: #000000;
              }
            }
          }

        }

        .view__more__address__block {
          display: flex;
          flex-direction: column;
          padding-top: 15px;
          align-items: center;

          .view__more__address__button {
            display: flex;
            align-items: center;
            flex-direction: row;
            cursor: pointer;
            text-align: center;
            min-width: fit-content;
            font-size: 14px;
            font-family: 'SSTmedium';
            font-weight: 600;
            border-radius: 5px;
            border: none;
            color: #000000;
            background-color: #ffffff;
            outline: none;
            letter-spacing: 0.3px;
            margin-bottom: 0px;
            width: auto;


            &:hover {
              text-decoration: underline;
              text-decoration-color: #818181;
            }

            svg {
              height: 16px;
              width: auto;
              margin-left: 8px;
              color: #000000;

              @media screen and (max-width:1280px) {
                margin-left: 6px;
              }
            }


          }
        }
      }




      .pd__return__block {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        margin: 0px 0px 20px;
        border-radius: 10px;
        background-color: #F8F8F8;

        @media screen and (max-width:1280px) {
          padding: 8px 12px;
        }

        .pd__icon__block {
          width: 32px;
          margin: 0px 16px 0px 0px;

          .pd__return_period_icon {}
        }

        .pd__return__text__block {
          .pd__learn__more__link {
            font-size: 15px;
          }
        }
      }
    }

    .pd__newArrival__block {
      width: 100%;
      padding: 0px;
    }

    .pd__similarProducts__block {
      width: 100%;
      padding: 0px;
      margin: 40px 0px 40px;

      .section__title {
        font-size: 24px;
      }
    }
  }
}

.build__bundle__page__container {
  .products__details__inner__block {
    .product__details__block {
      position: relative !important;
      top: unset !important;
      left: unset !important;
    }
  }

  .pd__bundle__pro__itembox {
    .delivery__taken__area {
      margin: 0px;
    }

    .store__pickup__taken__area {
      margin: 0px;
    }

    .pd__bundle__pickup__offerbox {
      .store__pickup__location__listing {
        .locationCard {
          padding: 15px;
          border: 1px solid #e7e7e7;
        }

        .location__show__more__button {
          border: none;
          padding: 8px 10px;
          width: 100%;
          text-align: center;
          font-family: "SSTbold";
          color: #000000;
          font-size: 15px;
          background-color: unset;
          text-decoration: underline;
        }
      }
    }
  }


}

.pdp__sticky__add__to__cart__container {
  position: fixed;
  bottom: 0px;
  // height: 170px;
  width: 100%;
  background: #dcdcdc 0% 0% no-repeat padding-box;
  border-top: 1px solid #787878;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  .pdp__sticky__add__to__cart__block {
    display: flex;
    align-items: center;

    .pdp__sticky__counter__block {
      margin: 0px 20px;
      // height: 64px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border: 1px solid #D1D1D1;
      width: 130px;

      .counter__decrease__block {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .counter {
        display: flex;
        align-items: center;
        justify-content: center;
        border-right: 1px solid #D1D1D1;
        border-left: 1px solid #D1D1D1;
        font-size: 16px;
        padding: 3px;
      }

      .counter__increase__block {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .buynow___button {
      text-decoration: none;
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      font-family: 'SSTbold';
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 8px 30px;
      background-color: #000000;
      text-align: center;
      text-transform: uppercase;
      width: fit-content;
      border: none;
      margin: 0px 20px;
      // height: 64px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover,
      &:focus {
        -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }

    }

    // .build__bundle___button {
    //   text-decoration: none;
    //   border-radius: 5px;
    //   color: #ffffff;
    //   font-size: 15px;
    //   font-family: 'SSTbold';
    //   font-weight: bold;
    //   letter-spacing: 0.85px;
    //   padding: 8px 30px;
    //   background-color: #808080;
    //   text-align: center;
    //   text-transform: uppercase;
    //   width: fit-content;
    //   border: none;
    //   margin: 0px 20px;
    //   // height: 64px;
    //   &:hover,&:focus {
    //     -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
    //     box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);      
    //     transform: translateY(-2px);
    //     -webkit-transform: translateY(-2px);
    //     transition: all 0.2s ease-in-out;
    //     -webkit-transition: all 0.2s ease-in-out;
    //   }
    // }
    .build__bundle___button {
      text-decoration: none;
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      font-family: 'SSTbold';
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 8px 15px !important;
      background-color: #c11111;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      width: fit-content;
      border: none;
      justify-content: space-between;
      align-items: center;
      margin: 0px 20px;
      // height: 64px;
      cursor: pointer;
      position: relative;

      &:hover,
      &:focus {
        -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;

        &:before {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }

    .addToCart__button {
      text-decoration: none;
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      font-family: 'SSTbold';
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 8px 30px;
      background-color: #dc3a1a;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      width: fit-content;
      border: none;
      justify-content: space-between;
      align-items: center;
      margin: 0px 20px;
      // height: 64px;
      cursor: pointer;
      position: relative;

      .addToCart__icon {
        margin: 0px 10px 0px 0px;
        width: 16px;
      }

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #FFFFFF;
        border-radius: 5px;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }

      &:hover {
        -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }

      &:focus {
        color: #000000;
        -webkit-box-shadow: 0 3px 3px -4.8px rgb(32 32 32 / 60%);
        box-shadow: 0 3px 3px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;

        &:before {
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }

        .addToCart__icon {
          filter: brightness(0) invert(0);
        }
      }

    }

    .outofStock__button {
      text-decoration: none;
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      font-family: 'SSTbold';
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 8px 30px;
      background-color: #666666 !important;
      text-align: center;
      text-transform: uppercase;
      display: flex;
      width: fit-content;
      border: none;
      justify-content: space-between;
      align-items: center;
      margin: 0px 20px;
      // height: 64px;
      cursor: pointer;
      position: relative;
    }
  }
}



@media screen and (max-width: 1600px) {
  .product__carousel__block {}

  .product__details__right__block {
    padding: 0px 12px 0px 20px !important;
  }
}

@media screen and (max-width: 1500px) {
  .product__details__page__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}

@media screen and (max-width: 1450px) {
  .product__details__page__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}

@media screen and (max-width: 1400px) {
  .pd__mb__product__image {
    height: 114px !important;
  }

  .exp__rd__package__total__block {
    margin: 0px 0px 50px !important;
  }

  .pd__product__price__block .heading1 {
    font-size: 18px;
  }

  .pdp__sticky__add__to__cart__block .heading3 {
    font-size: 14px;
  }

  .product__details__page__container .product__details__page__block .product__details__left__block__new .products__details__inner__block .product__details__block {
    padding: 0px 20px;
  }

  .product__details__page__container .product__details__page__block .product__details__right__block {
    padding: 0px 12px 0px 30px;
  }
}

@media screen and (max-width: 1200px) {
  .pd__product__total__price {
    font-size: 24px !important;
  }

  .product__details__block {
    padding: 0px 10px 0px 10px !important;
  }

  .pdp__sticky__add__to__cart__block button {
    padding: 8px 12px !important;
    margin: 0 10px !important;
    min-width: 20%;
    // height: 54px !important;
  }

  .pdp__sticky__add__to__cart__container {
    height: 110px;
  }
}

@media screen and (max-width: 992px) {
  .product__details__right__block {
    display: none;
  }

  .product__details__page__block {
    width: 100% !important;
  }

  .product__carousel__block {}

  .pd__size__title {
    margin: 0px 0px 10px !important;
  }

  .pd__form__field {
    width: 100% !important;
  }

  .pd__input__field {
    width: 100% !important;
  }

  .pd__block__title {
    margin: 0px 0px 10px !important;
  }

  .exp__rd__package__total__block {
    margin: 0px 0px 20px !important;
  }

  .exp__rd__addToCart__button {
    padding: 10px !important;
    margin: 0px 0px 10px !important;
  }

  .delivery__wrapper {
		  padding-top: 10px;
		  margin: 0 25px;
	}
}

@media screen and (max-width: 768px) {
  .product__carousel__block {
    margin: 0px 0px 30px 0px !important;
  }

  .product__details__block {
    padding: 0px !important;
  }

  .pd__save__price__button {
    margin: 0px !important;
  }

  .pd__input__check {
    height: 20px !important;
    width: 20px !important;
  }
}

@media screen and (max-width: 576px) {
  .product__carousel__block {}

  .product__details__block {
    padding: 0px !important;
  }

  .pd__product__name {
    font-size: 20px !important;
  }

  .pd__product__total__price {
    font-size: 24px !important;
  }

  .pd__rating__block {
    margin: 0px 0px 20px !important;
  }

  .pd__size__button__active {
    font-size: 12px !important;
    // padding: 10px 10px !important;
    // margin: 0px 10px 10px 0px !important;
  }

  .pd__size__button {
    font-size: 12px !important;
  }

  .pd__input__field {
    padding: 6px 5px 7px 15px !important;
  }

  .pd__input__button {
    padding: 6px 30px !important;
  }

  .pd__pickup__text {
    width: 80% !important;
  }

  .pd__bundle__button__block {
    padding: 0px 6px 0px 0px !important;
  }

  .pd__addToCart__button__block {
    padding: 0px 0px 0px 6px !important;
  }

  .pd__bundle__button {
    font-size: 14px !important;
  }

  .pd__addToCart__icon {
    margin: 0px 6px 0px 0px !important;
  }

  .pd__addToCart__button {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 480px) {
  .product__carousel__block {}

}

@media screen and (max-width: 414px) {
  .product__carousel__block {}

  .pd__pickup__text {
    width: 100% !important;
  }
}

@media screen and (max-width: 390px) {
  .product__carousel__block {}
}

@media screen and (max-width: 375px) {
  .product__carousel__block {}
}

// .col-4.counter__increase__block {
//   background: black;
//   color: #fff;
//   padding: 9px;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
// }


// .col-4.counter__increase__block img {
//   color: #ffffff !important;
//   fill: #fff !important;
//   filter: brightness(10);
// }


// .col-4.counter__decrease__block {
//   background: black;
//   color: #fff;
//   padding: 9px;
//   border-top-left-radius: 5px;
//   border-bottom-left-radius: 5px;
// }
// input[type="number"] {
//   width: 35px;
//   padding: 0px 4px;
//   font-size: 14px;
//   text-align: center;
//   border: 1px solid #000000;

//   &::-webkit-inner-spin-button,
//   &::-webkit-outer-spin-button {
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     margin: 0; 
//   }
//   &:focus{                
//     outline: none;
//   }
// }

// .col-4.counter__decrease__block img {
//   color: #ffffff !important;
//   fill: #fff !important;
//   filter: brightness(10);
// }

// .col-4.counter__increase__block:hover {
//   background: #ff6421;
//   transition: all 0.3s ease-in-out;
//   -webkit-transition: all 0.3s ease-in-out;
// }

// .col-4.counter__decrease__block:hover {
//   background: #ff6421;
// transition: all 0.3s ease-in-out;
//   -webkit-transition: all 0.3s ease-in-out;
// }


// .row.sc__counter__box {
//   border: none !important;
// }

.delete__icon__button__block img:hover {
  color: red !important;
  fill: red !important;
  filter: invert(1);
}

.cms-content {
  max-width: 1420px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
}

.cms-content h2 {
  padding-bottom: 25px;
}
.productOfferTag{
  color: #fff;
  background: #dc3a1a;
  padding: 10px 12px;
  font-size: 14px;
  text-align: center;
  width: auto !important;
  max-width: 100%;
  // position: absolute;
  bottom: 115px;
  left: 15px;
  word-break: break-word;
  display: inline-block;
  margin: 15px auto;
}
.productOfferTag p{
  margin: 0px;
}
// body[dir="rtl"] .productOfferTag{
//   left:auto;
//   right: 15px;
// }
/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .product__details__page__container {
    .product__details__page__block {
      .product__details__left__block__new {
        .products__details__inner__block {
          .product__details__block {

            .pd__unlock__membership__block {
              .pd__unlock__membership {
                .pd__icon__block {
                  margin: 0px 0px 0px 15px;
                }
              }
            }

            .pd__category__favourite__button__block {
              .pd__category__button {
                margin: 0px 0px 10px 14px;
              }
            }

            .rating__and__sku__area {
              .product__sku__box {
                .text3 {
                  margin-left: 0px !important;
                  margin-right: 5px !important;
                }

                .pro__sku__copy {
                  margin-left: 0px;
                  margin-right: 10px;
                }
              }
            }

            .loyalty__points__area {
              .col-md-6.pe-1 {
                padding-left: 0.25rem !important;
                padding-right: 15px !important;
              }

              .col-md-6.ps-1 {
                padding-left: 15px !important;
                padding-right: 0.25rem !important;
              }

              .loyalty__item__box {
                .loyalty__contentbox {
                  margin-right: 10px;
                }
              }
            }

            .pd__delivery__block {
              .pd__form__block {
                .pd__form__field {
                  .pd__input__field {
                    border-radius: 0px 4px 4px 0px;
                  }

                  .pd__input__button {
                    border-radius: 4px 0px 0px 4px;
                  }
                }
              }
            }

            .pd__Allbuttons__area {

              .pd__buy__button__block {
                padding: 0px 0px 0px 12px !important;

                @media screen and (max-width:1200px) {
                  padding: 0px 0px 0px 10px !important;
                }

                .pd__buy__button {
                  font-family: 'SSTArabicBold' !important;
                  &.pd__apple__buy__btn{
                    flex-direction: row-reverse;
                    svg{
                      margin: 0px 3px 2px;                  
                    }
                  }
                }
              }

              .pd__qty__block {
                padding: 0px;
              }

              .pd__addToCart__button__block {
                padding: 0px 0px 0px 12px !important;

                @media screen and (max-width:1200px) {
                  padding: 0px 0px 0px 10px !important;
                }

                .pd__addToCart__button {
                  font-family: 'SSTArabicBold';

                  .pd__addToCart__icon {
                    margin: 0px 0px 0px 12px;

                    @media screen and (max-width:1350px) {
                      margin: 0px 0px 0px 10px;
                    }
                  }

                  .spinner-border {
                    margin-left: 0px !important;
                    margin-right: 10px !important;

                    @media screen and (max-width:1420px) {
                      margin-left: 0px !important;
                      margin-right: 8px !important;
                      width: 0.8rem;
                      height: 0.8rem;
                    }

                    @media screen and (max-width:1280px) {
                      margin-left: 0px !important;
                      margin-right: 6px !important;
                      width: 0.7rem;
                      height: 0.7rem;
                    }
                  }
                }
                
                .notifyMe__button {
                  font-family: 'SSTArabicBold';

                  @media screen and (max-width:1366px) {
                    font-size: 14px;
                  }

                  img {
                    margin: 0px 0px 0px 10px;

                    @media screen and (max-width:1200px) {
                      margin: 0px 0px 0px 8px;
                    }
                  }
                }
              }

              .pd__bundle__button__block {
                padding: 0px 0px 0px 6px !important;

                @media screen and (max-width:1200px) {
                  padding: 0px !important;
                }
              }
            }

            .my_reward_icon {
              img {
                float: right;
                margin-left: 13px;
                margin-right: 0px !important;
              }
            }

            .exp__rd__block {
              .exp__rd__main__block {
                .exp__rd__package__block {
                  .exp__rd__package__inner__block {
                    .exp__rd__addToCart__button {
                      .exp__rd__addToCart__icon {
                        margin: 0px 0px 0px 7px;
                      }
                    }
                  }
                }
              }
            }

            .pdp__sticky__counter__block {
              .col-4.counter__decrease__block {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
              }

              .counter {
                padding: 0px !important;

                input {
                  border: unset;
                  height: 30px;
                }
              }

              .col-4.counter__increase__block {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
              }
            }

            .pd__prod__size__install__area {
              .pd__install__info__area {
                strong {
                  margin-left: 0px;
                  margin-right: 5px;
                }
              }

              .pd__warranty__size__block {
                .pd__size__block {
                  .pd__size__button__block {
                    .pd__size__button {
                      margin: 0px 0px 8px 6px;
                    }

                    .pd__size__button__active {
                      margin: 0px 0px 8px 6px;
                    }
                  }
                }
              }
            }

            .pd__total__price__block {
              .inner_price_section {
                display: flex;
                float: unset;
                margin-right: 0px;
                margin-left: 10px;

                .heading4 {
                  @media screen and (max-width:1200px) {
                    font-size: 23px !important;
                  }
                }

                .text3 {
                  padding-left: 0px;
                  padding-right: 10px;
                }
              }

              .save-amount-box {
                margin-right: 0px;
                margin-left: 10px;
              }

              .save_price_rating {
                display: flex;
                justify-content: flex-start;
              }
            }

            .pd__product__price__block {
              .pd__product__monthly__price__block {
                text-align: left;
              }
            }

            .product__details__titlebox {

              .pd__favourite__button,
              .pd__share__button {
                float: left;
              }

              .pd__favourite__button {
                margin-left: 0px;
              }
            }
            .pd__prod__size__install__area {
              .pd__install__info__area {
                .install__option__area {
                  .install__Ans {
                    label {
                      margin-left: 0px;
                      margin-right: 5px;
                    }
                  }
                }
              }
            }


          }

          .carousel__main__container {
            .carousel__dots__main__block {
              margin: 0px 0px 0px 10px;
            }
          }

          .product__carousel__main__block {
            .product__carousel__block {
              .pd__social__media__compare__block {
                .pd__compare__block {
                  .pd__compare__input__check {
                    margin: 0px 0px 0px 10px;
                  }

                  .pd__compare__text {
                    font-family: 'SSTArabic';
                  }
                }

                .pd__social__media__icon__block {
                  .pd__share__text {
                    margin: 0px 0px 0px 20px;
                    font-family: 'SSTArabicBold';
                    font-size: 14px;
                  }
                }
              }
            }
          }





        }

        .accordian__block {
          padding-right: 0px;
          margin-right: 0px;
          padding-left: 10px;
          margin-left: 10px;
          width: 97%;
        }
      }

      .product__details__right__block {
        padding: 0px 20px 0px 12px !important;
        border-left: none;
        border-right: 1px solid #eeeeee;

        .pd__return__block {
          .pd__icon__block {
            margin: 0px 0px 0px 15px;
          }
        }

        .pd__mb__product__block {
          .pd__mb__product__detail__block {
            .mb__pd__related__pricebox {
              .text4 {
                margin: 0px 5px 0px 0px;
              }
            }

            .pd__compare__block {
              .pd__compare__input__check {
                margin: 0px 0px 0px 8px;
              }
            }

            .pd__relatedPro__item__buttonBox {
              text-align: left;
            }
          }
        }

        .pd__related__product__section {
          .pd__mb__block__title {
            font-family: 'SSTArabicBold';
            font-size: 18px;
          }

          .pd__mb__product__block {
            .pd__mb__product__detail__block {
              .heading7 {
                font-family: 'SSTArabicBold';
              }

              .mb__pd__related__pricebox {
                .text4 {
                  margin-left: 0px !important;
                  margin-right: 5px !important;
                }
              }

            }

            .product__delivery__option__area {
              .product__delivery__itembox {
                padding: 5px 4px;

                &:last-child {
                  margin-right: 5px;
                }

                img {
                  margin-left: 4px;
                }

                span {
                  @media screen and (max-width:1480px) {
                    font-size: 10px;
                  }
                }
              }
            }

            .compare__cart_box {
              .pd__compare__block {
                .pd__compare__input__check {
                  margin: 0px 0px 0px 8px;
                }

                .pd__compare__text {
                  font-family: 'SSTArabic';
                }
              }

              .pd__relatedPro__item__buttonBox {
                text-align: left;

                @media screen and (max-width:1360px) {
                  margin-left: 0px;
                  margin-right: auto;
                }

                button {
                  font-family: 'SSTArabicBold';
                  font-size: 13px;
                }

                .pd__addToCart__button {
                  min-width: 122px;

                  .btn__spinner {
                    min-width: 101.8px;
                    min-height: 14px;
                  }
                }
                .pre-order{
                  min-width: 106px;
                }
                .pd__related__action__btn {
                  img {
                    margin-right: 0px;
                    margin-left: 6px;
                  }
                }
              }
            }
          }

          .view__more__address__block {
            .view__more__address__button {
              svg {
                margin-left: 0px;
                margin-right: 8px;

                @media screen and (max-width:1280px) {
                  margin-right: 6px;
                }
              }
            }
          }
        }

      }



    }
  }

  .pdp__sticky__add__to__cart__container {
    .pdp__sticky__add__to__cart__block {
      .addToCart__button {
        margin: 0px 0px 0px 10px;

        .addToCart__icon {
          margin: 0px 0px 0px 10px;
        }
      }

      .build__bundle___button {
        padding: 8px 20px !important;
      }
    }
  }

  .col-4.counter__decrease__block {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .col-4.counter__increase__block {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

/*-------------------------- Arabic view End ------------------------*/