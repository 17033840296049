.margin-alert{
  width: 90%;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.cart__popup__block {
  position: absolute;
  right: 0px;
  width: 407px;
  top: 0px;
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  overflow-y: hidden;
  padding: 0px 0px 400px;

    @media screen and (max-width:576px) {
      width: 94%;
    }
  .cart__popup__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px;
    border-bottom: 1px solid #818181;
    background-color: #ffffff;

    .heading3{ line-height: 1; }    
    .cancel__button {
      cursor: pointer;
    }
  }

  .cart__popup__content {
    overflow-y: auto;
    height: 96vh;
    margin: 0px 0px 400px;

    .cart__popup__listing__area{
      max-height: 82vh;
      overflow: scroll;
      overflow-x: hidden;
      margin-right: 3px;
      @media screen and (max-width:576px) {
        max-height: 75vh;        
      }
      @media screen and (max-height:1000px) {
        max-height: 75vh;        
      }
      @media screen and (max-width:320px) {
        max-height: 62vh;        
      }
      .cart__popup__product__block {
        padding: 0px 20px;
        display: flex;
        align-items: flex-start;
        margin: 20px 0px 0px;
        position: relative;
  
        @media screen and (max-width:580px) {
          padding: 0px 10px;
        }
  
        .cart__popup__product__image__block {
          // overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
  
          .cart__popup__product__image {
            width: 80%;
            @media screen and (max-width:421px) {
              width: 100%;
            }
          }
        }
        p.heading7 {  
          @media screen and (max-width:480px) {
            font-size: 15px;
            line-height: 1.4;
          }
        }
        .align-middle{
          display: flex;
          align-items: center;
          img{
            width: 16px;
            height: 16px;
          }
          .text4{ margin-left: 10px !important; }
        }
        .cart__popup__loading{
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;     
          left: 0px;
          top: 0px;
          background-color: #ffffff47;     
        }
        .sc__counter__block {        
          .sc__counter__box {
            width: 105px;
            .counter__decrease__block, .counter__increase__block {
                padding: 6px !important;
                width: 30%;                
                height: 27px;
                border-radius: 0px;
            }
            input[type="number"] {
              width: 40%;
              border-radius: 0px;
            }
            .counter__decrease__block {
              img{
                width: 14px;
              }
            }
            .counter{
              font-size: 15px;
            }
          }
          .delete__icon__button__block{
            width: 17px;
            img {
              cursor: pointer;
              &:hover{
                filter: invert(48%) sepia(13%) saturate(3757%) hue-rotate(328deg) brightness(95%) contrast(80%);
              }
            }
          }
        }
      }
    }
    
    &.apple_pay_active{

      .cart__popup__listing__area{
        max-height: 67vh;
        overflow: scroll;
        overflow-x: hidden;
        margin-right: 3px;
        @media screen and (max-width:576px) {
          max-height: 69vh;        
        }
        @media screen and (max-height:1380px) {
          max-height: 76vh;        
        }
        @media screen and (max-height:1200px) {
          max-height: 73vh;        
        }
        @media screen and (max-height:1150px) {
          max-height: 69vh;        
        }
        @media screen and (max-height:1000px) {
          max-height: 66vh;        
        }
        @media screen and (max-height:800px) {
          max-height: 57vh;        
        }
        @media screen and (max-height:600px) {
          max-height: 47vh;        
        }
        @media screen and (max-width:320px) {
          max-height: 55vh;        
        }
      }
    }

    .cart__total__block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px 20px;
      .heading6 {
        font-size: 17px;
      }
    }
    .cart__discount__block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 20px 0px;
      .heading6 {
        font-size: 14px;
      }
    }
    .visa__notes__box{
      margin: 0px 20px 15px !important;
      padding: 15px 10px;

      
      @media screen and (max-width:480px) {
        padding: 10px 10px;
      }
      img {
        height: 20px;
      }
      
    }

    .cart__popup__button__block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px 0px 10px;
      padding: 0px 20px;

      @media screen and (max-width:480px) {
        padding: 0px 18px;
      }
      @media screen and (max-width:321px) {
        display: flex;
        flex-direction: column;
      }
      .pd__apple__buy__btn{
        width: 100%;
        border-radius: 5px;
        color: #ffffff;
        font-size: 16px;
        font-family: 'SSTbold';
        font-weight: bold;
        letter-spacing: 0.85px;
        padding: 10px 20px;
        background-color: #000000;
        text-align: center;
        border: none;
        text-decoration: none;

        @media screen and (max-width:420px) {
          padding: 8px 8px;
          font-size: 14px;
        }
        svg {
          width: 14px;
          height: 14px;
          margin-right: 5px;
          margin-top: -3px;
        }
        &:disabled{
          background-color: #707070;
          opacity: 0.65;
        }
      }
      .checkout__button {
        width: 48%;
        border-radius: 5px;
        color: #ffffff;
        font-size: 16px;
        font-family: 'SSTbold';
        font-weight: bold;
        letter-spacing: 0.85px;
        padding: 10px 20px;
        background-color: #dc3a1a;
        text-align: center;
        border: none;
        text-decoration: none;        

        @media screen and (max-width:420px) {
          padding: 8px 8px;
          font-size: 14px;
        }
        @media screen and (max-width:321px) {
          width: 100%;
          margin-bottom: 10px;
        }
        &:hover{
          box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
          transform: translateY(-2px);
          -webkit-transform: translateY(-2px);
          transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;
          opacity: 0.8;
        }
      }

      .continue__shopping__button {        
        width: 49%;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000005;
        border: 2px solid #000000;
        border-radius: 5px;
        color: black;
        outline: none;
        font-size: 16px;
        font-family: 'SSTbold';
        font-weight: bold;
        padding: 8px 8px;
        text-decoration: none;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width:420px) {
          padding: 8px 6px;
          font-size: 14px;          
        }
        @media screen and (max-width:350px) {
          font-size: 13px;
        }
        @media screen and (max-width:321px) {
          width: 100%;         
        }
        &:hover{
          box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
          transform: translateY(-2px);
          -webkit-transform: translateY(-2px);
          transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;
          // opacity: 0.8;
        }
      }
    }

    .cart__popup__frequently__bought__togather {
      background-color: #f9f9f9;
      padding: 20px 20px 100px;

      .heading5 {
        font-size: 18px;
      }

      .carouselTypeTwo__container {
        margin: 20px 0px 0px;
        .arrow__block{
          .arrow{
            padding: 6px 10px;
            display: flex;
          }
          .left__arrow{
            img{ width: 10px; }
          }
          .right__arrow {
            img{ width: 10px; }
            transform: translateX(49%);
          }
        } 

        .cart__popup__frequentlyBoughtTogether__block{
          align-items: flex-start;
          .productEleven__block{
            .addToCart__button{
              background-color: transparent;
              padding: 0px;
              img {
                filter: invert(41%) sepia(90%) saturate(3401%) hue-rotate(356deg) brightness(97%) contrast(105%);
              }
              .spinner-border.text-light{
                color: #000000 !important;
                margin-left: 0px !important;
              }
            }
          }
        }
      }
    }
  }

  .cart__popup__content::-webkit-scrollbar {
    width: 0px !important;

    /* transform: translateY(100%); */
  }

}

/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {

  .cart__popup__block {
    right: auto;
    left: 0px;
    .cart__popup__content {
      .cart__popup__listing__area{
        margin-right: 0px;
        margin-left: 3px;
        .cart__popup__product__block {
          .align-middle {
            .text4{
              margin-left: 0px !important;
              margin-right: 10px !important;
            }
          }
          .sc__counter__box{
            margin-left: 20px;
            margin-right: 0px;
          }
        }
      }
      .checkout__button {
        padding: 10px 8px;
        @media screen and (max-width:400px) {
          width: 49%;
        }
        @media screen and (max-width:321px) {
          width: 100%;
        }
      }
      .continue__shopping__button {
        @media screen and (max-width:480px) {
          line-height: 1.22;
        }
      }
      .cart__popup__button__block {
        .continue__shopping__button,
        .checkout__button {
          font-family: 'SSTArabicBold';
          font-size: 15px;
          @media screen and (max-width:480px) {
            font-size: 14px;
            padding: 10px 5px;
          }
          @media screen and (max-width:400px) {
            font-size: 13px;
          }
          @media screen and (max-width:380px) {
            font-size: 12.5px;
          }
        }
        .pd__apple__buy__btn {
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;
          align-items: center;
          svg{
            margin-left: 0px;
            margin-right: 3px;
          }
        }
        
      }
    }
  }
  

}
/*-------------------------- Arabic view end ------------------------*/
