.product__list__page__block {
  .banner__section__one {
    background: #000000;
    // height: 200px;
    width: 100%;
    margin: 0px auto;
    padding: 0% 4%;
    .banner__section__one__title__block {
      // background: #F2F2F2;
      display: flex;
      align-items: center;
      .banner__section__one__title {
        margin: 2rem 0rem;
        font-family: 'SSTbold';
        font-weight: bold;
        font-size: 30px;
        color: #ffffff;        
      }
    }
    .banner__section__one__image__block {
      // height: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      .banner__section__one__image {
        // margin-top: 40px;
        // width: 328px;
        object-fit: contain;
        max-height: 130px;
        margin-top: 10px;
        display: none;
        @media screen and (max-width: 1200px) {
          max-height: 100px;
        }
      }
    }
  }
}
/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .product__list__page__block {
    .banner__section__one {
      .banner__section__one__title__block {
        .banner__section__one__title{
          font-family: 'SSTArabicBold';
          font-size: 22px;     
        }
      }
    }
  }

}

/*-------------------------- Arabic view End ------------------------*/
  

