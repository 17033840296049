.product__comparison__page__container {
  background-color: #f5f4f4;
  padding: 0px 12px 0px;
  @media(max-width:992px) { padding: 0px 0px 0px; }
  
  .product__comparison__page__block {
    padding: 0px 0px;
    width: 1420px;
    margin: 0px auto;
  }
}

@media screen and (max-width: 1500px) {
  .product__comparison__page__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .product__comparison__page__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 992px) {
  .product__comparison__page__block {
    width: 100% !important;
  }
}
