.bundle_city_wrapper {
    z-index: 100;
  
  //   background-color: #F7F7FA;
    position: relative;
  
    .info {
      padding: 5px 28px;
      color: #8D8E8F;
    }
  
    @media (max-width: 1200px) {
      width: 100vw;
      height: 90vh;
    }
  
    .deliverySelection {
      display: block;
      padding: 0px 22px;
  
      .cityName {
        font-size: 22px;
        color: #6212BA;
        padding: 5px;
        font-weight: 500;
      }
  
      .deliveryOption {
        display: flex;
        margin: 10px 0px;
        cursor: pointer;
  
        &::before {
          display: flex;
          margin: 0px 5px;
          content: "";
          align-items: center;
          justify-content: center;
          border: 1px solid #979797;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          font-size: 12px;
          cursor: pointer;
        }
  
        &.selected::before {
          background-color: #0091FF;
          border-color: #3EACFF;
          content: "●";
          color: #FFF;
          font-size: 20px;
          padding-left: 1px;
          padding-bottom: 2px;
  
          html[dir="rtl"] & {
            padding-left: 0px;
            padding-bottom: 2px;
          }
        }
      }
    }
  
    .storeCards {
      display: grid;
      padding: 13px;
      max-height: 40vh;
      overflow-y: auto;
      grid-template-columns: 1fr 1fr;
  
      @media (max-width: 1200px) {
        grid-template-columns: 1fr;
      }
  
      .notFound {
        display: flex;
        position: absolute;
        width: 100%;
        justify-content: center;
        top: 65%;
        font-weight: 500;
        color: #A8B6C8;
        font-size: 24px;
        z-index: -1;
      }
    }
  
    .inner {
      display: flex;
      flex-direction: column;
      width: 100%;
  
      .title {
        display: flex;
        align-items: center;
        padding: 20px 35px 20px 35px;
  
        label {
          flex: 1;
          font-weight: 500;
        }
        .closeIcon {
          width: 22px;
          height: 22px;
          // background-image: url("/assets/img/closeIcon.png");
          background-size: 100% 100%;
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
          -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
        }
      }
  
      .search {
        margin: 20px 35px;
        min-height: 55px;
        max-width: calc(100% - 70px);
        border: none;
      }
  
      .items {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: auto;
        max-height: 50vh;
        position: absolute;
        width: 93%;
        margin: 30px 35px 0 35px;
        top: 135px;
        z-index: 100;
        background: #FFF;
        border: 2px solid transparent;
        border-radius: 9px;
  
        @media (max-width: 1200px) {
          max-height: 59vh;
        }
  
        @media (max-width: 425px) {
          width: 83%;
        }
  
        @media (min-width: 426px) and (max-width: 767px) {
          width: 88%;
        }
  
        @media (min-width: 991px) and (max-width: 1440px) {
          width: 91%;
        }
  
        .item {
          display: flex;
          margin: 5px;
          align-items: center;
          padding: 5px 20px;
          line-height: 2.5;
          opacity: 1;
          cursor: pointer;
          // border: 2px solid #979797;
          border-radius: 10px;
  
          span {
            flex: 1;
          }
  
          &.selected,
          &:hover {
            // border: 2px solid #0091FF;
          }
  
          &:after {
            display: flex;
            content: "";
            align-items: center;
            justify-content: center;
            border: 1px solid #979797;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            font-size: 12px;
          }
  
          &.selected:after {
            background-color: #dc3a1a;
            border-color: #dc3a1a;
            content: "●";
            color: #FFF;
            font-size: 15px;
            padding: 1px 0px 0px 1px;
          }
        }
      }
    }
  
    // .buttonContainer {
    //   display: flex;
    //   justify-content: flex-end;
    //   margin: 0 20px 20px 0;
    //   .confirm {
    //     display: flex;
    //     width: 100%;
    //     justify-content: center;
    //     padding: 5px 17px;
    //     position: absolute;
    //     bottom: 20px;
    //     max-width: 120px;
    //     background: #dc3a1a;
    //     border-radius: 8px;
    //     height: 50px;
    
    //     .confirmButton {
    //       border: none;
    //       cursor: pointer;
    //       color: #FFF;
    //       background: none;
    //       font-size: 18px;
    //       font-weight: bold;
    //       // padding: 17px;
    //     }
    //   }
    // }
  
    .confirm {
      display: flex;
      // width: 100%;
      justify-content: flex-end;
      // padding: 20px 35px;
      // position: absolute;
      // bottom: 0;
      // background: #F7F7FA;
  
      // @media (max-width: 1200px) {
      //   padding: 17px;
      // }
  
      .confirmButton {
        border: none;
        cursor: pointer;
        color: #FFF;
        border-radius: 8px;
        background: #dc3a1a;
        font-size: 18px;
        font-weight: bold;
        padding: 0px 10px;
        height: auto;
      }
    }
  }
  