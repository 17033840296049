.inner__personal__block{
  padding: 5px 16px;
.profile__email__block,
.profile__mobile__block,
.profile__firstname__lastname {
  display: flex;
  align-items: center;

  .main__form__field__block {
    width: 370px;
    // height: 110px;
    position: relative;
    margin: 0px 20px 0px 0px;
    
    .profile__mobile__form__field{
      width: 100%;
      border: 1px solid #c8c8c8;
      border-radius: 4px;
      padding: 6px 15px 7px 50px;
    }
    .form__label {
    }
    .field__block {      
      display: flex;
      justify-content: space-between;
      align-items: center;      
      margin-bottom: 18px;

      .form__field {        
        width: 100%;
        outline: none;
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        padding: 6px 15px;
        font-size: 16px;
        letter-spacing: 0.3px;
        &:focus {
          background-color: #ffffff;
          border-color: #333333;
        }
      }
    }
    .react-tel-input{
      margin-bottom: 18px;
    }
    .invalid__message {
      color: red;
      margin: 0px;      
      position: absolute;
      font-size: 14px;
      width: 100%;
      height: auto;
      bottom: -2px;
      line-height: 1.2;
      letter-spacing: 0.5px;
    }
  }

  .form__edit__block {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;

    .heading6{
      font-size: 15px;
    }
    img{ width: 20px; }

  }
}
.profile__gender__block {
  display: flex;
  align-items: center;
  margin: 0px 0px 20px;
  .delivery__input__check {
    accent-color: #ff4f04;
    height: 16px;
    width: 16px;
    max-width: 16px;
    max-height: 16px;
    border-radius: 10px;
    margin: 0px 10px 0px 0px;
    // border: 16px solid #303030;
    text-transform: uppercase;
  }
  .delivery__selection__text {
    margin: 0px 20px 0px 0px;
    font-family: "SSTregular";
  }
}
.profile__preferences__block {
  margin: 30px 0px;
  .preferences__select__block {
    display: flex;
    align-items: center;
    // margin: 0px 0px 20px 0px;
    .preferences__select__input__check {
      accent-color: #000000;
      // height: 25px;
      // width: 25px;
      text-align: left;
      border-radius: 10px;
      margin: 0px 16px 0px 0px;
      // border: 30px solid #303030;
      text-transform: uppercase;
    }
    .preferences__select__text {
      font-size: 18px;
      font-family: "SSTregular";
      margin: 0px;
      width: 400px;
    }
  }
}
.profile__form__button__block {
  margin: 20px 0px 0px;
  .form__save__button {
    text-align: center;
    font-size: 14px;
   font-family: 'SSTbold';
font-weight: bold;
    border-radius: 5px;
    min-width: 150px;
    color: #ffffff;
    padding: 10px 25px;
    background-color: #dc3a1a;
    margin: 0px 12px 10px 0px;
    outline: none;
    border: none;
    letter-spacing: 0.3px;
    cursor: pointer;
    text-transform: uppercase;
    &:hover,&:focus{
      box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
    .btn__spinner{
      min-height: 21px;        
    }
  }
  .form__cancel__button {
    cursor: pointer;
    text-align: center;
    min-width: 150px;
    font-size: 14px;
   font-family: 'SSTbold';
font-weight: bold;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 10px 25px;
    color: #808080;
    background-color: #ffffff;
    margin: 0px 12px 10px 0px;
    outline: none;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    &:hover,&:focus{
      box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
      transform: translateY(-2px);
      -webkit-transform: translateY(-2px);
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
    }
  }
}
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {
  
  .inner__personal__block .profile__form__button__block .form__cancel__button,
  .inner__personal__block .profile__form__button__block .form__save__button{
      margin: 0px 0px 10px 12px;

  }

  .inner__personal__block {
    .profile__email__block {
      .form__edit__block {
        .heading6{ margin: 0px 5px 0px 0px !important; }
      } 
      .main__form__field__block {
        .field__block {
          .form__field{
            direction: ltr;
            text-align: right;
          }
        }
      }

    }
    .profile__mobile__block {
      .form__edit__block {
        .heading6{ margin: 0px 5px 0px 0px !important; } 
      }
      .main__form__field__block {
        .field__block {
          .form__field{
            direction: ltr;
            text-align: right;
          }
        }
      }
    }
    .profile__firstname__lastname {
      .form__edit__block {
        .heading6 { margin: 0px 5px 0px 0px !important; }
      }
    }
    .profile__preferences__block{
      .heading3,p {
        font-size: 21px;
      }
    }
    .profile__form__button__block{
      .form__cancel__button,.form__save__button{
        font-family: 'SSTArabicBold';
      }
    }
  }
  
  
}

/*-------------------------- Arabic view End ------------------------*/