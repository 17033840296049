.address__select__block {
  padding: 0px 0px 50px;
  margin: 20px 0px;
  border-bottom: 1px solid #818181;
  .address__block {
    padding: 0px 16px;
    .selected__address__inner__block {
      box-shadow: 0px 3px 6px #00000029;
      border: 2px solid #000000;
      padding: 20px 30px;
      margin-bottom: 20px;
      .address__title__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0px 0px 20px 0px;
        .address__tag {
          width: fit-content;
          padding: 8px 18px;
          background: #eeeeee;
          border: 1px solid #eeeeee;
          border-radius: 3px;
          line-height: 1.2;
        }
        .white__address__tag {
          width: fit-content;
          padding: 10px 20px;
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 3px;
        }
        .inner__address__button__block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .edit__button,
          .delete__button {
            outline: none;
            border: none;
            cursor: pointer;
            background-color: white;
          }
        }
        .full__address {
          width: 30%;
        }
      }
    }
    .address__inner__block {
      background: #ffffff;
      box-shadow: 0px 3px 6px #00000029;
      border: 2px solid #d1d1d1;
      padding: 10px 10px 30px 30px;
      margin-bottom: 20px;
      .address__title__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0px 0px 20px 0px;
        .address__tag {
          width: fit-content;
          padding: 8px 18px;
          background: #eeeeee;
          border: 1px solid #eeeeee;
          border-radius: 3px;
          span {
            color: #808080;
            font-size: 14px;
          }
        }
        .white__address__tag {
          width: fit-content;
          padding: 10px 20px;
          background: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 3px;
        }
        .inner__address__button__block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .edit__button,
          .delete__button {
            background-color: white;
            margin: 0px 0px 10px 0px;
            outline: none;
            border: none;
            letter-spacing: 0.3px;
            cursor: pointer;
            text-transform: uppercase;
            img{ width: 26px; }
          }
        }
        .full__address {
          width: 30%;
        }
      }
    }
  }
}
.newAddress__block {
  max-width: 1200px;
  padding: 6px;
  .newAddress__title__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 14px 20px;
    background-color: #ffffff;
    .cancel__button {
      cursor: pointer;
    }
  }
  .newAddress__form__field__row {
    margin: 0px 0px 0px 0px;
    // height: 110px;
    .main__form__field__block {
      margin: 0px 0px 10px;
      .profile__mobile__form__field{
        width: 100%;
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        padding: 6px 15px 7px 50px;        
        &:focus {
          background-color: #ffffff;
          border-color: 1px solid #333333;
        }
      }
      .form__label {
      }
      .field__block {      
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
  
        .form__field {        
          width: 100%;
          outline: none;
          border: 1px solid #c8c8c8;
          border-radius: 4px;
          padding: 6px 15px;
          font-size: 16px;
          letter-spacing: 0.3px;
          &:focus {
            background-color: #ffffff;
            border-color: #333333;
          }
        }
      }
      .invalid__message {
        color: red;
        margin: 3px 0px 0px;
        font-size: 12px;
      }
    }
  }
  .addressType__block {
    display: flex;
    align-items: center;
    padding: 0px 14px 0px;
    .delivery__input__check {
      accent-color: #ff4f04;
      height: 16px;
      width: 16px;
      max-width: 16px;
      max-height: 16px;
      border-radius: 10px;
      margin: 0px 10px 0px 0px;
      // border: 16px solid #303030;
      text-transform: uppercase;
      cursor: pointer;
    }
    .delivery__selection__text {
      margin: 0px 20px 0px 0px;
      cursor: pointer;
    }
  }
  .preferences__select__block {
    display: flex;
    align-items: center;
    margin: 20px 0px 20px 0px;
    padding: 0px 14px 0px;
    .preferences__select__input__check {
      accent-color: #DC3A1A;
      height: 16px;
      width: 16px;
      border-radius: 10px;
      margin: 0px 10px 0px 0px;
      // border: 30px solid #303030;
      text-transform: uppercase;
      cursor: pointer;
    }
    .preferences__select__text {
      font-size: 16px;
      font-family: "SSTregular";
      margin: 0px;
      width: 400px;
      cursor: pointer;
    }
  }
  .newAddress__form__button__block {
    margin: 0px;
    padding: 10px 0px 0px 10px;
    @media screen and (max-width:400px) {
      padding-left: 0px;
      text-align: center;
    }
    .form__save__button {
      text-align: center;
      font-size: 14px;
     font-family: 'SSTbold';
font-weight: bold;
      border-radius: 5px;
      min-width: 150px;
      color: #ffffff;
      padding: 10px 25px;
      background-color: #dc3a1a;
      margin: 0px 12px 10px 0px;
      outline: none;
      border: none;
      letter-spacing: 0.3px;
      cursor: pointer;
      text-transform: uppercase;
      &:hover,&:focus{
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
      @media screen and (max-width:400px) {
        width: 64%;
        margin-right: 0px;
      }
      .btn__spinner{
        min-height: 21px;        
      }
    }
    .form__cancel__button {
      cursor: pointer;
      text-align: center;
      min-width: 150px;
      font-size: 14px;
     font-family: 'SSTbold';
font-weight: bold;
      border: 1px solid #707070;
      border-radius: 5px;
      padding: 10px 25px;
      color: #808080;
      background-color: #ffffff;
      margin: 0px 12px 10px 0px;
      outline: none;
      letter-spacing: 0.3px;
      text-transform: uppercase;
      &:hover,&:focus{
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
      @media screen and (max-width:400px) {
        width: 64%;
        margin-right: 0px;
      }
    }
  }
}

.guest__checkout{
  .newAddress__form__field__row{
    height: inherit;
    margin-bottom: 0;
  }
}
.css-1pahdxg-control:hover {
  border-color: #000 !important;
  box-shadow: none;
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {
      
    .newAddress__block .newAddress__form__field__row .main__form__field__block .profile__mobile__form__field {
      width: 100%;
      border: 1px solid #c8c8c8;
      border-radius: 4px;
      padding: 6px 50px 7px 15px;
    }
    .newAddress__block .newAddress__form__field__row .main__form__field__block .profile__mobile__form__field{
      padding: 6px 50px 7px 15px;
      direction: ltr;
      text-align: right;
    }
    .newAddress__block .newAddress__form__field__row .main__form__field__block .field__block input[name="email"] {
      direction: ltr;
      text-align: right;
    }
    .newAddress__block .newAddress__form__field__row .main__form__field__block .react-tel-input .flag-dropdown{
      border-radius: 0px 3px 3px 0px;
    }
    .newAddress__block .addressType__block .delivery__input__check{
      margin: 0px 0px 0px 10px;
    }
    .newAddress__block .addressType__block .delivery__selection__text {
      margin: 0px 0px 0px 20px;
    }
    .newAddress__block .preferences__select__block .preferences__select__input__check {
      margin: 0px 0px 0px 10px;
    }
    .newAddress__block .preferences__select__block .preferences__select__text{
      font-family: 'SSTArabic';
    }
    .newAddress__block .newAddress__title__block .heading4 {
      font-size: 20px;
    }
    .newAddress__block .newAddress__form__button__block .form__save__button,
    .newAddress__block .newAddress__form__button__block .form__cancel__button {      
      font-family: 'SSTArabicBold';
    }
}

/*-------------------------- Arabic view End ------------------------*/