.visa__notes__box{    
    padding: 15px;
    border-radius: 15px;
    background-color: #F8F8F8;
    margin: 10px 0px !important;
    display: flex;
    flex-direction: row;    
    align-items: flex-start;

    
    @media screen and (max-width:480px) {
        margin: 10px 0px !important;
    }
    img {
        max-height: 25px;
        margin-top: 5px;
        margin-right: 10px;
        @media screen and (max-width:480px) { max-height: 20px;}
    }
    .visa__contentbox{
        p{
            font-size: 14px;
            color: #222;
            margin-bottom: 0px;
            line-height: 1.3;
            @media screen and (max-width:480px) {
                font-size: 12px;
            }
        }
    }
}

/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {
    .visa__notes__box{    
        img {
            margin-right: 0px;
            margin-left: 10px;
        }
    }
    
}
/*-------------------------- Arabic view end ------------------------*/