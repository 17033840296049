.pd__extended__warranty__block {
    margin: 20px 0px;
    @media screen and (max-width:992px) {
        margin: 20px 15px;
    }
    .heading7{
        font-size: 16px;
        font-family: "SSTbold";
        color: #000000;
    }
    .warranty__listingBox{
        margin: 10px -15px;

        .col-lg-6{
            padding-right: 0px;

            @media screen and (max-width:992px) {
                padding-right: 15px;
            }
        
            .extended__warranty__item__box{
                background-color: #F8F8F8;
                padding: 12px;
                border-radius: 10px;
                margin-bottom: 10px;
                position: relative;
                input[type="checkbox"]{
                    position: absolute;
                    right: 10px;
                    top: 8px;
                }

                .warranty__yearArea{
                    display: flex;
                    justify-content: center;
                    padding-bottom: 20px;
                    img {
                        max-width: 70px;
                        height: auto;
                        @media screen and (max-width:1366px) {
                            max-width: 60px;
                        }
                    }
                }
                .warranty__contentBox{

                    .warranty__disc__text{
                        font-size: 12px;
                        font-weight: 500;
                        font-family: "SSTregular";
                        color: #000000;
                        margin-bottom: 20px;
                    }
                    .warranty__priceBox{
                        margin-bottom: 20px;
                        .heading5{
                            font-size: 15px;
                            font-family: "SSTbold";
                            font-weight: 700;
                            color: #000000;
                            line-height: 1.2;
                        }
                    }                
                    .warranty__addToCart__btn{
                        border: none;
                        border-radius: 5px;
                        color: #ffffff;
                        font-size: 14px;
                        font-family: "SSTbold";
                        font-weight: bold;
                        line-height: 1.5;
                        letter-spacing: 0.85px;
                        padding: 8px 8px;
                        background-color: #dc3a1a;
                        text-align: center;
                        text-transform: uppercase;
                        text-decoration: none;
                        cursor: pointer;
                        display: flex;
                        width: 100%;
                        flex: 0 0 100%;
                        max-width: 100%;
                        align-items: center;
                        justify-content: center;

                        @media screen and (min-width:1281px) and (max-width:1480px) {
                            font-size: 13px;
                            padding: 6px;
                        }
                        @media screen and (min-width:1200px) and (max-width:1280px) {
                            font-size: 12px;
                            padding: 6px;
                        }
                        
                        .warty__addToCart__icon {
                            margin: 0px 5px 0px 0px;
                            height: 15px;
                            vertical-align: sub;
                            @media screen and (min-width:1281px) and (max-width:1480px) {
                                height: 13px;
                                margin: 0px 4px 0px 0px;
                            }
                            @media screen and (min-width:1200px) and (max-width:1280px) {
                                height: 13px;     
                                margin: 0px 5px 0px 0px;                           
                            }
                        }

                        .spinner-border{
                            margin-left: 3px !important;
                            width: 0.75rem;
                            height: 0.75rem;
                            @media screen and (max-width:1366px){
                                margin-left: 6px !important;
                            }
                        }
                        .btn__spinner {
                            height: 21px;
                            @media screen and (min-width:1281px) and (max-width:1480px) {
                                height: 19.5px;
                            }
                            @media screen and (min-width:1200px) and (max-width:1280px) {
                                height: 18px;
                            }
                        }
                    }
                } 
            }
        }
    }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {


    .pd__extended__warranty__block {
        .warranty__listingBox {
            .col-lg-6 {
                .extended__warranty__item__box {
                    .warranty__contentBox {
                        .warranty__addToCart__btn {
                            .warty__addToCart__icon{
                                margin: 0px 0px 0px 5px;
                                @media screen and (min-width:1281px) and (max-width:1480px) {                                    
                                    margin: 0px 0px 0px 4px;
                                }
                                @media screen and (min-width:1200px) and (max-width:1280px) {                                    
                                    margin: 0px 0px 0px 5px;                           
                                }
                            }
                            .spinner-border{
                                margin-left: 0px !important;
                                margin-right: 3px !important;
                                @media screen and (max-width:1366px){
                                    margin-left: 0px !important;
                                    margin-right: 6px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    

}

/*-------------------------- Arabic view end ------------------------*/