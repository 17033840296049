.home__page__category__block {
  width: 100%;
  margin: 0px 0px 10px 0px;

  .home__page__category__inner__block {
    width: 100%;
    max-width: 1450px;
    margin: 0px auto;
    padding: 20px 0px 20px;
    // background-color: white;
    border-radius: 0px 0px 15px 15px;
    // box-shadow: 0px 3px 6px #00000029;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;

    @media screen and (max-width:1500px) {
      width: 90% !important;
      max-width: 90% !important;
    }
    .slick-slider.slick-initialized{

      @media screen and (max-width:1330px) and (min-width:991px) {
        padding: 0px 40px;
      }
      @media screen and (max-width:992px) and (min-width:767px) {
        padding: 0px 25px;
      }
    }
    .category__slider__btn {
      position: absolute;
      width: 35px;
      height: 35px;
      top: 50%;
      transform: translateY(-50%) !important;
      padding: 8px 8px 8px 5px;
      background-color: #000000;
      text-align: center;
      border-radius: 50%;
      border: none;
      z-index: 2;

      @media screen and (max-width: 1280px) {
        width: 30px;
        height: 30px;
        padding: 3px 3px 4px 2px;
      }
      @media screen and (max-width: 992px) {
        width: 26px;
        height: 26px;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }

      &:hover {
        box-shadow: 0px 1px 3px #707070;
      }
      img {
        width: 10px;
        height: 18px;
        vertical-align: text-top;
        margin: 0px auto;

        @media screen and (max-width: 1280px) {
          width: 9px;
          height: 14px;
        }
      }
      &.left__arrow {
        left: -20px;
        right: auto;
        @media screen and (max-width: 992px) {
          left: 5px;
        }
      }
      &.right__arrow {
        left: auto;
        right: -20px;
        padding: 7px 3px 3px 3px;
        @media screen and (max-width: 992px) {
          right: 5px;
          padding: 5px 2px 3px 3px;
        }
      }
    }

    .slick-slider{
      @media (max-width: 767px) {
        padding: 0px 0px;
      }
      .slick-list{
        padding: 10px 5px !important;
        @media (max-width: 767px) {
          padding: 10px 0px !important;
          width: 100% !important;
          transform: unset !important;
        }
        .slick-track {
          display: flex;
          flex-direction: row;

          @media (max-width: 767px) {
            display: flex;
            flex-direction: row;
            flex: 0 0 100%;
            max-width: 100%;
            flex-wrap: wrap;
            width: 100% !important;
            transform: unset !important;
          }
          .slick-slide{
            // min-width: 250px;
            @media screen and (min-width:993px){
              width: 200px !important;
              flex:0 0 200px !important;
              max-width: 200px !important;
            }
            @media screen and (min-width:1360px){
              width: 240px !important;
              flex:0 0 240px !important;
              max-width: 240px !important;
            }            
            
            @media screen and (min-width:768px) and (max-width:992px) {
                width: 195px !important;
                flex:0 0 195px !important;
                max-width: 195px !important;
            }
            @media (max-width: 767px) {
                flex:0 0 33.3% !important;
                max-width: 33.3% !important;
                width: 33.3% !important;
            }
            @media (max-width: 350px) {
                flex:0 0 50% !important;
                max-width: 50% !important;
                width: 50% !important;
            }
            .category__block {
              display: flex;
              flex-direction: column;
              align-items: center;
              transition: all 0.2s;
              padding: 10px;
        
              &:hover {
                box-shadow: 0px 0px 9px #d9d9d9;
                transition: all 0.2s;
              }
              
              @media (max-width: 767px) {
                padding: 5px;
                .heading7 {
                  font-size: 14px;
                }
              }
              a {
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #000000;
                text-decoration: none;
                .category__image {
                  height: 80px;
                  width: auto;
                  object-fit: cover;
                  margin: 0px auto;
                }
              }
              
            }
          }
        }
        
      }
    }
    

    
  }
}

.home__page__category__block:nth-of-type(3n + 0) {
  // border-bottom: 1px solid red;
  // background-color: blue;
}

@media screen and (max-width: 1420px) {
  .home__page__category__inner__block {
    width: 100% !important;
  }
}

@media screen and (max-width: 1200px) {
  .category__image {
    height: 60px !important;
  }

  .checkout__right__block {
    padding: 0px !important;
    margin: 10px 0px;
  }

  .home__page__category__inner__block {
    width: 100% !important;
  }
}

@media screen and (max-width: 992px) {
  .home__page__category__inner__block {
    width: 100% !important;
  }

  .home__page__category__inner__block {
    // padding: 20px 0px 20px !important;
  }

  .category__image {
    height: 55px !important;
  }

  .home__page__category__block {
    margin-bottom: 0;
    padding-top: 0px;

    .home__page__category__inner__block {
      width: 100% !important;
      max-width: 100% !important;
      background: #f1f1f1;
      border-radius: 0;
      box-shadow: none;
      padding: 20px 0px 20px 0 !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .category__image {
    height: 72px !important;
  }

  .category__block {
    margin: 0px 0px 15px !important;
  }
}

@media screen and (max-width: 576px) {
  .category__image {
    height: 60px !important;
  }

  .category__block {
    // border-bottom: 1px solid red;
    // border-right: 1px solid red;
  }
}

/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {
  .home__page__category__block {
    .home__page__category__inner__block {
      .slick-slider {
        .slick-list {
          .slick-track {
            .slick-slide {
              .category__block {
                .heading7 {
                  font-size: 14px;
                  @media screen and (max-width: 992px) {
                    text-align: center;
                    font-size: 13px;
                  }
                  @media screen and (max-width: 767px) {
                    font-size: 13px;
                  }
                  @media screen and (max-width: 320px) {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
      
    }
  }
}

/*-------------------------- Arabic view end ------------------------*/
