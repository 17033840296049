.confirm__order__page__container {
  padding: 0px 12px;
  background-color: #f5f5f5;
  .confirm__order__page__block {
    padding: 20px 0px;
    width: 1420px;
    margin: 0px auto;
    .inner__confirm__order__block {
        max-width: 1200px;
        margin: 0px auto 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000000a;
      border: 1px solid #f1f1f1;
      border-radius: 10px;
      padding: 30px;
      .success_check_icon {
        margin: 0px 0px 30px;
      }
      .order__success__text {
        margin: 0px;
        text-align: center;
    }
    .order__success__email__text {
        margin: 20px 0px;
        text-align: center;
      }
      .continue__shopping__button {
        width: 48%;
        background: #DC3A1A 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000005;
        // border: 2px solid #000000;
        border-radius: 5px;
        color: white;
        outline: none;
        font-size: 16px;
       font-family: 'SSTbold';
font-weight: bold;
        padding: 16px 0px;
        text-decoration: none;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
@media screen and (max-width: 1450px) {
  .confirm__order__page__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 1200px) {
  .checkout__right__block {
    padding: 0px !important;
    margin: 10px 0px;
  }
}
@media screen and (max-width: 992px) {
  .confirm__order__page__block {
    width: 100% !important;
  }
}
