.product__pickup__block {
  margin: 16px 0px;

// bottom modal popup ui start
.mb__bottom_popup {
  .mb__dialog__end {
    display: flex;
    margin-bottom: 0 !important;
    align-items: flex-end;
    min-height: calc(100% - 1.8rem);
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
    .pickup__box {
      box-shadow: 0px 3px 6px #0000000a;
      border: 1px solid #F1F1F1;
      margin-bottom: 20px;
      padding: 20px;
      .mb__pin__right{
        p{
          font-size: 13px;
        }
      }
  }
    .modal-content {
      border-radius: 0;

      .modal-header {
        border-bottom: 0;
      }
    }
  }
}
  .product__block__title {
    font-size: 20px;
    font-family: 'SSTbold';
    font-weight: bold;
    margin: 0px 0px 30px;
  }

  .product__store__block {
    display: flex;
    align-items: flex-start;
    margin: 0px 0px 10px;
    padding-left: 38px;
    .product__store__icon {
      width: 23px;
      margin: 0px 15px 0px 0px;
    }

    .pickup__text__block {
      .product__pickup__text {
        width: 60%;
        margin: 0px;
        font-size: 14px;
        font-family: "SSTregular";
        @media (max-width:992px) {
          width: 90%;
        }
        .product__pickup__only__text {
          font-size: 15px;
          font-family: 'SSTbold';
          font-weight: bold;
        }
      }

      .product__store__location__link {
        color: #003cb4;
        font-size: 15px;
        @media (max-width:992px) { font-size: 14px; }
        &:hover{ font-weight: 600; }
      }
    }
  }
}

@media(max-width:767px){
  .product__pickup__block .mb__bottom_popup .mb__dialog__end .pickup__box{
    padding: 15px 10px;
  }
  .product__pickup__block .mb__bottom_popup .mb__dialog__end .pickup__box button{
    font-size: 14px;
  }
}