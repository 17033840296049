.mb__product__grid__block {
    // .productNine__block {
    width: 100%;
    background-color: #ffffff;
    // margin: 0px 0px 24px 0px;
    border-radius: 0px 5px 5px;
    height: 100%;
    box-shadow: 0px 3px 6px #0000000A;
    border: 1px solid #D1D1D1;
    position: relative;

    .productNine__header {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        left: 0px;

        img.quality__icon {
            display: inline-block;
            width: 21px;
            vertical-align: top;

            @media (max-width:480px) {
                width: 17px;
            }
        }

        .productNine__new__sticker__block {
            width: auto;
            // height: 30px;
            padding: 5px 10px 5px 5px;
            background: #ff9b04;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            border-bottom-right-radius: 15px;
            text-transform: uppercase;
            max-width: 115px;
            overflow: hidden;

            &.new__sticker__block__green {
                background: #08BB5D;
            }

            .productNine__new__sticker__text {
                margin: auto;
                font-family: 'SSTbold';
                font-weight: bold;
                font-size: 12px;
                color: #ffffff;
                line-height: 1;
            }
        }

        .quality__favourite__block {
            display: flex;
            align-items: center;
            padding: 3px 7px 0px 0px;
            width: 23px;
            height: 21px;

            .quality__icon {
                width: 36px;
                margin: 0px 10px 0px 0px;
            }

            .favourite__icon {
                display: block;
                width: 17px;
            }

            .favourite__icon__disable {
                display: none;
            }
        }
    }

    .productNine__content {
        padding: 35px 15px 15px 15px;

        .product__pics__area {
            position: relative;

            .productNine__image__slider {
                max-width: 100%;
                vertical-align: top;
                min-height: 125px;
                max-height: 125px;

                .slick-next {
                    background-repeat: no-repeat;
                    width: 6px;
                    height: 12px;
                    background-size: contain !important;
                    background: url(./../../assets/Icon/grey_right_arrow.svg);
                    background-repeat: no-repeat;
                    top: 50%;
                    right: -2px;
                    z-index: 1;

                    &:before {
                        display: none;
                    }
                }

                .slick-prev {
                    background-repeat: no-repeat;
                    width: 6px;
                    height: 12px;
                    background-size: contain !important;
                    background: url(./../../assets/Icon/grey_right_arrow.svg);
                    background-repeat: no-repeat;
                    transform: rotate(-185deg) matrix(1, 0, 0, 1, 0, 7);
                    top: 50%;
                    left: -2px;
                    z-index: 1;

                    &:before {
                        display: none;
                    }
                }

                @media (max-width:480px) {
                    min-height: 100px;
                    max-height: 100px;
                }

                .slick-slider {

                    // .slick-arrow{ display: none !important; }
                    .slick-list {
                        .slick-track {
                            .slick-slide {

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: contain;
                                    max-height: 125px;
                                    margin: 0px auto;

                                    @media (max-width:480px) {
                                        max-height: 100px;
                                    }
                                }
                            }
                        }
                    }

                    .slick-dots {
                        bottom: 6px;
                        background-color: #f7f7f75e;
                        display: flex !important;
                        flex-wrap: wrap;
                        justify-content: center;
                        max-width: max-content;
                        padding: 2px 5px;
                        //   height: 17px;
                        align-items: center;
                        border-radius: 7px;
                        margin: 0px auto;
                        left: 50%;
                        transform: translateX(-50%);

                        li {
                            width: 8px;
                            height: 8px;
                            margin: 0px 2px 2px;

                            @media (max-width:480px) {
                                width: 7px;
                                height: 7px;
                            }

                            button {
                                padding: 0px;
                                height: 8px;
                                width: 10px;

                                &:before {
                                    line-height: 10px;
                                    width: 8px;
                                    height: 8px;
                                }
                            }
                        }
                    }
                }


                .awssld__content {
                    background-color: inherit;
                }

                .awssld__bullets {
                    position: unset;
                    background-color: none;
                    //   border: 1px solid #000000;
                }

                .awssld__bullets button {
                    background: none;
                    border: 1px solid #000000;
                    transform: scale(0.7);
                    margin: 0px 1px 5px;
                }

                .awssld__bullets .awssld__bullets--active {
                    transform: scale(0.7);
                    background-color: #000000;
                    border: 1px solid #000000;
                }

                .awssld__bullets .awssld__bullets--loading {
                    //   transform: scale(0.6);
                }

                .awssld__controls {
                    visibility: hidden;
                }

                // .awssld__controls__arrow-right {
                //   visibility: hidden;
                // }
            }

            .soldOutLable {
                position: absolute;
                width: 100%;
                background: hsla(0,0%,85.1%,.76);
                height: 30px;
                top: 35%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 21px;
                color: rgba(0,0,0,.72);
                text-transform: uppercase;
                @media screen and (max-width:480px){
                    height: 22px;
                }
            }
            .cardLabel {
                display: flex;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                z-index: 2;
                img {
                    border-radius: 0 !important;
                    max-width: 25% !important;
                    max-height: 25% !important;
                }

                &.left_top {
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                &.center_top {
                    justify-content: center;
                    align-items: flex-start;
                }

                &.right_top {
                    justify-content: flex-end;
                    align-items: flex-start;
                }

                &.left_middle {
                    justify-content: flex-start;
                    align-items: center;
                }

                &.center_middle {
                    justify-content: center;
                    align-items: center;

                    img {
                        max-height: 50% !important;
                        max-width: 50% !important;
                    }
                }

                &.right_middle {
                    justify-content: flex-end;
                    align-items: center;
                }

                &.left_bottom {
                    justify-content: flex-start;
                    align-items: flex-end;
                }

                &.center_bottom {
                    justify-content: center;
                    align-items: flex-end;
                }

                &.right_bottom {
                    justify-content: flex-end;
                    align-items: flex-end;
                }
            }
        }

        .productNine__name__link {
            text-decoration: none;
            color: #000000;
            margin: 0px;

            .productNine__name {
                font-family: 'SSTbold';
                font-weight: bold;
                margin: 0px 0px 10px;
                font-size: 14px;
                line-height: 1.3;
                min-height: 93px;

                @media screen and (max-width:420px) {
                    font-size: 13px;
                    min-height: 86px;
                }
            }
        }

        // .size__button__block {
        //     padding: 20px 0px;
        //     display: flex;
        //     align-items: center;
        //     margin: 0px 0px 10px 0px;
        //     border-top: 1px solid #d9d9d9;
        //     border-bottom: 1px solid #d9d9d9;

        //     .size__button__active {
        //         font-size: 12px;
        //         border-radius: 3px;
        //         color: #ffffff;
        //         padding: 10px 8px;
        //         background-color: #000000;
        //         border: 1px solid #000000;
        //         outline: none;
        //         border: none;
        //         margin: 0px 10px 0px 0px;
        //     }

        //     .size__button {
        //         font-size: 12px;
        //         border: 1px solid #707070;
        //         border-radius: 3px;
        //         padding: 10px 8px;
        //         background-color: #ffffff;
        //         outline: none;
        //         margin: 0px 10px 0px 0px;
        //     }
        // }
        
        .mb__product-grid-view-pricebox {
            display: flex;
            flex-direction: column;
            min-height: 32px;
            align-items: flex-start;
            justify-content: flex-end;
            margin-bottom: 8px;

            span {
                margin-bottom: 0px !important;
                line-height: 14px;
                display: inline-flex;
                height: 16px;

                // &:first-child{ margin-right: 5px !important; }
                &.text4 {
                    position: relative;
                    width: fit-content;
                    line-height: 1.2;

                    &::after {
                        content: " ";
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        background-color: #666666;
                        -webkit-transform: translateY(-8px) rotate(-6deg);
                        transform: translateY(-8px) rotate(-6deg);
                        bottom: 0;
                        left: 0;
                    }

                    @media screen and (max-width:420px) {
                        font-size: 13px;
                    }
                }

                &.heading6 {
                    @media screen and (max-width:420px) {
                        font-size: 13px;
                    }
                }
            }
        }

        .plp__mezza__reward {
            img {
                width: auto;
                max-height: 26px;
                max-width: 80px;
                @media screen and (max-width:480px) {                    
                    max-width: 60px;
                    max-height: 20px;
                }
                @media screen and (max-width:380px) {                    
                    max-width: 50px;
                    max-height: 20px;
                }
            }
            div{
                &:last-child{
                    text-align: right;
                }
            }
        }
        .plp__mezza__reward{
            img{
                
            }
        }
        .productNine__feature__block {
            padding: 0px 0px;
            margin: 0px 0px 0px;

            .feature__text__block {
                display: flex;
                align-items: flex-start;
                margin: 0px 0px 5px;

                .feature__bullet {
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    margin: 0px 10px 0px 0px;
                    background-color: #c8c8c8;
                    min-width: 6px;
                    min-height: 6px;
                    margin-top: 7px;
                }

                p {
                    word-break: break-all;
                }
            }
        }

        .delivery-time-area {
            min-height: 40px;

            .heading7 {
                font-size: 14px;
                line-height: 1.2;
            }

            @media screen and (max-width:580px) {
                min-height: 50px;
                display: inline-block;
                line-height: 1;

                .text4,
                .heading7 {
                    font-size: 12px;
                    display: inline-block;
                    line-height: 1.1;
                }

                .heading7 {
                    &:last-child {
                        margin-bottom: 6px !important;
                    }
                }
            }
        }

        .mb_cart_com_btn {
            display: flex;
            justify-content: flex-end;
            margin-top: 6px;

            a{
                @media screen and (max-width:320px) {
                    width: 100%;
                }
            }
            .addToCart__button {
                display: flex;
                cursor: pointer;
                margin-left: auto;
                padding: 7px 10px;
                border-radius: 5px;
                font-size: 14px;
                font-family: "SSTbold";
                text-transform: uppercase;
                line-height: 0.95;
                background-color: #dc3a1a;
                color: #ffffff;
                
                @media screen and (max-width:480px) {
                    font-size: 12px;
                }
                @media screen and (max-width:320px) {
                    font-size: 11px;
                    padding: 7px 5px;
                    min-width: 100%;
                    justify-content: center;
                }
                &.pre-order{
                    min-width: 118px;
                    justify-content: center;
                    @media screen and (max-width:320px) {
                        min-width: 100%;
                    }
                }
                img {
                    width: 14px;
                    margin-right: 6px;

                    @media screen and (max-width:480px) {
                        height: 11px;
                    }
                    @media screen and (max-width:320px) {
                        margin-right: 3px;
                    }
                }
                .spinner-border{
                    margin-left: 5px !important;
                    height: 0.7rem !important;
                    width: 0.7rem !important;
                    margin-right: 5px !important;
                    @media screen and (max-width:320px) {
                        margin-left: 3px !important;
                        margin-right: 3px !important;
                    }
                }
                .btn__spinner{
                    width: 111.23px;
                    min-height: 13.3px;                        
                    @media screen and (max-width:480px) { width: 98.2px; min-height: 11.39px; }
                    @media screen and (max-width:380px) {  width: 98.21px; min-height: 11.39px; }
                    
                }
                &.pre-order{
                    .btn__spinner{
                        width: 98px;
                        min-height: 14px; 
                        @media screen and (max-width:480px) { min-height: 12px;  }
                        @media screen and (max-width:380px) { min-height: 11px; }
                        @media screen and (max-width:321px) { min-height: 11px; }
                    }
                }
            }
            .notifyMe__button{
                border-radius: 5px;
                color: #ffffff;
                font-size: 14px;
                font-family: "SSTbold";
                text-transform: uppercase;
                letter-spacing: 0.85px;
                padding: 7px 10px;
                line-height: 0.95;
                background-color: #FE8B21;
                cursor: pointer;
                margin-left: auto;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 118px;

                @media screen and (max-width:480px) {
                    font-size: 12px;
                    padding: 7px 9px;
                }
                @media screen and (max-width:320px) {
                    min-width: 100%;
                    font-size: 11px;
                    padding: 7px 7px;
                }
                img {
                    height: 11px;
                    margin-right: 5px;
                    @media screen and (max-width:480px) {
                        height: 11px;
                    }
                    @media screen and (max-width:320px) {
                        margin-right: 3px;
                    }
                }
                .spinner-border{
                    margin-left: 5px !important;
                    height: 0.7rem !important;
                    width: 0.7rem !important;
                    @media screen and (max-width:320px) {
                        margin-left: 3px !important;
                    }
                }
            }
            .outofStock__button {
                padding: 5px 10px;
                border-radius: 5px;
                background-color: #a7a7a7;
                color: #ffffff;
                font-size: 14px;
                line-height: 0.95;
                margin-left: auto;
            }
            .productNine__button__block {
                border: none;
                outline: none;
                display: flex;
                background: none;
                color: #dc3b2f;
            }
            .outofStock__button {
                padding: 7px 10px;
                border-radius: 5px;
                background-color: #a7a7a7;
                color: #ffffff;
                font-size: 14px;
                font-family: 'SSTbold';  
                text-transform: uppercase;
                line-height: 0.95;
                margin-left: auto;
                min-width: 118px;
                text-align: center;
                @media screen and (max-width:480px) {
                    font-size: 12px;
                }
                @media screen and (max-width:320px) {
                    min-width: 100%;
                    font-size: 11px;
                    padding: 7px 7px;
                }
                .spinner-border{
                    margin-left: 5px !important;
                    height: 0.7rem !important;
                    width: 0.7rem !important;
                    @media screen and (max-width:320px) {
                        margin-left: 4px !important;
                    }
                }
            }
        }


        .productNine__quickView__compare__block {
            margin: 10px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .productNine__button__block {
                border: none;
                outline: none;
                background: none;
                display: flex;
                align-items: center;

                .productNine__button__icon {
                    margin: 0px 10px 0px;
                }
            }
        }

        .pre-order-mbl {
            font-size: 14px;
            color: white;
            font-family: 'SSTbold';
            text-transform: uppercase;
            border-radius: 5px;
            padding: 7px 10px;
            line-height: 0.95;
            
            @media screen and (max-width:480px) {
                font-size: 12px;
            }
            .spinner-border{
                margin-left: 5px !important;
                height: 0.7rem !important;
                width: 0.7rem !important;
            }
        }
    }

    //   }
    .size__button__block {
        margin: 0px 0px 5px 0px;
        min-height: 54px;
  
        .size__button__listing {
          border-top: 1px solid #d9d9d9;
          border-bottom: 1px solid #d9d9d9;
          padding: 10px 0px;
          display: flex;
          flex: 0 0 100%;
          max-width: 100%;
          align-items: center;
          span{
            display: block;
            margin: 0px 8px 0px 0px;
          }
          button{
            position: relative;
            &:before{
              content: "";
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: #000000;
              border-radius: 3px;
              -webkit-transform: scaleX(0);
              transform: scaleX(0);
              -webkit-transform-origin: 50%;
              transform-origin: 50%;
              -webkit-transition-property: transform;
              transition-property: transform;
              -webkit-transition-duration: 0.3s;
              transition-duration: 0.3s;
              -webkit-transition-timing-function: ease-out;
              transition-timing-function: ease-out;
            }
          }
    
          .size__button__active {
            font-size: 12px;
            font-family: 'SSTmedium';
            border-radius: 3px;
            color: #ffffff;
            padding: 6px 8px;
            // line-height: 1.26;
            // background-color: #000000;
            border: 1px solid #000000;
            outline: none;
            border: none;
            margin: 0px 8px 0px 0px;
            z-index: 2;
            position: relative;
            &:before{
              -webkit-transform: scaleX(1);
              transform: scaleX(1);
              z-index: -1;
            }
            &:last-child {
              margin-right: 0px;
            }
          }
          .size__button {
            font-size: 12px;
            border: 1px solid #707070;
            border-radius: 3px;
            padding: 6px 8px;
            background-color: #ffffff;
            outline: none;
            margin: 0px 8px 0px 0px;
            &:last-child {
              margin-right: 0px;
            }
          }
        }      
      }

}


@media(max-width:576px) {
    .mb__product__grid__block .size__button__block .size__button__listing .size__button__active {
        padding: 6px 5px;}
    .mb__product__grid__block .size__button__block .size__button__listing .size__button {
        padding: 6px 5px;}
    .mb__product__grid__block .productNine__content .productNine__feature__block .feature__text__block p {
        font-size: 12px;
    }

    .mb__product__list__detail__sec .col-6:nth-child(odd) {
        padding-right: 5px;
    }

    .mb__product__grid__block .productNine__content .productNine__feature__block {
        margin-bottom: 0;
    }

    .mb__product__list__detail__sec .col-6 {
        margin-bottom: 9px !important;
    }

    .mb__product__list__detail__sec .col-6:nth-child(even) {
        padding-left: 5px;
    }

    .mb__product__grid__block .productNine__content .heading6 {
        font-size: 14px;
        margin: 0px !important;
    }

    .mb__product__grid__block .productNine__content .plp__mezza__reward span {
        font-size: 13px;
        margin-top: 4px;
    }

    .mb__product__grid__block .productNine__content .productNine__image__slider .awssld__bullets button {
        width: 10px;
        height: 10px;
    }

    .mb__product__grid__block .productNine__content {
        padding: 20px 10px 10px 10px;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .main__rating__block {
        margin: 0px 0px;
    }

    .main__rating__block .product__rating {
        margin: 0px 5px 0px 3px;
    }

    .mb__delivery__text span,
    .mb__delivery__text p {
        font-size: 12px;
        line-height: 1.1;
    }
    
}

/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {

    .mb__product__grid__block {
        .productNine__header {
            .productNine__new__sticker__block {
                border-bottom-right-radius: 0px;
                border-bottom-left-radius: 15px;
                padding: 5px 7px 5px 10px;
            }

            .quality__favourite__block {
                padding: 3px 0px 0px 7px;
            }
        }

        .productNine__content {
            .productNine__image__slider {
                .slick-next {
                    left: auto;
                }
            }

            .mb__product-grid-view-pricebox {

                span {
                    // &:first-child{ 
                    //     margin-right: 0px !important; 
                    //     margin-left: 5px !important;
                    // }
                }
            }
            .productNine__name__link {
                .productNine__name{
                    font-family: 'SSTArabicBold';
                }                
            }
            .mb_cart_com_btn {            
                .addToCart__button,.outofStock__button,
                .notifyMe__button,.pre-order {
                  font-family: 'SSTArabicBold';
                  font-weight: bold;                  
                }
                .addToCart__button {
                    margin-left: unset;
                    margin-right: auto;
                    line-height: 1;
                    @media screen and (max-width:380px) {
                        font-size: 10px;
                        margin-left: 0px;
                        padding: 7px;
                    }
                    @media screen and (max-width:320px) {
                        font-size: 9px;                    
                    }
                    img{
                        margin-right: 0px;
                        margin-left: 6px;
                        @media screen and (max-width:320px) {
                            margin-right: 0px;
                            margin-right: 3px;
                        }
                    }
                    .btn__spinner{
                        width: 108.08px;
                        min-height: 14px;                        
                        @media screen and (max-width:480px) { width: 95.5px; min-height: 12px; }
                        @media screen and (max-width:380px) {  width: 82.52px; min-height: 11px; }

                    }
                    &.pre-order{
                        .btn__spinner{
                            width: 98px;
                            min-height: 14px; 
                            @media screen and (max-width:480px) { min-height: 12px;  }
                            @media screen and (max-width:380px) { min-height: 11px; }
                            @media screen and (max-width:321px) { min-height: 11px; }
                        }
                    }
                }
                .notifyMe__button{
                    margin-left: unset;
                    margin-right: auto;
                    @media screen and (max-width:1200px) {
                      font-size: 14px;
                    }
                    @media screen and (max-width:480px) {
                        font-size: 12px;
                    }
                    @media screen and (max-width:415px) {
                        font-size: 10px;
                        line-height: 1.2;
                    }
                    @media screen and (max-width:380px) {
                        min-width: 100%;                     
                        font-size: 10px;
                        padding: 7.5px 6px;
                        line-height: 1.1;
                    }
                    img{
                      margin: 0px 0px 0px 10px;
                      @media screen and (max-width:1200px) {
                        margin: 0px 0px 0px 8px;
                      }
                      @media screen and (max-width:480px){
                        margin: 0px 0px 0px 6px;
                        height: 10px;
                      }
                      @media screen and (max-width:380px){
                        height: 10px;
                        margin: 0px 0px 0px 3px;                        
                      }

                    }                  
                }
            }
        }
    }

    .mb__product__list__detail__sec {
        .col-6:nth-child(even) {
            padding-right: 5px;
            padding-left: 15px;
        }

        .col-6:nth-child(odd) {
            padding-right: 15px;
            padding-left: 5px;
        }
    }

    .mb__product__list__bottom__filter {
        ul {
            padding: 0px;

            li {
                button {
                    img {
                        margin-left: 5px;
                        margin-right: 0px;
                    }
                }
            }

            li:first-child button {
                border-radius: 0px 35px 35px 0px;
            }

            li:last-child button {
                border-radius: 35px 0px 0px 35px;
            }
        }
    }


}

/*-------------------------- Arabic view End ------------------------*/