.locationCard {
  background-color: white;  
  width: 100%;
  border-radius: 9px;
  padding: 20px;
  display: grid;
  grid-template-columns: 75% 25%;
  margin: 10px 0;
  min-width: 350px;
  position: relative;

  @media screen and (max-width: 375px) {
    grid-template-columns: 70% 30%;
  }

  @media screen and (min-width: 376px) and (max-width: 500px) {
    grid-template-columns: 76% 24%;
    min-width: 330px;
  }
  
  .cardTitle {
    color: #000000;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.184px;
  }

  .cardDesc {
    color: #000000;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.16px;
    margin-top: 12px;
    width: 80%;
    min-height: 27px;
    html[dir="rtl"] & {
      line-height: 150%;
    }
  }

  .location {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a{
      display: flex;
      .text {        
        font-size: 14px;
        line-height: 1.2;        
        text-decoration-line: underline;
        color: #8D8E8F;
        margin-left: 8px;
        margin-bottom: 0px;
      }
    }
    

    img {
      overflow: visible;
    }
  }
}
.stores__location__modal{
  min-height: 350px;
  max-height: 350px;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 6px;
  display: inline-block;
  width: 100%;
  max-width: 100%;

  .locationCard{
    width: 49%;
    display: inline-block;
    margin-left: 8px;
    margin-bottom: 0px;
    
    &:nth-child(2n+1){
      margin-left: 0px;
    }
    @media screen and (max-width:992px) {
      min-width: 100%;
      max-width: 100%;
      width: 100%;
      margin-left: 0px;
    }
    .location{
      align-items: flex-end;
    }
  }
}

html[dir="rtl"] {
  .location {
    right: unset;
    left: 25px;

    img {
      margin-left: 5px;
    }
  }
  .stores__location__modal{
    padding-right: 0px;
    padding-left: 6px;
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {
  .stores__location__modal {
    .locationCard{
      margin-left: 0px;
      margin-right: 8px;
      &:nth-child(odd) {
        margin-right: 0px;
      }
    }
  }

}