.myOrders__container {
  padding: 0px 12px;
  background-color: #f5f5f5;
  .myOrders__block {
    padding: 0px 0px;
    width: 1420px;
    margin: 0px auto;
    .myOrders__inner__block {
      box-shadow: 0px 3px 6px #0000000a;
      background-color: #ffffff;
      position: relative;
      .mo__page__horizontal__line {
        border-bottom: 1px solid #acacac;
        margin: 0px;
      }
      .myOrders__title__block {
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .mo__search__reset_filter {
          cursor: pointer;
          text-align: center;
          min-width: fit-content;
          font-size: 14px;
          font-family: "SSTbold";
          font-weight: bold;
          padding: 4px 20px;
          background-color: #000000;
          color: #ffffff;
          border: 1px solid #000000;
          border-radius: 5px;
          // margin: 0px 12px 10px 0px;
          outline: none;
          letter-spacing: 0.3px;
          margin-left: 20px;

          @media (max-width: 992px) {
            margin-bottom: 0px;
          }
        }
        @media (max-width: 740px) {
          flex-direction: column;
          position: sticky;
          left: 0px;
          top: 0px;
          width: 250px;
        }
        .myOrders__title {
          @media (max-width: 740px) {
            margin-bottom: 5px;
          }
        }
        .myOrders__right__part {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @media (max-width: 740px) {
            flex-direction: column;

            span,
            div.mo__search__box__block,
            select {
              padding-left: 0px !important;
              margin-left: 0px !important;
              margin-bottom: 10px !important;
            }
          }
          .mo__select__block {
            padding: 6px 10px;
            width: 200px;
            margin: 0px 0px 0px 10px;
            border: 1px solid #dfdfdf;
            border-radius: 5px;
            font-family: "SSTmedium";
            font-size: 14px;
          }
          select:focus {
            outline: none;
            background: none;
          }
          .mo__search__box__block {
            padding-left: 20px;
            .mo__search__box {
              border: 1px solid #dfdfdf;
              border-radius: 5px;
              padding: 2px 7px 2px 0px;
              background-color: white;
              height: 32px;
              border-radius: 5px;
              display: flex;
              align-items: center;
              .mo__search__input {
                font-size: 14px;
                padding: 0px 10px;
                margin: 0px;
                background: none;
                outline: none;
                border: 0px;
                width: 96%;
              }

              input:-webkit-autofill,
              input:-webkit-autofill:hover,
              input:-webkit-autofill:focus,
              input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px white inset !important;
              }
            }
          }
        }
      }
      .mo__page__category__button__block {
        padding: 30px 20px;
        @media (max-width: 992px) {
          padding: 20px;
        }
        .mo__page__button__main__block {
          width: 100%;
          position: relative;
          margin: 0px auto;

          .mo__page__button__block {
            z-index: 5;
            display: flex;
            align-items: center;
            overflow-x: scroll;
            scroll-behavior: smooth;
            width: 100%;
            @media (max-width: 740px) {
              padding-bottom: 5px;
            }
            .mo__page__button__active {
              text-align: center;
              font-size: 14px;
              font-family: "SSTbold";
              font-weight: bold;
              border-radius: 5px;
              min-width: fit-content;
              color: #ffffff;
              padding: 10px 25px;
              border: 1px solid #000000;
              margin: 0px 12px 10px 0px;
              outline: none;
              letter-spacing: 0.3px;
              cursor: pointer;
              position: relative;
              z-index: 2;
              @media (max-width: 992px) {
                margin-bottom: 0px;
              }

              &::before {
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 5px;
                background-color: #000000;
                -webkit-animation: tabEffect 0.2s;
                animation: tabEffect 0.2s;
              }
              @keyframes tabEffect {
                0% {
                  -webkit-transform: scaleY(0);
                  transform: scaleY(0);
                  -webkit-transform-origin: 50% 0;
                  transform-origin: 50% 0;
                }
                100% {
                  -webkit-transform: scaleY(1);
                  transform: scaleY(1);
                  -webkit-transform-origin: 50% 0;
                  transform-origin: 50% 0;
                }
              }
            }
            .mo__page__button {
              cursor: pointer;
              text-align: center;
              min-width: fit-content;
              font-size: 14px;
              font-family: "SSTbold";
              font-weight: bold;
              border: 1px solid #707070;
              border-radius: 5px;
              padding: 10px 25px;
              color: #808080;
              background-color: #ffffff;
              margin: 0px 12px 10px 0px;
              outline: none;
              letter-spacing: 0.3px;
              @media (max-width: 992px) {
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .myOrders__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .myOrders__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}

@media screen and (max-width: 992px) {
  .myOrders__block {
    width: 100% !important;
    max-width: 100% !important;
    overflow: scroll;
    display: flex;
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {
  
  .mo__product__block__new .write_Review__link {
    margin: 0px 10px 0px 0px;
  }
  .mo__product__block__new .order__detail__list {
    padding: 0px;
  }
  .mo__product__block__new .order__detail__list li {
    margin-right: 0px;
    margin-left: 10px;
    display: flex;
  }
  .mo__product__block__new .order__detail__list li .heading7 {
    margin-right: 0px !important;
    margin-left: 10px !important;
    // @media screen and (max-width:992px) {
    //   margin-right: 0px !important;
    //   margin-left: 10px !important;
    // }
  }
  .mo__product__block__new .order__detail__list li .text3 {
    @media screen and (max-width: 992px) {
      float: right;
    }
  }

  .myOrders__container {
    .myOrders__block {
      .myOrders__inner__block {
        .myOrders__title__block {          
          .myOrders__right__part {
            .heading7 {
              margin-left: 10px !important;
              margin-right: 0px;
            }
            .mo__search__reset_filter {
              margin-left: 0px;           
              font-family: 'SSTArabicBold';   
            }
            .mo__search__box__block {
              .mo__search__box {
                .mo__search__input {
                  width: 89%;
                }
              }
            }
          }
        }
        .mo__page__category__button__block {
          .mo__page__button__main__block {
            .mo__page__button__block{
              .mo__page__button, .mo__page__button__active{
                margin: 0px 0px 10px 12px;
                font-family: 'SSTArabicBold';
              }
            }
          }
        }
        
        .mo__product__block__new {
          .mo__product__right__block {
            .mo__order__detail__button {
              font-family: 'SSTArabicBold';
            }
          }
        }
    

      }
    }
  }
}

/*-------------------------- Arabic view End ------------------------*/
