.category__screensize__block {
  width: 1420px;
  padding: 0px;
  margin: 30px auto;

  .section__title {
    font-family: "SSTbold";
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: left;
  }

  .inner__category__screensize__block {
    // overflow-x: auto;

    .main__screensize__block {
      // display: flex;
      // align-items: center;
      // justify-content: center;
      .screensize__block {
        margin: 10px auto;
        //   margin: 41px auto 0px 0px;
        background: #ffffff;
        width: 185px;
        height: 185px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        text-align: center;
        box-shadow: 5px 3px 6px #00000029;
        text-decoration: none;
        color: black;
        .screensize_text {
          margin: auto;
          font-family: "SSTbold";
          font-weight: bold;
          font-size: 30px;
          // padding: 10px;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .category__screensize__block {
    max-width: 90% !important;
  }
}
@media (max-width: 1360px) {
  .category__screensize__block
    .inner__category__screensize__block
    .main__screensize__block
    .screensize__block {
    width: 130px;
    height: 130px;
  }

  .category__screensize__block
    .inner__category__screensize__block
    .main__screensize__block
    .screensize__block
    .screensize_text {
    font-size: 22px;
  }
}

@media (max-width: 991px) {
  .category__screensize__block
    .inner__category__screensize__block
    .col-2.main__screensize__block {
    min-width: 140px;
  }

  .category__screensize__block
    .inner__category__screensize__block
    .main__screensize__block
    .screensize__block {
    margin: 10px auto;
  }

  .category__screensize__block .section__title {
    margin-bottom: 0px;
  }

  .category__screensize__block {
    margin: 20px auto;
  }

  .category__banner__container {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .category__screensize__block
    .inner__category__screensize__block
    .main__screensize__block
    .screensize__block {
    width: 90px;
    height: 90px;
    margin: 5px auto;
  }

  .category__screensize__block
    .inner__category__screensize__block
    .main__screensize__block
    .screensize__block
    .screensize_text {
    font-size: 14px;
  }

  .allCategory
    .three__ad__banner__container
    .three__ad__banner__block
    .inner__three__ad__banner__block
    .ad__banner__block {
    margin-bottom: 10px;
  }

  .category__banner__container {
    margin-bottom: 10px;
  }

  .category__screensize__block {
    margin: 10px auto;
  }
}
