// .banner__container {
//   display: flex;
//   justify-content: center;
//   //   align-items: center;
//   position: relative;
//   padding: 0px;

//   //   overflow-y: hidden !important;

//   .banner__block {
//     background-color: #ffffff;
//     padding: 0px;
//     margin: 0px auto 0px;
//     height: 450px;
//     width: 100%;
//     text-align: center;
//     position: relative;
//     display: flex;
//     overflow: hidden;
//     .navbar__area{
//       height: 112px;
//     }
//     .banner {
//       position: absolute;
//       top: 0px;
//       left: 0px;
//       width: 100%;
//       height: 100%;
//       opacity: 0;
//       transition: all 0.7s ease-in-out;
//       transform: scale(50%);

//       .banner__image {
//         width: 100%;
//         height: 100%;
//         // object-fit: contain;
//       }
//       .banner__title {
//         width: 700px;
//         text-align: left;
//         line-height: 120px;
//         font-family: "Bebas Neue", cursive;
//         font-size: 120px;
//         color: rgb(255, 255, 255);
//         // -webkit-text-stroke-width: 2px;
//         // -webkit-text-stroke-color: rgb(255, 255, 255);
//         position: absolute;
//         top: 270px;
//         left: 250px;
//         margin: 0px;
//       }
//     }

//     .activeBanner {
//       opacity: 1;
//       transform: translateX(0%) scale(100%);
//     }
//     .prevBanner {
//       opacity: 0;
//       transform: translateX(-100%) scale(50%);
//     }
//     .nextBanner {
//       opacity: 0;
//       transform: translateX(100%) scale(50%);
//     }
//     .banner__arrow__block {
//       width: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       position: absolute;
//       padding: 0px 25px;
//       top: 50%;
//       transform: translateY(-50%);

//       // bottom: 300px;
//       // left: 250px;

//       .icon__block {
//         cursor: pointer;
//         width: 15px;
//         // width: 5px;
//         // height: 5px;
//         // margin: 0px 8px;
//         // border-radius: 10px;
//       }
//     }
//   }


// }

// @media screen and (max-width: 1420px) {
//   .banner__block {
//     width: 100% !important;
//   }
// }
// @media screen and (max-width: 1400px) {
//   .banner__block {
//     height: 400px !important;
//   }
// }
// @media screen and (max-width: 1200px) {
//   .banner__block {
//     height: 330px !important;
//   }
//   .banner__block {
//     width: 100% !important;
//   }
// }
// @media screen and (max-width: 992px) {
//   .banner__block {
//     margin: 0px 0px 0px !important;
//     min-width: 100% !important;
//     margin: 0px auto;
//     height: 260px !important; 
//   }
// }
// @media screen and (max-width: 768px) {
//   .banner__block {
//     height: 301px !important;
//   }
// }
// @media screen and (max-width: 576px) {
//   .banner__block {
//     height: 140px !important;
//   }
//   .banner__container .banner__block .banner__arrow__block{ padding: 0px 15px; }
//   .banner__container .banner__block .banner__arrow__block .icon__block{ width: 11px; }
// }
// @media screen and (max-width: 414px) {
//   .banner__block {
//     height: 140px !important;
//   }
// }
// @media screen and (max-width: 375px) {
//   .banner__block {
//     height: 130px !important;
//   }
// }


.homePage__image__slider {
  display: inline-block;
  width: 100%;
  vertical-align: top;

  .awssld {

    // height: 395px;    
    .awssld__container {
      display: block;
      // flex-direction: row;
      padding: 0px;
      height: auto;

      .awssld__box {
        display: none;
        position: relative;
        height: auto;
        width: auto;
        transition: unset;

        .awssld__content {
          position: relative;
          transition: unset;

          ._product__container_image_block {
            transition: unset;

            ._product__container_image {
              visibility: visible;
            }
          }
        }

        &.awssld--active {
          display: block;
          transition: unset;

          .awssld__content {
            position: relative;

            ._product__container_image_block {
              transition: unset;
            }
          }
        }
      }
    }

    .awssld__bullets {
      position: absolute !important;
      bottom: 20px;
      z-index: 5;
    }
  }

  ._product__container_image_block {

    ._product__container_image {}
  }

  .awssld__content {
    background-color: inherit;
  }

  .awssld__bullets {
    position: unset;
    background-color: none;
    //   border: 1px solid #000000;
  }

  .awssld__bullets button {
    background-color: rgb(201, 201, 201);
    transform: scale(1);
    margin: 0px 20px 5px;
    width: 100px;
    border-radius: 10px;
    height: 4px;

  }

  .awssld__bullets .awssld__bullets--active {
    transform: scale(1);
    background-color: #000000;
    border: 1px solid #000000;
  }


  .awssld__bullets .awssld__bullets--loading {
    //   transform: scale(0.6);
  }

  .awssld__controls {
    visibility: hidden;
  }

  // .awssld__controls__arrow-right {
  //   visibility: hidden;
  // }
}

.slick-track {}

.home__banner__slick {
  position: relative;
  @media screen and (max-width:992px) {
    z-index: 1;
  }

  .slick-slide img {
    width: 100%;
  }

  .slick-prev {
    left: 150px;
    background: url(./../assets/Icon/left_arrow.svg) !important;
    width: 19px;
    height: 36px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.3s;
    z-index: 9;

    &::before {
      display: none !important;
    }
  }

  .slick-next {
    right: 150px;
    background: url(./../assets/Icon/right_arrow.svg) !important;
    width: 19px;
    height: 36px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.3s;

    &:before {
      display: none;
    }
  }

  .slick-prev:hover,
  .slick-next:hover,
  .slick-prev:hover,
  .slick-next:hover {
    opacity: 0.7;
  }

  .slick-dots li button:before {
    background: #dedede;
    content: "" !important;
    width: 50px;
    height: 2px;

  }

  .slick-dots li button,
  .slick-dots li {
    width: 50px;
    height: 2px;
  }

  .slick-dots li.slick-active button:before {
    background: #fff !important;
    opacity: 1 !important;
  }

  .slick-dots {
    bottom: 10px !important;
  }
}

@media(max-width:1199px) {
  .home__banner__slick .slick-next {
    right: 50px;
  }

  .home__banner__slick .slick-prev {
    left: 50px;
  }
}
@media(max-width:767px) {
  .home__banner__slick .slick-next {
    right: 20px;
    width: 13px;
    height: 25px;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
  }

  .home__banner__slick .slick-prev {
    left: 20px;
    width: 13px;
    height: 25px;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
  }

  .home__banner__slick .slick-dots li button, .home__banner__slick .slick-dots li {
      width: 30px;
      height: 2px;
  }
  .home__banner__slick .slick-dots li button:before{
    width: 30px;
    height: 2px;
  }
  .home__banner__slick .slick-dots {
    height: 20px;
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .home__banner__slick {
    .slick-prev{
      right: auto !important;
      left: 150px !important;
      @media screen and (max-width:992px) {  left: 70px !important;   }
      @media screen and (max-width:576px) {  left: 50px !important;   }
    }
    .slick-next{
      right: 150px !important;
      left: auto !important;
      @media screen and (max-width:992px) {  right: 70px !important;   }
      @media screen and (max-width:576px) {  right: 50px !important;   }
    }
  }

}

/*-------------------------- Arabic view end ------------------------*/
