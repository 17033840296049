.mobile__product__detail__page {
	.mb__block__bottom__line {
		background: #c8c9ca;
		height: 3px;
	}

	.mb__pd__size__title {
		font-size: 14px;
		font-family: 'SSTbold';
		margin-top: 0px;
		margin-bottom: 5px;
	}

	.store__pickup__location__listing {
		.locationCard {
			padding: 10px;
			border: 1px solid #e7e7e7;
			min-width: auto;
		}

		.location__show__more__button {
			border: none;
			padding: 8px 10px;
			width: 100%;
			text-align: center;
			font-family: "SSTbold";
			color: #000000;
			font-size: 15px;
			background-color: unset;
			text-decoration: underline;
			line-height: 1.1;
		}
	}

	.product__offer__block.my_reward_icon {
		padding: 13px 15px !important;

		p {
			margin: 0px !important;
			font-size: 16px;
		}
	}

	.earn_loyalty {
		padding-left: 32px;
		justify-content: space-between;
		margin: 0px 15px;

		p.heading6 {
			font-size: 16px;

			@media screen and (max-width:480px) {
				font-size: 14px;
			}
		}
	}

	.mb__pdp__topbar__section {
		padding: 17px 15px;
		display: flex;
		justify-content: space-between;

		.mb__pdp__back__btn {
			font-size: 15px;
			color: #5A68EC;
			text-decoration: none;
			font-weight: 500;
			display: flex;
			align-items: center;

			svg {
				margin-right: 5px;
			}
		}

		.mb__pdp__compare__block {
			display: flex;
			align-items: center;

			label {
				margin: 0px;
				margin-left: 5px;
				color: #000000;
				font-weight: 500;
			}
		}
	}

	.pd__return__block {}

	.visa__notes__box{
		@media screen and (max-width:992px) {
			margin: 10px 15px !important;
		}
	}


	.mb__product__details__titlebox {
		.mb__product__namebox {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;

			// max-width: calc(100% - 35px);
			.testfreaks-badge {
				width: 50%;

				&:nth-child(3) {
					display: none !important;
				}
			}

			.heading3 {
				display: flex;
				flex: 0 0 100%;
				max-width: 100%;

				@media screen and (max-width:480px) {
					font-size: 16px;
				}
			}

			.pdp__sticky__counter__area {
				// padding: 0px 15px;		

				@media screen and (max-width:320px) {
					display: flex;
					flex-direction: column;
					padding: 0px;
					margin: 0px auto;

					.col-6 {
						flex: 0 0 auto;
						width: 100%;
						max-width: 100%;
					}
				}

				.pdp__sticky__counter__block {
					max-width: 170px;
					display: flex;
					flex-direction: row;

					@media screen and (max-width:320px) {
						margin-top: 10px;
					}
				}

				.mb__sku__infobox {
					justify-content: flex-end;

					.heading6,
					.text3 {
						font-size: 16px !important;

						@media screen and (max-width:480px) {
							font-size: 14px !important;
						}
					}

					.mb__pro__sku__copy {
						font-size: 16px;
						font-weight: 500;
						margin-left: 5px;
						text-decoration: none;

						@media screen and (max-width:480px) {
							font-size: 14px !important;
						}
					}
				}
			}
		}


		.pd__favourite__button {
			padding: 4px 4px;
			font-size: 18px;
			font-family: "SSTregular";
			letter-spacing: 0.18px;
			border: 1px solid #707070;
			border-radius: 3px;
			background-color: #ffffff;
			float: right;

			.pd__favourite__icon {
				display: block;
			}

			.pd__favourite__icon__disable {
				display: none;
			}
		}
	}

	.pd__install__info__area {
		.heading6 {
			font-size: 14px;
		}

		label {
			width: 100px !important;
		}

		.install__option__area {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;

			.install__Ans {
				display: inline-flex;
				flex: 0 0 50%;
				max-width: 50%;

				@media screen and (max-width:480px) {
					max-width: 100px;
				}

				input[type="radio"] {
					accent-color: #dc3a1a;
					cursor: pointer;

					&:after {
						content: '';
						display: block;
						width: 50%;
						height: 38%;
					}
				}

				label {
					font-size: 14px;
					vertical-align: top;
					margin-left: 4px;
					font-weight: 700;
					color: #000000;
					cursor: pointer;
					margin-left: 5px;
				}
			}

			.install__message__box {
				display: flex;
				flex: 0 0 100%;
				max-width: 100%;
				margin-top: 6px;
				justify-content: space-between;

				.change_button {
					color: #5A68EC;
					cursor: pointer;
				}

				span {
					font-size: 15px;
					font-weight: 600;
					color: #dc3a1a;
				}
			}
		}

		input[type="checkbox"] {
			width: 16px;
			height: 16px;
			accent-color: #dc3a1a;
		}

		strong {
			font-size: 14px;
			vertical-align: top;
			margin-left: 4px;
		}
	}

	.mb__pdp__size__sec {
		.mb__pd__size__block {
			.col-7 {
				&+.mb__pd__price__col {
					padding-left: 6px;
				}

				:not(&)+.mb__pd__price__col {
					background-color: #cdcdcd;
				}
			}
		}
	}

	.loyalty__points__area {
		padding: 0px 15px;
		margin-top: 15px;
		row-gap: 5px;

		@media screen and (max-width:992px) {
			padding: 0px;
			margin: 15px 0px 0px;
		}

		.loyalty__item__box {
			padding: 15px 15px;
			background-color: #F8F8F8;
			border-radius: 15px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;

			@media screen and (max-width:480px) {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				min-height: 85px;
				height: 100%;
			}

			@media screen and (max-width:320px) {
				min-height: 93px;
				padding: 12px 12px;
			}

			img {
				width: auto;
				max-height: 27px;
				max-width: 90px;

				@media screen and (max-width:480px) {
					max-width: 80px;
					max-height: 24px;
				}

				@media screen and (max-width:320px) {
					max-width: 70px;
				}
			}

			.loyalty__contentbox {
				color: #000000;
				text-align: right;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				margin-top: 5px;

				@media screen and (max-width:480px) {
					text-align: center;
				}

				p {
					font-size: 13px;
					margin-bottom: 0px;
					line-height: 1.2;

					@media screen and (max-width:320px) {
						font-size: 12px;
					}
				}

				span {
					font-size: 11px;
					font-weight: 500;

					@media screen and (max-width:320px) {
						font-size: 10px;
					}
				}
			}
		}
	}

	.mb__accordion__section {
		margin-top: 20px;

		.accordion-item {
			margin-bottom: 10px;
			border-bottom: none;

			.accordion-header {
				button {
					background-color: #F8F8F8;
					border-radius: 15px;
					color: #000000;
					font-weight: 700;
					display: flex;
					justify-content: space-between;

					&:after {
						display: none;
					}

					span {
						font-size: 15px;
						color: #dc3a1a;
						font-weight: 600;

						@media screen and (max-width:480px) {
							font-size: 14px;
						}
					}

					@media screen and (max-width:480px) {
						padding: 13px 15px;
						font-size: 14px;
					}
				}
			}
		}

	}

	.pd__related__product__section {
		padding: 15px 5px 15px;
		border: 1px solid #DBDBDB;
		border-radius: 15px;
		margin: 0px 15px 20px;

		.pd__mb__block__title {
			font-size: 20px;
			font-family: 'SSTbold';
			font-weight: bold;
			color: #000000;
			letter-spacing: 0.24px;
			line-height: 24px;
			padding: 0px 15px 15px;
			border-bottom: 1px solid #E4E4E4;
			margin-bottom: 0px;

			@media screen and (max-width:1366px) {
				font-size: 18px;
			}
		}

		.pd__mb__product__block {

			padding: 15px 5px 15px;
			border-bottom: 1px solid #E4E4E4;
			margin: 0;

			&:last-child {
				border-bottom: none;
			}

			.pd__mb__product__image__block {
				position: relative;
            	height: max-content;
				// height: 100%;
				// width: 100%;
				.pd__mb__product__image {
					height: 50%;
					width: 100%;
					object-fit: contain;
				}
				.soldOutLable {
					position: absolute;
					width: 100%;
					background: hsla(0, 0%, 85.1%, 0.76);              
					top: 50%;
					left: 0px;
					transform: translateY(-50%);
					padding: 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-style: normal;
					font-weight: 700;
					font-size: 13px;
					line-height: 1.1;
					color: rgba(0, 0, 0, 0.72);
					text-transform: uppercase;
					text-decoration: none;
				  }
			}

			.pd__mb__product__detail__block {
				.pd__mb__product__name {
					text-decoration: none;
					color: #000000;
					display: block;
					margin-bottom: 10px;

					p {
						margin-top: 8px !important;
						margin-bottom: 6px !important;
						@media screen and (max-width:1280px) {
							font-size: 14px;
						}
					}
				}

				.product__delivery__option__area {
					@media screen and (max-width:1480px) {
						
					}

					.product__delivery__itembox {
						padding: 5px 4px;

						@media screen and (max-width:1480px) {
							margin-bottom: 0px;
						}

						&:last-child {
							margin-bottom: 0px;
						}

						img {
							margin-right: 4px;
						}

						span {
							@media screen and (max-width:1600px) {
								font-size: 9px;
							}
						}
					}
				}

				.mb__pd__related__pricebox {
					display: flex;
					flex-direction: row;

					@media screen and (max-width:1480px) {
						flex-direction: column;
					}

					span {
						font-size: 14px;

						@media screen and (max-width:1500px) {
							font-size: 13px;
						}
					}

					.text4 {
						color: #C8C8C8 !important;
						font-weight: normal;

						@media screen and (max-width:1480px) {
							margin-left: 0px !important;
						}
					}
				}

				.heading7,
				.text4 {
					display: block;
					line-height: 1.1;
					font-family: 'ArialBold';
					font-weight: bold;
				}

				.pd__mb__product__oldPrice {
					margin: 0px 10px 0px 10px;
				}

			}

			.compare__cart_box {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				padding-top: 6px;
				align-items: center;
				flex-wrap: wrap;

				@media screen and (max-width:1366px) {
					padding: 6px 10px;
				}

				.pd__compare__block {
					display: flex;
					align-items: center;
					margin: 0px 0px 0px;

					.pd__compare__input__check {
						accent-color: #000000;
						height: 15px;
						width: 15px;
						border-radius: 10px;
						margin: 0px 8px 0px 0px;
						// border: 15px solid #303030;
						text-transform: uppercase;
						cursor: pointer;

						@media screen and (max-width:1280px) {
							height: 13px;
							width: 13px;
						}
					}

					.pd__compare__text {
						font-size: 14px;
						font-family: "SSTregular";
						margin: 0px;
						cursor: pointer;
					}
				}

				.pd__relatedPro__item__buttonBox {
					text-align: right;

					@media screen and (max-width:1360px) {
						margin-left: auto;
					}

					.pd__related__action__btn {
						padding: 7px 10px;
						color: #ffffff;
						font-size: 12px;
						font-weight: bold;
						border-radius: 20px;
						text-transform: uppercase;
						border: none;
						line-height: 1;
						display: flex;
						flex-direction: row;
						align-items: center;
						text-align: center;

						img {
							height: 14px;
							margin-right: 6px;
						}

						&:hover {
							box-shadow: 0 1px 2px #666666;
						}

						.spinner-border {
							&.ms-3 {
								margin-left: 7px !important;
							}
						}
					}

					.pd__addToCart__button {
						border-radius: 20px;
						background-color: #dc3a1a;
						position: relative;
						margin-bottom: 0px;
						min-width: 127px;

						@media screen and (max-width:1360px) {}

						img {
							width: 14px;

							@media screen and (max-width:1360px) {
								width: 13px;
							}

							// & + .spinner-border{
							//   position: absolute;
							//   height: 20px;
							//   width: 20px;
							//   background-color: #ffffff45;
							//   border-radius: 50%;
							//   left: 0px;
							//   top: 0px;
							//   margin: 5px !important;
							//   padding: 0;
							//   &.ms-3{
							//     margin-left: 5px !important;
							//   }
							//   @media screen and (max-width:1360px){ 
							//     height: 17px;
							//     width: 17px;
							//   }
							// }
						}

						.btn__spinner {
							min-width: 95.35px;
							min-height: 14px;

							@media screen and (max-width:1360px) {
								min-width: 100px;
							}
							@media screen and (max-width:1280px) {}
							@media screen and (max-width:580px) {}
						}
					}
					.outofStock__button{
						background-color: #a7a7a7;
						cursor: unset;
						&:hover{
						  box-shadow: none;
						}
					}
					.pre-order {
						padding: 7px 10px;
						border-radius: 20px;
						background-color: #F3A100 !important;
						min-width: 109px;

						@media screen and (max-width:1360px) {
							padding: 5px 8px;
							font-size: 14px;
						}

						img {
							width: 14px;

							@media screen and (max-width:1360px) {
								width: 13px;
							}
						}
						.btn__spinner{
							min-height: 14px;
						}
					}

					.notifyMe__button {
						background-color: #FE8B21;
						color: #ffffff;
						border-radius: 20px;
					}

					.pd__buy__button {
						background-color: #000000;
					}
				}
			}

		}

		.view__more__address__block {
			display: flex;
			flex-direction: column;
			padding-top: 15px;
			align-items: center;

			.view__more__address__button {
				display: flex;
				align-items: center;
				flex-direction: row;
				cursor: pointer;
				text-align: center;
				min-width: fit-content;
				font-size: 14px;
				font-family: 'SSTmedium';
				font-weight: 600;
				border-radius: 5px;
				border: none;
				color: #000000;
				background-color: #ffffff;
				outline: none;
				letter-spacing: 0.3px;
				margin-bottom: 0px;
				width: auto;


				&:hover {
					text-decoration: underline;
					text-decoration-color: #818181;
				}

				svg {
					height: 16px;
					width: auto;
					margin-left: 8px;
					color: #000000;

					@media screen and (max-width:1280px) {
						margin-left: 6px;
					}
				}


			}
		}
	}
	.productOfferTagMobile{
		color: #fff;
		background: #dc3a1a;
		margin: 10px auto;
		padding: 10px 12px;
		font-size: 14px;
		width: auto !important;
		/* max-width: 100%; */
		word-break: break-word;
		text-align: center;
		display: inline-block;
	}
	.productOfferTagMobile p{
		margin: 0px;
	}
	.mb__product__detail__block{
		text-align: center;
	}
}

.mobile__build__bundle__page {
	.delivery__taken__area {
		margin: 0px 0px;
	}

	.store__pickup__taken__area {
		margin: 0px 0px;
	}

	.store__pickup__location__listing {
		margin: 10px 0px;

		.locationCard {
			min-width: auto;
			padding: 10px;
		}
	}
}

// banner css start
.mb__product__detail__carousel {
	// width: 85%;
	// margin: 0px auto;
	margin-top: 15px;

	.slick-slider {

		.slick-prev,
		.slick-next {
			background-color: transparent !important;

			&::before {
				color: #DC3A1A;
				font-size: 23px;
			}
		}

		.slick-prev {
			margin-left: 0px !important;
		}

		.slick-next {
			margin-right: 0px !important;
		}

		.slick-list {
			@media screen and (max-width:992px) {
				img {
					// max-height: 500px;
					margin: 0px auto;
					max-width: min-content !important;
				}
			}

			@media screen and (max-width:580px) {
				img {
					// max-height: 350px;
					margin: 0px auto;
					max-width: min-content !important;
				}
			}
		}
	}

	.carousel.activeCarousel {
		.carousel_zoomBox {
			// width: 428px;
			max-width: 100%;
			max-height: max-content;
			// height: 500px;

			// img{
			//   height: auto !important;
			//   width: 100% !important;
			// }
			img.carousel_zoom_img {
				&+div {
					background-color: #ffffff;

					img {
						max-width: 800px;
						height: auto !important;
						max-height: 950px;
					}

					div {
						@media screen and (max-width:992px) {
							display: none !important;
						}
					}
				}
			}
		}

	}



	// .carousel__main__container {
	// 	flex-wrap: wrap;

	// 	.carousel__block {
	// 		background: transparent;
	// 		border: 0;
	// 		height: 380px !important;

	// 		.carousel.activeCarousel{
	//           .carousel_zoomBox {
	//             // width: 428px;
	//             max-width: 100%;
	//             max-height: max-content;
	//             // height: 500px;

	//             // img{
	//             //   height: auto !important;
	//             //   width: 100% !important;
	//             // }
	//             img.carousel_zoom_img {
	//               & + div{
	//                 background-color: #ffffff;
	//                 img{
	//                   max-width: 900px;
	//                   height: auto !important;
	//                   max-height: 1000px;
	//                 }
	//               }                
	//             }
	//           }

	//         }
	// 	}

	// 	.pd__social__media__compare__block {
	// 		display: none;
	// 	}

	// 	.carousel__dots__main__block {
	// 		width: 100% !important;
	// 		order: 1;
	// 		height: 20px !important;

	// 		.carousel__dots__arrow__block {
	// 			display: none;
	// 		}

	// 		.carousel__dots__block {
	// 			display: flex;
	// 			justify-content: center;
	// 			height: 20px !important;
	// 			width: 100%;

	// 			.carousel__dot {
	// 				height: 10px !important;
	// 				width: 10px;
	// 				overflow: hidden;
	// 				border-radius: 100%;
	// 				margin: 0 5px;

	// 				.overlay {
	// 					display: none;
	// 				}

	// 				&.carousel__dotActive {
	// 					background: #000;
	// 				}

	// 				.carousel__thumbnail__image {
	// 					display: none;

	// 				}
	// 			}
	// 		}
	// 	}
	// }

}

// banner css end
// mobile pro detail
.mb__product__detail__block {
	align-items: center;
	display: flex;
	margin: 20px 0;

	.soldOutLable {
		text-align: center;
		font-size: 16px;
		padding: 5px;
		color: #e9555b;
		font-weight: 700;
		text-transform: uppercase;
		border-bottom: 1px solid #c8c9ca;
		margin-bottom: 5px;
	}

	.store__pickup__location__listing {
		.locationCard {
			padding: 15px;
			border: 1px solid #e7e7e7;
		}

		.location__show__more__button {
			border: none;
			padding: 8px 10px;
			width: 100%;
			text-align: center;
			font-family: "SSTbold";
			color: #000000;
			font-size: 15px;
			background-color: unset;
			text-decoration: underline;
		}
	}

	@media (max-width:992px) {
		margin: 0px 0px 20px;
	}

	.pd__product__company__logo {
		width: 130px;
	}


	.pd__category__favourite__button__block {
		margin: 12px 0px 12px 0px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		button {
			height: 35px !important;
		}

		.pd__category__button {
			padding: 4px 18px;
			margin: 0px 14px 10px 0px;
			font-size: 14px;
			font-family: "SSTregular";
			letter-spacing: 0.18px;
			border: 1px solid #707070;
			border-radius: 3px;
			background-color: #ffffff;
		}



		.pd__share__button {
			padding: 2px 6px;
			font-size: 18px;
			font-family: "SSTregular";
			letter-spacing: 0.18px;
			border: 1px solid #707070;
			border-radius: 3px;
			background-color: #ffffff;
			margin-right: 14px;
			line-height: 10px;
			width: 41px;
			height: 35px;
		}
	}

	.pd__category__button {
		padding: 4px 15px;
		margin: 0px 14px 5px 0px;
		font-size: 18px;
		font-family: "SSTregular";
		letter-spacing: 0.18px;
		border: 1px solid #707070;
		border-radius: 3px;
		background-color: #ffffff;
	}

	.mb__price__block {
		background: #CDEBCE 0% 0% no-repeat padding-box;
		border-radius: 5px;
		padding: 10px;

		.mb__save__off {
			background: #ff4f04;
			border-radius: 3px;
			color: #fff;
			font-family: 'SSTbold';
			font-weight: bold;
			font-size: 14px;
			margin: 4px 0 8px;
			padding: 2px 10px;
			text-transform: uppercase;
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
		}

		.mb__off__text p {
			letter-spacing: 0.18px;
			color: #FF4F04;
			margin-left: 10px !important;
		}
	}

	p.mb__saving__tagline {
		color: #808080;
		font-size: 11px;
		margin-top: 10px;

		span {
			color: #000;
			font-weight: 600;
			font-size: 15px;
		}
	}

	a.know__more__link {
		font-size: 10px;
		color: #003CB4;
	}
}

// mobile pro detail ends

// size sec start
.mb__pdp__size__sec {


	.mb__pd__size__button__block {
		.mb__pd__size__button {
			font-size: 13px;
			border: none;
			font-weight: 700;
			color: #000000;
			border-radius: 10px;
			padding: 11px 11px;
			font-family: "SSTbold";
			background-color: #F7F7F7;
			margin: 0px 12px 10px 0px;
			outline: none;
		}

		.mb__pd__size__button__active {
			font-size: 13px;
			font-weight: 700;
			border-radius: 10px;
			color: #ffffff;
			padding: 11px 11px;
			font-family: "SSTbold";
			background-color: #FE6321;
			border: none;
			margin: 0px 12px 10px 0px;
			outline: none;
		}
	}
}

// size sec end

// delvery sec start
.mb__delivery__sec {

	.exp__rd__select__block {
		width: 25px;
		display: flex;
		align-items: center;

		.exp__rd__check__icon {
			display: block;
			width: 18px;
			height: 18px;
		}

		.exp__rd__check__icon__disable {
			display: none;
		}
	}


}

// delvery sec end
.pd__return__block {
	display: flex;
	align-items: center;
	padding: 8px 16px;
	margin: 16px 15px 20px;
	border-radius: 15px;
	background-color: #F8F8F8;

	.pd__icon__block {
		width: 32px;
		margin: 0px 16px 0px 0px;

		.pd__return_period_icon {}
	}

	.pd__return__text__block {
		.heading7 {
			font-size: 13px;
		}

		.pd__learn__more__link {
			font-size: 15px;
		}
	}
}

.mb__return_policy {
	margin-bottom: 0px;

	li {
		position: relative;
		padding-right: 30px;
		width: 30%;
		height: 100%;

		span {
			display: inline-block;
			height: 40px;
			margin-bottom: 8px;

			img {
				height: 100%;
				width: auto;
			}
		}

		&::after {
			width: 1px;
			position: absolute;
			right: 0;
			height: 90%;
			background: #d1d1d1;
			content: "";
			top: 50%;
			transform: translateY(-50%);
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}
}

// super coin section

.mb__supercoin__Sec {
	.super__coin__text__block {
		padding-left: 30px !important;
	}
}

// highlight sec start
.mb__highlight__sec {
	.mb__highlight__list {
		list-style: none;
		margin-bottom: 0px;

		li {
			position: relative;
			font-size: 12px;
			color: #2B2B2B;
			margin-bottom: 8px;

			&:before {
				content: "\2022";
				color: #C8C8C8;
				font-weight: bold;
				display: inline-block;
				width: 1em;
				margin-left: -1em;
			}
		}
	}
}

// overview section start
#mb__overview__accordian {
	margin-bottom: 20px;

	.accordion-body {
		.testfreaks-reviews {
			&:nth-child(2) {
				display: none !important;
			}
		}

		@media (max-width:992px) {
			padding: 10px 5px;
		}

		@media (max-width:580px) {
			font-size: 14px;
		}

		h2 {
			@media (max-width:580px) {
				font-size: 19px;
			}
		}
	}

	.col-4,
	.col-6 {
		padding: 0;

		&:nth-child(0) {
			.accordion-button {
				border-right: 0 !important;
				border-left: 0 !important;
			}
		}

		.accordion-item {
			text-align: center;

			// border-bottom: 1px solid #d4d4d4;
			.accordion-button {
				justify-content: center;
				border: 1px solid #d4d4d4;
				border-radius: 0;
				border-top: 0;
				padding: 10px 12px;

				&:focus,
				&:not(.collapsed) {
					box-shadow: none;
					outline: none;
					background: transparent;
					background: #DF4E32;
					color: #fff;
					padding: 10px 12px;
				}

				&:after {

					display: none;
				}
			}
		}
	}
}

.mb__exp__rd__main__block {
	.col-6 {
		&:last-child {
			.expert__rd__product__block {
				&::after {
					display: none;
				}
			}
		}
	}

	justify-content: center;
}

.mb__people__bought__sec {
	.section__title {
		font-size: 20px;
	}
}

.mb__pdp__sticky__add__to__cart__container {
	position: fixed;
	width: 100%;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	// display: flex;
	right: 0;
	z-index: 99;
	padding: 15px 15px 20px;
	background-color: #ffffff;

	@media screen and (max-width:992px) {}

	@media screen and (max-width:580px) {}

	.mb__pdp__sticky__add__to__cart__block {
		display: flex;
		justify-content: center;

		@media screen and (min-width:580px) and (max-width:992px) {
			max-width: 65%;
			margin: 0px auto;
		}
		.pd__bundle__block_btn{
			&:empty{
				display: none;
			}
		}
	}

	&:first-child {
		border-radius: 35px 0px 0px 35px;
	}

	&:last-child {
		border-radius: 0px 35px 35px 0px;
	}

	// .mb__pdp__Qty{
	// 	position: relative;

	// 	&::before {
	// 		content: " ";
	// 		display: block;
	// 		position: absolute;
	// 		height: 8px;
	// 		width: 8px;
	// 		right: 12px;
	// 		top: 40%;
	// 		z-index: 2; 
	// 		cursor: pointer;               
	// 		border-right: 2px solid #000000;
	// 		border-bottom: 2px solid #000000;
	// 		background-color: transparent;
	// 		transform: rotate(45deg) translateY(-40%);
	// 		-webkit-transform: rotate(45deg) translateY(-40%);
	// 	  }

	// 	.mb__pdp__Qty__selectbox{
	// 		min-width: 60px;
	// 		height: 100%;
	// 		border: 1px solid #000000;
	// 		border-radius: 10px;
	// 		padding: 8px 18px 8px 6px;
	// 		text-align: left;
	// 		appearance: none;
	// 		-webkit-appearance: none;    
	// 		z-index: 2;
	// 		background-color: transparent;  
	// 	}
	// }
	.field__block {
		border: 1px solid #000000;
		border-radius: 10px;
		padding: 10px 5px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 60px;
		appearance: none;
		-webkit-appearance: none;
		z-index: 2;
		background-color: transparent;

		.form__field {
			width: 100%;
			outline: none;
			border: none;
			font-size: 16px;
			letter-spacing: 0.3px;
			text-align: center;
		}

		/* Chrome, Safari, Edge, Opera */
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		/* Firefox */
		input[type=number] {
			-moz-appearance: textfield;
		}
	}

	.pd__buy__button {
		text-decoration: none;
		border-radius: 10px;
		color: #ffffff;
		font-size: 14px;
		font-family: 'SSTbold';
		font-weight: bold;
		letter-spacing: 0.85px;
		padding: 13px 10px;
		background-color: #000000;
		text-align: center;
		text-transform: uppercase;
		border: none;
		// height: 64px;		
		width: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 30%;
		margin-right: 6px;

		@media screen and (max-width:480px) {
			font-size: 13px;
			padding: 13px 7px;
		}

		@media screen and (max-width:380px) {
			font-size: 12px;
		}

		@media screen and (max-width:320px) {
			font-size: 11px;
			padding: 9px 6px;
		}

		.spinner-border-sm {
			width: 0.8rem;
			height: 0.8rem;

			@media screen and (max-width:320px) {
				width: 0.6rem;
				height: 0.6rem;
			}

			&.ms-3 {
				margin-left: 7px !important;

				@media screen and (max-width:380px) {
					margin-left: 5px !important;
				}

				@media screen and (max-width:320px) {
					margin-left: 4px;
				}
			}
		}

		&.pd__apple__buy__btn{
			margin: 0px auto;
			svg {
			  margin: 0px 3px;			  
			  height: 12px;
			  width: 12px;
			  @media screen and (max-width:380px) {
				height: 11px;
			  	width: 11px;
			  }
			}
			&:disabled{
                background-color: #707070;
                opacity: 0.65;
              }
		}
	}

	.addToCart__button {
		text-decoration: none;
		width: 64%;
		color: #ffffff;
		font-size: 14px;
		font-family: 'SSTbold';
		font-weight: bold;
		letter-spacing: 0.85px;
		padding: 13px 10px;
		border-radius: 10px;
		background-color: #dc3b2f;
		text-align: center;
		text-transform: uppercase;
		display: flex;
		border: none;
		justify-content: center;
		align-items: center;
		margin-right: 7px;
		// height: 64px;
		cursor: pointer;

		@media screen and (max-width:480px) {
			font-size: 13px;
			padding: 13px 7px;
		}

		@media screen and (max-width:380px) {
			font-size: 12px;
			padding: 13px 6px;
		}

		@media screen and (max-width:320px) {
			font-size: 11px;
			padding: 9px 6px;
		}

		.addToCart__icon {
			margin: 0px 7px 0px 0px;
			height: 19px;

			@media screen and (max-width:480px) {
				height: 16px;
				margin: 0px 5px 0px 0px;
			}

			@media screen and (max-width:380px) {
				height: 14px;
			}

			@media screen and (max-width:320px) {
				height: 12px;
				margin-right: 3px;
			}
		}

		.spinner-border-sm {
			width: 0.8rem;
			height: 0.8rem;

			@media screen and (max-width:320px) {
				width: 0.6rem;
				height: 0.6rem;
			}

			&.ms-3 {
				margin-left: 7px !important;

				@media screen and (max-width:380px) {
					margin-left: 5px !important;
				}

				@media screen and (max-width:320px) {
					margin-left: 2px !important;
				}
			}

		}

	}

	.outofStock__button {
		text-decoration: none;
		width: 50%;
		color: #ffffff;
		font-size: 14px;
		font-family: 'SSTbold';
		font-weight: bold;
		letter-spacing: 0.85px;
		padding: 13px 10px;
		border-radius: 10px;
		background-color: #666666;
		/* #a7a7a7*/
		text-align: center;
		text-transform: uppercase;
		display: flex;
		border: none;
		justify-content: center;
		align-items: center;
		margin: 0px auto;
		// height: 64px;
		cursor: default;

		@media screen and (max-width:480px) {
			font-size: 13px;
			padding: 13px 7px;
		}

		@media screen and (max-width:380px) {
			font-size: 12px;
		}

		@media screen and (max-width:320px) {
			font-size: 11px;
			padding: 9px 6px;
		}

		.spinner-border-sm {
			width: 0.8rem;
			height: 0.8rem;

			@media screen and (max-width:320px) {
				width: 0.6rem;
				height: 0.6rem;
			}

			&.ms-3 {
				margin-left: 7px !important;

				@media screen and (max-width:380px) {
					margin-left: 5px !important;
				}

				@media screen and (max-width:320px) {
					margin-left: 2px;
				}
			}
		}
	}

	.notifyMe__button {
		border: none;
		border-radius: 10px;
		color: #ffffff;
		font-size: 15px;
		font-family: 'SSTbold';
		text-transform: uppercase;
		letter-spacing: 0.85px;
		padding: 13px 10px;
		line-height: 1.5;
		background-color: #FE8B21;
		text-transform: uppercase;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 0px;
		width: 95%;
		margin: 0px auto;

		img {
			height: 14px;
			margin: 0px 8px 0px 0px;

			@media screen and (max-width:480px) {
				height: 13px;
			}
		}

		@media screen and (max-width:480px) {
			font-size: 13px;
			padding: 13px 7px;
		}

		@media screen and (max-width:380px) {
			font-size: 12px;
		}

		@media screen and (max-width:320px) {
			font-size: 11px;
			padding: 9px 6px;
		}

		.spinner-border-sm {
			width: 0.8rem;
			height: 0.8rem;

			@media screen and (max-width:320px) {
				width: 0.6rem;
				height: 0.6rem;
			}

			&.ms-3 {
				margin-left: 7px !important;

				@media screen and (max-width:380px) {
					margin-left: 5px !important;
				}

				@media screen and (max-width:320px) {
					margin-left: 3px !important;
				}
			}
		}
	}
}

@media(max-width:767px) {
	.mb__pdp__size__sec .mb__pd__size__button__block button {
		margin-right: 3px !important;
	}

	.mb__pd__size__button__active,
	.mb__pd__size__button {
		font-size: 13px;
	}

	.mb__return_policy li {
		width: 31%;
		padding-right: 10px;
	}

	.mobile__product__detail__page .mb__pd__size__title {
		font-size: 14px;
		font-weight: 700;
	}

	.expert__rd__product__block::after {
		font-size: 31px;
	}

	#addAddressModal .modal-footer button {
		width: 45% !important;
		padding: 8px 15px;
	}

	.mb__product__detail__block .pd__product__company__logo {
		width: 100px;
	}
}


// bottom modal popup ui start
.mb__bottom_popup {
	.mb__dialog__end {
		display: flex;
		margin-bottom: 0 !important;
		align-items: flex-end;
		min-height: calc(100% - 1.8rem);
		max-width: 100%;
		margin-left: 0;
		margin-right: 0;

		.modal-content {
			border-radius: 0;

			.modal-header {
				border-bottom: 0;
			}
		}
	}
}

// bottom modal popup ui end
// address popup
.mb__adress__tag {
	border: 1px solid #EEEEEE;
	border-radius: 3px;
	background-color: #EEEEEE;
	font-size: 14px;
	text-transform: uppercase;
	position: absolute;
	top: 10px;
}

.inner__mobile__dealsOfTheDay__block {
	.second__md__product__block:first-child {
		padding-right: 5px;
	}
}

// available offer popup
.available_Offer_popup {
	.modal-body {
		padding-top: 0px;

		.mb__availableOffer__itembox {
			padding: 12px 10px;
			margin: 12px 0px;
			border: 1px solid #707070;
			border-radius: 3px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;

			img {
				width: 18px;
				margin-right: 10px;
			}
		}

	}
}

// Create Bundle popup
.create__bundle__popup {

	.modal-dialog-scrollable {
		margin-top: 0px !important;
		margin-bottom: 0px !important;

		@media (max-width: 992px) {
			height: calc(100% - 0rem);
		}

		.modal-content {
			margin-top: 2.15rem;

			.modal-body {
				padding: 0px;
				max-height: 80vh;

				.breadcrumbs__container {
					display: none;
				}

				.create__bundle__page__container {
					background-color: #ffffff;
					padding: 0px;

					.cb__page__title,
					.cb__page__description {
						padding: 0px 15px;
					}

					.create__bundle__page__inner__block {
						padding: 0px;
						margin: 0px;

						.create__bundle__left__block {
							box-shadow: unset !important;
							margin-bottom: 0px;

							.cb__product__block {
								padding: 0px 0px !important;

								.cb__product__left__block {
									margin-bottom: 15px !important;
								}

								.cb__product__right__block {
									padding: 0px 0px !important;

									.cb__product__company__logo {
										padding-left: 15px;
									}

									.heading4 {
										font-size: 18px;
										padding: 0px 15px;
									}

									.product__protect__block {
										padding: 15px !important;
										margin-top: 15px;
										border-top: 1px solid #cdcdcd;

										.heading6,
										.product__block__title__span {
											display: none !important;
										}
									}
								}
							}

							.cb__page__button__block__title {
								margin-bottom: 0px;
								margin-top: 10px;
								padding: 0px 15px;

								.heading3 {
									font-size: 16px;
								}
							}

							.cb__page__block__title {
								.heading3 {
									font-size: 16px;
								}
							}

							.productThree__product__block {
								padding-top: 0px;
							}

						}

						.create__bundle__right__block {
							display: none;
						}
					}
				}
			}

			.modal-footer {
				border-top: none;
				background-color: #DC3A1A;
				padding: 15px 25px;
				display: flex;
				justify-content: space-between;
				flex-direction: row;

				.bundle__total__pricebox {
					width: 48%;
					margin: 0px;

					.bundle__pro__items {
						font-size: 14px;
						margin-bottom: 0px;
						color: #ffffff;
					}
				}

				.bundle__cart__btn {
					width: 50%;
					padding: 9px 13px;
					background-color: #ffffff;
					box-shadow: 0px 3px 6px #00000005;
					font-family: 'SSTbold';
					color: #dc3a1a;
					margin: 0px;
				}
			}
		}
	}
}

.cms_daynamic_modal {
	.modal-body {
		.container {
			padding: 0px;

			p {
				line-height: 2;
			}

			ul,
			ol {
				li {
					@media screen and (max-width:580px) {
						font-size: 14px;
						line-height: 1.9;
					}
				}
			}
		}
	}
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

	.mobile__product__detail__page {

		.mb__pdp__topbar__section {
			.mb__pdp__compare__block {
				label {
					margin-left: 0px;
					margin-right: 5px;
				}
			}

			.mb__pdp__back__btn {
				svg {
					transform: rotateY(180deg);
					-webkit-transform: rotateY(180deg);
					margin-right: 0px;
					margin-left: 5px;
				}
			}
		}

		.mb__product__details__titlebox {
			.pd__favourite__button {
				float: left;
			}
		}

		.mb__product__detail__block {
			.pd__category__favourite__button__block {
				.pd__category__button {
					margin: 0px 0px 10px 14px;
				}
			}

			.pd__share__button {
				margin-left: 0px;
				margin-right: 14px;
			}


		}

		.mb__product__namebox {
			.pdp__sticky__counter__area {
				.mb__sku__infobox {
					.mb__pro__sku__copy {
						margin-left: 0px;
						margin-right: 6px;
					}
				}
			}
		}

		.mb__return_policy li {
			&:first-child::after {
				display: none;
			}

			&:last-child::after {
				display: block;
			}
		}

		.loyalty__points__area {
			margin: 15px 10px 0px;

			.loyalty__item__box {
				.loyalty__contentbox {
					@media screen and (min-width:480px) and (max-width:580px) {
						margin-right: 6px;
					}
				}
			}
		}
		.pd__related__product__section {
			.pd__mb__product__block {				
				.compare__cart_box {
					.pd__compare__block {
						.pd__compare__input__check {
							margin: 0px 0px 0px 8px;
						}
					}
					.pd__relatedPro__item__buttonBox {	
						margin-left:0px;					
						margin-right: auto;			
						.pd__related__action__btn {
							img{
								margin-right: 0px;
								margin-left: 6px;
							}			
						}
					}
				}
			}
			.view__more__address__block {
				.view__more__address__button {
					svg {						
						margin-left: 0px;
						margin-right: 8px;
						@media screen and (max-width:1200px) {
							margin-right: 6px;
						}
					}
				}
			}
		}
	}

	.pd__return__block .pd__icon__block {
		margin: 0px 0px 0px 16px;
	}

	.product__offer__block.my_reward_icon {
		@media screen and (max-width:992px) {
			padding: 13px 15px 0px !important;
		}

		img {
			float: right;
			margin-right: 0px !important;
			margin-left: 8px;
		}

	}

	.row.pd__product__price__block.earn_loyalty {
		margin: 15px 15px !important;
		padding-left: 0px !important;
		padding-right: 32px;

		@media screen and (max-width:992px) {
			margin: 15px 15px !important;
		}

		.pd__product__monthly__price__block {
			padding-left: 0px;
			padding-right: 16px;
			width: 130px;
			margin-right: auto;

			@media screen and (max-width:380px) {
				width: auto;
			}
		}
	}

	.mb__pdp__sticky__add__to__cart__container {
		left: 50%;
		right: auto;
		transform: translateX(-50%);

		.mb__pdp__sticky__add__to__cart__block {

			// .buynow___button {
			// 	border-radius: 35px 0px 0px 35px;
			// }
			.pd__buy__button {
				margin-right: 0px;
				margin-left: 6px;
				font-family: 'SSTArabicBold';
				&.pd__apple__buy__btn{
					margin-left: 0px;
                    flex-direction: row-reverse;
				}
			}

			.addToCart__button {
				// border-radius: 0px 35px 35px 0px;
				margin-right: 0px;
				margin-left: 6px;
				font-family: 'SSTArabicBold';

				.addToCart__icon {
					margin: 0px 0px 0px 7px;
				}
			}

			.notifyMe__button {
				font-family: 'SSTArabicBold';

				img {
					margin: 0px 0px 0px 8px;
				}
			}

			.outofStock__button {
				font-family: 'SSTArabicBold';
				// border-radius: 0px 35px 35px 0px;
			}

			&.oneBtnOption {

				.buynow___button,
				.addToCart__button,
				.outofStock__button {
					border-radius: 10px;
				}

			}
		}

		.mb__pdp__Qty {
			.mb__pdp__Qty__selectbox {
				padding: 8px 22px 8px 6px;
			}
		}
	}

	.oneBtnOption {

		.buynow___button,
		.addToCart__button,
		.outofStock__button {
			border-radius: 35px 35px;
		}
	}

	.create__bundle__popup {
		.modal-dialog-scrollable {
			.modal-content {
				.modal-header {
					.btn-close {
						margin-left: 0px !important;
					}
				}

				.modal-body {
					.create__bundle__page__container {

						.cb__page__title,
						.cb__page__description {
							padding: 0px !important;
						}

						.create__bundle__page__inner__block {
							.create__bundle__left__block {
								.cb__page__block__title {
									padding: 0px !important;
								}

								.cb__product__block {
									.cb__product__left__block {
										padding: 0px !important;
									}

									.cb__product__right__block {
										.heading4 {
											padding: 0px !important;
										}

										.product__protect__block {
											&:empty {
												display: none;
											}
										}
									}
								}

								.cb__page__category__button__block {
									padding: 10px 5px;

									.cb__page__button__main__block {
										.left__arrow {
											left: 0px !important;

											img {
												width: 12px;
											}
										}
									}
								}

								.productThree__product__block {
									.productThree__product__right__block {
										.productThree__addToPackage__button {
											margin-right: 0px;
											margin-top: 12px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.available_Offer_popup {
		.modal-header {
			.btn-close {
				margin-left: 0px;
			}
		}

		.modal-body {
			.mb__availableOffer__itembox {
				img {
					margin-right: 0px;
					margin-left: 10px !important;
				}
			}
		}
	}

	.cms_daynamic_modal {
		.modal-header {
			.btn-close {
				margin: -0.5rem -0.5rem -0.5rem 0px;
			}
		}
	}
}

/*-------------------------- Arabic view End ------------------------*/