.productList__main__container {
  margin-bottom: 27px;

  .productList__block {
    width: 100%;
    margin: 0px 40px 0px 0px;
    background-color: #ffffff;

    // padding: 20px;
    .product__card__top {
      display: flex;
      justify-content: space-between;

      .new__product__title {
        width: 85px;
        height: 30px;
        background: #ff9b04;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;

        border-bottom-right-radius: 15px;

        .new__product__text {
          margin: auto;
          font-family: 'SSTbold';
          font-weight: bold;
          font-size: 15px;
          color: #ffffff;
        }
      }

      .productList__quality__favourite__block {
        display: flex;
        //   justify-content: space-between;
        align-items: center;

        // margin: 13px 13px 0px 0p;
        .productList__quality__icon {
          width: 36px;
        }

        .productList__favourite__icon {
          display: block;
        }

        .productList__favourite__icon__disable {
          display: none;
        }
      }
    }

    .product__card {
      padding: 20px;

      .productList__header__block {
        padding: 14px;
        margin: 0px 0px 20px;
        width: 100%;

        .productList__image__block {
          width: 272px;
          height: 252px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          .productList__image {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: 50% 50%;
          }
        }

        .productList__compare__block {
          display: flex;
          align-items: center;
          margin: 20px 0px 0px;

          .productList__compare__input__check {
            accent-color: #000000;
            height: 25px;
            width: 25px;
            border-radius: 10px;
            margin: 0px 16px 0px 0px;
            // border: 25px solid #303030;
            text-transform: uppercase;
          }

          .productList__compare__text {
            font-size: 16px;
            font-family: "SSTregular";
            margin: 0px;
          }
        }
      }

      .productList__name {
        font-family: 'SSTbold';
        font-weight: bold;
        font-size: 18px;
        margin: 10px 0px 10px 0px;
        height: 82px;
      }

      .productList__series {
        font-family: "SSTregular";
        font-size: 14px;
        margin: 0px 0px 10px 0px;
        color: #808080;
        letter-spacing: 0.5px;
      }

      .rating__block {
        padding: 0px;
        margin: 0px 0px 10px 0px;

        .bGVcoD {
          padding: 0px;
        }
      }

      .productList__size__button__block {
        padding: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px 0px 10px 0px;

        .productList__size__button__active {
          font-size: 12px;
          border-radius: 3px;
          color: #ffffff;
          padding: 10px 8px;
          background-color: #000000;
          border: 1px solid #000000;
          outline: none;
          border: none;
        }

        .productList__size__button {
          font-size: 12px;

          border: 1px solid #707070;
          border-radius: 3px;
          padding: 10px 8px;
          background-color: #ffffff;
          outline: none;
        }
      }

      .prize__block {
        display: flex;
        align-items: flex-end;

        .old__prize {
          font-family: "SSTregular";
          font-size: 18px;
          text-decoration-line: line-through;
          color: #c8c8c8;
        }

        .new__prize {
          font-family: 'SSTbold';
          font-weight: bold;
          font-size: 18px;

          margin: 0px 0px 0px 5px;
        }
      }

      .productList__fetureText {
        font-family: 'SSTregular';
        font-size: 16px;
        color: #2b2b2b;
        width: auto;
      }

      .free__delivery {
        .free__delivery text {
          margin: 0px;
          color: #2b2b2b;
          font-family: 'SSTregular';
          font-size: 12px;
        }

        .delivery__day {
          color: #2b2b2b;
          font-family: 'SSTbold';
          font-weight: bold;
          font-size: 14px;
        }
      }

      .addToCart__button {
        border-radius: 5px;
        color: #ffffff;
        font-size: 16px;
        font-family: 'SSTbold';
        font-weight: bold;
        letter-spacing: 0.85px;
        padding: 20px 0px;
        background-color: #dc3a1a;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;

        .addToCart__icon {
          margin: 0px 20px 0px 0px;
        }
      }
    }
  }
}