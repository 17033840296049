.plp__main__category__section {
  padding: 0% 5%;
  overflow: hidden;
  background: #000000;
  .plp__inner__category__section {
    overflow-x: auto;
    display: flex;
    padding: 1.5% 0% 1.2%;
    justify-content: center;
    flex-direction: column;
    .plp__category__section {
      width: fit-content;
      display: flex;
      align-items: flex-end;
      .selected__plp__category__block {
        height: 100%;
        width: 220px;
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 0px;
        margin: 0px 0px 0px;
        cursor: pointer;
        .plp__category__image {
          height: 30px;
          margin: 0px 0px 10px;
        }
        @media screen and (max-width: 1200px) { width: 200px; }
      }
      .plp__category__block {
        height: 100%;
        width: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 0px;
        margin: 0px 0px 0px;
        cursor: pointer;

        &:hover {
          background-color: #ffffff78;
          // color: #dc3a1a;
          // .plp__category__image{
          //   filter: invert(70%) sepia(108%) saturate(3389%) hue-rotate(356deg) brightness(107%) contrast(88%);
          // }
          // .text4{ color: #dc3a1a !important; }
        }
        @media screen and (max-width: 1200px) { width: 200px; }
        @media screen and (max-width: 580px) { width: 180px; }
        
        .plp__category__image {
          height: 30px;
          margin: 0px 0px 10px;
        }
        
      }
    }
  }
  .plp__inner__category__section::-webkit-scrollbar {
    height: 10px !important;
    background: rgb(226, 226, 226);
    border-radius: 10px;
  }
  .plp__inner__category__section::-ms-scrollbar {
    height: 10px !important;
    background: rgb(226, 226, 226);
    border-radius: 10px;
  }
  .plp__inner__category__section::-webkit-scrollbar-track {
    opacity: 1;
    background: rgb(226, 226, 226);
    border-radius: 10px;
  }
  .plp__inner__category__section::-ms-scrollbar-track {
    opacity: 1;
    background: rgb(226, 226, 226);
    border-radius: 10px;
  }
  .plp__inner__category__section::-webkit-scrollbar-track:hover {
    opacity: 1;
    background: rgb(226, 226, 226);
    border-radius: 10px;
  }
  .plp__inner__category__section::-ms-scrollbar-track:hover {
    opacity: 1;
    background: rgb(226, 226, 226);
    border-radius: 10px;
  }
  // /* Handle */
  .plp__inner__category__section::-webkit-scrollbar-thumb {
    height: 4px !important;
      opacity: 1;
      background-color: #181818;
    border-radius: 10px;
  }

  /* Handle on hover */
  .plp__inner__category__section::-webkit-scrollbar-thumb:hover {
    background: #303030;
  }
  .plp__inner__category__section::-webkit-scrollbar-thumb {
    height: 4px !important;
    opacity: 1;
    background-color: #dc3a1a!important;
    border-radius: 10px;
  }
  .plp__inner__category__section::-moz-scrollbar-thumb,
  .plp__inner__category__section::-ms-scrollbar-thumb {
    background-color: #dc3a1a !important;
    border-radius: 10px !important;
  } 

  /*  -------- scroll for firefox start ----------  */

    .plp__inner__category__section {    
      scrollbar-color: #dc3a1a rgb(226, 226, 226);
      -ms-overflow-style: none; /* IE 11 */
      scrollbar-width: thin; /* Firefox 64 */      
    }
    
    .plp__inner__category__section::-webkit-scrollbar {
      height: 0.5em!important;
      border-radius: 5px !important ;
    }

  /*  --------- scroll for firefox end ---------  */
    

  // .category__section__block {
  //   &.active__category {
  //     background-color: red;
  //     fill: none;
  //   }
  //   min-height: 126px;
  //   width: 185px;
  //   position: relative;
  //   text-align: center;
  //   // display: inline-grid;
  //   align-items: center;
  //   margin: auto;
  //   .category__section__image__block {
  //     //   margin-left: 10px;
  //     &.active__image {
  //       background-color: #000000;
  //       // fill: none;
  //     }
  //     padding-top: 16px;
  //     .category__section__image {
  //       object-fit: cover;
  //       // width: 73px;
  //       min-height: 69px;
  //     }
  //   }
  //   .category__section__title__block {
  //     margin-top: 18px;
  //     .category__section__title {
  //      
  //       font-size: 18px;
  //       // color: #DC3A1A;
  //       color: #ffffff;
  //     }
  //   }
  // }
}

