.spinner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  
    div {
      display: inline-block;
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.2rem;
      
      border-radius: 100%;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        
        &.light {     
            background-color: rgb(255 255 255 / 72%);     
        }
        &.dark {     
            background-color: rgba(0, 0, 0, 0.5);     
        }
        @media screen and (max-width:480px) {
          width: 0.65rem;
          height: 0.65rem;
        }
        @media screen and (max-width:380px) {
          width: 0.6rem;
          height: 0.6rem;
        }
    }
  
    .dot1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
  
    .dot2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }
  }
  
  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }
  
  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
