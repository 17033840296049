.product__avilable__offers__block {
  margin: 10px 0px;
  padding: 15px;
  background-color: #F8F8F8;
  border-radius: 10px;

  @media screen and (max-width:1280px) {
    padding: 12px 12px;
  }

  @media screen and (max-width:992px) {
    margin: 10px 15px;
  }

  @media screen and (max-width:580px) {
    margin: 0px 15px;
  }

  .product__block__title {
    font-size: 20px;
    font-family: 'SSTbold';
    font-weight: bold;
  }

  .installment__availbility__infobox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    img {
      max-width: 40px;
    }

    .install__contentbox {
      margin-left: 8px;

      .heading5 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 6px !important;

        @media screen and (min-width:992px) and (max-width: 1280px) {
          font-size:15px;
        }
        @media screen and (max-width: 480px) {
          font-size: 15px;
        }

        @media screen and (max-width: 320px) {
          font-size: 14px;
          margin-bottom: 5px !important;
        }
      }

      p {
        font-size: 13px;
        line-height: 1.1;
        margin-bottom: 0px;
      }
    }
  }

  .product__offer__block {
    display: flex;
    align-items: center;
    margin: 10px 0px 10px 0px;

    .product__offer__icon {
      width: 20px;
      margin: 0px 12px 0px 0px;
    }

    .product__tamara_offer__icon {
      width: 20px;
      margin: 0px 12px 0px 0px;
      height: 15px;
      width: 50px;
      margin-left: 30px;
    }

    .tamara-product-widget {
      line-height: 1.4 !important;
      font-size: 13px !important;
      padding: 16px 80px 16px 16px !important;

      &:empty {
        display: none;
      }

      @media screen and (max-width: 1280px) {
        font-size: 12px !important;
        padding: 16px 60px 16px 16px !important;
      }

      @media screen and (max-width: 992px) {
        padding: 16px 110px 16px 16px !important;
      }

      @media screen and (max-width: 480px) {
        font-size: 13px !important;
        font-weight: normal;
        padding: 16px 85px 16px 16px !important;
      }

      .tamara-logo {
        width: 40px !important;
        height: auto !important;

        @media screen and (max-width: 992px) {
          width: 70px !important;
        }

        @media screen and (max-width: 480px) {
          width: 52px !important;
        }
      }
    }

    .installment__offer__box {
      width: 100%;
    }

    #TabbyPromo {
      @media screen and (max-width:992px) {
        width: 100%;
      }

      .styles__tabby-promo-snippet_font_inherit--b7b35 {}

      .styles__tabby-promo-snippet__content--88401 {
        .styles__tabby-promo-snippet__text--f30af {
          span {
            line-height: 1.4 !important;
            font-size: 13px !important;
          }
        }
      }
    }

    .styles__tabby-promo-snippet--54518.styles__tabby-promo-snippet_font_inherit--b7b35 .product__offer__text__box {
      display: flex;
      align-items: center;

      .product__offertype {
        margin: 0px;
        font-size: 14px;
        font-family: 'SSTbold';
        font-weight: bold;

        .product__offerText {
          margin: 0px 10px 0px 0px;
          font-size: 14px;
          font-family: "SSTregular" !important;
          font-weight: 400;
        }
      }

      .product__termsAndConditions__link {
        margin: 0px;
        font-size: 14px;
        font-family: 'SSTbold';
        font-weight: bold;
        text-decoration: none;
        color: #003cb4;
      }
    }
  }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .product__avilable__offers__block {
    .product__offer__block {
      .product__offer__icon {
        margin: 0px 0px 0px 12px;
      }
    }

    @media screen and (max-width:992px) {
      margin: 10px 15px !important;
    }

    .installment__availbility__infobox {
      .install__contentbox {
        margin-left: 0px;
        margin-right: 8px;
      }
    }


    .product__offer__block {
      .tamara-product-widget {
        padding: 16px 16px 16px 80px !important;

        @media screen and (max-width: 1280px) {
          padding: 16px 16px 16px 60px !important;
        }

        @media screen and (max-width: 992px) {
          padding: 16px 16px 16px 110px !important;
        }

        @media screen and (max-width: 480px) {
          padding: 16px 16px 16px 85px !important;
        }
      }
    }


  }



}

/*-------------------------- Arabic view End ------------------------*/