.wishlist__page__container {
  padding: 0px 12px;
  background-color: #f5f5f5;
  .wishlist__page__block {
    padding: 0px 0px;
    width: 1420px;
    margin: 0px auto;

    .wishlist__title__block {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000000a;
      border: 1px solid #f1f1f1;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media(max-width:992px) {
        display: none;
      }
      .heading3{ line-height: 1.1; }
      .wishlist__right__part {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .mw__search__box__block {
          padding-left: 20px;
          .mw__search__box {
            border: 1px solid #dfdfdf;
            border-radius: 5px;
            padding: 2px 7px 2px 0px;
            background-color: white;
            height: 32px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            .mw__search__input {
              font-size: 14px;
              padding: 10px 10px;
              margin: 0px;
              background: none;
              outline: none;
              border: 0px;
              width: 96%;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
              box-shadow: 0 0 0 30px white inset !important;
              -webkit-box-shadow: 0 0 0 30px white inset !important;
            }
          }
        }
      }
    }
    .wishlist__page__product__block{
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000000a;
      border: 1px solid #f1f1f1;
      padding: 12px 0px;
      margin: 0px auto;
      display: flex;
      align-items:  flex-start;
      flex-wrap: wrap;
      justify-content: flex-start;
      @media(max-width:992px) {
        background: unset;
        padding: 0px;
        border: unset;
        box-shadow: unset;
      }
      .wishlist__product__block{
        margin: 0px 0px 20px;
        height: 100%;

        @media(max-width:992px) {
          &:nth-child(3n+1),&:nth-child(3n) {            
            padding-left: 0px;
            padding-right: 0px;
          }
          &:nth-child(3n+2){            
            padding-left: 12px;
            padding-right: 12px;
          }       
          margin: 0px 0px 15px;   
        }
        
        @media(max-width:767px) {
          &:nth-child(odd){            
            padding-left: 0px;
            padding-right: 6px;
          }
          &:nth-child(even){            
            padding-left: 6px;
            padding-right: 0px;
          }          
        }
        .productEight__block{
          padding: 10px;
            border: 1px solid #D1D1D1;
            box-shadow: 0px 3px 6px #0000000A;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            
          @media(max-width:992px) {
            padding: 10px;
            border: 1px solid #D1D1D1;
            box-shadow: 0px 3px 6px #0000000A;
            border-radius: 5px;
            display: inline-block;
            flex-direction: column;
          }
          @media screen and (max-width:321px) {
            padding: 6px;
          }
          .productEight__header__block{
            background-color: #ffffff;
            padding: 0px;
            margin-bottom: 10px;
            @media(max-width:992px) {
              order: 1;
              display: flex;
              flex-direction: column;
            }
            .productEight__quality__favourite__block {
              @media(max-width:992px) { 
                display: none; 
                &:first-child{ display: flex;}
              }              
              .productEight__quality__icon{
                @media(max-width:992px) { 
                  margin: 0px;
                  width: 25px;
                }
              }
            }
            .sc__fav__sec{
              @media(max-width:992px) { 
                margin-right: 0px !important;
                padding: 0px !important;
              }
              .common__favourite__button{
                img{
                  @media(max-width:992px) { 
                    width: 16px;
                    height: 16px;
                    margin: 1px;
                  }
                }                
              }
            }
            .productEight__image__block {
              @media(max-width:992px) { height: 150px; }
            }            
          }
          .productEight__name__link{
            @media(max-width:992px) { order: 2; margin-bottom: 4px; }
          }
          .prize__block{
            @media(max-width:992px) { order: 3; }
          }
          .rating__block{
            @media(max-width:992px) { 
              order: 4;
              .main__rating__block{ padding-bottom: 0px; }
            }
          }
          .cart_end_icon{
            display: none;
            @media(max-width:992px) { 
              order: 5; 
              display: flex; 
              justify-content: end;
              .productEight_cart_icon { width: 15px; }
            }

          }

          .productEight__name {
            @media(max-width:992px) {
              font-size: 13px;
              min-height: 72px;
              line-height: 1.4;              
            }
            @media(max-width:480px) {
              font-size: 12px;
              min-height: 60px;
              line-height: 1.2;
            }
          }
          .series__labelbox{
            @media(max-width:992px) { display: none; }
          }
          .productEight__series {                       
            @media(max-width:992px) { display: none; }
          }
          .productEight__size__button__block {
              margin: 0px 0px 6px 0px;
              @media(max-width:992px) { display: none; }
              .productEight__size__button__active, .productEight__size__button {
                font-size: 12px;            
                border-radius: 3px;
                padding: 6px 8px;
                line-height: 1.3;
                margin-right: 6px;                
            }                        
          }
          .prize__block{
            @media(max-width:992px) { flex-direction: column; align-items:flex-start; }
            // .text1{ font-size: 22px; margin-bottom: 0px !important; 
            //   @media(max-width:992px) { font-size: 14px; }
            // }
            // .heading4 { font-size: 25px; margin-bottom: 0px !important; 
            //   @media(max-width:992px) { font-size: 18px; margin-left: 0px !important; line-height: 1; }
            // }            
            @media(max-width:992px) {
              .text3{ font-size: 13px;line-height: 1;margin-bottom: 5px !important; }
              .heading6 { font-size: 14px; margin-left: 0px !important;margin-bottom: 5px !important;line-height: 1; }
            }
          }
        }
      }
      .wishlist__product__emptyBox {
        margin: 20px;
        padding: 2.5rem 1.2rem;
        background-color: #f9f9f9;
        text-align: center;
        @media screen and (max-width:992px) {
            margin: 0px !important;
        }
        img {
          @media screen and (max-width:992px) {
            width: 85%;
            max-width: 180px;
            margin: 0px auto;
          }
        }
        .text3 {
          font-size: 16px;
          font-family: "SSTbold";
          color: #000000;
          margin: 12px 0px !important;
        }
        .continue_shopping_btn:hover {
          box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
          transform: scale(1.1);
        }
        .continue_shopping_btn {
          padding: 9px 12px;
          display: inline-block;
          background-color: #dc3a1a;
          color: #ffffff;
          font-size: 15px;
          text-align: center;
          text-decoration: none;
          border-radius: 5px;
          transform: perspective(1px) translateZ(0);
          box-shadow: 0 0 1px rgb(0 0 0 / 0%);
          transition-duration: 0.3s;
          transition-property: box-shadow, transform;
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .wishlist__page__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .wishlist__page__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 1200px) {
  .checkout__right__block {
    padding: 0px !important;
    margin: 10px 0px;
  }
}
@media screen and (max-width: 992px) {
  .wishlist__page__block {
    width: 100% !important;
    max-width:100% !important;
  }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .wishlist__page__container {
    .wishlist__page__block {
      .wishlist__page__product__block {
        .wishlist__product__block{
          &:nth-child(odd){
            padding-left: 12px;
            // padding-right: 0px;
          }
        }
      }
    }
  }

}

/*-------------------------- Arabic view End ------------------------*/