.checkout__header__section{
    background-color: #042426;
    padding: 2px 10px 0px;

    .checkout__header__container{
        margin: 0px auto;
        width: 1420px;
        padding: 16px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 1500px) {
          max-width: 93% !important;
        }
        @media screen and (max-width: 1450px) {
          max-width: 90% !important;
        }
        @media screen and (max-width: 992px) {
          max-width: 100% !important;
        }

        .go_back_btn{
            background-color: transparent;
            border: none;
            font-size: 16px;
            color: #fff;
            font-family: "SSTbold";
            display: flex;
            align-items: center;
            padding: 0px;
            margin-left: -5px;
            @media screen and (max-width:580px) { font-size: 14px; }
            @media screen and (max-width:320px) { font-size: 13px; }

            &:hover,&:focus,&:active{
                box-shadow: unset;
                outline: unset;
            }
            svg{
                width: 25px;
                height: 25px;
                @media screen and (max-width:580px) {
                    width: 21px;
                    height: 21px;
                }
                @media screen and (max-width:320px) {
                    width: 19px;
                    height: 19px;
                }
            }
        }
        .logo__box{
            .checkout__header__logo{
                background-color: transparent;
                border: none;
                padding: 0px;

                &:hover,&:focus,&:active{
                    box-shadow: unset;
                    outline: unset;
                }
                img.header2_logo {
                    max-width: 170px;
                    height: auto;
                    @media screen and (max-width:580px) {
                        max-width: 120px;
                    }
                    @media screen and (max-width:320px) {
                        max-width: 100px;
                    }
                    @media screen and (max-width:280px) {
                        max-width: 85px;
                    }
                }
            }
        }
        .secure__checkout__label{
            font-size: 16px;
            color: #ffffff;
            margin-bottom: 0px;

            @media screen and (max-width:1200px) {
                font-size: 15px;
            }
            @media screen and (max-width:992px) {
                font-size: 14px;
            }
            @media screen and (max-width:580px) {
                font-size: 13px;
            }
            @media screen and (max-width:320px) {
                font-size: 12px;
            }
        }

    }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

    .checkout__header__section {
        .checkout__header__container {
            .go_back_btn{
                margin-left: 0px;
                margin-right: -5px;
                svg {
                    transform: rotateY(180deg);
                    -webkit-transform: rotateY(180deg);
                    -moz-transform: rotateY(180deg);
                }
            }
        }
    }

}

/*-------------------------- Arabic view End ------------------------*/