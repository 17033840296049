.textFonts {
  font-family: 'SSTregular';
}

.subscribeSectionContent {
  display: flex !important;
  align-items: center !important;
  padding: 10px;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
  margin: unset !important;
  min-height: 75px;

  .text {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 700;

    html[dir='rtl'] & {
      gap: 3px;
    }

    h2 {
      font-size: 14px;
      font-weight: 700;
      color: #000000;

      html[dir='rtl'] & {
        font-size: 16px;
        line-height: 14px;
      }
    }

    h3 {
      @extend .textFonts;
      font-size: 10px;
      font-weight: 700;
      color: #000000;

      html[dir='rtl'] & {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }

  .tick {
    background-image: url('./../../../assets/Icon/check-icn@2x 2.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin: 0 23px;
  }

  .price {
    @extend .textFonts;
    font-weight: bold !important;
    font-size: 15px;
    color: #40a629;

    html[dir='rtl'] & {
      line-height: 14px;
    }

    @media screen and (max-width: 992px) {
      font-size: 12px;
    }
  }

  .priceSection {
    display: flex;
    width: 100%;
    align-items: center;
  }
}

.subscribeSectionContentPDP {
  @extend .subscribeSectionContent;
  flex-wrap: nowrap;

  .textSection {
    min-width: 110px;
  }

  .text {
    text-align: unset;

    html[dir='rtl'] & {
      gap: 7px;
    }

    h2 {
      font-size: 18px;

      html[dir='rtl'] & {
        font-size: 18px;
        line-height: 18px;
      }
    }

    h3 {
      font-size: 14px;

      html[dir='rtl'] & {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .tick {
    background-image: url('./../../../assets/Icon/check-icn@2x 2.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 34px;
    height: 24px;
  }

  .price {
    font-size: 20px;
  }

  .priceSection {
    justify-content: flex-end;
  }

  .highlight {
    font-size: 16px;

    html[dir='rtl'] & {
      line-height: 16px;
    }

    @media screen and (max-width: 992px) {
      font-size: 10px;
    }
  }
}

.highlight {
  @extend .textFonts;
  max-width: 90%;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  font-size: 10px;
  padding: 6px 8px;
  cursor: pointer;
  border-radius: 5px;
  z-index: 5;
  background-color: #40a629;

  html[dir='rtl'] & {
    font-size: 12px;
    line-height: 10px;
  }
}

@media screen and (max-width: '576px') {
  .subscribeSectionContentPDP {
    .tick {
      margin: 0 -5px;
    }
  }
}
