.productEight__block {
  width: 100%;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 5px;
  &:hover{ box-shadow: 0px 0px 9px #d9d9d9;  }

  .productEight__header__block {
    background-color: #f1f4f9;
    padding: 14px;
    margin: 0px 0px 20px;
    width: 100%;
    .product__pics__area {
      position: relative;

    .productNine__image__slider{
      width: 85%;
      margin: 0px auto;
      min-height: 160px;
      max-height: 160px;

      @media screen and (max-width:580px) {
        width: 100%;
        min-height: 100px;
        max-height: 100px;
      }
      .slick-prev:before, .slick-next:before{
        color: #c7c7c7;
      }
      .slick-next {
        background-repeat: no-repeat;
        width: 8px;
        height: 20px;
        background-size: contain !important;
        background: url(./../../assets/Icon/grey_right_arrow.svg);
        background-repeat: no-repeat;
        z-index: 2;
        &:before{
          display: none;
        }
        @media screen and (max-width:580px) {
          height: 12px;
          right: -8px;
        }
      }
      .slick-prev {
          background-repeat: no-repeat;
          width: 8px;
          height: 20px;
          background-size: contain !important;
          background: url(./../../assets/Icon/grey_right_arrow.svg);
          background-repeat: no-repeat;
          transform: rotate(175deg) translate(0, 0%);
          top:41%;
          z-index: 2;
          &:before{
            display: none;
          }
          @media screen and (max-width:580px) {
            top: 47%;
            height: 12px;
            left: -8px;
          }
      }
      .slick-slider{
  
        // .slick-arrow{ display: none !important; }
        .slick-list {          
          .slick-track{
            .slick-slide{
  
              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                max-height: 160px;
                margin: 0px auto;
                @media screen and (max-width:580px) {
                  max-height: 100px;
                }
              }
            }
          }       
        }
        .slick-dots{ 
          bottom: 6px;
          background-color: #f7f7f75e;
          display: flex !important;
          max-width: max-content;
          padding: 2px 5px;
          height: 17px;
          align-items: center;
          border-radius: 7px;
          margin: 0px auto;
          left: 50%;
          transform: translateX(-50%);
  
          li{
            width: 10px;
            height: 10px;
            margin: 0px 2px;
  
            button{
              padding: 0px;
              height: 10px;
              width: 10px;
              &:before{
                line-height: 10px;
                width: 10px;
                height: 10px;
              }
            }
          }
        }
      }
    }
    .soldOutLable {
      position: absolute;
      width: 100%;
      background: hsla(0,0%,85.1%,.76);
      height: 30px;
      top: 40%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 21px;
      color: rgba(0,0,0,.72);
      text-transform: uppercase;
      @media screen and (max-width:480px){
        top: 40%;
      }
    }
    .productEight__quality__favourite__block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .productEight__quality__icon {
        width: 28px;
        margin: 6px;  
        @media (max-width:992px) {
          // display: none;
        }
      }
      .productEight__favourite__icon {
        cursor: pointer;
        margin: 6px;
        display: block;
        width: 18px;
        @media (max-width:992px) {
          width: 14px;
        }
      }
      .productEight__favourite__icon__disable {
        margin: 10px 0px;
        display: none;
      }
      .productEight_delete_icon{
        width: 12px;
        margin: 0px 0px 3px;
        display: none;  
        @media (max-width:992px) { display: block; }
      }
    }
    .productEight__image__block {
      height: 252px;
      width: auto;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .productEight__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
      }
    }
    .productEight__compare__block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .productEight__compare__input__check {
        accent-color: #000000;
        height: 20px;
        width: 20px;
        border-radius: 10px;
        margin: 0px 16px 0px 0px;
        // border: 20px solid #303030;
        text-transform: uppercase;
      }
      .productEight__compare__text {
        font-size: 16px;
        font-family: "SSTregular";
        margin: 0px;
      }
    }
  }
}

  .productEight__name__link {
    
    text-decoration: none;
    color: #000000;
    .productEight__name{
      font-family: 'SSTbold';
      font-weight: bold;
      font-size: 14px;
      line-height: 1.3;
      margin: 0px 0px 6px 0px;
      min-height: 55px;

      @media screen and (max-width:992px) {
        min-height: 72px;    
      }
    }
  }  
  .productEight__series {
    font-family: "SSTregular";
    font-size: 14px;
    margin: 0px 0px 4px 0px;
    line-height: 1.2;
    color: #808080;
    letter-spacing: 0.5px;
  }

  .rating__block {
    padding: 0px;
    margin: 0px 0px 0px 0px;
    min-height: 28px;
    .bGVcoD {
      padding: 0px;
    }
  }
  .size__button__block {    
    margin: 0px 0px 5px 0px;
    min-height: 54px;
    
    .size__button__listing {
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      padding: 10px 0px;
      display: flex;
      align-items: center;
      flex: 0 0 100%;
      max-width: 100%;
      align-items: center;
      button{
        position: relative;
        &:before{
          content: "";
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #000000;
          border-radius: 3px;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: 50%;
          transform-origin: 50%;
          -webkit-transition-property: transform;
          transition-property: transform;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
        }
      }
  
      .size__button__active {
        font-size: 12px;
        font-family: 'SSTmedium';
        border-radius: 3px;
        color: #ffffff;
        padding: 6px 8px;
        // line-height: 1.26;
        // background-color: #000000;
        border: 1px solid #000000;
        outline: none;
        border: none;
        margin: 0px 8px 0px 0px;
        z-index: 2;
        position: relative;
        &:before{
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          z-index: -1;
        }
        &:last-child {
          margin-right: 0px;
        }
        @media screen and (max-width:480px) {
          padding: 4px 5px;
        }
      }
      .size__button {
        font-size: 12px;
        border: 1px solid #707070;
        border-radius: 3px;
        padding: 6px 8px;
        background-color: #ffffff;
        outline: none;
        margin: 0px 8px 0px 0px;
        &:last-child {
          margin-right: 0px;
        }

        @media screen and (max-width:480px) {
          padding: 4px 5px;
        }
      }
    }    
  }
  .addToCart__button {
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    font-family: 'SSTbold';
    font-weight: bold;
    letter-spacing: 0.85px;
    padding: 8px 10px;
    background-color: #dc3a1a;
    // box-shadow: 0px 1px 3px 0px #928d8dde;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    display:flex;
    align-items: center;
    justify-content: center;      
    position: relative;

    @media screen and (max-width:580px) {
      font-size: 13px;
      padding: 6px 8px;
    }
       
    .addToCart__icon {
      margin: 0px 10px 0px 0px;
      width: 16px;

      @media screen and (max-width:580px) {
        margin: 0px 5px 0px 0px;
        width: 15px;
      }
      @media screen and (max-width:380px) {
        margin: 0px 5px 0px 0px;
        width: 15px;
      }
      @media screen and (max-width:321px) {
        width: 11px;
      }
    }
    .spinner-border-sm {
      
      @media screen and (max-width:580px) {
        margin-left: 5px !important;
        width: 0.8rem;
        height: 0.8rem;
      }
      @media screen and (max-width:321px) {
        position: absolute;
        right: 5px;
        margin-left: 3px !important;
        width: 0.5rem;
        height: 0.5rem;
      }
      @media screen and (max-width:281px) {
        right: 4px;
      }
    }
    &:hover,&:focus {
      -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
      box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      opacity: 0.8;
    }
    .btn__spinner{
      min-height: 21px;
      @media screen and (max-width:1366px) { }
      @media screen and (max-width:580px) { }
    }
  }
  // .notifyMe__button{
  //   border-radius: 5px;
  //   color: #ffffff;
  //   font-size: 14px;
  //   font-family: 'SSTbold';
  //   font-weight: bold;
  //   letter-spacing: 0.85px;
  //   padding: 8px 10px;
  //   background-color: #ff8800;
  //   // box-shadow: 0px 1px 3px 0px #928d8dde;
  //   text-align: center;
  //   text-transform: uppercase;
  //   text-decoration: none;
  //   cursor: pointer;
  //   display:flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  .outofStock__button{
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    font-family: 'SSTbold';
    font-weight: bold;
    letter-spacing: 0.85px;
    padding: 8px 15px;
    background-color: #a7a7a7;    
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    
    @media screen and (max-width:580px) {
      font-size: 13px;
      padding: 6px 10px;
    }    
  }
  .notifyMe__button{
    border-radius: 5px;
    color: #ffffff;
    font-size: 14px;
    font-family: 'SSTbold'; 
    text-transform: uppercase;
    letter-spacing: 0.85px;
    padding: 8px 15px;
    line-height: 1.5;                
    background-color: #FE8B21;    
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;

    @media screen and (max-width:580px) {
      font-size: 13px;
      padding: 6px 10px;
    } 
    &:hover,&:focus {
      -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
      box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      opacity: 0.8;
    }
    img {
      margin: 0px 8px 0px 0px;
      height: 14px;
      width: auto;
      vertical-align: sub;     
      
      @media screen and (max-width:480px) {
        margin: 0px 6px 0px 0px;
        height: 13px;
      }
    }
  }

  .productEight__size__button__block {
    padding: 0px;
    display: flex;
    align-items: center;
    margin: 0px 0px 10px 0px;

    button{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000000;
        border-radius: 3px;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
    }
    .productEight__size__button__active {
      font-size: 12px;
      border-radius: 3px;
      color: #ffffff;
      padding: 10px 8px;      
      border: 1px solid #000000;
      // outline: none;
      // border: none;
      // margin: 0px 10px 0px 0px;
      z-index: 2;
      position: relative;
      margin: 2px 0px;
        &:before{
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          z-index: -1;
        }
    }
    .productEight__size__button {
      font-size: 12px;
      border: 1px solid #707070;
      border-radius: 3px;
      padding: 10px 8px;
      background-color: #ffffff;
      outline: none;
      margin: 0px 10px 0px 0px;    
    }
  }
  .prize__block {
    margin-bottom: 8px;
    min-height: 47px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    span {        
      // &:first-child { margin-right: 8px !important; }
      // &:last-child { margin-left: 0px !important; margin: 0px !important; }
      &.text3{
        margin-bottom: 0px !important;
        position: relative;
        width: fit-content;
        line-height: 1.2;
        &::after{
          content: " ";
          display: block;
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: #666666;
          -webkit-transform: translateY(-8px) rotate(-6deg);
          transform: translateY(-8px) rotate(-6deg);            
          bottom: 0;
          left: 0;
        }
      }
    }
    .old__prize {
      font-family: "SSTregular";
      font-size: 18px;
      text-decoration-line: line-through;
      color: #c8c8c8;
    }
    .new__prize {
     font-family: 'SSTbold';
font-weight: bold;
      font-size: 18px;

      margin: 0px 0px 0px 5px;
    }
  }
//   .addToCart__button {
//     border-radius: 5px;
//     color: #ffffff;
//     font-size: 16px;
//    font-family: 'SSTbold';
// font-weight: bold;
//     letter-spacing: 0.85px;
//     padding: 20px 0px;
//     background-color: #dc3a1a;
//     text-align: center;
//     text-transform: uppercase;
//     cursor: pointer;
//     .addToCart__icon {
//       margin: 0px 20px 0px 0px;
//     }
//   }
}

/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {

  .productEight__block {
    .prize__block {
      .heading6 {
        margin: 0px 0px 8px 0px !important;
      }
    }
    .productEight__header__block {
      .productNine__image__slider {
        .slick-prev {
          left: -20px;
          right: auto;
        } 
        .slick-next{
          left: auto;
          right: -20px;
        }
      }
    }
    .productEight__name__link {
      .productEight__name{        
        font-family: 'SSTArabicBold';
        font-size: 13px;
      }      
    }
    .addToCart__button,.outofStock__button,
    .notifyMe__button,.pre-order {
      font-family: 'SSTArabicBold';
      font-weight: bold;
      font-size: 14px;
    }
    .addToCart__button{
      .addToCart__icon{
        margin: 0px 0px 0px 10px;
      }
    }
    .notifyMe__button{
      
      @media screen and (max-width:381px) {
        font-size: 11px;
      }
      img{
        margin: 0px 0px 0px 8px;        
        @media screen and (max-width:480px){
          margin: 0px 0px 0px 6px;
        }
      }                  
    }
    .size__button__block {
      .size__button__listing {
        .size__button{
          margin: 0px 0px 0px 8px;
          &:last-child{
            margin: 0px;
          }
        }
      }
    }
  }
    

}

/*-------------------------- Arabic view End ------------------------*/
