.product__comparison__list__container {
  .product__comparison__list {
    padding: 0 30px;
    @media(max-width:992px) { padding: 0px 15px; }

    .breadcrumbs__container {
      padding: 0px;
      margin-bottom: 15px;      
      .breadcrumbs__block {
        width: 100% !important;
        .back__button__block { display: none; }
        // .home__image{ margin-left: 0px; }
      }
    }
    .shopping__compare__emptyBox{
      margin: 20px;
      padding: 2.5rem 1.2rem;
      background-color: #f9f9f9;
      text-align: center;
      img { max-width: 80%; }
      .text3{
        font-size: 16px;
        font-family: 'SSTbold';
        color: #000000;
        margin: 12px 0px !important;
      }
      .continue_shopping_btn{
        padding: 9px 12px;
        display: inline-block;
        background-color: #dc3a1a;
        color: #ffffff;
        font-size: 15px;
        text-align: center;
        text-decoration: none;
        border-radius: 5px;
        -webkit-transform: perspective(1px) translateZ(0);
        transform: perspective(1px) translateZ(0);
        box-shadow: 0 0 1px rgb(0 0 0 / 0%);
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-property: box-shadow, transform;
        transition-property: box-shadow, transform;

        &:hover{
          box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
          -webkit-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }
    
  }
  .product__comparison__list__title__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f2f2f2;
    padding: 20px 30px 20px;
    
    @media(max-width:992px) { padding: 20px 15px 20px !important; background-color:#f2f2f2;  }
    @media(max-width:480px) {
      padding: 20px 0px !important;
    }
    .heading3{
      @media(max-width:992px) { font-size: 20px; }
      @media(max-width:480px) { font-size: 17px; }
      background: #f2f2f2;
    }
    .remove__comparison__block {
      display: flex;
      background: #000;
      align-items: center;
      text-align: center;
      padding: 10px 15px;      
      border-radius: 4px;
      cursor: pointer;
      @media(max-width:992px) {
        width: auto;
        min-height: auto;
      }
      @media(max-width:480px) {
        padding: 8px 10px;
      }
      .remove__comparison {
        background: #000;
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 0.05px;
        color: #ffffff;
        text-transform: capitalize;
        margin-bottom: 0px;
        margin-right: 6px;

        @media(max-width:992px) {
          font-size: 16px;
          margin: 0px;
          line-height: 1.1;
        }
        @media(max-width:480px) {
          font-size: 15px;
        }
      }
      .remove__comparison__icon {
        width: 14px;
        height: 17px;
        @media(max-width:992px) { display: none; }
        
        img { vertical-align: top; }
      }
    }
  }
  .product__highlight__container {    
    @media(max-width:992px) {
      display: none;
      flex-wrap: wrap;
      flex-direction: row;
      padding: 20px 0px;
      min-height: auto;
    
    .product__highlight__block {
      width: 50%;
      padding: 0px 8px;
      .product__highlight__image__block{
        height: 110px;
        width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        .product__highlight__image{
          width: 70px;
          height: auto;
        }
      }

    }    
  }
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {
  .product__comparison__list__container {
    .product__comparison__list__title__block {
      .remove__comparison__block {
        .remove__comparison{
          margin-right: 0px;
          margin-left: 6px;
        }
      }
    }
  }

}

/*-------------------------- Arabic view end ------------------------*/