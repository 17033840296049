@import "src/SCSS/_variables.scss";

// .cookieDisclaimer {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     z-index: 99999;
//     position: fixed;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 100px;
//     background-color: rgba(0, 0, 0, 0.9);
    
//     .inner {
//       display: flex;
//       margin: 0 auto;
//       align-items: center;
//       width: 100%;
//       max-width: 785px;
//       font-size: 12px;
//       font-weight: 400;
//       color: $textSecondary;
  
//       .text {
//         flex: 1;
  
//         a {
//           color: $textSecondary;
//           text-decoration: underline;
          
//           &:hover {
//             color: $textLink;
//           }
//         }
//       }
  
//       button {
//         margin: 0 5px;
//       }
//     }
//   }

  .cookieDisclaimer {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFF;
    border-top: 1px solid rgba(151, 151, 151, 0.25);
    
    .inner {
      display: flex;
      margin: 15px auto;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 785px;
      font-size: 10px;
      font-weight: 300;
  
      @media screen and (max-width:992px) {
        margin: 7px auto;
      }
      .text {
        flex: 1;
        padding: 0 15px;
        text-align: center;
  
        a {
          color: $textLink;
          text-decoration: underline;
        }
      }
  
      button {
        background: black;
        border: 1px solid black;
        margin-top: 15px;
        padding: 8px 15px;
        font-size: 15px;
        line-height: 1.3;

        @media screen and (max-width:992px) {
          margin-top: 7px;
          padding: 4px 9px;
          font-size: 12px;
          line-height: 1.1;
        }

      }
    }
  }

  @media (min-width: $mobileBreakpoint) {
  
    .cookieDisclaimer {
      .inner {
        margin: 15px auto;
        flex-direction: row;
  
        .text {
          text-align: start;
        }
  
        button {
          margin-top: 0;
        }
      }
    }
  }