.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  justify-content: space-between;

  .tabby {
    display: flex;
    flex-direction: column;
    padding: 10px;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;

    span {
      font-size: 13px;
      text-align: center;
      color: #1b2630;
      font-weight: 500;
    }
  }

  .dot {
    height: 10px;
    width: 10px;
    background-color: #1b2630;
    border-radius: 50%;
    display: inline-block;
  }

  .dottedBorder {
    border: 1px solid #fff;
    background-color: #27272a;
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }

  .dotEmptyLine {
    height: 6px;
    width: 6px;
    margin: 1px 2px;
    background-color: #1b2630;
    border-radius: 50%;
    display: inline-block;

    &.future {
      background-color: #d8d8d8;
    }
  }

  .yellowText {
    color: #1b2630;
    font-weight: 700;
  }

  .breakdown {
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      font-size: 15px;
    }

    .installment {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      .scheduledDay {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
      }

      .currency {
        font-size: 15px;
      }

      .currencyToday {
        color: #1b2630;
        font-weight: 700;
      }
    }
  }
}
