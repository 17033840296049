.countdown__area{
    padding: 8px 15px;
    background-color: #dc3a1a;
    border-radius: 8px;
    margin: 8px 0px;
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width:1200px) {  padding: 8px 10px;  }
    @media screen and (max-width:992px) { margin-bottom: 0px; margin-top: 12px; }
    @media screen and (max-width:300px) { flex-direction: column; justify-content: center; }

    .discount__label__box{
        display: flex;
        flex-direction: column;
        color: #ffffff;
        h6{
            margin: 0px;
            @media screen and (max-width:1200px) { font-size: 16px; }
            @media screen and (max-width:300px) { line-height: 1.2; }
        }
        p{
            margin: 0px;
            font-size: 14px;
            @media screen and (max-width:300px) { line-height: 1; margin-bottom: 4px; }
        }
    }
    .countdown__time__box{
        display: flex;
        flex-direction: row;        
        .countdown__itembox{
            display: flex;
            flex-direction: column;
            padding: 0px 5px;
            align-items: center;
            color: #ffffff;

            &:last-child{
                padding-right: 0px;
            }
            
            h6{
                margin: 0px;
                font-size: 17px;
                @media screen and (max-width:1200px) { font-size: 16px; }
            }
            p{
                margin: 0px;
                font-size: 13px;
                font-weight: 500;
                opacity: 0.8;
                letter-spacing: 0.6px;
            }
        }
    }
}