.recentlyViewed__container {
    display: flex;
    flex: 0 0 100%;
  .recentlyViewed__block {
    width: 1420px;
    padding: 0px;
    margin: 60px auto;

    .section__title {
      font-family: "SSTbold";
      font-weight: bold;
      font-size: 24px;
      line-height: 1.2;
    }
    .main__rv__block {
      .pd__productOne__block {
        width: 215px !important;
        margin: 10px 15px 10px 0px;
        @media screen and (max-width: 1500px) {
          width: 211px !important;
          margin: 10px 10px 10px 0px;
        }
        @media screen and (max-width: 1450px) {
          width: 23.7% !important;
          margin: 10px 12px 10px 0px;
        }
        @media screen and (max-width: 1380px) {
          width: 23.85% !important;
          margin: 10px 8px 10px 0px;
        }
        @media screen and (max-width: 1120px) {
          margin: 10px 5px 10px 0px;
          padding: 8px;
        }
        &:last-child {
          margin-right: 0px;
        }

        .Probox__discount__labelBox{ display: none; }
        
        .productOne__image__block {
          max-width: 100%;
          img {
            width: 100%;
            max-width: 100%;
            height: auto;
          }
        }
        .product__delivery__option__area {
          @media screen and (max-width:1450px) {
            flex-direction: column;
            min-height: 55px;
          }
          .product__delivery__itembox {
            @media screen and (max-width:1450px) {
              &:first-child {
                margin-bottom: 5px;
              }
            }
            @media screen and (max-width:1380px) {
              margin: 0px 5px 0px 0px;            
            }
            @media screen and (max-width:1280px) {
              padding: 5px 4px;
            }
            &:last-child{
              margin-right: 0px;
            }
            img{
              @media screen and (max-width:1380px) {
                margin-right: 4px;  
              }
              @media screen and (max-width:1280px) {
                margin-right: 4px; 
                height: 9px; 
              }              
            }
            span{
              @media screen and (max-width:1380px) {
                font-size: 9px;
              }
              @media screen and (max-width:1280px) {
                font-size: 8px;
              }
            }
          }
        }
        .addToCart__button {
          @media screen and (max-width: 1320px) {
            font-size: 13px;            
          }
          @media screen and (max-width: 1135px) {
            font-size: 12px;
            padding: 6px 8px;
          }
          @media screen and (max-width: 1000px) {
            font-size: 11px;
          }
          img {
            width: 16px;
            margin-right: 5px;
            @media screen and (max-width: 1120px) {
              width: 14px;
            }
            @media screen and (max-width: 1000px) {
              width: 10px;
            }
          }
          .spinner-border{
            margin-left: 10px !important;
            @media screen and (max-width:1420px) {
              margin-left: 8px !important;
              width: 0.8rem;
              height: 0.8rem;
            }  
            @media screen and (max-width:1280px) {
              margin-left: 6px !important;
              width: 0.7rem;
              height: 0.7rem;
            }  
          }
          .btn__spinner {
            @media screen and (max-width: 1320px) {
              min-height: 19.5px;
            }
            @media screen and (max-width: 1135px) {
              min-height: 18px;
            }
          }
        }
        .outofStock__button{
          @media screen and (max-width:1366px) {
              font-size: 14px;
          }
          @media screen and (max-width: 1320px) {
            font-size: 13px;            
          }
          @media screen and (max-width: 1120px) {
            font-size: 12px;
            padding: 6px 8px;
          }
          @media screen and (max-width:580px) {
            padding: 8px 10px;
            font-size: 13px;      
          }
        }
      }
      .inner__rv__block {
        // width: 100%;
        display: flex;
        justify-content: space-between;
        .rv__left__block {
          width: 65.67%;
          background-color: #ffffff;
          border-radius: 10px;
          padding: 15px;
          .rv__desktop__screen {
            padding: 15px 0px;

            .productOne__block {
              width: 24%;
              margin-left: 6px;
              margin-right: 0px;
              margin-bottom: 6px;
              padding: 12px;

              &:hover {
                box-shadow: 0px 0px 9px #d9d9d9;
              }
              .productOne__image__block {
                max-width: 100%;
                height: 150px;
                min-height: 150px;
                text-align: center;
                img {
                  width: auto;
                  height: 100%;
                  object-fit: contain;
                  object-position: 50% 50%;
                  &:hover {
                    transform: scale(1.1);
                    -webkit-transform: scale(1.1);
                    transition: all 0.3s ease-in-out;
                    -webkit-transition: all 0.3s ease-in-out;
                  }
                }
              }
              .prize__block {
                @media screen and (max-width: 1320px) {
                  display: flex;
                  flex-direction: column;
                }
              }
            }
            .rv__product__block {
              display: flex;
              flex-direction: column;
              align-items: center;
              .rv__product__image__block {
                height: 180px;
                width: 100%;
                margin: 0px 0px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                .rv__product__image {
                  // height: auto;
                  width: 100%;
                  // object-fit: cover;
                  //   margin: 0px 0px 10px 0px;
                  &:hover {
                    transform: scale(1.1);
                    -webkit-transform: scale(1.1);
                    transition: all 0.3s ease-in-out;
                    -webkit-transition: all 0.3s ease-in-out;
                  }
                }
              }
            }
          }
          // .rv__mobile__screen {
          //   display: none;
          // }
        }
        .rv__right__block {
          background-color: #ffffff;
          width: 33%;
          border-radius: 10px;

          .right__part__title {
            width: 100%;
          }
          .rv__right__part__bg__image {
            border-radius: 10px;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .recentlyViewed__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .recentlyViewed__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 1200px) {
  .rv__desktop__screen {

  }
  .rv__mobile__screen {
    
    // .carouselTypeTwo__container {
    //   margin: 10px 0px 15px;
    // }
    .productFive__block {
      p.heading6 {
        font-size: 16px;
      }
    }
  }
  .rv__left__block .arrow__block .left__arrow,
  .rv__left__block .arrow__block .right__arrow {
    transform: translateX(0) !important;
    padding: 6px 11px;
    img {
      width: 15px;
    }
  }
}
@media screen and (max-width: 992px) {

  .rv__desktop__screen {
    display: none;
  }
  .rv__mobile__screen {
    display: block;
  }
  .recentlyViewed__block {
    width: 100% !important;
    max-width: 100% !important;
    margin-top: 0 !important;
  }
  .recentlyViewed__container {
    background: #fff;
  }
  .recentlyViewed__container .rv__mobile__screen .section__title:empty {
    display: none;
  }
  .recentlyViewed__container .rv__mobile__screen .carouselTypeTwo__block{
    padding: 0px 0px;
  }
  .recentlyViewed__container .recentlyViewed__block .section__title {
    font-size: 22px !important;
    padding: 10px 0;
    margin: 0;
  }
  .rv__left__block .arrow__block .left__arrow,
  .rv__left__block .arrow__block .right__arrow {
    padding: 6px 9px;
    img {
      width: 11px;
    }
  }
  .rv__left__block {
    padding: 0px 0px !important;
    width: 100% !important;
  }
  .rv__right__block {
    display: none;
  }
  
}
@media screen and (max-width: 768px) {
  .inner__rv__block {
    flex-direction: column !important;
  }
  // .rv__left__block {
  //   width: 100% !important;
  //   margin: 0px 0px 20px !important;
  // }
  .rv__right__block {
    width: 100% !important;
  }
  .recentlyViewed__container .recentlyViewed__block .section__title {
    font-size: 22px !important;
  }
  .rv__left__block .arrow__block .left__arrow,
  .rv__left__block .arrow__block .right__arrow {
    img {
      width: 10px;
    }
  }
}
@media screen and (max-width: 576px) {
  .recentlyViewed__container .recentlyViewed__block .section__title {
    font-size: 20px !important;
  }

  .rv__mobile__screen .productFive__block p.heading6 {
    font-size: 14px;
  }
  .rv__left__block .arrow__block .left__arrow,
  .rv__left__block .arrow__block .right__arrow {
    padding: 6px 9px;
    display: inline-flex;
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {
  .recentlyViewed__container {
    .recentlyViewed__block {
      .main__rv__block {
        .inner__rv__block {
          .rv__left__block{
            .rv__desktop__screen{
              .pd__productOne__block{
                margin: 10px 0px 10px 8px;
                &:last-child{
                  margin-left: 0px;
                }

                .product__delivery__option__area {
                  @media screen and (max-width:1450px) {
                    flex-direction: column;
                    min-height: 55px;
                  }
                  .product__delivery__itembox {
                    @media screen and (max-width:1450px) {
                      &:first-child{
                        margin-bottom: 5px;
                      }
                    }
                    @media screen and (max-width:1380px) {
                      margin: 0px 0px 0px 5px;            
                    }
                    &:last-child{
                      margin-right: 5px;
                      margin-left: 0px;
                    }
                  }
                }
                .addToCart__button,.outofStock__button,.notifyMe__button {
                  flex-direction: unset;
                  font-family: 'SSTArabicBold';
                  font-weight: bold;
                }
                .addToCart__button {

                  img {
                    @media screen and (max-width: 1160px) {
                      margin: 0px 0px 0px 5px;
                    }
                    @media screen and (max-width: 1010px) {
                      margin: 0px 0px 0px 4px;
                    }
                  }
                  .btn__spinner {
                    min-height: 21px;
                    @media screen and (max-width: 1320px) {
                      min-height: 19.5px;
                    }                    
                    @media screen and (max-width: 1135px) {
                      min-height: 18px;
                    }
                  }
                }
              }
            }            
          }
        }
        
      }
    }
  }
}


.recentlyViewed__container
  .recentlyViewed__block
  .main__rv__block
  .pd__productOne__block
  .productOne__image__block
  img {
  height: 100% !important;
}

/*-------------------------- Arabic view end ------------------------*/
