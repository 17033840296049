.popupContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 470px;
  min-width: 325px;

  h1 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
  }

  p {
    font-size: 1rem;
  }

  .readMore {
    cursor: pointer;
    font-size: 1rem;
    text-decoration: underline;
    font-weight: 600;
  }
}

@media screen and (max-width: 1200px) {
  .popupContainer {
    max-width: unset;
  }

}