.payment__status__container{
    padding: 15px 12px;
    background-color: #ffffff;    

    .payment__status__block{
        padding: 2rem;
        width: 80%;
        margin: 0px auto;
        background-color: #ffffff;
        border-radius: 5px;
        text-align: center;
        min-height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .orderid__link{
            text-decoration: none;
            color: #000000;
        }
        .order__success__text {
            margin: 0px !important;
            text-align: center;
        }
        .order__success__email__text {
            margin: 0px 0px 20px;
            text-align: center;
          }
        @media (max-width:992px) {
            width: 90% !important;
        }
        .panyment__success__icon{ 
            width: 100px; 
            margin-bottom: 15px;
            @media (max-width:576px) {
                width: 80px;
            }
        }        
        .heading2{
            @media (max-width:576px) {
                font-size: 22px;
            }
        }
        .continue__shopping__btn {
            padding: 9px 12px;
            background-color: #DC3A1A;
            color: #ffffff;
            font-size: 15px;
            text-align: center;
            font-family: 'SSTmedium';
            display: inline-block;
            border-radius: 5px;
            text-decoration: unset;
        }
    }
}