.carousel__main__container {
  width: 100%;
  // height: 100%;
  padding: 0px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .carousel__dots__main__block {
    margin: 0px 0px 0px 0px;
    // padding: 0px !important;
    z-index: 1;
    width: 100% !important;
    // height: 540px;
    overflow: auto;
    // overflow-y: scroll;
    position: relative;
    display: flex;
    align-items: center;

    .carousel__dots__block {
      margin: 0px;
      z-index: 1;
      width: 100%;
      overflow: auto;
      // overflow-y: scroll;
      height: 89%;
      scroll-behavior: smooth;
      padding: 0px;

      .slick-slider {
        position: static;

        button {
          background: unset !important;
        }

        .slick-next {          
          width: 30px;
          height: auto;
          top: 50%;          
          right: 1%;
          transform: translate(0,-50%);
          -webkit-transform: translate(0,-50%);
          z-index: 2;

          @media screen and (max-width:1366px) {
            width: 25px;
            height: 25px;
          }
          @media screen and (max-width:480px) {
            width: 20px;
            height: 20px;
          }
          &::before {
            content: "›";
            display: block;
            font-family: unset;
            // background-image: url(./../assets/Icon/down_arrow.svg);
            width: 30px;
            height: 30px;
            color: #ffffff;
            background-color: #333333de;
            border-radius: 50%;
            font-size: 26px;
            line-height: 0.95;    
            opacity: 1;
            @media screen and (max-width:1366px) {
              width: 25px;
              height: 25px;
              line-height: 0.75;
            }
            @media screen and (max-width:480px) {
              width: 20px;
              height: 20px;
              font-size: 20px;
            }
          }
        }

        .slick-prev {
          width: 30px;
          height: auto;
          top: 50%;          
          left: 1%;
          transform: translate(0,-50%);
          -webkit-transform: translate(0,-50%);
          z-index: 2;

          @media screen and (max-width:1366px) {
            width: 25px;
            height: 25px;
          }
          @media screen and (max-width:480px) {
            width: 20px;
            height: 20px;
          }
          &::before {
            content: "‹";
            display: block;
            font-family: unset;
            // background-image: url(./../assets/Icon/up_arrow.svg);
            width: 30px;
            height: 30px;
            color: #ffffff;
            background-color: #333333de;
            border-radius: 50%;
            font-size: 26px;
            line-height: 0.95;
            opacity: 1;
            @media screen and (max-width:1366px) {
              width: 25px;
              height: 25px;
              line-height: 0.75;
            }
            @media screen and (max-width:480px) {
              width: 20px;
              height: 20px;
              font-size: 20px;
            }
          }
        }
      }

      .carousel__dot {
        margin: 0px 0px 10px;
        // border: 1px solid #c8c8c8;
        width: 100%;
        height: 76px;
        overflow: hidden;
        @media screen and (max-width:1366px) {
          height: 60px;
        }
        .carousel__thumbnail__image {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .overlay {
          position: sticky;
          /* Sit on top of the page content */
          display: block;
          /* Hidden by default */
          width: 100%;
          /* Full width (cover the whole page) */
          height: 100%;
          /* Full height (cover the whole page) */
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0,
              0,
              0,
              0.5);
          /* Black background with opacity */
          z-index: 15;
          /* Specify a stack order in case you're using a different order for other elements */
          cursor: pointer;
          /* Add a pointer on hover */
        }
      }

      .carousel__dotActive {
        // background-color: rgb(255, 0, 0);
      }
    }

    .carousel__dots__block::-webkit-scrollbar {
      display: none;

      /* transform: translateY(100%); */
    }

    .carousel__dots__arrow__block {
      position: absolute;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .carousel__main__block {
    width: 100%;
    position: relative;
    

    .carousel__block {
      padding: 0px;
      margin: 0 0px 20px;
      min-height: 450px;
      width: 100% !important;
      overflow: hidden;
      position: relative;
      top: 0px;
      left: 0px;
      // border: 1px solid #c8c8c8;

      // background-color: #F0F4F9;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width:1366px) {
        min-height: 350px;   
      }
      @media screen and (max-width:992px) {
        min-height: 500px;
        height: auto;
      }
      @media screen and (max-width:480px) {
        min-height: 350px;   
      }
      @media screen and (max-width:280px) {
        min-height: 240px;   
      }
      .carousel {
        position: absolute;
        padding: 10px;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.7s ease-in-out;
        transform: scale(50%);

        
        .carousel__image {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .carousel__title {
          width: 700px;
          text-align: left;
          line-height: 120px;
          font-family: "Bebas Neue", cursive;
          font-size: 120px;
          color: rgb(255, 255, 255);
          // -webkit-text-stroke-width: 2px;
          // -webkit-text-stroke-color: rgb(255, 255, 255);
          position: absolute;
          top: 270px;
          left: 250px;
          margin: 0px;
        }
      }

      .activeCarousel {
        opacity: 1;
        transform: translateX(0%) scale(100%);
        display: flex;	
        align-items: center;
        @media screen and (max-width:992px) {
          justify-content: center;
        }
      }
      
      .prevCarousel {
        opacity: 0;
        transform: translateX(-100%) scale(50%);
      }

      .nextCarousel {
        opacity: 0;
        transform: translateX(100%) scale(50%);
      }

      .carousel__arrow__block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        padding: 0px 25px;
        top: 50%;
        transform: translateY(-50%);
        // filter:invert(1)
        // bottom: 300px;
        // left: 250px;

        .arrow__icon__block {
          cursor: pointer;
          // width: 5px;
          // height: 5px;
          // margin: 0px 8px;
          // border-radius: 10px;
        }
      }

      .cardLabel {
        display: flex;
        position: absolute;
        top: 0px;
        left: 0px;
        min-width: 22%;
        width: 23%;
        height: auto;        
        z-index: 1;

        img {
          border-radius: 0 !important;
          // max-width: 25% !important;
          // max-height: 25% !important;
        }

        &.left_top {
          justify-content: flex-start;
          align-items: flex-start;
          top: 0px;
          left: 0px;
        }

        &.center_top {
          justify-content: center;
          align-items: flex-start;
          top: 50%;
          transform: translateX(-50%);
          left: 0px;          
        }

        &.right_top {
          justify-content: flex-end;
          align-items: flex-start;
          top: 0px;
          right: 0px;
        }

        &.left_middle {
          justify-content: flex-start;
          align-items: center;
          top: 50%;
          transform: translateY(-50%);
          left: 0px;          
        }

        &.center_middle {
          justify-content: center;
          align-items: center;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          width: 50%;
          img {
            // max-height: 50% !important;
            height: auto;
            max-width: 100% !important;
            width: 100%;
          }
        }

        &.right_middle {
          justify-content: flex-end;
          align-items: center;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);          
        }

        &.left_bottom {
          justify-content: flex-start;
          align-items: flex-end;
          bottom: 0px;
          left: 0px;
        }

        &.center_bottom {
          justify-content: center;
          align-items: flex-end;
          bottom: 0px;
          left: 50%;
          transform: translateX(-50%);          
        }

        &.right_bottom {
          justify-content: flex-end;
          align-items: flex-end;
          bottom: 0px;
          right: 0px;
        }
      }
    }

    
  }
}

// @media screen and (max-width: 1420px) {
//   .carousel__dots__main__block  {
//     height: 100% !important;
//   }
//   .carousel__main__block {
//     height: 100% !important;
//   }
// }
@media screen and (max-width: 1600px) {
  // .carousel__dots__main__block {
  //   height: 500px !important;
  // }

  // .carousel__block {
  //   height: 500px !important;
  // }
}

@media screen and (max-width: 1200px) {
  // .carousel__dots__main__block {
  //   height: 500px !important;
  // }

  // .carousel__block {
  //   height: 500px !important;
  // }
}

@media screen and (max-width: 992px) {
  // .carousel__dots__main__block {
  //   height: 408px !important;
  // }

  // .carousel__block {
  //   height: 408px !important;
  // }

  .carousel__dot {
    height: 85px !important;
    margin-bottom: 0px !important;
  }

  // .carousel__dots__block {
  //   height: 84% !important;
  // }

  .arrow__icon__block {
    // transform: scale(70%);
  }

  .pd__social__media__icon__block {

    margin: 0px 0px 20px !important;
  }
}

@media screen and (max-width: 768px) {
  // .carousel__dots__main__block {
  //   height: 630px !important;
  // }

  // .carousel__block {
  //   height: 630px !important;
  // }

  .carousel__dot {
    height: 84px !important;
  }

  // .carousel__dots__block {
  //   height: 84% !important;
  // }

  .arrow__icon__block {
    // transform: scale(100%) !important;
  }
}

@media screen and (max-width: 576px) {
  // .carousel__dots__main__block {
  //   height: 474px !important;
  // }

  .carousel__block {
    height: 474px !important;
  }

  .carousel__dot {
    height: 70px !important;
  }

  // .carousel__dots__block {
  //   height: 86% !important;
  // }

  .arrow__icon__block {
    // transform: scale(100%) !important;
  }
}

@media screen and (max-width: 480px) {
  // .carousel__dots__main__block {
  //   height: 380px !important;
  // }

  .carousel__block {
    height: 380px !important;
  }

  .carousel__dot {
    height: 60px !important;
    margin-bottom: 0px !important;
  }

  // .carousel__dots__block {
  //   height: 86% !important;
  // }

  .arrow__icon__block {
    // transform: scale(70%) !important;
  }
}

@media screen and (max-width: 414px) {
  // .carousel__dots__main__block {
  //   height: 327px !important;
  // }

  .carousel__block {
    height: 327px !important;
  }

  // .carousel__dots__block {
  //   height: 82% !important;
  // }

  .arrow__icon__block {
    // transform: scale(60%) !important;
  }
}

@media screen and (max-width: 390px) {
  // .carousel__dots__main__block {
  //   height: 306px !important;
  // }

  .carousel__block {
    height: 306px !important;
  }
  
  // .carousel__dots__block {
  //   height: 82% !important;
  // }

  .arrow__icon__block {
    // transform: scale(60%) !important;
  }
}

@media screen and (max-width: 375px) {
  // .carousel__dots__main__block {
  //   height: 293px !important;
  // }

  .carousel__block {
    height: 293px !important;
  }

  // .carousel__dots__block {
  //   height: 82% !important;
  // }

  .arrow__icon__block {
    // transform: scale(60%) !important;
  }
}



/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .carousel__main__container {
    .carousel__main__block {
      
    }

    .carousel__dots__main__block {
      .carousel__dots__block {
        .slick-slider {
          .slick-prev {
            right: auto;
          }
        }
      }
    }
  }

}

/*-------------------------- Arabic view End ------------------------*/