.create__bundle__page__container {
  padding: 0px 12px;
  background-color: #f5f5f5;
  .create__bundle__page__block {
    padding: 10px 0px;
    width: 1420px;
    margin: 0px auto;
    .cb__page__title {
      padding: 0px;
      margin: 0px 0px 0px;
    }
    .cb__page__description {
      padding: 0px;
      margin: 0px 0px 20px 0px;
    }
    .create__bundle__page__inner__block {
      padding: 0px 12px;
      .create__bundle__left__block {
        padding: 0px;
        margin: 0px 0px 50px 0px;
        background-color: #ffffff;
        box-shadow: 0px 3px 6px #0000000a;
        .cb__product__block {
          width: 100%;
          height: fit-content;
          padding: 20px 0px;
          margin: 0px;
          .cb__product__left__block {
            padding: 0px 20px 0px;

            .cb__product__image__block {
              width: 100%;
              height: 250px;
              padding: 10px;
              background-color: transparent;
              border: 1px solid #c8c8c8;

              .cb__product__image {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
          .cb__product__right__block {
            padding: 10px 20px 0px 5px;
            .pd__mb__product__name {
              text-decoration: none;
              color: #000000;
              display: block;
              margin-bottom: 10px;
            }
            .cb__product__company__logo {
              margin: 0px 0px 16px;
              width: 90px;
            }
            .cb__product__productName {
            }
            .productThree__product__company__logo{ width: 90px; }
            .product__protect__block {
              padding: 10px 0px;
              .heading6{ 
                font-size: 16px;
                margin-bottom: 10px !important;
                display: inline-block; 
              }              
              .product__block__title__span{
                font-size: 16px;
                margin-bottom: 10px !important;
                display: inline-block;
              }

            }
            
            .cb__protect__block {
              .cb__title__block {
                font-size: 20px;
                font-family: 'SSTbold';
                font-weight: bold;
                margin: 0px 0px 0px;
                .cb__block__title__span {
                  font-size: 14px;
                  font-family: "SSTmedium";
                  margin: 0px;
                  color: #3b3b3b;
                }
              }
              .cb__rating__block {
                display: flex;
                align-items: center;
                padding: 0px 0px 10px;
                .cb__star__rating__block {
                }
                .cb__product__rating__icon {
                  margin: 6px 10px 0px;
                }
                .cb__product__rating {
                  margin: 6px 4px 0px;
                  font-size: 14px;
                  color: #dc3a1a;
                 font-family: 'SSTbold';
font-weight: bold;
                }
                .cb__product__totalRating {
                  margin: 6px 0px 0px;
                  font-size: 14px;
                  color: #101010;
                  font-family: "SSTregular";
                }
              }
              .cb__protect__selection__block {
                .cb__protection__selection {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin: 20px 0px 0px 0px;
                  .cb__protection__form__block {
                    display: flex;
                    align-items: center;
                    .cb__input__check {
                      accent-color: #000000;
                      height: 18px;
                      width: 18px;
                      border-radius: 10px;
                      margin: 0px 16px 0px 0px;
                      // border: 18px solid #303030;
                      text-transform: uppercase;
                    }
                    .cb__protection__text {
                      margin: 0px;
                      width: 60%;
                      font-size: 16px;
                      color: #3b3b3b;
                      font-family: "SSTregular";
                      line-height: 20px;
                    }
                  }
                  .cb__protection__price__block {
                    width: 30%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    .cb__protection__price {
                      font-size: 18px;
                     font-family: 'SSTbold';
font-weight: bold;
                      text-align: right;
                      margin: 0px;
                    }
                    .cb__protection__monthly__price {
                      font-size: 14px;
                      font-family: "SSTregular";
                      color: #808080;
                      margin: 0px;
                      text-align: right;
                    }
                  }
                }
              }
            }
          }
        }
        .cb__page__block__title {
          margin: 10px 0px 10px;
          padding: 0px 15px 0px;
        }
        .cb__page__horizontal__line {
          border-bottom: 1px solid #acacac;
          margin: 0px;
        }
        .cb__page__category__button__block {
          padding: 15px;

          .cb__page__button__main__block {
            width: 100%;
            position: relative;
            margin: 0px auto;
            display: flex;
            flex-direction: row;

            .arrow__block {
              z-index: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 99%;
              position: absolute;
              top: 50%;
              transform: translateY(-60%);
            }
            .arrow {
              filter: invert(0.4);
              cursor: pointer;              

              @media (max-width:992px) {
                background-color: transparent;
                padding: 5px 10px;
              }
              img { width: 15px; }
            }
            .left__arrow {
              position: relative;
              // top: 50%;
              // transform: translateY(-50%) !important;
              z-index: 3;
              // transform: translateX(-100%);
              order: 1;
              display: flex;
              justify-content: center;
              @media (max-width:1200px) {
                transform: unset;
              }
            }
            .right__arrow {
              position: relative;
              // top: 50%;
              // right: 1%;
              // transform: translateY(-50%) !important;
              z-index: 3;
              order: 3;
              display: flex;
              justify-content: center;
              // width: 15px;
              @media (max-width:1200px) {
                transform: unset;
              }
            }
            .cb__page__button__block {
              z-index: 5;
              display: flex;
              align-items: center;
              overflow-x: hidden;
              scroll-behavior: smooth;
              width: 96%;
              order: 2;
              margin: 0px 12px;
              .cb__page__button__active {
                text-align: center;
                font-size: 14px;
                font-family: 'SSTbold';
                font-weight: bold;
                border-radius: 3px;
                min-width: fit-content;
                color: #ffffff;
                padding: 10px 15px;
                background-color: #000000;
                border: 1px solid #000000;
                margin: 0px 12px 0px 0px;
                outline: none;
                border: none;
                letter-spacing: 0.3px;
                cursor: pointer;
              }
              .cb__page__button {
                cursor: pointer;
                text-align: center;
                min-width: fit-content;
                font-size: 14px;
                font-family: 'SSTbold';
                font-weight: bold;
                border: 1px solid #707070;
                border-radius: 3px;
                padding: 10px 15px;
                color: #808080;
                background-color: #ffffff;
                margin: 0px 12px 0px 0px;
                outline: none;
                letter-spacing: 0.3px;
              }
            }
          }
        }
      }

      .create__bundle__right__block {
        padding: 0px 0px 0px 20px;
        .cb__package__summary__block {
          box-shadow: 0px 3px 6px #0000000a;
          background-color: #ffffff;

          .cb__ps__title {
            font-size: 24px;
            font-family: 'SSTbold';
            font-weight: bold;
            padding: 15px;
            line-height: 1;
            border-bottom: 1px solid rgb(224, 224, 224);
            margin: 0px;
          }
          .cb__ps__detail__block {
            padding: 0px 15px 15px;
            .cb__ps__detail__inner__block {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid rgb(224, 224, 224);
              padding: 17px 0px;

              .cb__ps__detail__title {
                margin: 0px;
                font-size: 16px;
                font-family: "SSTregular";
              }
              .cb__ps__detail__price {
                margin: 0px;
                font-size: 16px;
               font-family: 'SSTbold';
font-weight: bold;
              }
            }
            .cb__ps__detail__total__order__block {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 20px 0px;
              .cb__ps__detail__total__order {
                font-size: 18px;
               font-family: 'SSTbold';
font-weight: bold;
                margin: 0px;
              }
            }
            .cb__addToCart__button__block {
              width: 100%;
              padding: 0px;

              .cb__addToCart__button {
                border-radius: 5px;
                color: #ffffff;
                font-size: 16px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 15px 10px;
                background-color: #DC3A1A;
                text-align: center;
                text-transform: uppercase;
                cursor: pointer;
                .cb__addToCart__icon {
                  margin: 0px 15px 0px 0px;
                  width: 22px;
                  vertical-align: text-bottom;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .create__bundle__page__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .create__bundle__page__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 1200px) {
  .create__bundle__page__block {
    width: 100% !important;
  }
  .create__bundle__right__block {
    padding: 0px !important;
    margin: 10px 0px;
  }
}
@media screen and (max-width: 992px) {
  .create__bundle__page__block {
    width: 100% !important;
    max-width: 100% !important;
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .create__bundle__page__container {
    .create__bundle__page__block {
      .create__bundle__page__inner__block {
        .create__bundle__left__block {
          .cb__page__category__button__block {
            .cb__page__button__main__block{
              .left__arrow{
                order: 3;
              }
              .right__arrow{
                order: 1;
              }
            } 
          }
        }
      }
    }
  }



  }

/*-------------------------- Arabic view End ------------------------*/