@import "variables";

.wrapper {
  padding: 8px 0;
  font-weight: 500;
  font-size: 13px;

  .item {
    display: flex;
    flex-direction: row;

    &.name {
      font-size: 16px;
      line-height: 2.34;
      color: #1B2630;
      font-weight: normal;
    }

    span {
      font-weight: normal;
  
      &:after {
        content: ':';
        padding: 0 5px;
      }
    }
  
    a {
      color: #6212BA;
    }
  }

  .hours {
    display: flex;
    flex-direction: column;
    margin-top: 15px;

    .title {
      font-weight: normal;
      line-height: 1.8;
    }
    
    .items {
      display: flex;
      flex-direction: column;

      .item {
        padding: 10px 0;
        border-top: 1px solid rgba(129, 129, 129, 0.2);

        span {
          display: flex;
          min-width: 100px;

          &::after {
            content: '';
          }
        }

        label {
          line-height: 1.4;
          font-size: 14px;
          white-space: nowrap;
        }
  
        .hourItems {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    padding: 16px 18px;
    font-size: 16px;
    
    .item {
      &.name {
        font-size: 20.8px;
      }
    }
  }
}