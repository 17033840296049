// @media screen and (max-width: 768px) {
//   .first__feature__category__title {
//     margin: 0px  !important;
//   }
//   .first__feature__category__subtitle {
//     margin: 0px 0px 0px 0px !important;
//   }
// }
.homePage {
  background-color: #f5f4f4;
  position: relative;

  .adImage__container {
    background-color: #f5f4f4;
    padding: 60px 12px 60px 12px;
    display: inline-block;

    .adImage {
      width: 1420px;
      max-width: 100%;
      margin: 0px auto;
      padding: 0px;

      @media screen and (max-width:1500px) {
        width: 93% !important;
        max-width: 93% !important;
      }

      @media screen and (max-width:1450px) {
        width: 90% !important;
        max-width: 90% !important;
      }

      .adImage__block {
        width: 100%;
        // height: 300px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .adImage__image {
          width: 100%;
          // height: 100%;
          object-fit: cover;
        }
      }
    }

    &.playStation_bottom_banner {
      padding: 0px 15px;

      .adImage {
        margin: 0px auto 20px;

        .adImage__block {
          height: auto;
        }
      }
    }
  }

  .sony__expert__block {
    position: fixed;
    width: 65px;
    height: 65px;
    bottom: 20px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    padding: 0px;
    z-index: 12;

    .expert__button {
      background: none;
      border: none;
      outline: none;
      padding: 0px;
      border-radius: 50%;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgb(0 0 0 / 0%);
      -webkit-transition-duration: 0.4s;
      transition-duration: 0.4s;
      -webkit-transition-property: box-shadow, transform;
      transition-property: box-shadow, transform;

      &:hover {
        box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }
}

.live__chat__popup__container__disable {
  display: none;
}

.live__chat__popup__container {
  position: fixed;
  top: 0px;
  padding: 0px;
  margin: 0px;
  width: 100%;
  z-index: 11;
  height: 100vh;
  overflow-y: hidden;
  background-color: #02020250;
}

.top__trending__section {
  .pc__block {
    margin-top: 50px;
  }
}

.top__trending__section,
.top__ratedProduct__section {
    display: flex;
    flex: 0 0 100%;
  .pc__block {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 18px !important;

    @media screen and (max-width:580px) {
      padding: 0px 10px !important;
    }

    @media screen and (max-width:480px) {
      padding: 0px 4px !important;
    }

    .section__title {
      font-size: 24px;
      color: #222222;
      line-height: 1;
      margin-bottom: 25px;
    }

    .main__product__container__block {
      padding: 0px !important;

      @media screen and (max-width:580px) {
        padding: 0px 15px !important;
      }
    }
  }
  &.ele_homepage_category_four,
  &.ele_homepage_category_seven,
  &.ele_Cameras {
      .pc__block {
        margin-top: 60px;
      }
    }
  }

.skelaton-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 25px;
  align-items: center;
  overflow: hidden;
  margin: 0 10px;
}

.skeleton-container{
  overflow-x: hidden;
}