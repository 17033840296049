$bgPrimary: #FFF;
$bgSecondary: #37086A;
$bgDark: #1F033D;
$bgDarkGray: #29292D;
$bgBorder: #0A0A0B;
$bgDanger: #E02020;
$bgWarning: #FA6400;
$bgFooter: #151517;
$bgInput: #111113;
$bgNewsletter: #2E0F53;
$bgCartPrimary: #FFF;
$bgCartSecondary: #151618;
$bgCartSuccess: #6DD400;
$bgUserNav: #35353A;

$textPrimary: #4E4E4E;
$textSecondary: #FFF;
$priceText: #1B2630;
$textLink: #006DC0;
$textDanger: #E9555B;
$textSuccess: #7FAF00;
$textWarning: #F7B500;
$textTitle: #F2F2F2;
$textDiscount: #A335EF;
$textFooter: #A6A6A6;
$discountText: #0057AD;
$discountBigText: #34C759;
$highlightText: #20D600;
$textMidGray: #8A8A8A;
$textProductTitle: #250970;
$textWishlist: #D93E3E;
$profItems: #2DBA6E;
$infoBg: #F1E4FF;

$btnPrimary: #6212BA;
$btnBullet: #80868E;
$btnDisabled: #C4C4C4;
$btnColor: rgb(219, 219, 219);
$btnFacebook: #6D7278;
$borderCartPrimary: #D4D7DA;
$borderNewsletter: #602C9E;
$borderDarkShade: #535357;
$linkBg: #A31DFF;
$wBalance: #8CFF45;
$dLinkBg: #A335EF;

$alertSuccess: #308B0E;
$alertError: #F55C4F;

$facebook-color: #415698;

$btn-padding: 0 14px;
$btn-lg-padding: 0 40px;
$btn-xlg-padding: 0 71px;

$transitionStyle: cubic-bezier(0.03, 0.98, 0.52, 0.99);

$mobileBreakpoint: 1200px;

$mobileBreakpointSmall: 320px;
$mobileBreakpointMedium: 375px;
$mobileBreakpointLarge: 425px;
$mobileBreakpointXLarge: 575px;
$mobileBreakpointTablet: 768px;

$headerHeightDesktop: 128px;
$footerHeightDesktop: 363px;

$headerHeightMobile: 92px;
$footerHeightMobile: 648px;

$productCardWidth: 203.75px;
$productCardWidthMobile: 192px;

$fontFamily: Rubik;
$fontFamilyRTL: Cairo;