.newsletter_subscriptions_popup_pg{
    position: relative;
    padding: 25px 15px;
    background-color: #00000075;
    display: flex !important;
    align-items: center;
    min-height: 70vh;
    
    .newsletter_subscriptions_popup_box {
        background-color: #ffffff;
        border: 1px solid #F1F1F1;
        box-shadow: 0px 3px 6px #0000000A;        
        .newsletter_popup_header{
            padding: 12px 18px;
            border-bottom: 1px solid #D1D1D1;
            margin: 0px 5px;
        }
        .newsletter_popup_body{
            padding: 18px 24px;
            .form-group{
                margin: 12px 0px 15px;                
                input {
                    border: 1px solid #c8c8c8;
                    border-radius: 4px;
                    width: 100%;
                    padding: 7px 10px;
                    color: #3B3B3B;
                }
            }
            .newsletter_subscriptions_btn {
                padding: 13px 15px;
                background-color: #DF4E32;
                color: #ffffff;
                text-align: center;
                border: none;
                margin-top: 5px;
                border-radius: 5px;
                box-shadow: 0px 3px 6px #00000005;
                width: 100%;
            }
        }
    }
}

.invalid__message{
    color: red;
    margin: 5px 0px 0px;
}