.main__facets__clear_filter{
  display:flex;
  justify-content:flex-end;  
  margin:1rem;
  cursor:pointer;
  color:#dc3a1a;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  
  &:hover {
    opacity: 0.5;
  }
  @media screen and (max-width:991px) {
        margin-top:0rem;
    }
}
.main__facets__clear_filter.disable_filter{
  cursor: not-allowed;
  user-select: none;
}

.main__facets__block {
    padding: 20px 15px 20px;
    border-bottom: 2px solid #e8e8e9;
    @media screen and (max-width:580px) {
        padding: 8px;
    }
  .main__facets__title {
    font-size: 16px;
    font-family: "SSTbold";
    margin: 0px 0px 15px 0px;
    width: 100%;
  }
  .facet__block {
    display: flex;
    align-items: center;
    margin: 0px 0px 12px 0px;
    flex: 0 0 100%;
    max-width: 100%;
    &:last-child{ margin-bottom: 0px; }
    
  .exp__rd__select__block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .exp__rd__check__icon {
      display: block;
    }

    .exp__rd__check__icon__disable {
      display: none;
    }
  }
    .preferences__select__input__check {
      accent-color: #dc3a1a;
      height: 17px;
      width: 17px;
      border-radius: 0px;
      margin: 0px 12px 0px 0px;
      // border: 30px solid #303030;
      text-transform: uppercase;
      cursor: pointer;
    }
    .facet__item__label {
      font-size: 14px;
      font-family: "SSTmedium";
      margin: 0px;
      // width: 90%;
      color: #3B3B3B;
      cursor: pointer;
      label {
        margin-right: 5px;
      }
    }
    .facet__item__label__disable{
      display: none;
    }
  }
  .accordion-item{
    border: none;
    .main__facets__title{
      margin-bottom: 0px;
      color: #000000;
    }
    .accordion-button{
      padding: 0px;
      background-color: unset;
      box-shadow: unset;

      &:hover{
        .main__facets__title{
          display: inline-block;
          text-decoration: underline;
        }
      }
      &:not(.collapsed)::after{
        filter: grayscale(1);
      }
      &:focus{ 
        outline: unset; 
        background-color: unset;
        box-shadow: unset;
      }
    }
    .accordion-body{
      padding: 20px 0px 8px;
      .facet__block {
        margin: 0px 0px 18px 0px;        
        &:last-child{
          margin-bottom: 0px;
        }
      }
    }
  }
}

.facet__block {
  display: flex;
  align-items: center;
  margin: 0px 0px 10px 0px;
  
.exp__rd__select__block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .exp__rd__check__icon {
    display: block;
  }

  .exp__rd__check__icon__disable {
    display: none;
  }
}
  .preferences__select__input__check {
    accent-color: #dc3a1a;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    margin: 0px 12px 0px 0px;
    // border: 30px solid #303030;
    text-transform: uppercase;
    cursor: pointer;
  }
  .facet__item__label {
    font-size: 14px;
    font-family: "SSTmedium";
    margin: 0px;
    // width: 90%;
    color: #3B3B3B;
  }
  .facet__item__label__disable{
    display: none;
  }
}



/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {
  .main__facets__block{
    .facet__block {
      .preferences__select__input__check{
        margin: 0px 0px 0px 12px;
      }  
      .facet__item__label{   
        font-family: 'SSTArabicMedium';
        .me-2{
          margin-right: 0px !important;
          margin-left: 0.5rem !important;
        }
      }     
      
    }     
    .main__facets__title{
      padding-left: 8px;
      font-family: 'SSTArabicBold';
    }
  } 

  

}

/*-------------------------- Arabic view End ------------------------*/
