.loyalty__points__block {
    input {
        accent-color: #ff4f04;
        height: 16px;
        width: 16px;
        // border-radius: 10px;
        margin: 0px 16px 0px 0px;
        // border: 18px solid #303030;
        text-transform: uppercase;
        @media screen and (max-width:1300px) {
            height: 17px;
            width: 17px;
            margin: 0px 10px 0px 0px;
        }
        @media screen and (max-width:992px) {
            height: 16px;
            width: 16px;
            max-width: 16px;
            max-height: 16px;
        }
    }
    label{
        img{
            width: auto;
            max-height: 28px;
            max-width: 95px;
            
        }
    }
    .sum__points {
        color: #22D400;
        font-size: 14px;
        font-weight: 600;
    }

    .sum__eq {
        font-size: 12px;
    }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

    .loyalty__points__block {
        input{
            margin: 0px 0px 0px 16px;
        }
    }
    

}

/*-------------------------- Arabic view End ------------------------*/