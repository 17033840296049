.super__coin__block {
    display: flex;
    align-items: center;
    padding: 0px;
    background: #fff;
    margin: 0px;
    .super__coin__image__block {
      padding: 0px;
      height: 100%;
      display: flex;
      align-items: center;

      .super__coin__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-width: 140px;
      }
    }
    .super__coin__text__block {
      padding: 0px 0px 0px 12px;
      .super__coin__title {
        font-size: 16px;
        font-family: 'SSTbold';
        font-weight: bold;
        color: #000000;
        margin: 0px 0px 5px;
        line-height: 1.1;
        .coin {
          height: 12px;
          vertical-align: inherit;
        }
      }
      .super__coin__condition {
        font-size: 14px;
        font-family: "SSTregular";
        color: #808080;
        margin: 5px 0px 0px;
        line-height: 1.1;
      }
    }
  }