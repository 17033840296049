.breadcrumbs__container {
  padding: 12px 12px;
  background: #f5f5f5;

  .breadcrumbs__block {
    padding: 0px;
    width: 1420px;
    margin: 0px auto;
    display: flex;
    align-items: center;

    .back__button {
      // min-height: 32px;
      border: 1px solid #707070;
      border-radius: 3px;
      // width: 82px;
      text-align: center;
      background: #ffffff00;
      display: flex;
      align-items: center;
      padding: 8px 10px;

      @media screen and (max-width:992px) {
        padding: 4px 8px;
      }

      .left__arrow {
        margin-right: 10px;
      }
      .back__text {
        margin: auto;
        font-family: "SSTregular";
        font-size: 14px;
      }
    }
    .home__image {
      // min-height: 9px;
      width: 14px;
      margin: 0px 10px 0px 16px;
      display: flex;
      .home__icon {
      }
    }
    .right__arrow {
      margin: 0px 10px 0px 6px;
      display: flex;
      @media screen and (max-width: 992px) {
        margin: 0px 6px 0px 0px;
        width: 9px;
      }
    }
    .page__title {
      text-decoration: none;
      color: #808080;
      margin: 0px 10px 0px 6px;
      @media screen and (max-width: 992px) {
        margin: 0px 10px 0px 4px;
      }
      .page__title__text {
        font-family: "SSTregular";
        font-size: 14px;
        margin-bottom: 0px;
      }
      .breadCrumbs__highlight {
        color: #000000 !important;
      }
    }
    h5 {
      color: #303030;
      margin: 0px;
      padding: 10px 0px;
    }
  }
}
.bg-white {
  .breadcrumbs__container {
    background: transparent;
  }
}

@media screen and (max-width: 1500px) {
  .breadcrumbs__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}

@media screen and (max-width: 1450px) {
  .breadcrumbs__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}

@media screen and (max-width: 992px) {
  .breadcrumbs__block {
    width: 100% !important;
    max-width: 100% !important;
  }
  .breadcrumbs__container .breadcrumbs__block .back__button__block {
    display: none;
  }
  .breadcrumbs__container .breadcrumbs__block .home__image {
    margin: 0px 10px 0px 10px;
  }
}



/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .breadcrumbs__container{
    .breadcrumbs__block{
      .back__button{
        .left__arrow{
          margin-left: 10px;
          margin-right: 0px;
          transform: rotateY(175deg) !important;
          -webkit-transform: rotateY(175deg) !important;
        }
      }
      .page__title {
        .page__title__text{
          font-family: 'SSTArabic';
          font-size: 13px;
        }
      }
      .home__image {
        margin: 0px 16px 0px 10px;
      }
    }    
  }
  

}

/*-------------------------- Arabic view End ------------------------*/
