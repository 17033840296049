.productSeven__block {
  width: 340px;
  margin: 0px 40px 40px 0px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;

  .productSeven__header__block {
    background-color: #F1F4F9;
    padding: 0px;
    margin: 0px 0px 5px;
    width: 100%;

    .productSeven__quality__favourite__block {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .productSeven__quality__icon {
        width: 26px;
      }

      .productSeven__favourite__icon {
        display: block;
      }

      .productSeven__favourite__icon__disable {
        display: none;
      }
    }

    .productSeven__image__block {
      width: 272px;
      height: 252px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .productSeven__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: 50% 50%;
      }
    }

    .productSeven__compare__block {
      display: flex;
      align-items: center;
      margin: 20px 0px 0px;

      .productSeven__compare__input__check {
        accent-color: #000000;
        height: 17px;
        width: 17px;
        border-radius: 10px;
        margin: 0px 10px 0px 0px;
        // border: 17px solid #303030;
        text-transform: uppercase;
      }

      .productSeven__compare__text {
        font-size: 16px;
        font-family: "SSTregular";
        margin: 0px;
      }
    }
  }

  .productSeven__name {
    font-family: 'SSTbold';
    font-weight: bold;
    font-size: 18px;
    margin: 10px 0px 10px 0px;
    height: 82px;
    color: #000000;
    text-decoration: unset;
    .heading6{
      line-height: 1.2;
      color: #000000;
    }
  }

  .productSeven__series {
    font-family: "SSTregular";
    font-size: 14px;
    margin: 0px 0px 10px 0px;
    color: #808080;
    letter-spacing: 0.5px;
  }

  .rating__block {
    padding: 0px;
    margin: 0px 0px 10px 0px;

    .bGVcoD {
      padding: 0px;
    }
  }

  .productSeven__size__button__block {
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px 10px 0px;

    button{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000000;
        border-radius: 3px;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
    }

    .productSeven__size__button__active {
      font-size: 12px;
      border-radius: 3px;
      color: #ffffff;
      padding: 6px 8px;
      // background-color: #000000;
      border: 1px solid #000000;
      outline: none;
      border: none;
      margin: 0px 8px 0px 0px;
      z-index: 2;
        position: relative;
        &:before{
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          z-index: -1;
        }
        &:last-child {
          margin-right: 0px;
        }
    }

    .productSeven__size__button {
      font-size: 12px;
      border: 1px solid #707070;
      border-radius: 3px;
      padding: 6px 8px;
      background-color: #ffffff;
      outline: none;
      margin: 0px 8px 0px 0px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .prize__block {
    display: flex;
    align-items: flex-end;

    .old__prize {
      font-family: "SSTregular";
      font-size: 18px;
      text-decoration-line: line-through;
      color: #c8c8c8;
    }

    .new__prize {
      font-family: 'SSTbold';
      font-weight: bold;
      font-size: 18px;

      margin: 0px 0px 0px 5px;
    }
  }
.addToCart__button{
  border-radius: 5px;
    color: #ffffff;
    font-size: 15px;
    font-family: 'SSTbold';
    font-weight: bold;
    letter-spacing: 0.85px;
    padding: 8px 15px;
    background-color: #DC3A1A;    
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
    .addToCart__icon{
      margin: 0px 10px 0px 0px; 
      width: 19px;
    }
  }
  .outofStock__button{
    font-family: "SSTmedium";
    font-size: 16px;
    text-align: center;
    width: 100%;
    color: white;
    background-color: #a7a7a7;
    padding: 10px 0px;
    cursor: pointer;
    border-radius: 5px;
  }
}