.removeclass::after{
  display: none!important;
}
.cross-line{
  text-decoration: line-through!important;
}
.disable-bundle{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgb(69 63 63 / 20%);
    left: 0;
    right: 0;
}
.discountAmount{

  border: 1px solid black;
  border-radius: 50px;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width:480px) {
    width: fit-content;
    margin: 0px auto;
  }
}
.pd__bundle__block {
  border: 1.5px solid #000000;
  border-radius: 9px;
  position: relative;
  padding: 10px;
  width: 100%;
  @media screen and (max-width: 992px) {
    margin: 0 0px 15px;
    border-left: none;
    border-right: none;
    border-radius: 0px;
  }
  @media screen and (max-width:580px) {
    padding: 14px;
  }

  .pd__bundle__title__block {
    display: flex;
    align-items: center;
    padding-bottom: 15px;

    .pd__bundle_title_icon {
      margin-right: 7px;
      height: 35px;
      @media screen and (max-width:480px) {
        margin-right: 5px;
        height: 30px;
      }
      @media screen and (max-width:280px) {
        height: 24px;
      }
    }

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 125.99%;
      color: #000000;
      @media screen and (max-width:480px) {
        font-size: 15px;
      }
    }
  }

  .pd__bundle_item__block {
    position: relative;
    @media screen and (max-width:992px) {
      margin: 0px -5px;
    }

    .col-6{
      @media screen and (max-width:580px) {
        position: relative;
      }
    }

    .pd__bundle_img__block {
      display: flex;
      justify-content: center;
      margin: 20px 0 10px;
      position: relative;
      @media screen and (max-width:580px) {
        position: unset;
      }

      .pd__bundle_item_img {
        cursor: pointer;
        height: 65px;
        width: 80px;
        object-fit: contain;
      }

      &::after {
        position: absolute;
        content: "+";
        bottom: 50%;
        right: -20px;
        top: 15%;
        color: #DC3A1A;
        font-size: 28px;
        font-weight: 600;
        @media screen and (max-width:580px) {
          right: 1px;
        }
      }
    }

    &:last-child {
      background: red;
    }

    .pd__bundle_checkbox {
      position: absolute;
      top: -20px;
      right: 0px;
      accent-color: #dc3a1a;    
      cursor: pointer;  
      @media screen and (max-width:580px) {
        right: 1px;
        top: 0px;
      }
    }

    .pd__bundle_img__desc {
      font-weight: 500;
      font-size: 10px;      
      line-height: 14px;
      margin-bottom: 0px;
      color: #000000;
      min-height: 60px;
    }

    .pd__bundle_img__price {
      font-style: normal;
      font-weight: 700;
      font-size: 15.3063px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 10px;
    }
  }


  .pd__bundle_buy__btn {
    background: #dc3a1a;
    border: none;
    color: #fff;
    width: 100%;
    padding: 8px 15px;
    border-radius: 9px;
    margin-top: 10px;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    .btn__spinner{
      min-height: 20px;
    }
  }
  .pd__frequently__totalarea{
    @media screen and (max-width:480px) {      
      flex-direction: column;
    }
  }
  .or_box{
    display: inline-block;
    width: 100%;
    position: relative;
    text-align: center;
    p{
      font-size: 16px;
      color: #000000;
      padding: 5px 8px;      
      position: relative;
      text-align: center;
      width: 90%;
      margin: 0px auto;
      &::before{
        content: " ";
        display: block;
        height: 1px;
        width: 100%;
        background-color: #000000;
        position: absolute;
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        z-index: -1;
      }
      span{
        font-size: 15px;
        font-weight: bold;
        color: #000000;
        padding: 5px 8px;
        background-color: #ffffff;
      }
    }
  }
  .build__bundle__btn{
    padding: 8px 15px;
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-top: 5px;
    border: none;
    border-radius: 9px;
  }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .pd__bundle__block{
    font-family: 'SSTArabic';
    .pd__bundle_item__block{
      .pd__bundle_img__block{
        &::after{
          left: 0px;
          right: auto;
        }
      }    
      .pd__bundle_img__price{
        font-family: 'SSTArabicBold';
      }
      .pd__bundle_buy__btn{
        font-family: 'SSTArabicBold';
      }
    } 
    .pd__bundle__title__block {
      .pd__bundle_title_icon{
        margin-right: 0px;
        margin-left: 5px;   
      }
    }
  }
}
/*-------------------------- Arabic view End ------------------------*/