$mobileBreakpointSafari: 1201px;
$mobileBreakpoint: 1200px;
$gap: 29px;

.wrapper {
  display: flex;
  margin: 0 auto;
  position: relative;
  align-items: center;
  width: 100%;
  max-width: $mobileBreakpointSafari;
  padding: 5px;

  &.isFullWidth {
    max-width: 100% !important;
    padding: 0;
  }

  &.isFullWidth {
    max-width: 100% !important;
    padding: 0;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 15px);
    font-size: 18px;
    font-weight: 300;
    width: 30px;
    height: 30px;
    z-index: 1;
    border-radius: 50%;
    background-color: #FFF;
    cursor: pointer;
    box-shadow: 0 0 0 2px rgba(32, 32, 32, 0.125);
    transition: all ease 0.25s;

    &.prev {
      left: -15px;
      right: unset;
    }

    &.next {
      left: unset;
      right: -15px;
    }

    &.moveNav {
      &.prev {
        left: 10px;
      }

      &.next {
        right: 10px;
      }
    }
  }

  .storeNavigation {
    top: calc(50% - 12px);
    width: 20px;
    height: 20px;
    font-size: 12px;

    &.moveNav {
      &.prevStore {
        left: -23px;
        right: unset;
      }

      &.nextStore {
        left: unset;
        right: -23px;
      }
    }

    [dir="rtl"] {
      &.moveNav {
        &.prevStore {
          left: unset;
          right: unset;
        }

        &.nextStore {
          left: unset;
          right: -23px;
        }
      }
    }
  }

  .scroller {
    display: flex;
    flex: 1 1 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    padding: 0;
    overscroll-behavior-x: contain;
    scrollbar-width: none;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    position: relative;
    gap: $gap;

    &::-webkit-scrollbar {
      display: none;
    }

    >* {
      scroll-snap-align: start;
    }

    &.center {
      >* {
        scroll-snap-align: center;
      }
    }
  }

  @media (min-width: $mobileBreakpoint) {
    padding: 0;

    .navigation {
      top: calc(50% - 20px);
      width: 40px;
      height: 40px;
      font-size: 24px;

      &.prev {
        left: -20px;
      }

      &.next {
        right: -20px;
      }

      &.moveNav {
        &.prev {
          left: 20px;
        }

        &.next {
          right: 20px;
        }
      }

      &:hover,
      &:focus {
        box-shadow: 0 0 2px 2px rgba(32, 32, 32, 0.4);
      }
    }

    .scroller {

      &.hero {
        html[dir="ltr"] &>* {
          margin-right: $gap;
        }

        html[dir="rtl"] &>* {
          margin-left: $gap;
        }

        html[dir="ltr"] &>*:last-child {
          margin-right: 0;
        }

        html[dir="rtl"] &>*:last-child {
          margin-left: 0;
        }

        html[dir="ltr"] &:after {
          margin-left: $gap;
        }

        html[dir="rtl"] &:after {
          margin-right: $gap;
        }

        html[dir="ltr"] &:before {
          margin-right: $gap;
        }

        html[dir="rtl"] &:before {
          margin-left: $gap;
        }
      }
    }
  }
}

[dir="rtl"] {
  .wrapper {
    .navigation {
      &.prev {
        left: unset;
        right: 15px;
      }

      &.next {
        left: 10px; // -15px;
        right: unset;
      }
    }

    @media (min-width: $mobileBreakpoint) {
      .navigation {
        &.prev {
          left: unset;
          right: 20px;
        }

        &.next {
          left: 20px;
          right: unset;
        }
      }
    }
  }
}