.product__comparison__info__area {  
    display: flex;
    flex-direction: row;
    flex: 0 0 100%;
    max-width: 100%;
    overflow: scroll;
    margin: 0px;
    overflow-y: hidden;
  .product__comparison__itembox_listbox{
    @media screen and (max-width:480px) {
      padding: 0px;
    }
    .row {    
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: stretch;    

        .col-10.col-md-4 {
          min-height: 100%;
        }
        .productNine__block {
          border: 1px solid #d1d1d1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .productNine__header {
            padding: 0px 10px 0px 0px;
            .product__comparison__close__icon__block{
              margin-top: 6px;
            }
          }
          .productNine__content{
            padding: 0px 10px 15px;
            .plp__mezza__reward {
              img{
                @media screen and (max-width:992px) {
                   max-width: 71px; 
                }
              }
            }
          }
        }
    .product__comparison__main__container {
      margin-bottom: 20px;

      &:first-child { 
        padding-left: 0px; 
        @media(max-width:992px) { padding-left: 8px; }
      }

      @media(max-width:992px) { 
        // padding: 8px; 
        // border: 1px solid #D1D1D1;
        // box-shadow: 0px 3px 6px #0000000A;
        border-radius: 5px;
        width: 32.3%;
        min-width: 238px;
        margin-right: 8px;
      }
      @media(max-width:480px) { 
        width: 49%;
        max-width: 49%;
      }
      .product__comparison__block {
        width: 100%;
        margin: 0px 0px 0px 0px;
        background-color: #ffffff;
        border: 1px solid #D1D1D1;
        padding: 12px;        
        
        // &:hover{          
        //   box-shadow: 0px 0px 9px #d9d9d9;
        // }
        .product__card {
          .product__comparison__header__block {
            padding: 14px;
            margin: 0px 0px 15px;
            width: 100%;
            // background: #f1f4f9;
            position: relative;
            @media(max-width:992px) { 
              padding: 0px;
              background-color: transparent;
              margin-bottom: 10px;
            }
            .product__card__top {
              display: flex;
              justify-content: end;
              position: absolute;
              width: 20px;
              height: 20px;
              right: 0px;
              top: 0px;
              @media(max-width:992px) {
                right: -3px;
                top: -5px;
              }
              .product__comparison__close__icon__block {
                cursor: pointer;
                height: 20px;
                display: flex;
                align-items: flex-start;
              
                .product__comparison__close__icon {
                  width: 20px;
                  @media(max-width:992px) { width: 19px; }
                }
              }
            }
            .product__comparison__image__block {
              width: 86%;
              height: 210px;
              overflow: hidden;
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 auto;
              @media(max-width:992px) {
                max-width: 100%;
                height: 160px;
              }
              @media(max-width:480px) {
                .heading6{
                  font-size: 15px;
                  line-height: 1.2;
                }

              }
              .product__comparison__image {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: 50% 50%;
              }
              .addToCart__button{
                @media(max-width:992px){ padding: 12px 0px; }
              }
            }
          }

          .product__comparison__name {
            font-family: 'SSTbold';
            font-weight: bold;
            font-size: 18px;
            margin: 0px 0px 10px;
            height: 62px;
            color: #000000;
            text-decoration: unset;

            .heading6 {
              margin-bottom: 0px;
              color: #000000;
              line-height: 1.2;
              @media(max-width:992px) {
                font-size: 15px;
              }
            }            
            @media(max-width:992px) { height: 78px; }
            @media(max-width:480px) { height: 70px; }
          }
          .product__comparison__series {
            font-family: "SSTregular";
            font-size: 14px;
            margin: 0px 0px 10px 0px;
            color: #808080;
            letter-spacing: 0.5px;
          }
          .rating__block {
            padding: 0px;
            margin: 0px 0px 5px 0px;
            .bGVcoD {
              padding: 0px;
            }
          }
          .productSeven__series{ 
            margin-bottom: 8px; 
            line-height: 1;
          }
          .prize__block {
            margin-bottom: 8px;
            min-height: 40px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            span {        
              // &:first-child { margin-right: 8px !important; }
              // &:last-child { margin-left: 0px !important; margin: 0px !important; }
              &.text3{
                margin-bottom: 0px !important;
                position: relative;
                width: fit-content;
                line-height: 1.2;
                &::after{
                  content: " ";
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 1px;
                  background-color: #666666;
                  -webkit-transform: translateY(-8px) rotate(-6deg);
                  transform: translateY(-8px) rotate(-6deg);            
                  bottom: 0;
                  left: 0;
                }
              }
            }

            .old__prize {
              font-family: "SSTregular";
              font-size: 18px;
              text-decoration-line: line-through;
              color: #c8c8c8;
            }
            .new__prize {
              font-family: 'SSTbold';
              font-weight: bold;
              font-size: 18px;
              margin: 0px 0px 0px 5px;
            }
          }
          .product__comparison__fetureText {
            font-family: 'SSTregular';
            font-size: 16px;
            color: #2b2b2b;
            width: auto;
          }
          .free__delivery {
            .free__delivery text {
              margin: 0px;
              color: #2b2b2b;
              font-family: 'SSTregular';
              font-size: 12px;
            }
            .delivery__day {
              color: #2b2b2b;
            font-family: 'SSTbold';
    font-weight: bold;
              font-size: 14px;
            }
          }
          .addToCart__button {
            border-radius: 5px;
            color: #ffffff;
            font-size: 14px;
            font-family: 'SSTbold';
            font-weight: bold;
            letter-spacing: 0.85px;
            padding: 8px 15px;
            background-color: #dc3a1a;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            @media(max-width:480px) {
              font-size: 13px;
            }
            .addToCart__icon {
              margin: 0px 10px 0px 0px;
              width: 20px;
              @media(max-width:480px) { width: 18px;
                margin-right:6px; }
            }
            &:hover{
              box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
              transform: translateY(-2px);
              transition: all 0.2s ease-in-out;
              -webkit-transition: all 0.2s ease-in-out;
              opacity: 0.8;
            }
          }
          .outofStock__button{
            border-radius: 5px;
            color: #ffffff;
            font-size: 14px;
            font-family: 'SSTbold';
            font-weight: bold;
            letter-spacing: 0.85px;
            padding: 8px 15px;
            background-color: #a7a7a7;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            @media(max-width:480px) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}

  .product__comparison__all__specification__block {

    @media(max-width:992px) {
      padding: 0px;
    }
    .specification__title__block {
      .specification__listing__area{
        // border: 1px solid #cdcdcd;
        .specification__category__block {
          &:nth-child(even){
            background-color: #f1f4f9;

            .specification__subcategory__title{
              background-color: #f1f4f9;
            }
          }
          .specification__subcategory__block {
            // background: #f1f4f9;
            text-align: center;
            font-size: 16px;
            font-family: "SSTregular";            
  
            @media(max-width:992px) {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
            }
            .specification__subcategory__title {
              // background: #f1f4f9;
              color: #101010;
              font-size: 16px;
              font-family: 'SSTregular';
              padding: 10px 0px;
              // border: 1px solid rgba(0, 0, 0, 0.1);
              @media(max-width:1200px) {
                width: 33.3%;
                min-width: 310px;
                font-size: 14px;
              }
              @media(max-width:1130px) {
                width: 33.3%;
                min-width: 33.3%;
                font-size: 14px;
              }
              @media(max-width:992px) {
                width: 33.3%;
                min-width: 220px;
                font-size: 14px;
                // background-color: #f1f4f9;
              }
              @media(max-width:480px) { 
                width: 55%;
                max-width: 55%;
                min-width: 290px;
               }
              @media(max-width:320px) { 
                width: 52%;
                max-width: 55%;
                min-width: 220px;
              }
            }
          }
        }
      }
      
    }
  }

  .product__comparison__availability {
    margin-bottom: 20px;
    .product__comparison__availability__container {
      padding: 0px 0px 15px;      
      margin: 0px;
      
      @media(max-width:992px) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 0px 0px 15px;
      }
      .product__comparison__availability__block {
        background: #f1f4f9;
        padding: 10px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        @media(max-width:1200px) {
          width: 33.3%;
          min-width: 310px;
          font-size: 14px;
        }
        @media(max-width:1130px) {
          width: 33.3%;
          min-width: 33.3%;
          font-size: 14px;
        }
        @media(max-width:992px) {
          margin-bottom: 16px;
          padding: 12px;
          width: 32.5%;
          min-width: 220px;
        }
        @media(max-width:480px) { 
          width: 55%; 
          max-width: 55%;
          min-width: 290px;
        }
        .product__comparison__availability__block__item {
          display: flex;
          align-items: flex-start;
          margin: 16px 0px;
          
          .item__image {
            margin-right: 13px;
            @media(max-width:480px) { 
              margin-right: 7px;
              width: 21px; 
            }
          }
          .item__text__block {
            .item__text {
              margin: 0px;
              font-size: 16px;
              font-family: "SSTregular";
              .item__strong__text {
                font-size: 16px;
              font-family: 'SSTbold';
  font-weight: bold;
              }
            }
            .item__store__location__link {
              color: #003cb4;
            }
          }
        }
        .delivery__taken__area {
          background-color: white;
          margin-top: 0px !important;
          @media screen and (max-width:992px) {
            margin: 10px 0px;
          }
        }
        .store__pickup__taken__area {
          background-color: white;
          @media screen and (max-width:992px) {
            margin: 10px 0px;
          }
        }
        .store__pickup__location__listing{ 
          .locationCard {
            padding: 10px;
            border: 1px solid #e7e7e7;
            min-width: 100%;
            max-width: 100%;
            @media screen and (max-width:1180px) {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
            }
            .location{
              @media screen and (max-width:1180px) {
                margin-top: 6px;
              }
            }
          }
          .location__show__more__button{
            border: none;
            padding: 4px 10px;
            width: 100%;
            text-align: center;
            font-family: "SSTbold";
            color: #000000;
            font-size: 15px;
            background-color: unset;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .product__comparison__offer {
    margin-bottom: 20px;
    .product__comparison__offer__block {
      // background: #f1f4f9;
      margin: 0px;
      // padding: 10px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .product__comparison__offer__item {
        background: #f1f4f9;
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;

        @media(max-width:992px) {
          width: 32.3%;
          min-width: 240px;
          background-color: #f1f4f9;
        }
        @media(max-width:480px) { 
          width: 52%; 
          max-width: 52%;
        }

        .product__offer__block {
          display: flex;
          align-items: center;
          margin: 16px 0px 16px 0px;
          @media(max-width:480px) { 
            align-items: flex-start;
          }
          .product__offer__icon {
            width: 32px;
            margin: 0px 16px 0px 0px;
            @media(max-width:480px) { 
              width: 20px;
              margin: 0px 10px 0px 0px;
            }
          }
          .product__offer__text__box {
            display: flex;
            align-items: center;
            .product__offertype {
              margin: 0px;
              font-size: 14px;
              font-family: 'SSTbold';
              font-weight: bold;
              .product__offerText {
                margin: 0px 10px 0px 0px;
                font-size: 14px;
                font-family: "SSTregular";
              }
            }
            .product__termsAndConditions__link {
              margin: 0px;
              font-size: 14px;
              font-family: 'SSTbold';
              font-weight: bold;
              text-decoration: none;
              color: #003cb4;
            }
          }
        }
      }

      .product__card__finance__offer {
        margin-top: 29px;
        .finance__offertext {
          color: #003cb4;
          margin: 0px;
        }
      }
    }
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .product__comparison__list__container {
    .product__comparison__list{
      .product__comparison__info__area {
        .product__comparison__itembox_listbox {
          .row {
            .productNine__block {
              border-radius: 5px 0px 5px 5px;
              .productNine__header{
                padding: 0px 0px 0px 10px;
              }
            }
          }
        }
        .product__comparison__all__specification__block{
          .heading3{
            font-size: 1.5rem;
            @media screen and (max-width:1280px){
              font-size: 1.3rem;
            }
          }
        }
      }    
    }
  }
  

}

/*-------------------------- Arabic view end ------------------------*/