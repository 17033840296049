.mb__product__list__container {
    padding-top: 0px !important;

    .modal#SortByModal{
        .modal-body{
            label[for="sortBy"]{
                margin-left: 10px;
                margin-right: 10px;
                &:last-child{
                    margin-right: 0px;
                }
            }
        }
    }
    .mb__filter__related__popup{
        height: auto;
        top: auto;
        bottom: 30px;
        .modal-body{
            max-height: 60vh;
            overflow: scroll;
            overflow-x: hidden;

            .main__facets__clear_filter {
                margin-bottom: 0px;
            }
        }
    }

}

// filter section start
.mb__filter__list {
    .row {
        .banner__section__one {
            background: #000000;
            // height: 200px;
            width: 100%;
            margin: 0px auto;
            padding: 0% 4%;
            .banner__section__one__title__block {
              // background: #F2F2F2;
              display: flex;
              align-items: center;
              .banner__section__one__title {
                margin: 2rem 0rem;
                font-family: 'SSTbold';
                font-weight: bold;
                font-size: 30px;
                color: #ffffff;     
                @media screen and (max-width:580px) {
                    margin: 1rem 0rem 1rem;
                    font-size: 21px;
                    width: 100%;
                }   
              }
            }
            .banner__section__one__image__block {
              // height: 100%;
              display: flex;
              align-items: flex-end;
              justify-content: flex-start;
              .banner__section__one__image {
                // margin-top: 40px;
                // width: 328px;
                object-fit: contain;
                max-height: 130px;
                margin-top: 10px;
                display: none;
                @media screen and (max-width: 1200px) {
                  max-height: 100px;
                }
              }
            }
          }
    }


    .grid__button {
        display: none;
    }

    .mb__grid__active {
        display: block;
    }

    .mb__grid__active,
    .mb__list__active {
        background: #DF4E32;
        color: #fff;

        img {
            width: 20px;
            filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(199deg) brightness(103%) contrast(101%);
        }
    }

    .col-5 {
        min-height: 43px;
        padding: 0;
        box-shadow: 0px 0px 3px #b5b5b5;
        @media screen and (max-width:992px) {
            margin-left: auto;
        }
    }
    .PLP_noProduct_box{
        padding: 25px 0px;
        h1 {
            font-size: 1.35rem;
            text-align: center;
            margin-bottom: 0px;
        }
    }

    .dropdown {

        border: 1px solid #d4d4d4;
        border-bottom: 0;

        button:focus {
            box-shadow: none;
        }
    }

    .dropdown-menu {
        width: 100%;
        border-radius: 0;

        li {
            input[type="checkbox"] {
                width: 20px;
                height: 20px;
                accent-color: black;
            }
        }
    }

    .dropdown-toggle {
        border-radius: 0;

        &.show {
            background: #DF4E32;
            color: #fff;

            &:after {
                filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(199deg) brightness(103%) contrast(101%);
            }
        }

        &:after {
            background: url(./../../assets/Icon/down_arrow.svg);
            border: none;
            width: 13px;
            height: 8px;
            background-size: contain;
            background-repeat: no-repeat;
            vertical-align: initial;
            margin-left: 10px;
        }
    }
    .PLP_dsk_noProduct_box{
        background-color: #f5f4f4;
        padding: 20px 15px;
        min-height: 40vh;
        align-items: center;
        display: flex;
        justify-content: center;
    }
}
.mb_campare_selectbox { display: none; }

.mb__product__list__container.listing_compare_active {

    .mb__product__grid__block,
    .mb__product__list__detail__block {
        position: relative;
        padding-bottom: 25px;

        .addToCart__button, .notifyMe__button, .outofStock__button, .pre-order{ display: none !important; }

        .mb_campare_selectbox {
            position: absolute;
            width: 100%;
            height: auto;
            padding: 12px 15px;
            background-color: #DF4E32;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0px;
            bottom: -1px;
            border-radius: 0px 0px 5px 5px;
            @media (max-width:480px) { padding: 9px 12px; }

            .mb_campare_checkbox{  
                width: 23px;  
                height: 16px;
                background-color: #ffffff;
                color: #DF4E32;
                accent-color:#DF4E32;
                margin-right: 5px;
                &:checked{
                    border-color: #DF4E32;
                }
                @media (max-width:567px) {
                    width: 20px;  
                    height: 16px;
                }
            }
            .mb_campare_label{
                color: #ffffff;
                font-size: 16px;
                font-family: "SSTregular";
                margin-bottom: 0px;
                line-height: 1;
                margin-left: 6px;
                @media (max-width:567px) {
                    font-size: 15px;
                }
            }
    
        }
    }    
    // .mb__product__list__detail__block {
    //     .mb_campare_selectbox {
    //         border-radius: 0px 0px 5px 0px;
    //     }
    // }
}

// mb__product__list__breadcrumb start
.mb__product__list__breadcrumb {
    .breadcrumbs__container {
        padding-top: 10px;
    }
}

// mb__product__list__breadcrumb end


.mb__product__list__detail__sec {
    background: #f5f5f5;
    padding-top: 10px;
}

// bottom fixed filter
.mb__product__list__bottom__filter {
    position: fixed;
    width: 95%;
    z-index: 2;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width:992px) { width: 75%; }
    
    @media screen and (max-width:580px) { width: 95%; }

    a{
        text-decoration: none;
    }
    ul {
        display: flex;
        list-style-type: none;
        align-items: center;
        justify-content: center;

        li {
            width: 33.33%;

            &:first-child {
                button {

                    border-radius: 35px 0 0 35px;
                }
            }

            &:last-child {
                button {
                    border-radius: 0 35px 35px 0;
                }
            }            

            button {
                width: 100%;
                height: 43px;
                padding: 0;
                font-size: 16px;
                font-family: 'SSTregular';
                border-radius: 0;
                @media screen and (max-width:420px) {
                    font-size: 14px;
                    height: 43px;
                }
                @media screen and (max-width:320px) {
                    height: 40px;
                }
                @media screen and (max-width:281px) {
                    font-size: 13px;
                }

                img {
                    margin-right: 10px;
                    height: 16px;
                    vertical-align: text-bottom;
                    @media screen and (max-width:420px) {
                        height: 15px;
                    }
                    @media screen and (max-width:281px) {
                        height: 14px;
                        margin-right: 5px;
                    }
                }

                &.btn__primary__orange {
                    &:hover , &:focus{
                        background: #DF4E32;
                        color: #fff;
                    }
                }
            }
        }
    }
    .mb_compare_pro_btn{
        display: none;        
    }
}
.mb__product__list__bottom__filter.campare__btn__active{
    ul { display: none; }
    .plp_compare_back_btn {
        display: inline-flex;
        padding: 9px 10px;
        font-size: 24px;
        border-radius: 20px;
        margin-bottom: 12px;
        margin-right: 10px;
    }
    .mb_compare_pro_btn {
        border-radius: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;        
        font-family: 'SSTmedium';
        padding: 9px 15px;
        font-size: 16px;        
        max-width: calc(100% - 59px);
        width: 90%;
        margin-bottom: 12px;
        img { margin-right: 5px; }
    }
}

@media(max-width:576px) {    
    .mb__product__list__detail__block .productTen__middle__block .plp__mezza__reward span {
        font-size: 13px;
        margin-top: 4px;
    }
}


/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {

    .mb__product__list__container {
        
        &.listing_compare_active{
            .mb__product__grid__block, .mb__product__list__detail__block{
                .mb_campare_selectbox {
                    .mb_campare_checkbox{
                        margin-right: 0px;
                        margin-left: 6px;
                    }
                    .mb_campare_label{
                        font-family: "SSTArabic";
                        margin-right: 6px;
                        margin-left: 0px;
                    }
                }
            }
        }
        .mb__product__list__bottom__filter {
            ul {
                li {
                    button { 
                        font-size: 14px;
                        font-family: 'SSTArabicBold';
                        img {
                            @media screen and (max-width:321px) {
                                height: 13px;
                                vertical-align: baseline;
                            }
                        }
                    }
                }
            }
            &.campare__btn__active{
                .plp_compare_back_btn{
                    direction: rtl;
                    margin-right: 0px;
                    margin-left: 10px;
                    svg {
                        transform: rotateY(180deg);
                        -webkit-transform: rotateY(180deg);
                        -moz-transform: rotateY(180deg);
                    }
                }
            }
        }        
        .mb_compare_pro_btn{
            font-family: 'SSTArabicBold';
            img{
                margin-right: 0px;
                margin-left: 5px;
            }
        }
    }
    .modal#SortByModal {
        .modal-header{
            .heading5 {
                font-size: 17px;
            }
        }
        .modal-body {
            label[for=sortBy]{
                margin-left: 0px;
                margin-right: 10px;
                font-size: 12px;

                &:nth-child(2){
                    margin-left: 5px;
                }
            }
        }
    }
    .mb__filter__list{
        
        .mb__grid__active,.mb__list__active{
            @media screen and (max-width:992px) {
                font-size: 15px;
            }
            @media screen and (max-width:768px) {
                font-size: 14px;
            }
            img{ 
                margin-right: 0px !important; 
                margin-left: 5px !important; 
            }
        } 
        .col-5{
            @media screen and (max-width:321px) {
                width: 160px;

            }
        }
        .banner__section__one {
            .banner__section__one__title__block {
                .banner__section__one__title{
                    font-family: 'SSTArabicBold';
                    font-size: 20px;       
                }
            }
        }
    }     
        

}

/*-------------------------- Arabic view End ------------------------*/