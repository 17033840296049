$fontFamily: Rubik, SSTRegular, Arial, sans-serif;
$fontFamilyRTL: Cairo, SSTArabic, Arial, sans-serif;
$textMidGray: #4E4E4E;

.errorMessage {
  color: red;
  font-weight: 600;
  font-family: $fontFamily;
}

.braviaForm {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 auto;
  font-family: $fontFamily;
  margin-bottom: 50px;

  .field {
    display: flex;
    flex-direction: column;

    select {
      padding: 10px;
      font-size: 16px;
      border: 1px solid #cacece;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 5px;
      appearance: none; // removes the default browser styling
      background: url(./../../assets/Icon/icon-down-arrow.png) no-repeat right center;
      background-size: 20px;
      background-position: 98% 50%;

      &.error {
        border: 2px solid red;
      }
    }

    p {
      font-size: 16px;
      color: $textMidGray;
      font-weight: 600;
    }

    label {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
      color: $textMidGray;

      .termsNcondi {
        cursor: pointer;
        text-decoration: underline;
      }
    }


    input[type="checkbox"] {
      cursor: pointer;
      margin-right: 5px;
    }

    & [type="text"],
    & [type="email"] {
      padding: 10px;
      font-size: 16px;
      border: 1px solid #cacece;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 5px;

      &.error {
        border: 2px solid red;
      }
    }

    .subsText {
      display: flex;
      font-weight: 600;
      font-size: 12px;
      color: $textMidGray;
    }
  }

  button {
    cursor: pointer;
    background-color: #000;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: 500;

    &:disabled {
      cursor: not-allowed;
      background-color: #cacece;
      color: #000;
    }

  }
}

.checkBoxField {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;

  label {
    position: relative;
    cursor: pointer;
    font-size: 11px !important;
    font-weight: 600 !important;
    user-select: none;
    display: flex;
    align-items: center;

    .custom-checkbox {
      position: relative;
      display: inline-block;
      width: 20px; // size of the custom checkbox
      height: 20px;
      margin-right: 5px;

      &::before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border: 3px solid #cacece; // border color and thickness
        background: #fafafa;
        display: block;
      }

      input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
      }

      input[type="checkbox"]:checked~.checkmark {
        background-color: #cacece;
      }

      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      input[type="checkbox"]:checked~.checkmark:after {
        display: block;
      }

      .checkmark:after {
        left: 7px;
        top: 3px;
        width: 6px;
        height: 11px;
        border: solid #000;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}

body[dir='rtl'] {

  .errorMessage,
  .braviaForm {
    font-family: $fontFamilyRTL;
  }

  .braviaForm>.field>select {
    background-position: 2% 50%;
  }

  .braviaForm>.field>label>input[type="checkbox"] {
    margin-right: 0;
    margin-left: 5px;
  }

  .checkBoxField>div>label>.custom-checkbox {
    margin-right: 0;
    margin-left: 5px;
  }
}