.store__pickup__taken__area{
    padding: 15px 0px;
    border-radius: 15px;
    background-color: #F8F8F8;
    margin: 10px 0px;

    @media screen and (max-width:992px) {
        margin: 10px 15px;
    }
    @media screen and (max-width:480px) {
        padding: 11px 0px;
    }

    .delivery__to__box{
        color: #000000;
        label{
            font-size: 14px;
            font-weight: 500;
            @media screen and (max-width:320px) { font-size: 12px; }
        }
        strong{
            font-weight: 600;
            margin-left: 5px;
            font-size: 14px;
            display: inline-block;
            @media screen and (max-width:320px) { font-size: 12px; }
        }       
    }
    .delivery__day__box{
        color: #000000;
        font-size: 14px;
        text-align: right;

        @media screen and (max-width:320px) { font-size: 13px; }
        p{
            margin-bottom: 0px;
            font-weight: 500;            
        } 
        strong{
            font-weight: 700;
            display: inline-block;
        }
        .delivery__to__change__btn{
            color: #5A68EC;
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;
            margin-left: 7px;
            transition: all .3s ease-in-out;
            -webkit-transition: all .3s ease-in-out;
            &:hover{
                color: #000000;
            }
        }
    }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

    .store__pickup__taken__area {
        .delivery__to__box{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            strong{
                margin-left: 0px;
                margin-right: 5px;
            }
            .delivery__to__change__btn{
                margin-left: 0px;
                margin-right: 15px;
            }
        }
        .delivery__day__box{
            text-align: left;
        }
    }

}

/*-------------------------- Arabic view end ------------------------*/
