.more__services__section{   
    margin: 20px 0px;

    @media screen and (max-width:992px) {
        margin: 15px 15px;
    }

    .accordion-item{                
        border: none;

        .accordion-button{
            background-color: #F8F8F8;
            border-radius: 15px !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 15px;

            @media screen and (max-width:480px){
                padding: 12px;
            }
            &::after{
                display: none;
            }
            &:focus {
                box-shadow: 0 0 0 0.25rem rgb(220 58 26 / 7%);
            }
            .more__services__label{
                display: flex;
                align-items: center;

                img{
                    width: 32px;
                    margin: 0px 8px 0px 0px;
                    @media screen and (max-width:1280px){
                        width: 26px;
                    }
                    @media screen and (max-width:480px){
                        width: 22px;
                    }
                }
                p{
                    font-size: 15px;
                    font-family: 'SSTmedium';
                    color: #000000;
                    font-weight: 500;
                    margin-bottom: 0px;
                    @media screen and (max-width:480px){
                        font-size: 13px;
                    }
                }
            }
            .see__More__services__btn {
                font-size: 16px;
                color: #DC3A1A;
                font-family: 'SSTmedium';
                text-transform: capitalize;
                @media screen and (max-width:992px){ 
                    font-size: 14px; 
                }
                @media screen and (max-width:480px){
                    font-size: 13px;
                    font-weight: 500;
                }.more__services__section{   
                    margin: 20px 0px;
                
                    @media screen and (max-width:992px) {
                        margin: 15px 15px;
                    }
                
                    .accordion-item{                
                        border: none;
                
                        .accordion-button{
                            background-color: #F8F8F8;
                            border-radius: 15px !important;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            padding: 15px;
                
                            @media screen and (max-width:480px){
                                padding: 12px;
                            }
                            &::after{
                                display: none;
                            }
                            &:focus {
                                box-shadow: 0 0 0 0.25rem rgb(220 58 26 / 7%);
                            }
                            .more__services__label{
                                display: flex;
                                align-items: center;
                
                                img{
                                    width: 32px;
                                    margin: 0px 8px 0px 0px;
                                    @media screen and (max-width:1280px){
                                        width: 26px;
                                    }
                                    @media screen and (max-width:480px){
                                        width: 22px;
                                    }
                                }
                                p{
                                    font-size: 15px;
                                    font-family: 'SSTmedium';
                                    color: #000000;
                                    font-weight: 500;
                                    margin-bottom: 0px;
                                    @media screen and (max-width:480px){
                                        font-size: 13px;
                                    }
                                }
                            }
                            .see__More__services__btn {
                                font-size: 16px;
                                color: #DC3A1A;
                                font-family: 'SSTmedium';
                                text-transform: capitalize;
                                @media screen and (max-width:992px){ 
                                    font-size: 14px; 
                                }
                                @media screen and (max-width:480px){
                                    font-size: 13px;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                
                }
                
            }
        }
    }

}