.live__chat__popup__block {
  background: #ffffff 0% 0% no-repeat padding-box;
  // border: 1px solid #707070;
  border-radius: 5px;
  width: 320px;
  position: absolute;
  right: 20px;
  bottom: 93px;
  padding: 25px 20px 20px;
  

  .inner__live__chat__popup__block {
    .live__chat__popup__header{
        display: flex;
        justify-content: flex-end;
        position: absolute;
        height: 20px;
        width: 20px;
        right: 5px;
        top: 5px;
        .cancel__button{
            cursor: pointer;
        }        
    }
    .live__chat__option__block {
        margin: 0px 0px 5px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 3px;
        
      .live__chat__option__icon {
        height: 20px;
        padding: 0px 5px 0px 0px;
      }
      .live__chat__option__text{
        padding: 0px;
        margin: 0px;
        font-size: 16px;
        color: #000000;
      }
      &:hover{
        background-color: #dc3a1a9c;
        border-radius: 5px;
        .live__chat__option__icon{ 
          filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(12deg) brightness(106%) contrast(103%);
        }
        .live__chat__option__text{
          color: #ffffff;
        }
      }
    }
  }
}
