.productTen__block {
  background-color: #ffffff;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 9px #d9d9d9;
  }

  .productTen__left__block {
    padding: 0px 0px 10px;

    .productTen__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .productTen__new__sticker__block {
        // width: 85px;
        // height: 30px;
        background: #ff9b04;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-bottom-right-radius: 15px;
        padding: 5px 9px 6px 6px;

        .productTen__new__sticker__text {
          margin: auto;
          font-family: 'SSTbold';
          font-weight: bold;
          font-size: 13px;
          color: #ffffff;
          line-height: 1;
        }
      }

      .quality__favourite__block {
        display: flex;
        align-items: center;
        padding: 8px 8px 0px 0px;
        margin-bottom: 5px;

        .quality__icon {
          width: 18px;
          margin: 0px 8px 0px 0px;
        }

        .favourite__icon {
          cursor: pointer;
          display: block;
          width: 18px;
        }

        .favourite__icon__disable {
          display: none;
        }
      }
    }

    .product__pics__area {
      position: relative;

      .productTen__image__slider {
        width: 85%;
        margin: auto;
        min-height: 160px;
        max-height: 160px;

        .slick-slider {

          .slick-prev:before,
          .slick-next:before {
            color: #c7c7c7;
          }

          .slick-next {
            background-repeat: no-repeat;
            width: 8px;
            height: 20px;
            background-size: contain !important;
            background: url(./../../assets/Icon/grey_right_arrow.svg);
            background-repeat: no-repeat;
            right: -15px;

            &:before {
              display: none;
            }
          }

          .slick-prev {
            background-repeat: no-repeat;
            width: 8px;
            height: 20px;
            background-size: contain !important;
            background: url(./../../assets/Icon/grey_right_arrow.svg);
            background-repeat: no-repeat;
            transform: rotate(175deg) translate(0, 0%);
            top: 41%;
            left: -15px;

            &:before {
              display: none;
            }
          }

          // .slick-arrow{ display: none !important; }
          .slick-list {
            .slick-track {
              .slick-slide {

                img {
                  height: 100%;
                  width: 100%;
                  object-fit: contain;
                  max-height: 160px;
                  margin: 0px auto;
                }
              }
            }
          }

          .slick-dots {
            bottom: 6px;
            background-color: #f7f7f75e;
            display: flex !important;
            max-width: max-content;
            padding: 2px 5px;
            height: 17px;
            align-items: center;
            border-radius: 7px;
            margin: 0px auto;
            left: 50%;
            transform: translateX(-50%);

            li {
              width: 10px;
              height: 10px;
              margin: 0px 2px;

              button {
                padding: 0px;
                height: 10px;
                width: 10px;

                &:before {
                  line-height: 10px;
                  width: 10px;
                  height: 10px;
                }
              }
            }
          }
        }

        .productTen__name__link {
          text-decoration: none;
          color: #000000;
          margin: 0px;

          .productTen__name {
            font-family: 'SSTbold';
            font-weight: bold;
            margin: 0px 0px 10px;
            font-size: 15px;
            line-height: 1.4;
            min-height: 55px;
          }
        }

        ._product__container_image {
          // width: 70%;
        }

        .awssld__content {
          background-color: inherit;
        }

        .awssld__bullets {
          position: unset;
          background-color: none;
          //   border: 1px solid #000000;
        }

        .awssld__bullets button {
          background: none;
          border: 1px solid #000000;
          transform: scale(0.7);
          margin: 0px 1px 5px;
          width: 10px;
          height: 10px;
        }

        .awssld__bullets .awssld__bullets--active {
          transform: scale(0.7);
          background-color: #000000;
          border: 1px solid #000000;
        }

        .awssld__bullets .awssld__bullets--loading {
          //   transform: scale(0.6);
        }

        .awssld__controls {
          visibility: hidden;
        }

        // .awssld__controls__arrow-right {
        //   visibility: hidden;
        // }
      }

      .soldOutLable {
        position: absolute;
        width: 95%;
        background: hsla(0,0%,85.1%,.76);
        height: 30px;
        top: 35%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 0px 0px 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 21px;
        color: rgba(0,0,0,.72);
        text-transform: uppercase;
      }
      .cardLabel {
        display: flex;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 95%;
        height: 100%;
        margin: 0px 5px;

        img {
          border-radius: 0 !important;
          max-width: 25% !important;
          max-height: 25% !important;
        }

        &.left_top {
          justify-content: flex-start;
          align-items: flex-start;
        }

        &.center_top {
          justify-content: center;
          align-items: flex-start;

          img {
            max-width: 50% !important;
          }
        }

        &.right_top {
          justify-content: flex-end;
          align-items: flex-start;
        }

        &.left_middle {
          justify-content: flex-start;
          align-items: center;

          img {
            max-height: 50% !important;
          }
        }

        &.center_middle {
          justify-content: center;
          align-items: center;

          img {
            max-height: 50% !important;
            max-width: 50% !important;
          }
        }

        &.right_middle {
          justify-content: flex-end;
          align-items: center;

          img {
            max-height: 50% !important;
          }
        }

        &.left_bottom {
          justify-content: flex-start;
          align-items: flex-end;
        }

        &.center_bottom {
          justify-content: center;
          align-items: flex-end;

          img {
            max-width: 50% !important;
          }
        }

        &.right_bottom {
          justify-content: flex-end;
          align-items: flex-end;
        }
      }
    }

    .productTen__quickView__compare__block {
      margin: 10px 0px 0px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0px 20px 0px;

      .productTen__button__block {
        border: none;
        outline: none;
        background: none;
        display: flex;
        align-items: center;

        .productTen__button__icon {
          margin: 0px 8px 0px 0px;
          width: 13px;
        }

        p {
          font-size: 14px;
        }

        svg {
          margin-right: 3px;
        }

        &:hover {
          color: #dc3a1a;
          transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;

          p {
            text-decoration: underline;
            text-decoration-color: #cdcdcd;
            color: #dc3a1a;
          }
        }
      }
    }
  }

  .productTen__middle__block {
    padding: 15px;

    .productTen__name__link {
      text-decoration: none;
      color: #000000;
      margin: 0px;

      .productTen__name {
        font-family: 'SSTbold';
        font-weight: bold;
        margin: 0px 0px 10px;
        font-size: 15px;
        line-height: 1.4;
        min-height: 45px;
      }
    }

    .plp__tamara__box {
      display: inline-flex;
      width: 100%;
      flex: 0 0;
      max-width: 100%;
      justify-content: flex-start;
    }

    .size__button__block {
      margin: 0px 0px 10px 0px;
      min-height: 51px;

      .size__button__listing {
        padding: 10px 0px;
        display: flex;
        align-items: center;      
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        flex: 0 0 100%;
        max-width: 100%;
        
        button {
          position: relative;
  
          &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #000000;
            border-radius: 3px;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 50%;
            transform-origin: 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
          }
        }
  
        .size__button__active {
          font-size: 12px;
          font-family: 'SSTmedium';
          border-radius: 3px;
          color: #ffffff;
          padding: 6px 8px;
          line-height: 1.26;
          border: 1px solid #000000;
          outline: none;
          margin: 0px 10px 0px 0px;
          z-index: 2;
          position: relative;
  
          &:before {
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            z-index: -1;
          }
        }
  
        .size__button {
          font-size: 12px;
          font-family: 'SSTmedium';
          border: 1px solid #707070;
          border-radius: 3px;
          padding: 6px 8px;
          line-height: 1.26;
          background-color: #ffffff;
          outline: none;
          margin: 0px 10px 0px 0px;
        }
      }
      
    }

    .delivery-time-area {
      margin-bottom: 8px;
      display: flex;

      .text4,
      .heading7 {
        font-size: 12px;
        line-height: 1;
        margin-bottom: 8px;
      }
    }

    .productNine__feature__block {
      padding: 10px 0px;
      border-bottom: 1px solid #d9d9d9;
      margin: 0px 0px 10px;

      .feature__text__block {
        display: flex;
        align-items: center;
        margin: 0px 0px 10px;

        .feature__bullet {
          width: 6px;
          height: 6px;
          border-radius: 10px;
          margin: 0px 10px 0px 0px;
          background-color: #c8c8c8;
        }
      }
    }
  }

  .productTen__right__block {
    padding: 15px;

    .plp__mezza__reward {
      img {
        // width: 80px;
        width: auto;
        max-height: 26px;
        max-width: 80px;
      }
      div{
          &:last-child{
              text-align: right;
          }
      }
    }

    @media screen and (max-width: 1400px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .product-list-view-pricebox {
      margin-bottom: 15px;
      text-align: right;
      min-height: 40px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;

      @media screen and (max-width: 1400px) {
        margin-bottom: 0px;
      }

      span {

        // &:first-child { margin-right: 8px !important; }
        &.text3 {
          position: relative;
          width: fit-content;
          line-height: 1.2;

          &::after {
            content: " ";
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #666666;
            -webkit-transform: translateY(-8px) rotate(-6deg);
            transform: translateY(-8px) rotate(-6deg);
            bottom: 0;
            left: 0;
          }
        }
      }
    }

    .addToCart__button {
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      font-family: 'SSTbold';
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 8px 10px;
      background-color: #dc3a1a;
      // box-shadow: 0px 1px 3px 0px #928d8dde;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 1400px) {
        width: 180px;
      }

      .addToCart__icon {
        margin: 0px 10px 0px 0px;
        width: 16px;
      }

      &:hover,
      &:focus {
        -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        opacity: 0.8;
      }
      .btn__spinner{
        min-height: 21px;
        @media screen and (max-width:1366px) {        
        }
        @media screen and (max-width:580px) {          
        }
      }
    }

    // .notifyMe__button{
    //   border-radius: 5px;
    //   color: #ffffff;
    //   font-size: 14px;
    //   font-family: 'SSTbold';
    //   font-weight: bold;
    //   letter-spacing: 0.85px;
    //   padding: 8px 10px;
    //   background-color: #ff8800;
    //   // box-shadow: 0px 1px 3px 0px #928d8dde;
    //   text-align: center;
    //   text-transform: uppercase;
    //   text-decoration: none;
    //   cursor: pointer;
    //   display:flex;
    //   align-items: center;
    //   justify-content: center;
    // }
    .outofStock__button {
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      font-family: 'SSTbold';
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 8px 15px;
      background-color: #a7a7a7;
      text-transform: uppercase;
      cursor: default;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0px;

      @media screen and (max-width: 1400px) {
        width: 180px;
      }
    }

    .notifyMe__button {
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      font-family: 'SSTbold';  
      text-transform: uppercase;
      letter-spacing: 0.85px;
      padding: 8px 15px;
      line-height: 1.5;                
      background-color: #FE8B21;       
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0px;
      
      &:hover,&:focus {
        -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        opacity: 0.8;
      }
      @media screen and (max-width: 1400px) {
        width: 180px;
      }
      @media screen and (max-width:992px) {
        font-size: 14px;
        padding: 6px 10px;
        line-height: 1.1;
      }
      @media screen and (max-width:480px) {
        font-size: 13px;
        padding: 6px 10px;
      }

      img {
        margin: 0px 8px 0px 0px;
        height: 14px;
        width: auto;
        vertical-align: sub;     
        
        @media screen and (max-width:480px) {
          margin: 0px 6px 0px 0px;
          height: 13px;
        }
      }      
    }
  }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .productTen__block {
    .productTen__left__block {
      .productTen__header {
        .productTen__new__sticker__block {
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 15px;
          .productTen__new__sticker__text {
            font-family: 'SSTArabicBold';
          }
        }

        .quality__favourite__block {
          padding: 8px 0px 0px 8px;

          .quality__icon {
            width: 18px;
            margin: 0px 0px 0px 8px;
          }
        }
      }

      .productTen__quickView__compare__block {
        svg {
          margin-right: 0px;
          margin-left: 3px;
        }
      }

      .product__pics__area {
        .productTen__image__slider {
          .slick-slider {
            .slick-next {
              left: auto;
            }
          }
        }
      }
    }

    .productTen__right__block {
      .product-list-view-pricebox {
        span {
          // &:first-child{ 
          //   margin-right: 0px !important;
          //   margin-left: 8px !important; 
          // }        
        }
      }
      .addToCart__button,.outofStock__button,
      .notifyMe__button,.pre-order {
        font-family: 'SSTArabicBold';
        font-weight: bold;
        font-size: 14px;
      }
      .addToCart__button {
        .addToCart__icon {
          margin: 0px 0px 0px 10px;
        }
      }
      .notifyMe__button{

        @media screen and (max-width:1200px) {
          font-size: 14px;
        }
        img{
          margin: 0px 0px 0px 8px;
          
          @media screen and (max-width:480px){
            margin: 0px 0px 0px 6px;
          }
        }                  
      }
      .size__button__block {
        .size__button__listing {
          .size__button{
            margin: 0px 0px 0px 8px;
            &:last-child{
              margin: 0px;
            }
          }
        }
      }
    }

    .productTen__middle__block {
      .productTen__name__link {
        .productTen__name {
          font-family: 'SSTArabicBold';
        }
      }
    }
  }


}

/*-------------------------- Arabic view End ------------------------*/