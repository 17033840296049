.page {
  display: flex;
  flex-direction: column;
  padding: 28px;
  font-weight: 300;
  // @include gap(20px, "column");
  div[class="table-responsive"] {
    overflow-x: auto;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    table {
      border-spacing: 0px !important;
      width: 100%;

      thead {
        background: #792BDD;
        height: 60px;

        th {
          color: white;
          font-weight: 400;
          font-size: 15px;
        }
      }

      td {
        text-align: center !important;
        padding: 5px;
        font-size: 15px;
      }

      tbody {
        tr {
          height: 64px;
        }

        tr[class="gray-bg-warr"] {
          background-color: #E7E7E7;
        }

        tr {
          td:nth-child(1) {
            max-width: 250px;
          }
        }
      }

      .grayBg {
        background-color: red;
      }
    }
  }

  div[class="warranty-note"] {
    h4 {
      margin: 20px 0px;
      color: #422468;
      font-size: 24px;
      line-height: 18px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      color: #9C4FFF;
    }
  }

  h1 {
    margin: 25px 0px;
    position: relative;
    font-size: 30px !important;
    font-weight: bold;
    color: #422468;
  }

  h3 {
    margin: 10px 0px;
    color: black;
    font-weight: 500;
    font-size: 18px;
  }

  p {
    margin: 10px 0px;
    font-weight: 400;
    font-size: 14px;
  }

  &.campaign {
    padding: 0;
    // @include gap(0, "column");
  }

  @media (min-width: 1200px) {
    padding: 65px 0;

    h1 {
      font-size: 32px;
    }
  }
}

html[dir="rtl"] {
  .page {
    h1 {
      &:after {
        right: 0;
        left: unset;
      }
    }
  }
}
