.address__popup__block {
  margin: 0px 0px 0px 0px;
  width: 900px;
  max-width: 100%;
  
  .address__title__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;
    border-bottom: 1px solid #818181;
    background-color: #ffffff;
    @media screen and (max-width:992px) {
      padding: 15px 20px;
    }
    .cancel__button {
      cursor: pointer;
    }
  }
  .address__content__block {
    background-color: #ffffff;
    padding: 0px;
    position: relative;
    z-index: 5;
    // max-height: 85vh;
    // overflow: scroll;
    // overflow-x: hidden;

    .address__form__field__row {
      margin: 0px 0px 0px 0px;      
      width: 100%;
      .main__form__field__block {
        margin: 0px 0px 10px;
        .profile__mobile__form__field{
          width: 100%;
          border: 1px solid #c8c8c8;
          border-radius: 4px;
          padding: 7px 16px 6px 50px;
        }
        ._customselect {
          height: 37px;
          background-color: #ffffff;
        }
        .form__label {
        }
        .field__block {          
          display: flex;
          justify-content: space-between;
          align-items: center;
          .form__field {
            border: 1px solid #c8c8c8;
            border-radius: 4px;
            padding: 6px 16px;
            width: 100%;
            outline: none;            
            font-size: 16px;
            letter-spacing: 0.3px;
            &:focus {
              border-color: #000000;
            }
          }
        }
        .invalid__message {
          color: red;
          margin: 5px 0px 0px;
          font-size: 14px;
          line-height: 1.1;
        }
      }
    }
    .addressType__block__fieldbox{
      margin: 0px 15px 6px;
      .addressType__block{
        .delivery__selection__text{
          cursor: pointer;
        }
      }      
    }
    .form-switch {
      margin: 10px 15px 10px;
      @media screen and (max-width:992px) {
        margin: 10px;
      }
    }
    
      .addressType__block{
        input[type="radio"]{
          accent-color: #ff4f04;
          width: 15px;
          height: 15px;
          cursor: pointer;
        }        
      }
  }
}
.address__form__button__block {
  margin: 0px;
  padding: 0px 0px 0px 10px;
  text-align: right;
  display: inline-block;
  width: 100%;

  @media screen and (max-width:992px) {
    text-align: center;
    margin: 0px auto;
  }
  @media screen and (max-width:400px) {
    padding-left: 0px;
    text-align: center;
  }
  
  .form__save__button {
    text-align: center;
    font-size: 14px;
   font-family: 'SSTbold';
    font-weight: bold;
    border-radius: 5px;
    min-width: 150px;
    color: #ffffff;
    padding: 8px 20px;
    background-color: #dc3a1a;
    margin: 0px 12px 0px 0px;
    outline: none;
    border: none;
    letter-spacing: 0.3px;
    cursor: pointer;
    text-transform: uppercase;
    &:hover{
      box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
      transform: translateY(-2px);
      transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      opacity: 0.8;
    }
    @media screen and (max-width:350px) {
      margin-right: 0px !important;
      width: 70.5%;
      margin-bottom: 10px;
    }
    .btn__spinner{
      min-height: 21px;        
    }
  }
  .form__cancel__button {
    cursor: pointer;
    text-align: center;
    min-width: 150px;
    font-size: 14px;
    font-family: 'SSTbold';
    font-weight: bold;
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 7px 20px;
    color: #808080;
    background-color: #ffffff;
    margin: 0px 12px 0px 0px;
    outline: none;
    letter-spacing: 0.3px;
    text-transform: uppercase;
   
    &:hover{
      background-color: #808080;
      color: #ffffff;
      box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
      transform: translateY(-2px);
      transition: all 0.4s ease-in-out;
      -webkit-transition: all 0.4s ease-in-out;
      opacity: 0.8;
    }
    @media screen and (max-width:992px) {
      margin-right: 0px;
    }
    @media screen and (max-width:350px) {           
      width: 70.5%;
    }
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .address__popup__block {
    .address__content__block{
      .form-switch {
        float: right;
        display: flex;
        padding-left: 0px !important;
        padding-right: 2.7em !important;
        input[type="checkbox"]{
          margin-right: -2.5em;
          margin-left: 0px;
        }
        .heading7{
          margin-right: 10px !important;
        }
      }
      .delivery__selection__text{
        margin-left: 0px !important;
        margin-right: 12px !important;

        &:nth-child(2){
          margin-left: 10px !important;
        }
      }
      
      
    }    
    .address__form__field__row {
      .main__form__field__block {
        .profile__mobile__form__field{
          padding: 7px 50px 6px 15px;
          direction: ltr;
          text-align: right;
        }
      }
    }
  }

  .address__form__button__block {
        
    .form__save__button {
      margin-left: 0px !important;
      font-family: 'SSTArabicBold';
      @media screen and (max-width:992px) {
        margin-left: 8px !important;
        margin-right: 0px !important;  
      }          
      
    }
    .form__cancel__button{
      font-family: 'SSTArabicBold';
    }
  }

  .modal-header .btn-close{
    margin: 0px !important;
  }
}

/*-------------------------- Arabic view end ------------------------*/