.profile__page__container {
  padding: 0px 12px 20px;
  background-color: #f5f5f5;

  .profile__page__block {
    padding: 0px 0px;
    width: 1420px;
    margin: 0px auto;

    .profile__page__title__block {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000000a;
      border: 1px solid #f1f1f1;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .heading3 {
        @media(max-width:992px) {
          font-size: 20px;
        }
      }
    }

    .profile__page__content__block {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000000a;
      border: 1px solid #f1f1f1;
      margin: 0px auto;

      .profile__tab__button__block {
        padding: 16px;
        margin: 0px 0px 0px;
        padding-bottom: 0px;
        @media(max-width:992px) {
          display: flex;
          overflow: scroll;
          overflow-y: hidden;
          margin: 0px;

          &::-webkit-scrollbar {
            height: 10px;
          }

          &::-webkit-scrollbar-track {
            background-color: #A1A1A1;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #EFEFEF;
            border-radius: 10px;
          }
        }

        .profile__page__button__active {
          text-align: center;
          font-size: 14px;
          font-family: 'SSTbold';
          font-weight: bold;
          border-radius: 5px;
          min-width: fit-content;
          color: #ffffff;
          padding: 10px 25px;          
          border: 1px solid #000000;
          margin: 0px 12px 10px 0px;
          outline: none;          
          letter-spacing: 0.3px;
          cursor: pointer;
          position: relative;
          z-index: 1;
          // -webkit-transition-property: color;
          // transition-property: color;
          // -webkit-transition-duration: 0.5s;
          // transition-duration: 0.5s;          

          @media(max-width:992px) {
            font-size: 13px;
          }
          
            &::before{
              content: "";
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              border-radius: 5px;
              background-color: #000000;
              -webkit-animation: tabEffect .2s;
              animation: tabEffect .2s;          
              // -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
              // transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);    
              
              // -webkit-transition-property: transform;
              // transition-property: transform;
              // -webkit-transition-duration: 0.5s;
              // transition-duration: 0.5s;
              // -webkit-transition-timing-function: ease-out;
              // transition-timing-function: ease-out;  
            }
            @keyframes tabEffect {
              0%{
                -webkit-transform: scaleY(0);
                transform: scaleY(0);
                -webkit-transform-origin: 50% 0;
                transform-origin: 50% 0;                
              }
              100%{
                -webkit-transform: scaleY(1);
                transform: scaleY(1);
                -webkit-transform-origin: 50% 0;
                transform-origin: 50% 0;                
              }
            }
        }

        .profile__page__button {
          cursor: pointer;
          text-align: center;
          min-width: fit-content;
          font-size: 14px;
          font-family: 'SSTbold';
          font-weight: bold;
          border: 1px solid #707070;
          border-radius: 5px;
          padding: 10px 25px;
          color: #808080;
          background-color: #ffffff;
          margin: 0px 12px 10px 0px;
          outline: none;
          letter-spacing: 0.3px;

          
          @media(max-width:992px) {
            font-size: 13px;
          }
                    
        }
      }

      .address__select__block {
        border-bottom: none;
        margin: 0px;
        padding: 0px 0px 20px;
        @media(max-width:992px){
          padding-top: 10px;
        }
      }

      .newAddress__block {
        width: 100%;
        max-width: 100%;

        .newAddress__title__block {
          padding-bottom: 5px;
          border-bottom: 1px solid #D1D1D1;
          margin: 0px 10px 12px 10px;
          padding-left: 0px;
        }

        .newAddress__form__field__row {
          max-width: 67%;

          @media(max-width:992px) {
            max-width: 100%;
            height: auto;
            margin-bottom: 0px;
          }

          .main__form__field__block {

            @media(max-width:992px) {
              margin-bottom: 10px;
            }
          }
        }

        p.heading2 {
          font-size: 20px;
        }
      }

      .personal__block__disable {
        display: none;
      }

      .personal__block {
        display: block;
        @media(max-width:991px) {
          margin-top: 20px;

        }
        .profile__email__block,
        .profile__mobile__block,
        .profile__firstname__lastname {
          @media(max-width:992px) {
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 10px;
          }

          .main__form__field__block {

            @media(max-width:992px) {
              height: auto;
              margin: 0px 0px 10px 0px;
              max-width: 100%;

              &:last-child {
                margin: 0px 0px 15px 0px;
              }

            }

            .heading7 {

              @media(max-width:992px) {
                font-size: 14px;
                line-height: 1;
              }
            }

            .delivery__input__check {
              width: 20px;
              height: 20px;
            }

            .delivery__selection__text {
              font-size: 15px;
            }

          }
        }

        .profile__preferences__block {

          @media(max-width:992px) {
            margin-bottom: 0px;
          }

          .preferences__select__block {
            @media(max-width:992px) {
              display: flex;
              align-items: center;
              margin: 0px 0px 0px 0px;
              flex: 0 0 100%;
              flex-wrap: wrap;
              flex-direction: row;
            }

            .preferences__select__input__check {

              @media(max-width:992px) {
                // height: 25px;
                // width: 25px;
                margin-left: 1px;
                // margin-bottom: 10px;
                accent-color: #DC3A1A;
              }
            }

            .preferences__select__text {
              font-size: 15px;
              width: 95%;
              max-width: calc(100% - 43px);
              // margin-bottom: 10px;
            }

          }
        }

        .profile__form__button__block {
          @media(max-width:992px) {
            margin-top: 20px;
            // text-align: center;
          }
        }

      }

      .address__block__disable {
        display: none;
      }

      .address__block {
        display: block;
        .selected__address__inner__block,.address__inner__block {
          padding: 10px 10px 30px 30px;
          min-height: 91%;

          @media (min-width:1280px) and (max-width:991px) {
              padding: 10px 10px 20px 20px;
              .full__address{
                p{ min-height: 80px; }
              }
          }
          
          @media(max-width:992px){  padding: 10px 10px 20px 20px; }
          .address__title__block {
              align-items: flex-start;
            .white__address__tag{
              @media(max-width:992px){ line-height: 1; }
            }
          }
          .inner__address__button__block{

            .edit__button,.delete__button {
              img{
                width: 33px;
                @media(max-width:992px){ width: 26px; }
              }
            }
          }
          .address__tag {
            .heading7{
              color: #808080;
              @media(max-width:992px){
                font-size: 14px;
              }
            }
            @media(max-width:992px){
              line-height: 1;
            }
          }
          .full__address {
            .text4 {
              @media(max-width:992px){
                font-size: 13px;
              }
            }
          }

        }
        .address__popup__block{
          @media(max-width:992px){
            max-width: 100%;
          }          
          .address__content__block{
            padding: 15px 10px;
            .address__form__field__row {
              height: auto;
              margin-bottom: 0px;
              .main__form__field__block {
                margin: 0px 0px 20px;
              }
            }
            .form-check.form-switch {
              margin: 0px 15px 20px;
            }
          }
        }
      }

      .password__block__disable {
        display: none;
      }

      .password__block {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .profile__page__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .profile__page__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}

@media screen and (max-width: 1200px) {
  .checkout__right__block {
    padding: 0px !important;
    margin: 10px 0px;
  }
}

@media screen and (max-width: 992px) {
  .profile__page__block {
    width: 100% !important;
    max-width: 100% !important;
  }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .profile__page__container{
    .profile__page__block{
      .profile__page__content__block{
        .personal__block {
          .profile__preferences__block {
            .preferences__select__block {
              .preferences__select__input__check {
                margin-right: 0px;
                margin-left: 15px;
              }
              .preferences__select__text{
                font-family: 'SSTArabic';
              }
            }
          }
        }
        .profile__tab__button__block{
          .profile__page__button__active{
            margin: 0px 0px 10px 12px;
            font-family: 'SSTArabicBold';
            font-size: 13px;
          } 
          .profile__page__button{
            margin: 0px 0px 10px 12px;
            font-family: 'SSTArabicBold';
            font-size: 13px;
          }
        }
        .newAddress__block{
          p:nth-child(7) {
            margin: 0px 14px 20px 0px !important;
          }
        }
      }    
      .profile__page__title__block {
        .heading3,p{
          font-size: 20px;
        }
      }
    }    
  }
  .inner__personal__block .profile__email__block .main__form__field__block, 
  .inner__personal__block .profile__mobile__block .main__form__field__block, 
  .inner__personal__block .profile__firstname__lastname .main__form__field__block {
      width: 370px;
      margin: 0px 0px 10px 20px;
  }
  .inner__personal__block .profile__gender__block .delivery__input__check{
      margin: 0px 0px 0px 10px;
  }
  .inner__personal__block .profile__gender__block .delivery__selection__text {
    margin: 0px 0px 0px 20px;
  }
  .inner__personal__block .profile__email__block .main__form__field__block .profile__mobile__form__field,
  .inner__personal__block .profile__mobile__block .main__form__field__block .profile__mobile__form__field,
  .inner__personal__block .profile__firstname__lastname .main__form__field__block .profile__mobile__form__field {
      padding: 6px 50px 7px 15px;
      direction: ltr;
      text-align: right;
  }
  .main__form__field__block .react-tel-input .selected-flag {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }  

}

/*-------------------------- Arabic view End ------------------------*/