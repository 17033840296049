$mobileBreakpoint: 1200px;

.wrapper {
  display: block;
  // margin-right: 5px;
  margin-left: 0;
  position: relative;
  flex: 0 0 100%;
  transition: all ease 0.25s;
  border-radius: 12px;
  overflow: hidden;

  &.isFullWidth {
    border-radius: 0px;
  }

  &.isFullWidth img {
    border-radius: 0px;
  }

  img {
    margin: 0;
    width: 100%;
    height: 100%;
    min-height: 120px;
    overflow: hidden;
    border-radius: 12px;
    object-fit: cover;
    object-position: center center;
  }

  @media (min-width: $mobileBreakpoint) {
    flex: 1 1 100%;

    img {
      height: 100%;
      min-height: 222px;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

[dir="rtl"] {
  .wrapper {
    margin-right: 0;
    margin-left: 5px;

    @media (min-width: $mobileBreakpoint) {
      flex: 1 1 100%;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}