.productEleven__block {
    width: 200px;
    margin: 10px 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px 40px 0px 0px;
    .productEleven__image__block {
      width: 110px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin: 0px 0px 10px;
      .productEleven__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .productEleven__product__price {
      font-size: 18px;
     font-family: 'SSTbold';
font-weight: bold;
      margin: 0px;
    }
    .productEleven__product__oldPrice {
      font-size: 18ox;
      font-family: "SSTregular";
      color: #808080;
      text-decoration: line-through;
      margin: 0px 0px 20px;
    }
    .productEleven__rating__block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      padding: 20px 0px 0px;
      .rating__block {
      }
      .pd__product__rating__icon {
        margin: 6px 10px 0px;
      }
      .productEleven__rating__text__block {
        display: flex;
        align-items: center;
        padding: 10px 0px;
        flex-wrap: wrap;
        .productEleven__product__rating {
          margin: 0px 10px 0px 0px;
  
          font-size: 14px;
          color: #dc3a1a;
         font-family: 'SSTbold';
font-weight: bold;
        }
        .productEleven__product__totalRating {
          margin: 0px;
        }
      }
    }
    .productEleven__select__block {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .productEleven__check__icon {
        display: block;
        width: 22px;
        height: 22px;
      }
      .productEleven__check__icon__disable {
        display: none;
      }
    }
    .addToCart__button{
      border-radius: 5px;
        color: #ffffff;
        font-size: 15px;
        font-family: 'SSTbold';
        font-weight: bold;
        letter-spacing: 0.85px;
        padding: 8px 15px;
        background-color: #DC3A1A;    
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 6px;
        .addToCart__icon{
          margin: 0px 10px 0px 0px; 
          width: 19px;
        }
      }
      .outofStock__button{
        font-family: "SSTmedium";
        font-size: 14px;
        text-align: center;
        width: 100%;
        color: white;
        background-color: #a7a7a7;
        padding: 8px 5px;
        cursor: pointer;
        border-radius: 5px;
      }
  }
  @media screen and (max-width: 1706px) {
    .productEleven__block {
      width: 100% !important;
    }
  }
  @media screen and (max-width: 1200px) {
    .productEleven__block {
      width: 80% !important;
    }
  }
  @media screen and (max-width: 576px) {
    .productEleven__block {
      width: 100% !important;
    }
  }
  