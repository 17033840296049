.product__list__page__container {
  background-color: #ffffff;
  padding: 0px 12px 0px;
  height: auto;
  .product__list__page__block {
    background-color: #ffffff;
    padding: 0px 0px;
    width: 1420px;
    margin: 0px auto;
    .plp__newArrival__block {

      width: 100%;
      padding: 0px;
      margin: 0px 0px 50px;
    }
    .PLP_dsk_noProduct_box {
      background-color: #f5f4f4;
      padding: 20px;
      min-height: 51vh;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }
}
.plp__product__popup__container__disable {
  display: none;
}
.plp__product__popup__container {
  position: fixed;
  top: 70px;
  margin: 0px;
  width: 100%;
  z-index: 2;
  height: 94vh;
  overflow-y: hidden;
  background-color: #02020250;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}
.plp__compare__popup__container__disable {
  display: none;
}
.plp__compare__popup__container {
  position: fixed;
  top: 70px;
  margin: 0px;
  width: 100%;
  z-index: 2;
  height: 94vh;
  overflow-y: hidden;
  background-color: #02020250;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}

@media screen and (max-width: 1450px) {
  .product__list__page__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 992px) {
  .product__list__page__block {
    width: 100% !important;
  }
}