.checkout__signin__form__area,.mb_checkout__signin__form__area {
    position: relative;
    top: 21px;
    right: 0px;
    z-index: 2;
    background-color: #ffffff;
    width: 80%;
    max-width: 610px;
    padding: 20px 18px;
    // box-shadow: 0px 0px 9px #d9d9d9;
    border-radius: 13px;
    margin: 0px ;
    z-index: 2;
    @media screen and (max-width:1360px) {
       width: 460px;
       max-width: 460px;
    }
    @media screen and (max-width:992px) {
      right: 0px;
      width: 390px;
      max-width: 390px;
    }
    @media screen and (max-width:480px) {
        max-width: 95vw;
        margin: 0px 2vw;
    }
    .signin__button {
      background-color: #dc3a1a;
      // box-shadow: 0px 3px 6px #00000005;
      border-radius: 6px;
      color: #ffffff;
      outline: none;
      border: none;
      width: 100%;
      font-size: 16px;
      font-family: "SSTbold";
      font-weight: bold;
      padding: 13px 10px;
      margin: 0px 0px 0px;

      &:hover,
      &:focus {
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        opacity: 0.8;
      }
      .btn__spinner{
        min-height: 16px;
        @media screen and (max-width:1366px) { }
        @media screen and (max-width:580px) { }
      }
    }

    .signup__button {
      background: #dc3a1a;
      // box-shadow: 0px 3px 6px #00000005;
      // border: 2px solid #000000;
      border-radius: 6px;
      color: #ffffff;
      outline: none;
      width: 100%;
      font-size: 16px;
      font-family: "SSTbold";
      font-weight: bold;
      padding: 13px 10px;
      margin: 0px;

      &:hover,
      &:focus {
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        opacity: 0.8;
      }
      .btn__spinner{
        min-height: 16px;
        @media screen and (max-width:1366px) { }
        @media screen and (max-width:580px) { }
      }
    }

    .signin__signup__popup__closeBtn{
      display: none;
      width: 20px;
      height: 20px;
      padding: 4px;
      color: #ffffff;
      justify-content: center;
      align-items: center;
      background-color: #d1d1d1;
      border-radius: 10px;
      margin: -10px 0px 8px -7px;
      border: none;

      svg {
        width: 100%;
        height: auto;
      }
    }
    .nav-tabs{
      padding: 3px;
      background-color: #000000;
      border-radius: 8px;
      border: none;

      .nav-item{
        width: 50%;
        .nav-link{
          margin-bottom: 0px;
          border: none;
          border-radius: 6px;
          width: 100%;                        
          font-size: 15px;
          font-family: "SSTmedium";
          color: #ffffff;
          line-height: 1.2;
          &.active{
            background-color: #ffffff;
            color: #000000;
          }
        }
      }
    }
    .tab-content{
          // max-height: 74vh;
          overflow: scroll;
          overflow-x: hidden;
          @media screen and (max-width:1200px) {
            max-height: fit-content;
          }
       @media screen and (max-width:767px) {          
          overflow: scroll;
          overflow-x: hidden;
       }
       @media screen and (max-height:767px) {
          // max-height: 55vh;
       }
      .tab-pane{
        &.active{
          border: none !important;
        }
      } 
    }
  }
/*-------------------------- Arabic view start ------------------------*/
    body[dir="rtl"].arabicFonts {
        .signin__signup__popup {
            right: auto;
            left: 0px;
            @media screen and (max-width:992px) {
                z-index: 5;
            }
            @media screen and (max-width:480px) {
              left: -2.5vw;
            }
            .nav-tabs {
              .nav-item {
                .nav-link{
                  font-family: 'SSTArabicMedium';
                }
              }
            }
            .signin__button,.signup__button,
            .signin__signup__popup__closeBtn {
              font-family: 'SSTArabicBold';
            }
          }
    }

/*-------------------------- Arabic view End ------------------------*/