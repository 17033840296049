.heading1 {
  font-size: 36px;
  font-family: 'SSTbold';
  font-weight: bold;

}

.heading2 {
  font-size: 30px;
  font-family: 'SSTbold';
  font-weight: bold;

}

.heading3 {
  font-size: 24px;
  font-family: 'SSTbold';
  font-weight: bold;

}

.heading4 {
  font-size: 22px;
  font-family: 'SSTbold';
  font-weight: bold;

}

.heading5 {
  font-size: 20px;
  font-family: 'SSTbold';
  font-weight: bold;

}

.heading6 {
  font-size: 18px;
  font-family: 'SSTbold';
  font-weight: bold;
  line-height: 20px;

}

.heading7 {
  font-size: 15px;
  font-family: 'SSTbold';
  font-weight: bold;

}

.label-bold {
  font-size: 15px;
  font-family: 'SSTbold';
  font-weight: bold;
}

.text1 {
  font-size: 20px;
  font-family: "SSTregular";
}

.text2 {
  font-size: 18px;
  font-family: "SSTregular";
}

.text3 {
  font-size: 16px;
  font-family: "SSTregular";
  overflow: hidden;
}

.text4 {
  font-size: 14px;
  font-family: "SSTregular";
}

.text5 {
  font-size: 12px;
  font-family: "SSTregular";
}

.text6 {
  font-size: 10px;
  font-family: "SSTregular";
}

@media screen and (max-width: 1600px) {
  .heading1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 992px) {
  .heading1 {
    font-size: 24px;
  }
}

@media screen and (max-width: 576px) {
  .heading1 {
    font-size: 18px;
  }

  .heading3 {
    font-size: 18px;
  }

  .text2 {
    font-size: 14px;
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .heading1,.heading2,.heading3,.heading4,.heading5,.heading6,.heading7,.label-bold {
    font-family: 'SSTArabicBold';
    font-weight: bold;
  }

  .text1,.text2,.text3,.text4,.text5,.text6 {
    font-family: 'SSTArabic';
    font-weight: normal;
  }

}

/*-------------------------- Arabic view end ------------------------*/