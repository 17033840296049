.ourBlogs__container {
  margin: 50px 0px;
  .ourBlogs__block {
    width: 1420px;
    margin: 0px auto;
    padding: 0px;
    .section__title__block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .section__title {
       font-family: 'SSTbold';
font-weight: bold;
        font-size: 40px;
      }
      .viewall__button {
        background: #ffffff00 0% 0% no-repeat padding-box;
        border: 2px solid #000000;
        border-radius: 3px;
        outline: none;
        color: #000000;
        text-align: center;
        font-size: 16px;
        width: 125px;
        height: 50px;
        text-transform: uppercase;
       font-family: 'SSTbold';
font-weight: bold;
      }
    }
    .ourBlogs__row {
      .blog {
        .blog__image__block {
          width: 100%;
          height: 349px;
            overflow: hidden;
          .blog__image {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
        .blog__title {
            margin: 10px 0px;
            font-size: 18px;
           font-family: 'SSTbold';
font-weight: bold;
        }
        .blog__description {
            margin: 0px;
            font-size: 15px;
            font-family: "SSTregular";
        }
        .blog__date__block {
            display: flex;
            align-items: center;
            margin: 10px 0px;
          .blog__calender__icon {
          }
          .blog__Date {
              margin: 0px;
              font-size: 14px;
            font-family: "SSTregular";
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .ourBlogs__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .ourBlogs__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 992px) {
  .ourBlogs__block {
    width: 100% !important;
    min-width: 100% !important;
    margin: 0px auto;
  }
}
@media screen and (max-width: 768px) {
  .ourBlogs__block {
    width: 100% !important;
  }
}
@media screen and (max-width: 576px) {
  .ourBlogs__block {
    width: 100% !important;
  }
}
