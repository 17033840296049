.main__rating__block {
  display: flex;
  align-items: center;
  padding: 0px 0px 5px;
  margin-top: 10px;

  .product__rating__icon {
    margin: 0px;
    width: 15px;
    height: 15px;
  }

  .product__rating {
    margin: 0px 6px 0px 6px;
    font-size: 13px;
    color: #dc3a1a;
   font-family: 'SSTbold';
font-weight: bold;
  }

  .product__totalRating {
    margin: 0px;
    font-size: 13px;
    color: #707070;
    font-family: "SSTregular";
  }
}