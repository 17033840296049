.otpModel__container {
  margin: 10px 0px 0px 0px;
  width: 100%;
  @media screen and (max-height:740px) {
    padding-right: 10px;
    max-height: 70vh;
    overflow: scroll;
    overflow-x: hidden;
  }
  @media screen and (max-height:610px) {
    padding-right: 10px;
    max-height: 64vh;
  }

  .otpModel__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid #818181;
    background-color: #ffffff;

    .cancel__button {
      cursor: pointer;
      width: 19px;
      height: 19px;
    }
  }

  .otpModel__content {
    padding: 10px 0px 0px 0px;
    background-color: #ffffff;

    .otp__number__block {
      margin: 0px 0px 6px 0px;

      .change__button {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000005;
        border: 2px solid #000000;
        border-radius: 5px;
        color: black;
        outline: none;
        font-size: 16px;
        font-family: 'SSTbold';
        font-weight: bold;
        padding: 6px 20px;
        margin: 0px 0px 0px 20px;
      }
    }

    .main__form__field__block {
      margin: 0px 0px 10px;

      .form__label {}
      .heading7{
        @media screen and (max-width:1400px) {
          margin-bottom: 5px !important;
        }
        margin-bottom: 5px;
      }
      .field__block {
        border: 1px solid #c8c8c8;
        border-radius: 4px;
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form__field {
          width: 100%;
          outline: none;
          border: none;
          font-size: 16px;
          letter-spacing: 0.3px;
        }
      }

      .invalid__message {
        color: red;
        margin: 5px 0px 0px;
      }
    }

    .signup__button {
      background: #dc3a1a 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border-radius: 5px;
      color: white;
      outline: none;
      border: none;
      width: 100%;
      font-size: 16px;
      font-family: 'SSTbold';
      font-weight: bold;
      padding: 16px 0px;
      line-height: 1;
    }

    .resendOtp__msg {
      font-weight: bold;
      margin-top: 10px;
      text-align: center;
      .timer{
        color: green;
      }
      p{
        margin-bottom: 9px;
      }
    }
    .resendOtp__button {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border: none;
      border-radius: 5px;
      color: black;
      outline: none;
      width: 100%;
      font-size: 16px;
      font-family: 'SSTbold';
      font-weight: bold;
      padding: 10px 0px;
      margin: 0px 0px 0px;
      line-height: 1;
    }

    .otp__or__block {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      margin: 0px 0px 15px;

      .otp__mid__line {
        height: 1px;
        width: 100%;
        background-color: #dbdbdb;
        z-index: 0;
        transform: translateY(-20px);
      }

      .otp__or__text__block {
        background-color: #f6f6f6;
        width: fit-content;
        padding: 8px;
        border-radius: 50%;
        z-index: 1;

        .otp__or__text {
          font-size: 14px;
          font-family: 'SSTbold';
          font-weight: bold;
          margin: 0px;
        }
      }
    }

    .signin__with__password__button {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border: 2px solid #000000;
      border-radius: 6px;
      color: black;
      outline: none;
      width: 100%;
      font-size: 16px;
      font-family: 'SSTbold';
      font-weight: bold;
      padding: 13px 10px;
      margin: 0px 0px 0px;
      line-height: 1;
      .btn__spinner{
        min-height: 16px;
      }
    }

    .google__signin__button {
      background: #ff3d2b 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border-radius: 5px;
      color: white;
      outline: none;
      border: none;
      width: 100%;
      font-size: 16px;
      font-family: "SSTregular";
      padding: 13px 10px;
      margin: 0px 0px 10px;
      display: flex;
      align-items: center;

      .signin__button__icon {
        margin: 0px 30px 0px 0px;
      }
    }
  }
}