.Probox__discount__labelBox{
    padding: 0px 6px 0px 0px;
    background-color: #dc3a1a;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 30px;
    &:empty {        
        display: none;
    }
    @media screen and (max-width:580px) {
        z-index: 2;
        position: relative;
        min-height: 25px;
    }
    .timer-iconBox{        
        max-width: 26px;
        min-width: 6px;
        &:empty {
            visibility: hidden;
        }
        @media screen and (max-width:580px) { width: 20px; }
        @media screen and (max-width:320px) { width: 19px; }
        
    }
    span{
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        @media screen and (max-width:580px) { font-size: 12px; }
        @media screen and (max-width:320px) { font-size: 10px; }
    }
}
/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

    .Probox__discount__labelBox{
        padding: 0px 0px 0px 6px;       
    }

}
/*-------------------------- Arabic view end ------------------------*/