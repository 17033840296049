.user__delivery__address__block__disable {
  display: none;
}

.user__delivery__address__block {
  .bundleError{
    display: flex;
    height: 64px;
    border: 1px solid #EEA540;
    border-radius: 4px;
    align-items: center;
    padding: 0px 40px;
    margin-top: 20px;
    background-color: rgba(255, 227, 149, 0.5);
    position: relative;
    font-size: 12px;
    margin-bottom: 10px;

    &::before{
      display: flex;
      position: absolute;
      content: 'i';
      left: 10px;
      width: 22px;
      height: 22px;
      background-color: #EEA540;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      color: white;
    }
  }
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #0000000a;
    margin: 20px 0px;
    border: 1px solid #C7C7C7;
    .delivery__address__block {
      padding: 16px 0px 0px;

      .delivery__address__option__area{
        padding-bottom: 18px;
        padding-top: 10px;
        border-bottom: 1px solid #acacac;
        .delivery__option__item{
          .delivery__option__btn{
            user-select: none;
            padding: 8px 10px;
            margin: 10px 0px;
            border: 2px solid #dbdbdb;
            border-radius: 6px;
            box-shadow: 0px 2px 6px #dbdbdb;
            display: flex;
            align-items: center;
            font-size: 16px;
            .delivery__input__check{
              margin-right: 10px;
              width: 15px;
              height: 15px;                    
              accent-color: #000000;
            }
            img{ 
              margin-right: 6px; 
              width: 22px;
            }
            &.active{
              border-color: #dc3a1a;
              color: #ffffff;
              background-color: #dc3a1a;
              img{
                // filter: invert(28%) sepia(87%) saturate(1745%) hue-rotate(349deg) brightness(90%) contrast(99%);
                filter: brightness(0) invert(1);
              }
              input[type="radio"] {
                accent-color: #dc3a1a;
              }
            }
          }
        }
      }
      .delivery__address__title__block {
        display: flex;
        align-items: center;
        padding: 0px 16px;

        &.select__address__block{
          padding: 18px 16px 0px;
        }
      }

      .checkout__address__add__area{
        display: flex;
        flex-direction: row;

        .address__select__block {
          padding: 0px 0px;
          margin: 20px 0px;
          border-bottom: 0;        
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;   
          flex:0 0 96%;
          max-width: calc(100% - 80px);   

          .address__block {
            padding: 0px 16px;

            .selected__address__inner__block,.address__inner__block {                                    
              box-shadow: 0px 3px 6px #00000029;
              border: 2px solid #d1d1d1;
              padding: 15px;
              margin-bottom: 20px;
              border-radius: 15px;
              cursor: pointer;
              
              .user__address__info__area{
                display: inline-block;
                width: 75%;
                max-width: calc(100% - 80px);
                padding-right: 5px;
              }
              .address__action__area{
                display: inline-block;
                width: 70px;
                vertical-align: top;

                .inner__address__button__block {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .edit__button,
                  .delete__button {
                    text-align: center;
                    font-size: 14px;
                    font-family: 'SSTbold';
                    font-weight: bold;
                    border-radius: 5px;                        
                    color: #000000;
                    padding: 0px;
                    width: 30px;
                    // background-color: #c8c8c8;
                    // margin: 0px 0px 10px 0px;
                    outline: none;
                    border: none;
                    letter-spacing: 0.3px;
                    cursor: pointer;
                    text-transform: uppercase;
                    &:hover{
                      transition: all 0.3s ease-in-out;
                      box-shadow: 0px 1px 4px #d9d9d9;
                    }
                  }
                }
                .address__tag {
                  width: 100%;
                  text-align: center;
                  padding: 5px 10px !important;
                  background: #ff9b04;
                  border: 1px solid #ff9b04;
                  border-radius: 8px;
                  margin: 5px 0px 5px;                      
                  line-height: 15px !important;
                  transition: all 0.3s ease-in-out;
                  span{
                    font-size: 12px !important;
                    color: #ffffff;
                  }
                  &:hover{
                    box-shadow: 0px 1px 4px #d9d9d9;
                  }
                }
              }
              
              .white__address__tag {
                width: fit-content;
                padding: 5px 10px !important;
                background: #ffffff;
                border: 1px solid #ffffff;
                border-radius: 3px;
                margin: 0px 0px 10px;
                height: 30px;
                line-height: 15px !important;
                span{
                  font-size: 12px !important;
                }
              }

              .full__address {
                width: 90%;
                min-height: 50px;
              }

              .address__button__block {
                margin: 15px 0px 0px;

                .delivery__button {
                  text-align: center;
                  font-size: 12px;
                  font-family: 'SSTbold';
                  font-weight: bold;
                  border-radius: 5px;
                  width: 100%;
                  color: #ffffff;
                  padding: 8px 8px;
                  background-color: #dc3a1a;
                  margin: 0px ;
                  outline: none;
                  border: none;
                  letter-spacing: 0.3px;
                  cursor: pointer;
                  text-transform: uppercase;
                }

                
              }
            }
            .selected__address__inner__block {
              border: 2px solid #000000;
            }

            // .address__inner__block {
            //   background: #ffffff;
            //   box-shadow: 0px 3px 6px #00000029;
            //   border: 2px solid #d1d1d1;
            //   padding: 20px 30px;
            
            //   .address__tag {
            //     width: fit-content;
            //     padding: 5px 10px !important;
              
            //     background: #eeeeee;
            //     border: 1px solid #eeeeee;
            //     border-radius: 3px;
            //     margin: 0px 0px 10px;
            //     height: 30px;
            //     line-height: 15px !important;
            //     span{
            //       font-size: 12px !important;
            //     }
            //   }

            //   .white__address__tag {
            //     width: fit-content;
            //     padding: 5px 10px !important;
            //     background: #ffffff;
            //     border: 1px solid #ffffff;
            //     border-radius: 3px;
            //     margin: 0px 0px 10px;
            //     height: 30px;
            //     line-height: 15px !important;
            //     span{
            //       font-size: 12px !important;
            //     }
            //   }

            //   .full__address {
            //     width: 90%;
            //     height: 50px;
            //   }

            //   .address__button__block {
            //     margin: 20px 0px 0px;

            //     .delivery__button {
            //       text-align: center;
            //       font-size: 16px;
            //       font-family: 'SSTbold';
            //       font-weight: bold;
            //       border-radius: 5px;
            //       width: 100%;
            //       color: #ffffff;
            //       padding: 10px 15px;
            //       background-color: #dc3a1a;
            //       margin: 0px 12px 10px 0px;
            //       outline: none;
            //       border: none;
            //       letter-spacing: 0.3px;
            //       cursor: pointer;
            //       text-transform: uppercase;
            //     }

            //     .inner__address__button__block {
            //       display: flex;
            //       align-items: center;
            //       justify-content: space-between;

            //       .edit__button,
            //       .delete__button {
            //         text-align: center;
            //         font-size: 16px;
            //         font-family: 'SSTbold';
            //         font-weight: bold;
            //         border-radius: 5px;
            //         width: 49%;
            //         color: #000000;
            //         padding: 10px 25px;
            //         background-color: #c8c8c8;
            //         margin: 0px 0px 10px 0px;
            //         outline: none;
            //         border: none;
            //         letter-spacing: 0.3px;
            //         cursor: pointer;
            //         text-transform: uppercase;
            //       }
            //     }
            //   }
            // }
          }                
        }

        .add__new__address__block {
          padding: 10px 5px;
          display: flex;
          justify-content: center;
          flex: 0 0 80px;
          max-width: 80px;
          align-items: center;

          .location__button {
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            text-align: center;
            min-width: fit-content;
            font-size: 14px;
            font-family: 'SSTbold';
            font-weight: bold;
            border: none;
            border-radius: 5px;              
            color: #000000;
            background-color: transparent;
            outline: none;
            letter-spacing: 0.3px;

            .location__icon {
              margin: 0px 7px 0px 0px;
              width: 20px;
            }
            &:hover{
              color: #dc3a1a;
              .heading7{
                color: #dc3a1a !important;
              }
            }

          }
        }

      }
      .view__more__address__block{
        display: flex;
        justify-content: center;
        button{
          margin-bottom: 12px;
          &:hover{
            text-decoration: underline;
            text-decoration-color: #818181;
          }
        }
      }            

    }

    .store__pickup__mainarea{
      width: 99% !important;
      margin: 20px auto;
      padding: 15px;
      .invalid__message {
        color: red;
        margin: 0px 0px 5px 0px;
        font-size: 16px;
      }
      .store__pickup__heading{
        display: flex;
        flex-direction: row;   
        justify-content: space-between;   
        @media screen and (max-width:580px) {
          flex-direction: column;   
        }

        .heading4 {
          display: inline-block;
          margin-bottom: 0px !important;
          
          @media screen and (max-width:580px) {
            margin-bottom: 10px !important;
          }
        }
        .css-b62m3t-container{                
          width: 250px;
        }
        .search__store__availablebox{
           width: 40%;
           @media screen and (max-width:580px) {
            width: 100%;
           } 
           input, button{
            border-color: #6c757d;
              &:focus{
                box-shadow: none;
                border-color: #6c757d;
              }
            }
            button {
              display: flex;
              align-items: center;
              justify-content: center;
            }
        }
      }
      .store__pickup__options__box{
        max-height: 370px;
        overflow: scroll;
        overflow-x: hidden;
        padding-right: 5px;
        margin-bottom: 20px;

        .store__pickup__itembox{
          display: flex;
          flex-direction: row;
          margin-bottom: 10px;          
          @media screen and (max-width:992px) {
            border-bottom: 1px solid #303030;  
          }
          .store__pickup__select__area{
            width: 25px;
            display: flex;
            align-items: center;
            justify-self: center;
            input {
              width: 17px;
              height: 17px;
              accent-color: #ed4b2b;
            }
          }
          .pickup__store__infobox{
            width: 95%;
            max-width: calc(100% - 25px);
            background-color: #ffffff;
            // border-left: 4px solid #e33124;
            // border-radius: 6px;
            // box-shadow: 0px 1px 2px #cdcdcd;
            padding: 12px 5px;
            .product__store__status{
              margin-bottom: 5px;
              width: 100%;
              // color: #e33124;
            }
            .pickup__store__location{
              font-size: 12px;
              color: #8994a6;
              @media screen and (max-width:580px) {
                display: block;
                width: 100%;  
              }
            }
            .pickup__store__time{
              font-size: 12px;
              color: #8994a6;
            }
            .store__opening__hours__btn{
              background-color: #dc3a1a;
              padding: 8px 10px;
              color: #fff;
              font-size: 15px;
              line-height: 1.2;
              margin: 10px 0px 0px auto;
              float: right;
              border:none;
              &:hover{
                box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                transform: translateY(-2px);
                transition: all 0.2s ease-in-out;
                -webkit-transition: all 0.2s ease-in-out;
              }
            }
          }
        }
      }
    }

    

    .view__more__address__button {
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      text-align: center;
      min-width: fit-content;
      font-size: 14px;
      font-family: 'SSTbold';
      font-weight: bold;
      border-radius: 5px;
      border: none;
      color: #000000;
      background-color: #ffffff;
      outline: none;
      letter-spacing: 0.3px;
    }

    .delivery__selcetion__pickup__store {
      padding: 15px 5px;
      margin: 0px;
      
      .delivery__preferences__block {
        .delivery__preferences__title__block {
          .heading6{
            font-size: 16px;
          }
        }
        
        .delivery__selection__block {
          .delivery__selection {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0px 0px 0px;

            .delivery__selection__form__block {
              display: flex;
              align-items: center;

              .delivery__input__check {
                accent-color: #ff4f04;
                height: 17px;
                width: 17px;
                border-radius: 10px;
                margin: 0px 16px 0px 0px;
                // border: 17px solid #303030;
                text-transform: uppercase;
              }

              .delivery__selection__text {
                margin: 0px;
              }
            }

            .delivery__price__block {
              width: 30%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              .delivery__price {
                text-align: right;
                margin: 0px;
              }
            }
          }
        }
      }

      .delivery__selection__form__block {
        display: flex;
        flex: 0 0 75%;
        max-width: 75%;
        align-items: flex-start;
        input {
          margin-right: 10px;
          margin-left: 5px;
          width: 16px;
          height: 16px;
          max-width: 16px;
          max-height: 16px;
          margin-top: 6px;
          accent-color: #ff4f04;
                    
        }
      }
      .delivery__pickup__store {}

      .continue__button__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
       
        .continue___button {
          text-decoration: none;
          border-radius: 5px;
          color: #ffffff;
          font-size: 14px;
          line-height: 1.4;
          font-family: 'SSTbold';
          font-weight: bold;
          letter-spacing: 0.85px;
          padding: 8px 20px;
          background-color: #dc3a1a;
          text-align: center;
          text-transform: uppercase;
          width: fit-content;
          border: none;
          margin: 0px;
          // height: 44px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 0.4s ease-in-out;
          -webkit-transition: all 0.4s ease-in-out;

          &:before {
              content: "";
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background: #cdcdcd;
              border-radius: 4px;
              transform: scaleX(0);
              transform-origin: 0 50%;
              transition-property: transform;
              transition-duration: 0.3s;
              transition-timing-function: ease-out;       
          }
          &:hover{
            box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
            transform: translateY(-2px);
            -webkit-transform: translateY(-2px);                  
          }
          &:focus{
            color: #000000;
            box-shadow: 0 3px 3px -4.8px rgb(32 32 32 / 60%);
            transform: translateY(0px);
            -webkit-transform: translateY(0px);    
            transition: all 0.3s ease-in-out;
            -webkit-transition: all 0.3s ease-in-out;            
            &:before{
              transform: scaleX(1);
              -webkit-transform: scaleX(1);
            }
          }
          .btn__spinner{
            min-width: 76.22px;
            min-height: 19.5px;     
          }
        }
      }
    }
    
    .checkout__page__horizontal__line {
      border-bottom: 1px solid #acacac;
      margin: 0px;
    }
  }

  .user__payment__block__disable {
    display: none;
  }
  
  .user__payment__block {
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #0000000a;
    margin: 0px 0px;
    padding: 15px 15px;
    border: 1px solid #C7C7C7;
    margin-top: 20px;

    .payment__title__block {
      display: flex;
      align-items: center;
      padding: 0px;
      margin: 0px 0px 20px;
      img{ width: 21px; }
    }
    .payment__block {
      padding: 0px 1px 12px;
      .text4{
        font-weight: 500;
        font-family: 'SSTmedium';
        text-transform: uppercase;
      }

      .cart_apply_credit_wrapper {
        display: flex;
        max-width: unset;
        flex-direction: row;
        align-items: center;
        position: relative;
        padding: 20px 0px;
        border-radius: 10px;
        background-color: #F9F9FA;

        .cart_apply_credit_inner {
          display: flex;
          margin: 0 !important;
          flex: 1;
          flex-direction: row;
          align-items: center;

          .cart_apply_credit_title {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 0 8px;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: -0.11px;
            color: #1B2630;

            .cart_apply_credit_current {
              display: flex;
              align-items: center;
              line-height: 1.65;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.25;
              letter-spacing: -0.27px;
              color: #1B2630;

              &.cart_apply_credit_applied {
                // color: $bgCartSuccess;
              }
            }
          }

           .store_credit_title{
              display: flex;
              flex: 1;
              flex-direction: column;
              padding: 0 16px;
              font-size: 14px;
              font-weight: normal;
              letter-spacing: -0.11px;
              color: #1B2630;

              .store_credit_current {
                display: flex;
                align-items: center;
                line-height: 1.65;
                // @include gap(5px, "row");
                font-size: 14px;
                font-weight: 500;
                line-height: 1.25;
                letter-spacing: -0.27px;
                color: #1B2630;

                &.store_credit_applied {
                  color: #6DD400;
                }
              }
            }

          .payment__input__check:checked {
            accent-color: #ff4f04;
          }

        }

        .cart_apply_credit_addMoney {
          margin: 0 9px 0 0;
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.15;
          letter-spacing: normal;
          color: #6212BA;

          html[dir="rtl"] & {
            margin: 0 0 0 9px;
          }
        }

        // .cart_apply_credit_addButton {
        //   background-color: transparent;

        //   .cart_apply_credit_plusIcon {
        //     width: 18px;
        //     height: 18px;
        //     background-image: url("/assets/img/plusIcon0091.png");
        //     background-size: 100% 100%;
        //     padding: unset;
        //     image-rendering: -moz-crisp-edges; /* Firefox */
        //     image-rendering: -o-crisp-edges; /* Opera */
        //     image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
        //     image-rendering: crisp-edges;
        //     -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
        //   }
        // }

        .cart_apply_credit_pending {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: rgba(255, 255, 255, 0.5);
        }

        @media screen and (max-width:480px) {
          padding: 22px 0px;
        }
      }



      .pay__with__item__listBox{
        flex-direction: row;
        flex-wrap: wrap;
        .payment__form__block{
          margin-right: 12px;
                
          @media screen and (max-width:480px) {
            max-width: 48% !important;
            flex: 0 0 49% !important;
            min-width: 48%;
            margin-right: 8px;            
            &:nth-child(even) { margin-right: 0px; }    
          }
          
        }
      }    
      .card__block {
        display: flex;

        .card {
            /* object-fit: cover; */
            width: auto;
            height: 22px !important;
            margin: 0px 5px 0px 0px;
            &:last-child{
              margin: 0px;
            }
        }
      }
      .payment__form__block {
        display: flex;
        align-items: center;
        padding: 10px 30px 10px 10px;
        min-width: 185px;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        // border-radius: 5px;
        border: 1px solid #c7c7c7;
        margin-bottom: 15px;
        justify-content: space-between;

        @media screen and (max-width:580px) {
          padding: 10px 10px 10px 10px;
        }
        .payment__input__check {
          cursor: pointer;
          accent-color: #ff4f04;
          height: 17px;
          width: 17px;
          border-radius: 10px;
          margin: 0px 16px 0px 0px;
          // border: 17px solid #303030;
          text-transform: uppercase;
          @media screen and (max-width:580px) {
            margin: 0px 13px 0px 0px;
          }
        }       
        img {
          width: auto;
          max-height: 28px;
          max-width: 80px;
        }

        .payment__selection__text {
          margin: 0px;
          width: 100%;
        }
      }
      .payment__form__main__block {
        margin: 0px 0px 10px;

       

        .payment__detail__form__block {
          padding: 10px 15px 15px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #eeeeee;
          border-radius: 5px;
          position: relative;

          .payment_process_loading{
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0px;
            top:0px;
            background-color: #ffffffb8;
            z-index: 3;
          }
          .address__content__block {
            .payment__card__block {
              display: flex;
              width: 100%;
              justify-content: flex-start;
              flex-direction: column;

              .payment__form__field__row {
                width: 100%;
                margin: 0px;
                display: flex;
                align-items: flex-end;
                // margin: 10px;

                .month__year__form__field__block {
                  // width:100%
                  margin: 0px 12px 0px -12px;
                }

                .main__form__field__block {

                  input:focus,
                  textarea:focus {
                    outline: none !important;
                    background: none !important;
                  }

                  .card__holder__field:focus {}

                  .field__block {
                    width: 100%;

                    .form__field {
                      width: 100%;
                      padding: 8px 10px;
                      // background: #ffffff 0% 0% no-repeat padding-box;
                      border: 1px solid #c8c8c8;
                      border-radius: 4px;
                    }
                    img {
                      width: auto;
                      max-height: 28px;
                      max-width: 80px;
                    }
                    .apply_btn{
                      background-color: #dc3a1a;
                      color: #fff;
                      transition: all 0.4s ease-in-out;
                      -webkit-transition: all 0.4s ease-in-out;
                      -moz-transition: all 0.4s ease-in-out;
                      margin-top: 15px;
                      &:hover{
                        opacity: 0.8;
                      }
                      .btn__spinner{
                        min-height: 24px;
                      }
                    }
                  }

                  .invalid__message {
                    color: red;
                    margin: 5px 0px 0px;
                  }
                  
                }
                .pay__promoCode__applybox{
                  .promo__applyBtn{
                    
                    @media screen and (max-width:575px) {
                      margin-top: 10px;
                    }
                  }                    
                }
              }
            }
          }
        }
        .payment__detail__form__block.tamara__paybox {
          border: none;
          padding: 0px;

          .tamara__installments__label{
            width: 70%;
            text-align: center;
            font-size: 15px;
            color: #b863f3;
          }
          .tamara__installments__progressbox {
            padding: 10px 3%;
            border: 1px solid #6dc7be;
            border-radius: 10px;
            .tamara__logo__pic{ 
              margin-bottom: 15px; 
              width: 55px;
            }
            ul{
              margin: 0px auto 25px;
              padding: 0px;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              position: relative;
              width:95%;

              &:before {
                content: " ";
                display: block;
                position: absolute;
                height: 4px;
                width: 85%;
                background-color: #6dc7be;
                left: 50%;
                top: 48%;
                transform: translate(-50%);
              }

              li {
                list-style-type: none;
                text-align: center;

                .tamara_amt_box {
                  font-size: 15px;
                  font-family: 'SSTbold';
                  color: #6dc7be;
                  margin-bottom: 0px;
                }
                .circle-box{
                  height: 20px;
                  width: 20px;
                  background-color: #6dc7be;
                  border-radius: 50%;
                  margin: 5px auto;
                }
                .tamara_date_label{
                  font-size: 14px;
                }
              }
            }
          }
        }

      }
    }

    .continue__button__block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width:580px) {
        margin-top: 20px !important;
      }
      .btn{
        display: block;
        height: 44px !important;
        padding: 6px 8px;
        &:hover , &:focus {
          background-color: #dc3a1a;
          color: #ffffff;
        }
        &[disabled]{
            background-color: #707070;
            cursor: not-allowed;
            border-color: #707070;
        }
      }
      .continue___button__link {
        text-decoration: none;

        .continue___button {
          border-radius: 5px;
          color: #ffffff;
          font-size: 16px;
          font-family: 'SSTbold';
          font-weight: bold;
          letter-spacing: 0.85px;
          padding: 10px 60px;
          background-color: #dc3a1a;
          text-align: center;
          text-transform: uppercase;
          width: fit-content;
          border: none;
          margin: 0px;
          height: 64px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .pd__apple__buy__btn {
        display: block;        
        padding: 8px;
        height: 44px;
        width: 100%;
        border-radius: 5px;
        color: #ffffff;
        font-size: 16px;
        font-family: 'SSTbold';
        font-weight: bold;
        letter-spacing: 0.85px;        
        background-color: #000000;
        text-align: center;
        border: none;
        text-decoration: none;
        @media (max-width:576px) {
          margin-bottom: 15px;
        }
        svg {
          width: 15px;
          height: 15px;
          margin-right: 5px;
          margin-left: 5px;
          margin-top: -2px;
        }
        &:disabled{
          background-color: #707070;
          opacity: 0.65;
        }
      }
    }
  }

  .checkout__login__main__block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 0px 0px;
    margin: 0px;
    padding-top: 15px;
    display: flex;
    align-items: center;

    .checkout__signin__button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      cursor: pointer;
      @media screen and (min-width:581px) and (max-width:992px) {
        width: 8%;
      }
      img {
        height: 23px;
      }
      .done__icon {
        position: absolute;
        top: 55%;
        left: 50%;
        transform: translateX(-50%);
        width: 18px;
      }
    }

    .checkout__middle__line__block {
      height: 1px;
      background-color: #818181;
      @media screen and (min-width:581px) and (max-width:992px) {
        width: 38%;
      }
    }

    .checkout__delivery__button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      @media screen and (min-width:581px) and (max-width:992px) {
        width: 8%;
      }
      img {
        height: 23px;
      }
      .done__icon {
        position: absolute;
        top: 52%;
        left: 50%;
        transform: translateX(-50%);
        width: 18px;
      }
    }

    .checkout__payment__button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;
      @media screen and (min-width:581px) and (max-width:992px) {
        width: 8%;
      }
      img {
        height: 23px;
      }
      .done__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        width: 18px;
      }
    }
  }

.mb__checkout__page {
    // padding-top: 40px;
    background: #F5F5F5;

    .checkout__bottom__text {
        color: #C4C4C4;
        font-size: 12px;
        padding: 0 5px;

        @media(max-width:992px) {
            margin: 10px 30px;
            padding-bottom: 20px;
        }
    }

    .sc__ps__detail__inner__block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgb(224, 224, 224);
        padding: 20px 0px;

        .sc__ps__detail__title {
            margin: 0px;
            font-size: 16px;
            font-family: "SSTregular";
        }

        .sc__ps__detail__price {
            margin: 0px;
            font-size: 16px;
            font-family: 'SSTbold';
            font-weight: bold;
        }
        &.discount_info_row{
          position: relative;
          .discount_remove_spinner {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0px;
            top: 0px;
            background-color: #ffffffb8;
            text-align: center;
            z-index: 2;
          }
          button{
            &:focus{
              box-shadow: unset;
            }
          }
        }
    }

    .sc__ps__detail__total__order__block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0px;

        .sc__ps__detail__total__order {
            font-size: 18px;
            font-family: 'SSTbold';
            font-weight: bold;
            margin: 0px;
        }
    }

    .sc__product__block .sc__product__middle__block {
        padding: 0px 0px 0px;
    }

    .checkout__package__summary__block {
        // box-shadow: 0px 3px 6px #0000000a;
        background-color: #ffffff;

        .checkout__os__title {
            font-size: 24px;
            font-family: 'SSTbold';
            font-weight: bold;
            padding: 20px;
            border-bottom: 1px solid rgb(224, 224, 224);
            margin: 0px;
        }

        .checkout__os__detail__block {
            padding: 10px 20px 20px;

            .checkout__os__detail__inner__block {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid rgb(224, 224, 224);
                padding: 20px 0px;

                &:last-child {
                    border-bottom: 0;
                }

                .checkout__os__detail__title {
                    margin: 0px;
                    font-size: 16px;
                    font-family: "SSTregular";
                }

                .checkout__os__detail__price {
                    margin: 0px;
                    font-size: 16px;
                    font-family: 'SSTbold';
                    font-weight: bold;
                }
            }

            .checkout__os__detail__total__order__block {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 30px 0px;

                .checkout__os__detail__total__order {
                    font-size: 18px;
                    font-family: 'SSTbold';
                    font-weight: bold;
                    margin: 0px;
                }
            }
        }
    }

    .mb__checkout__pro__summary {
        .product__avilable__offers__block {
            margin-top: 0;
            margin: 0px 0px;
        }

        .sc__product__image__block {
            height: auto;
        }

        .sc__product__block .sc__offers__discounts__block {
            padding: 0;
        }

        .protection__plan__block .selected__plan__block .remove__plan p {
            background-image: url("./../../assets/Icon/delete.svg");
            font-size: 0;
            height: 22px;
            width: 24px;
            background-size: contain;
            margin-left: auto !important;
            cursor: pointer;
            background-repeat: no-repeat;
        }

        .sc__product__block {
            .sc__product__image__block {
                background-color: transparent;
                border: none;
            }

            .mb__sc__counter {
                border: 1px solid #D1D1D1;
                border-radius: 3px;

                .counter__decrease__block {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    a {
                      margin: 0px 6px;
                      padding: 0px;
                    }
                }

                .counter {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-right: 1px solid #D1D1D1;
                    border-left: 1px solid #D1D1D1;
                    font-size: 20px;
                }

                .counter__increase__block {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }
        }

    }

    .mb__sc__fixed__btn {
        background: #fff;
        box-shadow: 0px 3px 6px #00000029;
        padding: 10px 30px;
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        align-items: center;
        z-index: 10;
        color: #000 !important;
        .continue___button__link {
            text-decoration: none;
        }
    }

    // hr {
    //     height: 4px;
    //     background: #f1f1f1;
    // }

    .mb__pd__size__title {
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    ul.mb__progress__bar {
        flex-wrap: nowrap;
        /* flex: 1; */
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        list-style-type: none;
        padding-left: 0;
        margin-top: 10px;
        background: #fff;
        padding-bottom: 10px;

        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            content: "";
            background: #d6d6d6;
            width: 90%;
            height: 1px;
            z-index: 0;

        }

        li {
            background: #fff;
            /* flex: 1; */
            z-index: 1;
            // width: 30%;
            text-align: left;
            position: relative;
            padding: 0 15px;
            cursor: pointer;

            img {
                width: 30px;
                height: 30px;
            }

            img.done__icon {
                position: absolute;
                left: 30px;
                bottom: -5px;
                width: 20px;
                height: 20px;
            }
        }
    }


    .mb__user__payment__block__disable {
        display: none;
    }

    .mb__saved__card__block {
        background: #FFF7F5;
        padding: 10px;

        img.card__img {
            width: 55px;
            height: auto;
        }

        .form-control,
        .btn {
            height: 46px;
            padding: 10px 20px;

        }
    }

    .mb__user__payment__block {

        // background-color: #ffffff;
        //   box-shadow: 0px 3px 6px #0000000a;
        //   margin: 0px 30px;
        //   padding: 0px 20px 20px;
        //   border: 1px solid #C7C7C7;
        //   margin-top: 20px;
        .checkout__coupon__block {
            margin: 0px 30px;

            .input-group {
                position: relative;
                width: 90%;

                button#button-addon2 {
                    background: transparent;
                    border: 0;
                    position: absolute;
                    right: 10px;
                    top: 50%;

                    transform: translateY(-50%);
                    color: #000;
                    font-weight: 600;
                    z-index: 9;
                }

                input.form-control {
                    background: #FFDCA7;
                    border: 1px solid #1B26303B;
                    border-radius: 0;
                    padding-right: 60px;

                    &:focus {
                        box-shadow: none;
                        border-color: #000;
                    }
                }
            }

        }

        .payment__input__check__small {
            height: 20px;
            width: 20px;
            accent-color: #ff4f04;
            // border: 30px solid #303030;
        }

        .payment__input__check {
            accent-color: #ff4f04;
            height: 18px;
            width: 18px;
            border-radius: 10px;
            margin: 0px 16px 0px 0px;
            // border: 18px solid #303030;
            text-transform: uppercase;
        }

        .card__block {
            display: flex;

            .card {
                /* object-fit: cover; */
                width: 30px;
                height: auto !important;
                margin: 0px 5px 0px 0px;
            }
        }

        .payment__block {
            background-color: #ffffff;
            box-shadow: 0px 3px 6px rgb(0 0 0 / 4%);
            margin: 0px 30px;
            padding: 10px 20px 20px;
            border: 1px solid #C7C7C7;
            margin-top: 20px;

            .payment__title__block {
                padding: 0 !important;
            }

            .payment__form__block {
                display: flex;
                align-items: center;
                padding: 10px 10px;
                background: #f2f2f2 0% 0% no-repeat padding-box;
                // border-radius: 5px;
                border: 1px solid #BFBFBF;

                .payment__selection__text {
                    margin: 0px;
                    font-weight: 500;
                    font-family: "SSTmedium";
                }
            }

            .payment__title__block {
                display: flex;
                align-items: center;
                padding: 0px 16px;
                margin: 0px 0px 20px;
                img{
                    width:25px;
                }
            }

            .payment__form__main__block {
                margin: 0px 0px 10px;



                .payment__detail__form__block {
                    padding: 20px 0 20px 10px;
                    background: #ffffff 0% 0% no-repeat padding-box;
                    //   border: 1px solid #eeeeee;
                    border-radius: 5px;

                    .address__content__block {
                        .payment__card__block {
                            display: flex;
                            width: 100%;
                            justify-content: flex-start;
                            flex-direction: column;

                            .payment__form__field__row {
                                width: 100%;

                                // margin: 10px;
                                .month__year__form__field__block {
                                    // width:100%
                                    //   margin: 0px 12px 0px -12px;
                                }

                                .main__form__field__block {
                                    .field__block {
                                        width: 100%;

                                        .form__field {
                                            width: 80%;
                                            padding: 10px;
                                            background: #ffffff 0% 0% no-repeat padding-box;
                                            border: 1px solid #c8c8c8;
                                            border-radius: 4px;
                                        }
                                    }

                                    .invalid__message {
                                        color: red;
                                        margin: 5px 0px 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .continue__button__block {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .continue___button__link {
                text-decoration: none;

                .continue___button {
                    border-radius: 5px;
                    color: #ffffff;
                    font-size: 16px;
                    font-family: 'SSTbold';
                    font-weight: bold;
                    letter-spacing: 0.85px;
                    padding: 10px 60px;
                    background-color: #dc3a1a;
                    text-align: center;
                    text-transform: uppercase;
                    width: fit-content;
                    border: none;
                    margin: 0px;
                    height: 64px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }

}
@media(max-width:991px){
    .checkout__coupon__block{
        display: contents;
    }
    .checkout__summary__block ul {
        hr:last-child{
            display: none;
        }
       
    }
    .checkout__summary__block ul li:last-child{
        display: none;
    }
}
@media(max-width:576px) {
    .mb__checkout__page .checkout__summary__block hr{
        margin: 0 -6px;
    }
    .mb__checkout__page .mb__user__payment__block .payment__block, .mb__checkout__page .checkout__summary__block{
        margin: 10px 5px;
        padding: 10px 10px 20px;
    }
  
    .mb__checkout__page ul.mb__progress__bar li img {
        width: 20px;
    }

    .mb__checkout__page ul.mb__progress__bar li {
        padding: 0 5px;
    }

    .mb__checkout__page ul.mb__progress__bar li img.done__icon {
        position: absolute;
        left: 19px;
        bottom: -5px;
        width: 15px;
        height: 15px;
    }

    .mb__sc__counter img {
        width: 15px;
    }

    .mb__checkout__page .mb__checkout__pro__summary .sc__product__block .mb__sc__counter {
        padding: 0;
    }

    .product__avilable__offers__block .product__offer__block .product__offer__icon {
        width: 20px;
    }

    .mb__checkout__page .mb__sc__fixed__btn {
        padding: 10px 15px;
    }

    .mb__checkout__page .mb__checkout__pro__summary .sc__product__block .mb__sc__counter .counter {
        font-size: 14px;
    }

    .mb__checkout__page .mb__checkout__pro__summary .sc__product__image__block {
        padding: 0;
    }

    .mb__checkout__page .mb__user__payment__block .card__block .card {
        width: 25px;

    }
    .mb__sc__fixed__btn .btn{
        padding: 7px 10px;
        font-size: 14px;
    }
    .mb__checkout__page .checkout__bottom__text {
        margin: 0px 10px;
        padding-bottom: 10px;
    }    
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {


  
  .user__payment__block {
    .payment__title__block{
      p{ 
        margin-left: 0px !important; 
        margin-right: 10px !important;
      }
    }
    .payment__block {
      
      .payment__form__main__block {
        .payment__detail__form__block {
          .address__content__block {
            .payment__card__block {
              .payment__form__field__row {
                .main__form__field__block {
                  .field__block {
                    .form__field[name="phone"],
                    .form__field[name="cardNumber"],
                    .form__field[name="couponCode"] {
                      direction: ltr;
                      text-align: right;
                    }
                  }
                }
              }
            }
          }
        }
      }
      

      .payment__form__block{
        margin-left: 8px !important;
        margin-right: 0px !important;
        @media screen and (max-width:480px) {
          &:nth-child(even) {
            margin-left: 0px !important;
          }
        }
      }
      .payment__input__check{
        margin-left: 10px !important;
        margin-right: 0px !important;
      }
    }
    .continue__button__block {
      .btn{
        font-family: 'SSTArabicBold';
      }
      .pd__apple__buy__btn{
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
      }
    }
  }
  
  .user__delivery__address__block {
    .delivery__address__block {
      .delivery__address__title__block{
        .heading6{
          margin: 0px 10px 0px 0px !important;
        }
      }
      .checkout__address__add__area {
        .add__new__address__block {
          .location__button {
            padding: 0px !important;
          }
        }
      }
      .delivery__address__option__area {
        .delivery__option__item {
          .delivery__option__btn{
            .delivery__input__check{
              margin-right: 0px;
              margin-left: 10px;
            }
            img{
              margin-right: 0px;
              margin-left: 10px;
            }
          }
        }
      }
    }
    
    .delivery__selcetion__pickup__store {
      .delivery__preferences__block {
        .delivery__selection__block {
          .delivery__selection {
            .delivery__selection__form__block {
              .delivery__input__check{
                margin: 0px 0px 0px 15px !important;
              }
              input[type="radio"] {
                margin-left: 6px;
                margin-right: 0px;
              }              
            }            
          }
        }
      }
      .continue__button__block {
        .continue___button{
          font-family: 'SSTArabicBold';
        }
      }
    }
    .store__pickup__mainarea {
      .store__pickup__heading {
        @media screen and (max-width:480px) {
          font-size: 19px;
        }
        .search__store__availablebox{
          flex-direction: row-reverse;
        }
      }
      .store__pickup__options__box {
        .store__pickup__itembox {
          .pickup__store__infobox {
            padding: 12px 5px 12px 0px;
            .store__opening__hours__btn{
              float: left;
            }
            .pickup__store__location {
              float: unset;
            }
          }          
        }
      }
    }
  }

  
  
  

}
.bin-note{
  color: rgb(0 130 160);
    font-weight: 600;
    margin-bottom: 6px;
    font-size: 13px;
    text-align: center;
}
/*-------------------------- Arabic view End ------------------------*/