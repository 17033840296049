.wrapper {
  margin: 10px 0px;
  display: flex;
  align-items: center;
  padding: 16px;
  border: 1px solid #d6d6d3;
  font-size: 14px;
  line-height: 21px;
  border-radius: 7px;
  justify-content: space-between;
  cursor: pointer;

  .textWrapper {
    max-width: 350px;
  }

  img {
    object-fit: contain;
  }
}

.sectionLink {
  color: inherit;
  /* Use the inherited color */
  text-decoration: none;
  /* Optional: Remove underline */
}

.sectionLink {
  color: inherit;
  /* Ensure visited link retains the same color */
}

.kioskTabbyWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;

  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12);
  font-size: 14px;

  .textWrapper {
    max-width: 270px;
  }

  img {
    object-fit: contain;
  }
}
