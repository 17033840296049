body {
  margin: 0;
  font-family: 'SSTregular';
}
body[dir="rtl"].arabicFonts {
  font-family: 'SSTArabic';
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* sst fonts starts*/
@font-face {
  font-family: 'SSTregular';
  src: url('/public/font/SST-english/SST-Roman.woff2') format('woff2'),
      url('/public/font/SST-english/SST-Roman.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SSTbold';
  src: url('/public/font/SST-english/SST-Bold.woff2') format('woff2'),
      url('/public/font/SST-english/SST-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SSTmedium';
  src: url('/public/font/SST-english/SST-Medium.woff2') format('woff2'),
      url('/public/font/SST-english/SST-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* sst fonts ends*/
@font-face {
  font-family: 'SSTArabic';
  src: url('/public/font/SST-arabic/SSTArabic-Roman.woff2') format('woff2'),
      url('/public/font/SST-arabic/SSTArabic-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SSTArabicMedium';
  src: url('/public/font/SST-arabic/SSTArabic-Medium.woff2') format('woff2'),
      url('/public/font/SST-arabic/SSTArabic-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'SSTArabicBold';
  src: url('/public/font/SST-arabic/SSTArabic-Bold.woff2') format('woff2'),
      url('/public/font/SST-arabic/SSTArabic-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ArialBold';
  src: url('/public/font/arial-bold-webfont.woff2') format('woff2'),
      url('/public/font/arial-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

b,strong{
  font-family: 'SSTbold';
}
body[dir="rtl"].arabicFonts b,
body[dir="rtl"].arabicFonts strong {
  font-family: 'SSTArabicBold';
}
center ion-button.amwal-checkout-button::part(native) {
  color: #1B2630 !important;
  box-shadow: none;
  font-weight: 500;
  font-family: "SSTmedium";
  text-transform: uppercase;
  border-radius: 0px;
  background-image: url(assets/Icon/amwalPaymentIcons.png) !important;
  background-repeat: no-repeat !important;
  background-position: 96% !important;
  padding: 20px 30px 20px 19px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #c7c7c7;
  margin-bottom: 15px;
  cursor: pointer;
}
center ion-button.amwal-checkout-button .payment-brand-class, center ion-button.amwal-checkout-button .vl{
  display: none;
}
center ion-button.amwal-checkout-button div:not(.payment-brand-class):not(.vl){
  position: absolute;
  left: 18px;
  right: auto;
  top: -8px;
}
center ion-button.amwal-checkout-button div:not(.payment-brand-class):not(.vl):before {
  display: block;
  cursor: pointer;
  content: "";
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: solid 1px #6c6868;
  background-color: #FFF;
  position: absolute;
  left: -28px;
  right: auto;
  top: -1px;
}
body[dir=rtl] ion-button.amwal-checkout-button::part(native) {
    background-image: url(assets/Icon/amwalPaymentIconsArabic.png) !important;
    background-size: 153px;
    background-position: 1% center !important;
    margin-left: 9px;
    font-family: 'SSTArabicMedium' !important;
}
html.ios body[dir=rtl] ion-button.amwal-checkout-button::part(native) {
  background-image: url(assets/Icon/amwalPaymentIconsapplyArabic.png) !important;
  background-size: 45%;
}
html.ios ion-button.amwal-checkout-button::part(native) {
  background-image: url(assets/Icon/amwalPaymentIconsapply.png) !important;
  background-size: 45%;
}
body[dir=rtl] center ion-button.amwal-checkout-button div:not(.payment-brand-class):not(.vl):before {
  right: -32px;
  left: auto;
}
body[dir=rtl] center ion-button.amwal-checkout-button div:not(.payment-brand-class):not(.vl){
  right: 30px;
  left: auto;
}
body[dir="rtl"].mac-device ion-button.amwal-checkout-button::part(native) {
  background-image: url(assets/Icon/amwalPaymentIconsapplyArabic.png) !important;
  background-position-x: 1% !important;
}
body.mac-device ion-button.amwal-checkout-button::part(native) {
  background-image: url(assets/Icon/amwalPaymentIconsapply.png) !important;
  background-position: 94.5% !important;
  
}
@media screen and (max-width: 992px) {
 ion-button.amwal-checkout-button::part(native) {
    background-position: 100% !important;
    background-size: 49% !important;
  }
  body[dir=rtl] center ion-button.amwal-checkout-button div:not(.payment-brand-class):not(.vl){
    right: 11px;
  }
}

@media screen and (max-width: 480px) {
  ion-button.amwal-checkout-button::part(native) {
    background-position: 100% !important;
    background-size: 49% !important;
  }
}
