.main__filter__block {
  .filter__block {
    padding: 24px 16px 16px;
    border-bottom: 1px solid #727272;
 
    .filter__checkbox__block {
      display: flex;
      align-items: center;
      margin: 0px 0px 12px;
      // &.reviews {
      //   align-items: center;
      // }
      .filter__checkbox {
        accent-color: #000000;
        height: 24px;
        width: 24px;
        background: #ffffff;
        border: 1px solid #808080;
        border-radius: 4px;
        opacity: 1;
        margin-right: 16px;
      }
      .filter__title {
        color: #3b3b3b;
        font-size: 16px;
        font-family: "SSTregular";
      }
      .star__rating__block {
        display: flex;
        align-items: center;
        margin: 0px 0px 0px;
        .star__count {
          margin-left: 6px;
        }
        .star__rating {
          color: #dc3a1a;
          font-size: 16px;
         font-family: 'SSTbold';
font-weight: bold;
          margin: 0px 0px 0px 10px ;
        }
      }
    }
  }
 
}

