.container-fluid.cart__popup__container.plp__compare{
  background: transparent;
  // height: 370px;
  bottom: 0;
  z-index: 99999;
  position: fixed;
  top: auto;
  bottom: 0px;
  right: auto;
  left: 0px;
  padding: 0px;
  width: auto;
  height: max-content;

@media screen and (max-width:992px) {
  width: 100%;
  max-width: 100%;
  bottom: 145px;
}
.plp__compare__popup__block {
  background-color: #f5f5f5;
  // width: 100%;
  // position: absolute;
  // bottom: 0px;
  // right: 0px;
  padding: 0px 0px;
  box-shadow: 0px -3px 5px #d9d9d9;
  .plp__compare__popup__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 20px;
    border-bottom: 1px solid #f1f1f1;
    .cancel__button{
      cursor: pointer;
      width: 19px;
      @media (max-width: 992px) {
        // background-color: pink;
    }
    }
    .heading6{
      font-size: 15px !important;
    }
  }
  .plp__compare__popup__content {
    padding: 20px 20px 5px 20px;
    align-items: stretch;    
    justify-content: center;
    flex-wrap: wrap;
    display: flex;

    @media screen and (max-width:992px) {
      width: 100%;
      max-width: 100%;      
      overflow-y: hidden;
      flex-wrap: nowrap;
      justify-content: flex-start;
    }
    .compare__product__block {
      width: 200px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000000a;
      border-radius: 5px;
      padding: 20px 15px 15px;
      margin: 0px 20px 20px 0px;
      position: relative;
      display: flex;
      flex-direction: column;

      @media screen and (max-width:992px) {
        flex: 0 0 180px;
        margin: 0px 10px 15px 0px;
      }
      @media screen and (max-width:480px) {
        flex: 0 0 150px; 
      }
      .compare__product__header {
        position: absolute;
        height: 17px;
        width: 17px;
        right: 6px;
        top: 6px;
        
        .cancel__button{
          cursor: pointer;
          vertical-align: top;
        }
      }
      .compare__product__content {
        display: flex;
        align-items: center;
        flex-direction: column;
        flex: 1 0 auto;
        justify-content: space-between;
        img{
          height: auto;
          width: 100%;
          max-width: max-content;
          max-height: 150px;
          margin-top: 5px;
        }
        .heading7{
          font-size: 15px;
          @media screen and (max-width:480px) {
            font-size: 14px;
            line-height: 1.2;
          }
        }
      }
    }
    
  }
  .plp__compare__popup__button__block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid #e7e7e7;
    padding: 10px 20px;
    a{ text-decoration: none; }

    .compare__button {
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      font-family: 'SSTbold';
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 8px 15px;
      width: 150px;
      background-color: #dc3a1a;
      text-align: center;
      text-transform: uppercase;
      width: fit-content;
      border: none;
      display: flex;
      align-items: center;
      .compare__button__icon {
        margin: 0px 20px 0px 0px;
        @media screen and (max-width:992px) {
          margin: 0px 10px 0px 0px;
        }
        @media screen and (max-width:992px) {
          font-size: 14px;
        }
      }
      &:hover{
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
    }
    .removeAll__button{
        border-radius: 5px;
      color: #000000;
      font-size: 14px;
      font-family: 'SSTbold';
      font-weight: bold;
      padding: 8px 15px;
      width: 150px;
      background: none;
      text-align: center;
      text-decoration: underline;
      width: fit-content;
      border: none;
    }
  }
  .invalid_err_message{
    font-size: 13px;
    margin: 0px 10px 8px !important;
  }
}

}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  
  .container-fluid.cart__popup__container.plp__compare{
    right: 0px;
    left: auto;
    
    .plp__compare__popup__block{
      .plp__compare__popup__button__block{
        .removeAll__button{
          font-family: 'SSTArabicBold';
        }
        .compare__button{
          font-family: 'SSTArabicBold';
          .compare__button__icon{
            margin: 0px 0px 0px 10px;
          }
        } 
      } 
    }
  }
   

}

/*-------------------------- Arabic view End ------------------------*/