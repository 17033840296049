.isChildProduct{
  padding-left: 6%!important;
  margin-top: -22px!important;
}
.cross-price {
  font-size: 13px;
  color: rgb(255, 79, 4);
  text-decoration: line-through;
}
.virtualProductError {
  color: red;
}
.sc__product__block {
  background-color: #ffffff;
  width: 100%;
  height: fit-content;
  padding: 20px 0px;
  margin: 0px;
  .sc__product__left__block {
    padding: 0px 20px 0px;

    .sc__product__image__block {
      width: 100%;
      height: 172px;
      padding: 10px;

      // background-color: #e9f3f3;
      // border: 1px solid #c8c8c8;
      .sc__product__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .sc__product__middle__block {
    padding: 0px 20px 0px;
    .sc__product__company__logo {
      margin: 0px 0px 16px;
    }
    }
    
    .sc__offers__discounts__block {
      padding: 20px 0px 0px;
      .sc__form__block {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 20px 0px;
        .sc__form__field {
          width: 70%;
          .sc__input__field {
            width: 100%;
            border: 1px solid #c8c8c8;
            border-radius: 4px 0px 0px 4px;
            outline: none;
            padding: 10px 5px 10px 15px;
            font-size: 14px;
            font-family: "SSTregular";
          }

          .sc__input__button {
            border-radius: 0px 4px 4px 0px;
            color: #ffffff;
            padding: 9px 27px;
            background-color: #000000;
            width: fit-content;
            outline: none;
            border: none;
            font-size: 16px;
            font-family: 'SSTbold';
            font-weight: bold;
          }
        }
      }
    }                                                                      
    .sc__counter__block{

      .delete__icon__button__block{
        cursor: pointer;
        width: 15px;
        line-height: 1;
        img{
          cursor: pointer;
          &:hover{
            filter: invert(48%) sepia(13%) saturate(3757%) hue-rotate(328deg) brightness(95%) contrast(80%);
          }
        }
      }
    }
  
  .sc__product__right__block {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding: 0px 20px;
  }
}