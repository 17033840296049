.bundle__product__listArea {
    padding: 20px;
    border: 1px solid #e4e4e4;
    border-radius: 15px;
    @media screen and (max-width:1030px) { 
        padding: 15px;
    }
    @media screen and (max-width:992px) {
        border-radius: 0px;
    }

    .bundle__tab__list {
        display: flex;
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: flex-start;

        @media screen and (max-width:992px) {
            flex-wrap: nowrap;
            overflow: scroll;
            overflow-y: hidden; 
            padding-bottom: 7px !important;
            border-bottom: 1px solid #e4e4e4;
            scroll-padding-bottom: 5px;

            &::-webkit-scrollbar {
            display: none;
            }
            &::-webkit-scrollbar-thumb{
                background-color: transparent;
                height: 1px;
            }          
        }
        

        .nav-item{
            margin-right: 15px;
            @media screen and (max-width:670px) { 
                margin-right: 12px;
            }
            &:last-child{
                margin-right: 0px;
            }
            .nav-link {
                font-size: 17px;
                color: #000000;
                border-radius: 30px;
                padding: 5px 15px;
                line-height: 1.1;
                margin-bottom: 8px;                
                
                @media screen and (max-width:1500px) { 
                    font-size: 16px;
                    padding: 5px 14px;
                }
                @media screen and (max-width:1480px) { 
                    font-size: 16px;
                    padding: 5px 13px;
                }
                @media screen and (max-width:1366px) { 
                    font-size: 15px;
                    padding: 5px 12px;
                } 
                @media screen and (max-width:1280px) { 
                    font-size: 14px;
                    padding: 5px 8px;
                }
                @media screen and (max-width:1030px) { 
                    padding: 5px 7px;
                }
                @media screen and (max-width:670px) { 
                    width: max-content;
                }
                &.active {
                    background-color: #000000;
                    color: #ffffff;                    
                    font-family: 'SSTMedium';
                }
            }
        }        
    }

    .bundle__tab__contentbox {
        margin: 4rem 0rem 1rem;
        padding-right: 1.2rem;
        max-height: 500px;
        overflow: scroll;
        overflow-x: hidden;

        /* Track */
        &::-webkit-scrollbar-track {
            /* box-shadow: inset 0 0 5px grey;
                border-radius: 10px; */
            /* transform: translateY(100%); */
            z-index: 10;
            opacity: 0;
            background: #F3F3F3;
        }
        
        &::-webkit-scrollbar-track:hover {
            /* box-shadow: inset 0 0 5px grey;
            border-radius: 10px; */
            opacity: 1;
            background: #F3F3F3;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            opacity: 0;
            background-color: #D9D9D9;
            /* background: red; */
            border-radius: 10px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #303030;
        }
        @media screen and (max-width:992px) {
            margin: 1rem 0rem ;
            padding-right: 0.5rem;
        }

        .pd__bundle__productList {
            .pd__bundle__pro__itembox {
                padding: 15px;
                border-bottom: 1px solid #E0E6EF;
                @media screen and (max-width:992px) {
                    padding: 10px 0px;
                }
                .pd__bundle__Pro__imgBox {
                    width: 100%;

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }

                .pd__bundle__Pro__Name {
                    color: #000000;
                    margin-bottom: 20px;
                    font-size: 16px;
                    font-weight: bold;
                    text-decoration: none;

                    @media screen and (max-width:992px) {
                        font-size: 13px;
                        margin-bottom: 8px;
                    }
                }

                .pd__bundle__available__offerbox {
                    padding: 9px 0px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;

                    .bundle__available__iconbox {
                        margin-right: 12px;
                        width: 10%;

                        img {
                            max-height: 38px;
                            width: 35px;
                        }
                    }

                    .pd__bundle__avalable__content {
                        p {
                            color: #000000;
                            font-size: 14px;
                            margin-bottom: 5px;
                            line-height: 1.1;
                            @media screen and (max-width:992px){
                                font-size: 12px;
                                margin-bottom: 0px;
                            }
                        }

                        a {
                            font-size: 13px;
                            color: #000000;
                            font-family: 'SSTregular';
                            display: block;
                            text-decoration: none;
                            @media screen and (max-width:992px){
                                font-size: 12px;
                            }
                        }
                    }
                }
                .pd__bundle__pickup__block{
                    display: flex;
                    flex-direction: column;
                    .store__pickup__taken__area {
                        margin: 0px 0px 10px;
                    }
                    .store__pickup__location__listing{
                        display: flex;
                        flex-direction: column;
                        .locationCard {
                            padding: 15px;
                            border: 1px solid #e7e7e7;
                        }
                    }
                    .location__show__more__button {
                        border: none;
                        padding: 8px 10px;
                        width: 100%;
                        text-align: center;
                        font-family: "SSTbold";
                        color: #000000;
                        font-size: 15px;
                        background-color: unset;
                        text-decoration: underline;
                    }
                    
                }

                .pd__bundle__col_childBox {                    
                    text-align: right;
                    @media screen and (max-width:992px) { 
                        text-align: unset; 
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .pd__bundle__priceBox {
                        display: inline-block;
                        margin-left: auto;
                        width: auto;

                        @media screen and (max-width:992px) {
                            margin-left: unset;
                        }
                        .text3 {
                            color: #7D7D7D;
                            font-size: 15px;
                            font-family: 'SSTregular';
                            position: relative;
                            display: inline-block;
                            line-height: 1;

                            &::after {
                                content: " ";
                                display: block;
                                position: absolute;
                                width: 100%;
                                height: 1px;
                                background-color: #7D7D7D;
                                transform: translateY(-8px) rotate(-6deg);
                                bottom: 0;
                                left: 0;
                            }
                            @media screen and (max-width:992px) {
                                font-size: 13px;
                            }
                        }

                        .heading4 {
                            color: #000000;
                            font-weight: 900;
                            font-family: 'SSTbold';
                            font-size: 20px;
                            line-height: 1;
                            @media screen and (max-width:992px) {
                                font-size: 16px;
                            }
                        }
                    }

                    
                }

                .Add__bundle__btn {
                    background-color: #FE6321;
                    padding: 8px 12px;
                    color: #ffffff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: 'SSTmedium';
                    width: 23%;
                    flex: 0 0 23%;
                    max-width: 220px;
                    border: none;
                    border-radius: 9px;
                    margin-left: auto;

                    @media screen and (max-width:1280px) {
                        max-width: 190px;
                    }
                    @media screen and (max-width:992px) {
                        padding: 6px 10px;
                        font-size: 13px;
                        width: auto;
                        flex: 0 0 50%;
                        max-width: 220px;
                        margin-left: 0px;
                        border-radius: 3px;
                        line-height: 1.15;
                    }
                    @media screen and (max-width:380px) {
                        padding: 6px 5px;
                        font-size: 12px;
                    }
                    .btn__spinner{                        
                        min-height: 22.5px;
                        @media screen and (max-width:1366px) { min-height: 21px; } 
                        @media screen and (max-width:992px) { min-height: 19.5px; }
                        @media screen and (max-width:380px) { min-height: 11px; }
                    }
                }
            }
        }
    }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

    .bundle__product__listArea {
        .bundle__tab__contentbox {
            .pd__bundle__productList {
                .pd__bundle__pro__itembox {
                    .pd__bundle__available__offerbox {
                        .bundle__available__iconbox{
                            margin-right: 0px;
                            margin-left: 12px;
                        }
                    }
                }
            }
        }
    }

}

/*-------------------------- Arabic view End ------------------------*/