
.three__products__container {
    margin: 20px 0px;
    .three__products__block {
      width: 1420px;
      margin: 0px auto;
      padding: 0px;
      .three__products__row {
        .three__product__block {
          .ineer__three__product__block {
            padding: 20px;
            background-color: #f1f1f1;
  
            .three__product__image__block {
              width: fit-content;
              margin: 0px auto;
              overflow: hidden;
              height: 177px;
              display: flex;
              align-items: center;
              justify-content: center;
              .three__product__image {
                width: 100%;
                height: 100%;
              }
            }
            .three__product__name {
             font-family: 'SSTbold';
font-weight: bold;
              font-size: 30px;
              font-weight: bold;
              color: #363636;
              margin: 0px;
            }
            .three__product__description {
              font-size: 15px;
              color: #363636;
              margin: 0px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1450px) {
    .three__products__block {
      width: 90% !important;
      max-width: 90% !important;
    }
  
  }
  @media screen and (max-width: 1200px) {
    .three__product__image__block {
      height: 137px !important;
    }
    .three__product__name {
      font-size: 24px !important;
    }
    .three__product__description {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 992px) {
    .three__products__block {
      min-width: 100% !important;
      margin: 0px auto;
    }
    .three__product__image__block {
      height: 96px !important;
    }
    .three__product__name {
      font-size: 18px !important;
    }
    .three__product__description {
      font-size: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    .three__products__block {
      width: 100% !important;
      // margin: 0px ;
    }
    .ineer__three__product__block {
      padding: 10px !important;
      margin: 0px 0px 20px !important;
    }
    .three__product__image__block {
      height: 96px !important;
    }
    .three__product__name {
      font-size: 18px !important;
    }
    .three__product__description {
      font-size: 12px !important;
    }
  }
  