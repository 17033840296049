
.sonyMap__page__container{
    padding: 0px 12px;
    background-color: #f5f5f5;
    .sonyMap__main__block{
        padding: 15px;
        width: 1420px;
        margin: 0px auto;
        background-color: #ffffff;
        @media screen and (max-width: 1450px) {
            width: 90% !important;
            max-width: 90% !important;
        }
        @media screen and (max-width: 992px) {
            width: 100% !important;
            max-width: 100% !important;
        }

        .sonyMap__header{
            border-bottom: 1px solid #cdcdcd;
            padding: 10px 0px;
            margin-bottom: 20px;
        }
        .sonyMap__body{
            .map__listbox{
                .map__itembox{
                    .map__link{
                        text-decoration: none;
                        color: #dc3a1a;
                    }
                }
            }
        }

    }

}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {



}

/*-------------------------- Arabic view End ------------------------*/