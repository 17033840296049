.dealsOfTheWeek__container {
  margin: 40px 0px 20px;
  @media screen and (max-width:992px) {
    margin: 20px 0px 20px;
  }
  .dealsOfTheWeek__block {
    width: 1420px;
    padding: 0px;
    margin: 0px auto;
    .section__title {
     font-family: 'SSTbold';
      font-weight: bold;
      font-size: 24px;
      line-height: 1;
      @media screen and (max-width:580px) {
        font-size: 20px !important;
      }
    }
    .deals__block {
      overflow: hidden;
      height: auto;
      // border-radius: 15px;
      display: flex;
      margin: 0px 0px 0px 0px;
      // justify-content: center;
      // align-items: center;
      .deals__image {
        width: 100%;
        // height: 100%;
        // border-radius: 15px;
        // object-fit: cover;
      }
      a {
        display: block;
        width: 100%;
        border-radius: 12px;        
        img{
          border-radius: 12px;
        }
      }
    }
    .slick-slider{
      .slick-slide{    
        padding-right: 4px;    
      }
      .slick-arrow{
        height: 35px;
        width: 35px;
        z-index: 3;

        @media screen and (max-width:580px) {
          height: 24px;
          width: 24px;
        }
        @media screen and (max-width:380px) {
          height: 22px;
          width: 22px;
        }
        &::before{
          color: #b5b3b3;
          font-size: 35px;
          line-height: 1.1;
          @media screen and (max-width:580px) {
            font-size: 24px;
          }
          @media screen and (max-width:380px) {
            font-size: 22px;
          }
        }
        &.slick-prev{
          @media screen and (max-width:992px) {
            left: 6px !important;
          }
          @media screen and (max-width:580px) {
            left: 4px !important;
          }
        }
        &.slick-next{
          @media screen and (max-width:992px) {
            right: 5px !important;
          }
          @media screen and (max-width:580px) {
            right: 4px !important;
          }
        }

      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .dealsOfTheWeek__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .dealsOfTheWeek__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 1200px) {
  .deals__block {
    // height: 260px !important;
  }
}
@media screen and (max-width: 992px) {
  .deals__block {
    // overflow: hidden;
    // max-height: 255px !important;
  }
  .dealsOfTheWeek__block {
    min-width: 100% !important;
    margin: 0px auto;    
  }

}
@media screen and (max-width: 768px) {
  .dealsOfTheWeek__block {
    width: 100% !important;
   
  }
  .deals__block {
    // height: 200px !important;
  }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .dealsOfTheWeek__container {
    .dealsOfTheWeek__block {
      .slick-slider {
        .slick-arrow{
          &.slick-prev{
            @media screen and (max-width:992px) {
              left: auto !important;
              right: 5px !important; 
            }
          }
          &.slick-next{
            @media screen and (max-width:992px) {
              left: 6px !important;
              right: auto !important; 
            }
          }
        }
      }
    }
  }

}

/*-------------------------- Arabic view End ------------------------*/