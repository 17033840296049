.newsLetterModel__container{
    margin: 10px 0px 0px 0px;
    width: 100%;
    .newsLetterModel__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 0px;
        border-bottom: 1px solid #818181;
        background-color: #ffffff;
        .cancel__button {
            cursor: pointer;
          }
    }
    .newsLetterModel__content{
        padding: 30px 0px;
    background-color: #ffffff;
    .main__form__field__block {
        margin: 0px 0px 30px;
        .form__label {
        }
        .field__block {
          border: 1px solid #c8c8c8;
          border-radius: 4px;
          padding: 10px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .form__field {
            width: 100%;
            outline: none;
            border: none;
            font-size: 16px;
            letter-spacing: 0.3px;
          }
        }
      }
      .invalid__message{
        color: red;
        margin: 5px 0px 0px;
      }
      .subscribe__button{
        background: #dc3a1a 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border-radius: 5px;
      color: white;
      outline: none;
      border: none;
      width: 100%;
      font-size: 16px;
     font-family: 'SSTbold';
font-weight: bold;
      padding: 16px 0px;
      
      }
     .getOtp__button{
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000005;
        border: 2px solid #000000;
        border-radius: 5px;
        color: black;
        outline: none;
        width: 100%;
        font-size: 16px;
       font-family: 'SSTbold';
font-weight: bold;
        padding: 16px 0px;
        margin: 0px 0px 10px;
     }   
     .google__signin__button{
        background: #FF3D2B 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000005;
        border-radius: 5px;
        color: white;
        outline: none;
        border: none;
        width: 100%;
        font-size: 16px;
        font-family: "SSTregular";
        padding: 16px 20px;
        margin: 0px 0px 10px;
        display: flex;
        align-items: center;
        .signin__button__icon{
          margin: 0px 30px 0px 0px;  
        }
     }   
   
     
    }
}
.resetPassword__area{
  margin: 10px 0px 0px 0px;
    width: 100%;
  .newsLetterModel__header{
    padding: 15px 0px;
  }
  .newsLetterModel__content{
    padding: 20px 0px;

    .main__form__field__block{
      margin-top: 15px;
    }
    .subscribe__button{
      font-size: 14px;
      font-family: "SSTbold";
      font-weight: bold;
      padding: 12px 10px;
      .btn__spinner{
        min-height: 21px;        
      }
    }
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .resetPassword__area {
    .newsLetterModel__content {
      .subscribe__button{
        font-family: 'SSTArabicBold';
      }
    }
  }

}

/*-------------------------- Arabic view end ------------------------*/