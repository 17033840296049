@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

.HeroWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: 100px;
  gap: 20px;

  h1 {
    width: 100%;
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 70px;
    font-weight: 700;
  }

  .purple {
    color: #9E84FF;
    font-family: Poppins;
    font-size: 50px;
    font-weight: 700;
    margin-top: -40px;
  }

  .info {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 575px;
    gap: 20px;

    p {
      width: 100%;
      color: #FFFFFF;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      text-align: start;
    }
  }
}

@media screen and (max-width: 768px) {
  .HeroWrapper {
    padding: 20px;

    h1 {
      font-size: 40px;
    }

    .purple {
      font-size: 30px;
    }

    .info {
      p {
        font-size: 13px;
      }
    }
  }

}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .HeroWrapper {
    h1 {
      text-align: center;
    }

    .purple {
      text-align: center;
    }

    .info {
      p {
        text-align: center;
      }
    }


  }
}