.feature__category__container {
  .feature__category__block {
    width: 1420px;
    padding: 0px;
    margin: 0px auto;

    .section__title {
      font-family: 'SSTbold';
      font-weight: bold;
      font-size: 24px;
      line-height: 1;
    }

    .inner__feature__category__block {
      // display: flex;
      // flex: 0 0 100%;
      // max-width: 100%;
      // justify-content: center;

      .first__feature__category__block {
        width: 40%;
        margin: 0px 0px 20px;
        text-decoration: none;
        color: #000000;
        padding: 0px 9px;

        @media screen and (max-width:1100px) {
          width: 100%;
        }

        .first__feature__category {
          background: #ffffff;
          height: 100%;
          border-radius: 15px;
          padding: 80px 25px 21px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          transition: all 0.2s;

          &:hover {
            box-shadow: 0px 0px 9px #d9d9d9;

          }

          .deal__of__day__container {
            position: relative;
            display: flex;
            left: -53px;
            height: 60px;
            width: 305px;
            max-width: 60%;

            @media (max-width:1200px) {
              height: 58px;
            }

            // &::before{            
            //     content: " ";
            //     display: block;
            //     position: absolute;
            //     height: 29px;
            //     width: 28px;
            //     bottom: 0px;
            //     left: 0px;
            //     background-color: #ffaf2e;
            //     border-radius: 0px 0px 0px 6px;            

            // }
            .rectangleLeft {
              position: absolute;
              top: 39px;
              width: 28px;
              height: 22px;
              background-color: #cc8a15;
              // border-bottom-left-radius: 30px;
              // border-top-left-radius: 30px;
              border-radius: 20px 0px 0px 8px / 20px 0px 0px 15px;
            }

            .upper__rectangle__block {
              position: absolute;
              top: 0px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .rectangle {
                top: 0px;
                width: 240px;
                height: 40px;
                background: transparent linear-gradient(271deg, #ffdb3b 0%, #ffad2e 100%) 0% 0% no-repeat padding-box;
                font-family: 'SSTbold';
                font-weight: bold;
                font-size: 20px;
                display: flex;
                align-items: center;
                padding: 0px 20px;
                justify-content: flex-end;
                border-top-left-radius: 15px;
                text-transform: uppercase;
              }
            }

            .triangle-right {
              background: transparent linear-gradient(271deg, #ffdb3b 0%, #ffad2e 100%) 0% 0% no-repeat padding-box;
              //   padding-box;
              // width: 280px;
              // height: 74px;
              // position: relative;

              width: 0;
              height: 0;
              border-top: 20px solid transparent;
              border-left: 14px solid #ffdb3b;
              border-bottom: 20px solid transparent;
            }
          }
          .first__feature__action__area{
              display: flex;
              flex: 0 0 100%;
              max-width: 100%;
              position: absolute;
              width: 100%;
              left: 0px;
              top: 70px;
              justify-content: space-between;
              padding: 0px 20px;
            .fav_icon {
              display: flex;
              flex: 0 0 50%;
              max-width: 50%;
            }
            .pro__feature__optionBox {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-start;
              position: relative;
              // top: 15px;
              // right: 20px;
              // top: 50px;
  
              p {
                margin: 0px 7px 0px 0px;
                min-width: 50px;
                max-width: 80px;
                padding: 5px 4px;
                // background-color: #000000;
                // color: #ffffff;
                // border-radius: 8px;
                text-align: center;
                // line-height: 1;
                font-size: 13px;
                // box-shadow: 0px 1px 2px 0px #868383;
                background-color: #f5f4f4;
                color: #000000;
                font-weight: 600;
                border-radius: 4px;
                line-height: 1.5;
  
                &:last-child {
                  margin-right: 0px;
                }
              }
  
            }
          }
          .first__feature__category__image__block {
            width: 100%;
            // margin: 20px auto 40px;
            background: #ffffff;
            margin-left: 0px;
            // height: 100%;
            // display: flex;
            // justify-content: center;
            // align-items: center;

            .first__feature__category__image {
              object-fit: contain;
              // height: 100%;
              // max-width: 70%;
              max-width: 75%;
              margin: 0px auto;
              cursor: pointer;
              display: block;
              transition: all 0.3s;

              &:hover {
                transform: scale(1.1);
              }

              @media screen and (max-width: 1450px) {
                max-width: 100%;
              }
            }
          }

          .feature__Pricebox__area {
            margin-bottom: 8px;
            min-height: 40px;
            display: inline-flex;
            flex-direction: column;
            width: 50%;
            justify-content: flex-end;

            span {
              &.text3 {
                margin-bottom: 0px !important;
                position: relative;
                width: fit-content;
                line-height: 1.2;

                &::after {
                  content: " ";
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 1px;
                  background-color: #666666;
                  -webkit-transform: translateY(-8px) rotate(-6deg);
                  transform: translateY(-8px) rotate(-6deg);
                  bottom: 0;
                  left: 0;
                }
              }
            }

          }

          .heading__inner__text {
            margin-left: 0px;
            background: #ffffff;
            cursor: pointer;

            .first__feature__pro__namebox {
              text-decoration: none;

              p {
                min-height: 62px;
              }
            }

            .plp__tamara__box {
              display: inline-flex;
              width: 100%;
              max-width: 100%;
              justify-content: flex-start;
            }

            .product__title {
              font-family: 'SSTbold';
              font-weight: bold;
              height: 72px;
              overflow: hidden;
              text-decoration: none;
              color: #000000;
            }

            .cart_end_icon {
              // width: 30px;
              display: inline-block;
              float: right;
              // cursor: pointer;
              // // display: none;
              // transition: all 0.3s ease-in-out;
              // -webkit-transition: all 0.3s ease-in-out;

              // &:hover {
              //   transform: scale(1.1);
              //   -webkit-transform: scale(1.1);
              //   -moz-transform: scale(1.1);
              //   -ms-transform: scale(1.1);
              // }
              .addToCart__button {
                border-radius: 5px;
                color: #ffffff;
                font-size: 15px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 8px 15px;
                background-color: #DC3A1A;
                text-transform: uppercase;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 6px;

                .addToCart__icon {
                  margin: 0px 10px 0px 0px;
                  width: 19px;
                }

                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                  box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
                  transform: translateY(-2px);
                  -webkit-transform: translateY(-2px);
                  transition: all 0.2s ease-in-out;
                  -webkit-transition: all 0.2s ease-in-out;
                  opacity: 0.8;
                }

                // &:focus {
                //   -webkit-box-shadow: 0 5px 5px -4.8px rgb(32 32 32 / 60%);
                //   box-shadow: 0 5px 5px -4.8px rgb(32 32 32 / 60%);      
                //   transform: translateY(0px);
                //   -webkit-transform: translateY(0px);
                //   transition: all 0.2s ease-in-out;
                //   -webkit-transition: all 0.2s ease-in-out;
                // }
                .btn__spinner{
                  width: 136.9px;
                  min-height: 22.5px;
                  @media screen and (max-width:1366px) { }
                }
                &.pre-order{
                  .btn__spinner{
                    width: 118.8px;
                    min-height: 22.5px;
                    @media screen and (max-width:1366px) { width: 112.8px; min-height: 21px; }
                    @media screen and (max-width:1280px) { width: 108.4px; min-height: 21px; }
                    @media screen and (max-width:1200px) { width: 97.92px; min-height: 19.5px; }
                  }
                }
              }
              
              .outofStock__button {
                border-radius: 5px;
                color: #ffffff;
                font-size: 15px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 8px 15px;
                background-color: #a7a7a7;
                text-transform: uppercase;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 6px;
              }
            }
          }
        }
      }

      .second__category__area {
        // display: flex;
        // width: 100%;
        // flex: 0 0 100%;
        // max-width: 100%;

        // // flex-direction: column;
        // align-items: flex-start;

        @media screen and (max-width:1100px) {
          // width: 100%;
          // flex: 0 0 100%;
          // max-width: 100%;
        }

        .second__feature__category__block {
          display: flex;
          // width: 100%;
          // flex: 0 0 100%;
          // max-width: 100%;
          // width: 100%;
          // min-width: 100%;
          flex-direction: row;
          justify-content: space-between;
          // padding: 0px 9px;     

          .first__in__column {
            margin-bottom: 20px;
          }

          .feature__category {
            background-color: #ffffff;
            padding: 40px 30px 20px;
            // height: 48.6%;
            border-radius: 15px;
            text-decoration: none;
            color: #000000;
            position: relative;
            transition: all 0.2s;
            margin: 0px 0px 20px;
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &:hover {
              box-shadow: 0px 0px 9px #d9d9d9;
            }

            .fav_icon{
              // position: absolute;
              height: auto;
              width: 25px;
              left: 20px;
              top: 15px;
              cursor: pointer;
            }
            .pro__feature__optionBox {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-start;
              position: absolute;
              top: 15px;
              right: 20px;

              p {
                margin: 0px 7px 0px 0px;
                min-width: 50px;
                max-width: 80px;
                padding: 5px 4px;
                // background-color: #000000;
                // color: #ffffff;
                // border-radius: 8px;
                text-align: center;
                // line-height: 1;
                font-size: 13px;
                // box-shadow: 0px 1px 2px 0px #868383;
                background-color: #f5f4f4;
                color: #000000;
                font-weight: 600;
                border-radius: 4px;
                line-height: 1.5;

                &:last-child {
                  margin-right: 0px;
                }
              }

            }
            .product__pics__area{
              position: relative;
            .second__feature__category__image__block {
              width: 100%;
              margin: 5px auto 0px;
              padding: 0px 2px 10px;
              overflow: hidden;
              // max-height: 150px;
              min-height: 190px;
              display: flex;
              justify-content: center;
              align-items: center;

              .second__feature__category__image {
                height: 190px;
                max-height: 190px;
                // object-fit: contain;
                transition: all 0.3s;

                &:hover {
                  transform: scale(1.1);
                }
              }
            }
            .soldOutLable {
              position: absolute;
              width: 100%;
              background: hsla(0,0%,85.1%,.76);
              height: 30px;
              top: 45%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-style: normal;
              font-weight: 700;
              font-size: 15px;
              line-height: 21px;
              color: rgba(0,0,0,.72);
              text-transform: uppercase;
              @media screen and (max-width:480px){
                top: 35%;
              }
            }
          }
            .featureProduct__name {
              font-family: 'SSTbold';
              font-weight: bold;
              font-size: 14px;
              margin: 10px 0px 8px 0px;
              // height: 65px;
              text-decoration: none;
              color: #000000;
              display: block;
              overflow: hidden;
              min-height: 63px;
            }

            .plp__tamara__box {
              display: inline-flex;
              width: 100%;
              max-width: 100%;
              justify-content: flex-start;
            }

            .featureProduct__rating__block {
              margin: 0px 0px 5px;

              .main__rating__block {
                padding: 0px;
              }
            }

            .feature__Pricebox__area {
              // margin-bottom: 8px;
              min-height: 40px;
              display: inline-flex;
              flex-direction: column;
              width: 45%;
              justify-content: flex-end;

              @media screen and (max-width:1420px) {
                width: 42%;
              }

              @media screen and (max-width:1280px) {
                width: 40%;
              }

              @media screen and (max-width:1280px) {
                width: 38%;
              }

              span {
                &.text4 {
                  margin-bottom: 0px !important;
                  position: relative;
                  width: fit-content;
                  line-height: 1.2;

                  &::after {
                    content: " ";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background-color: #666666;
                    -webkit-transform: translateY(-8px) rotate(-6deg);
                    transform: translateY(-8px) rotate(-6deg);
                    bottom: 0;
                    left: 0;
                  }
                }
              }

            }

            .cart_end_icon {
              // width: 30px;
              display: inline-block;
              float: right;
              cursor: pointer;
              // display: none;
              // transition: all 0.3s ease-in-out;
              // -webkit-transition: all 0.3s ease-in-out;

              // &:hover {
              //   transform: scale(1.1);
              //   -webkit-transform: scale(1.1);
              //   -moz-transform: scale(1.1);
              //   -ms-transform: scale(1.1);
              // }
              .addToCart__button {
                border-radius: 5px;
                color: #ffffff;
                font-size: 15px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 8px 10px;
                background-color: #DC3A1A;
                text-transform: uppercase;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 6px;

                .addToCart__icon {
                  margin: 0px 10px 0px 0px;
                  width: 19px;

                  @media screen and (max-width:1420px) {
                    width: 18px;
                  }

                  @media screen and (max-width:1280px) {
                    width: 16px;
                    margin: 0px 8px 0px 0px;
                  }

                  @media screen and (max-width:1200px) {
                    width: 14px;
                    margin: 0px 5px 0px 0px;
                  }
                }

                .spinner-border {
                  margin-left: 10px !important;

                  @media screen and (max-width:1420px) {
                    margin-left: 8px !important;
                    width: 0.8rem;
                    height: 0.8rem;
                  }

                  @media screen and (max-width:1200px) {
                    margin-left: 6px !important;
                    width: 0.6rem;
                    height: 0.6rem;
                  }
                }

                @media screen and (max-width:1366px) {
                  font-size: 14px;
                }

                @media screen and (max-width:1200px) {
                  font-size: 13px;
                }

                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                  box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
                  transform: translateY(-2px);
                  -webkit-transform: translateY(-2px);
                  transition: all 0.2s ease-in-out;
                  -webkit-transition: all 0.2s ease-in-out;
                  opacity: 0.8;
                }

                // &:focus {
                //   -webkit-box-shadow: 0 5px 5px -4.8px rgb(32 32 32 / 60%);
                //   box-shadow: 0 5px 5px -4.8px rgb(32 32 32 / 60%);      
                //   transform: translateY(0px);
                //   -webkit-transform: translateY(0px);
                //   transition: all 0.2s ease-in-out;
                //   -webkit-transition: all 0.2s ease-in-out;
                // }
                .btn__spinner{
                  min-width: 136.8px;
                  min-height: 22.5px;                  
                  @media screen and (max-width:1366px) { min-width: 128.8px; min-height: 21px; }
                  @media screen and (max-width:1280px) { min-width: 124.58px;}
                  @media screen and (max-width:1200px) { min-width: 113.05px; min-height: 19.5px; }
                }

                &.pre-order{
                  .btn__spinner{
                    min-width: 118.8px;
                    min-height: 22.5px;
                    @media screen and (max-width:1366px) { min-width: 112.8px; min-height: 21px; }
                    @media screen and (max-width:1280px) { min-width: 108.4px; min-height: 21px; }
                    @media screen and (max-width:1200px) { min-width: 97.92px; min-height: 19.5px; }
                  }
                }
              }
              .notifyMe__button{
                border-radius: 5px;
                color: #ffffff;
                font-size: 15px;
                font-family: 'SSTbold';  
                text-transform: uppercase;
                letter-spacing: 0.85px;
                padding: 8px 10px;
                line-height: 1.5;                
                background-color: #FE8B21;  
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0px;
                
                &:hover,&:focus {
                  -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                  box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);
                  -webkit-transform: translateY(-2px);
                  transform: translateY(-2px);
                  transition: all 0.2s ease-in-out;
                  -webkit-transition: all 0.2s ease-in-out;
                  opacity: 0.8;
                }  
                .spinner-border {
                  margin-left: 10px !important;

                  @media screen and (max-width:1420px) {
                    margin-left: 8px !important;
                    width: 0.8rem;
                    height: 0.8rem;
                  }

                  @media screen and (max-width:1200px) {
                    margin-left: 6px !important;
                    width: 0.6rem;
                    height: 0.6rem;
                  }
                }
                @media screen and (max-width:1366px) {
                    font-size: 14px;
                }
                @media screen and (max-width:580px) {
                  padding: 8px 10px;
                  font-size: 13px;      
                }
                // @media screen and (max-width:480px) {
                //   font-size: 13px;
                //   padding: 5px 10px;
                //   line-height: 1;
                //   letter-spacing: 0.5px;
                // }
              
                img {
                  margin: 0px 8px 0px 0px;
                  height: 14px;
                  width: auto;
                  vertical-align: sub;     
                  
                  @media screen and (max-width:480px) {
                    margin: 0px 4px 0px 0px;
                    height: 13px;
                  }
                }
                
              }
              .outofStock__button {
                border-radius: 5px;
                color: #ffffff;
                font-size: 15px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 8px 12px;
                background-color: #a7a7a7;
                text-transform: uppercase;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 6px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .feature__category__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}

@media screen and (max-width: 1450px) {
  .feature__category__block {
    width: 90% !important;
    max-width: 90% !important;
  }

  // .first__feature__category__image {
  //   width: 100% !important;
  // }

  .feature__category {
    padding: 15px !important;
  }
}

@media screen and (max-width: 1400px) {
  .second__feature__category__image__block {
    width: 100% !important;
  }
}

@media screen and (max-width: 1200px) {
  // .first__feature__category__title {
  //   margin: 0px 0px 0px 100px !important;
  // }

  // .first__feature__category__subtitle {
  //   margin: 0px 0px 0px 100px !important;
  // }



  .feature__category__container .first__feature__category__image {
    height: 100% !important;
    // padding-top: 50px !important;
  }

  .feature__category__container .first__feature__category__image__block {
    height: 420px !important;
  }
}

@media screen and (max-width: 992px) {
  .feature__category {
    padding: 20px 20px 30px !important;
  }

  .feature__category__block {
    min-width: 100% !important;
    margin: 0px auto;
  }

  .feature__category__container {
    background: #fffbe4;
  }

  .second__feature__category__image {
    width: 100% !important;
  }

  .deal__of__day__container {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .first__feature__category__block {
    width: 100% !important;
  }

  .second__feature__category__block {
    width: 50% !important;
  }

  .second__feature__category__image {
    width: 90% !important;
  }
}

@media screen and (max-width: 576px) {
  .feature__category__block {
    width: 100% !important;
    margin: 0px auto;

    .inner__feature__category__block {
      display: flex;
      flex-direction: column;

      .first__feature__category__block {
        width: 100% !important;
      }

      .second__feature__category__block {
        width: 100% !important;
      }
    }
  }

  .first__in__column {
    margin-bottom: 20px !important;
  }

  .second__feature__category__image {
    width: 80% !important;
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .feature__category__container {
    .feature__category__block {
      .inner__feature__category__block {
        .first__feature__category__block {
          .first__feature__category {
            .deal__of__day__container {
              left: auto;
              height: 60px;
              right: -50px;

              &::before {
                bottom: 0px;
                left: auto;
                right: 0px;
                width: 26px;
                border-radius: 0px 0px 0px 6px;
              }

              .rectangleLeft {
                width: 26px;
                border-radius: 0px 20px 8px 0px/0px 20px 15px 0px;
              }

              .upper__rectangle__block {
                .rectangle {
                  top: 0px;
                  width: 240px;
                  height: 40px;
                  background: transparent linear-gradient(91deg, #ffdb3b 0%, #ffad2e 100%) 0% 0% no-repeat padding-box;
                  border-top-right-radius: 15px;
                  border-top-left-radius: 0px;
                }

                .triangle-right {
                  border-left: 15px solid transparent;
                  border-top: 18px solid transparent;
                  border-right: 15px solid #ffdb3b;
                  border-bottom: 20px solid transparent;
                }
              }
            }
            .first__feature__action__area{
                
              .fav_icon {
                justify-content: flex-end;
              }
              .pro__feature__optionBox {
                justify-content: flex-start;

                p {
                  margin: 0px 0px 0px 7px;

                  &:last-child {
                    margin-left: 0px;
                  }
                }
              }
            }
            .addToCart__button,.outofStock__button,.notifyMe__button,.pre-order {
              font-family: 'SSTArabicBold';
              font-weight: bold;      
            }
            .addToCart__button {
              .addToCart__icon {
                margin-right: 0px !important;
                margin-left: 10px !important;
              }
              .btn__spinner{
                width: 134.43px;
                min-height: 22.5px;
                @media screen and (max-width:1366px) { }
              }
              &.pre-order{
                .btn__spinner{
                  width: 111.77px;
                  min-height: 22.5px;
                  @media screen and (max-width:1366px) {  }
                }
              }
            }
            .notifyMe__button{

              @media screen and (max-width:1200px) {
                font-size: 14px;
              }
              img{
                margin: 0px 0px 0px 8px;
                @media screen and (max-width:1200px) {
                  margin: 0px 0px 0px 6px;
                }
                @media screen and (max-width:480px){
                  margin: 0px 0px 0px 4px;
                }
              }                  
            }

          }
        }

        .second__category__area {
          .second__feature__category__block {
            .feature__category {
              .cart_end_icon {
                float: left;
              }
              .addToCart__button,.outofStock__button,
              .notifyMe__button,.pre-order {
                font-family: 'SSTArabicBold';
                font-weight: bold;
              }
              .addToCart__button {
                .addToCart__icon {
                  margin-right: 0px !important;
                  margin-left: 10px !important;
                }
                .btn__spinner{
                  min-width: 134.43px;
                  min-height: 22.5px;
                  @media screen and (max-width:1366px) { min-width: 127.14px; min-height: 21px; } 
                  @media screen and (max-width:1200px) { min-width: 116.85px; }
                }
                &.pre-order{
                  .btn__spinner{
                    min-width: 111.77px;
                    min-height: 22.5px;
                    @media screen and (max-width:1366px) { min-width: 105.71px; min-height: 21px; }
                    @media screen and (max-width:1280px) { min-width: 103.71px; min-height: 21px; }
                    @media screen and (max-width:1200px) { min-width: 96.65px; min-height: 19.5px; }
                  }
                }
              }
              .notifyMe__button{

                @media screen and (max-width:1200px) {
                  font-size: 14px;
                }
                img{
                  margin: 0px 0px 0px 8px;
                  @media screen and (max-width:1200px) {
                    margin: 0px 0px 0px 6px;
                  }
                  @media screen and (max-width:480px){
                    margin: 0px 0px 0px 4px;
                  }
                }                  
              }

              .fav_icon{                
                width: 25%;
                left: auto;
                right: 20px;
                top: 15px;
              }
              .pro__feature__optionBox {
                left: 20px;
                right: auto;

                p {
                  margin: 0px 0px 0px 7px;

                  &:last-child {
                    margin-left: 0px;
                  }
                }
              }
            }
          }
        }

        .first__feature__category {
          .heading__inner__text {
            .cart_end_icon {
              float: left;
            }
          }
        }
      }
    }
  }
}

/*-------------------------- Arabic view end ------------------------*/



// .pd__productOne__block .prize__block span:first-child {
//     color: #333 !important;
// }

.feature__category__container .feature__category__block .inner__feature__category__block .second__feature__category__block .feature__category span.text4 {
  color: #444 !important;
}