.reviewar  .testfreaks{
  direction: rtl!important;
}
.reviewar .testfreaks-reviews .tf-score{
  float: right!important;
}
.reviewar  .testfreaks-reviews .tf-distributions{
  float: right!important;
  margin-right: 2rem!important;
}
.reviewar .testfreaks .pull-left{
  float: right !important;
}
.accordian__block {
  // border-top: 1px solid #eeeeee;
  margin-bottom: 15px;
  padding: 0px;
  .accordian__header__block {
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    cursor: pointer;
    background-color: #F8F8F8;
    border-radius: 20px;
    position: relative;
    z-index: 1;

    .accordian__header__block{
      p{
        color: #000;
      }
    }
    .accodian-active-status {
      color: #DC3A1A;
      font-size: 16px;
      font-weight: 500;
    }
    .accordian__title {
    
    }
    .accordian__arrow__icon {
      width: 20px;
    }
  }
  .accordian__detail__block__disable {
    display: none;
    // -webkit-transition: all .8s ease-in-out;
    // transition: all .8s ease-in-out;
    animation: smoothAccodion 2s forwards;
  }
  .accordian__detail__block {
    display: block;
    position: relative;
    padding: 15px 15px 0px;
    z-index: 0;
    // -webkit-transition: all .8s ease-in-out;
    // transition: all .8s ease-in-out;
    animation: smoothAccodion 2s forwards;

    @media screen and (max-width:480px) {
      padding: 10px 5px 0px;
    }
    .accordian__description__block {
      .data__title {
       
      }
      .accordian__detail__description {
    
      }
    }
    .accordian__data__block {
      .data__title {
       font-family: 'SSTbold';
font-weight: bold;
        font-size: 24px;
        margin: 0px 0px 20px 0px;
      }
      .accordian__keyValue__block {
        .testfreaks-reviews {
          &:nth-child(2){
            display: none !important;
          }
        }
        .keyValue {
          .key {
          }
          .value {
          }
        }
      }
    }
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .accordion-button{
    text-align: right;
  }

}

/*-------------------------- Arabic view End ------------------------*/