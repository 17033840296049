.main__recommand__product__block {
  padding: 15px 5px 15px;
  border: 1px solid #DBDBDB;
  border-radius: 15px;

  .heading5,
  .heading3 {
    font-size: 24px;
    color: #000000;
    line-height: 1.2;
    padding: 0px 15px 15px;
    margin-bottom: 0px;
    border-bottom: 1px solid #E4E4E4;

    @media screen and (max-width:480px) {
      font-size: 18px;
    }
  }

  .view__more__address__block {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
    align-items: center;



    .view__more__address__button {
      display: flex;
      align-items: center;
      flex-direction: row;
      cursor: pointer;
      text-align: center;
      min-width: fit-content;
      font-size: 14px;
      font-family: 'SSTmedium';
      font-weight: 600;
      border-radius: 5px;
      border: none;
      color: #000000;
      background-color: #ffffff;
      outline: none;
      letter-spacing: 0.3px;
      margin-bottom: 0px;
      width: auto;


      &:hover {
        text-decoration: underline;
        text-decoration-color: #818181;
      }

      svg {
        height: 16px;
        width: auto;
        margin-left: 8px;
        color: #000000;

        @media screen and (max-width:1280px) {
          margin-left: 6px;
        }
      }


    }
  }


}

.recommand__product__block {
  padding: 10px 0px 30px;
  border-bottom: 1px solid #eeeeee;
  background-color: #ffffff;
  margin: 0px 5px;

  .recommand__product__image__block {
    position: relative;
    height: max-content;

    // height: 100%;
    // width: 100%;
    .recommand__product__image {
      height: 50%;
      max-height: 120px;
      width: 100%;
      object-fit: contain;
    }

    .soldOutLable {
      position: absolute;
      width: 100%;
      background: hsla(0, 0%, 85.1%, 0.76);
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 1.1;
      color: rgba(0, 0, 0, 0.72);
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .recommand__product__detail__block {
    .prize__block {
      display: flex;
      margin-bottom: 8px;

      .old__prize {
        font-family: "SSTregular";
        font-size: 18px;
        text-decoration-line: line-through;
        color: #c8c8c8;
      }

      .new__prize {
        font-family: 'SSTbold';
        font-weight: bold;
        font-size: 18px;

        margin: 0px 0px 0px 5px;
      }
    }

    .pd__mb__product__name {
      text-decoration: none;
      color: #000000;
      display: block;
      margin-bottom: 10px;
    }

    .see__all__buying__option__button {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000005;
      border: 2px solid #000000;
      border-radius: 5px;
      color: black;
      outline: none;
      font-size: 15px;
      font-family: 'SSTbold';
      font-weight: bold;
      padding: 6px 8px;
      margin: 10px 0px 0px;

      &:hover,
      &:focus {
        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
      }
    }

    .addToCart__button {
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      font-family: "SSTbold";
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 8px 10px;
      background-color: #dc3a1a;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 7px;
      min-width: 150px;

      @media screen and (max-width:992px) {
        width: 200px;
        margin-left: auto;
      }

      .addToCart__icon {
        margin: 0px 10px 0px 0px;
        width: 19px;
      }

      &.pre-order {
        .btn__spinner {}
      }

      .btn__spinner {
        min-height: 21px;
      }

    }

    .notifyMe__button {
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      font-family: 'SSTbold';
      letter-spacing: 0.85px;
      padding: 8px 10px;
      line-height: 1.5;
      background-color: #FE8B21;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0px;
      border: none;
      min-width: 150px;

      @media screen and (max-width:992px) {
        width: 200px;
        margin-left: auto;
      }

      img {
        height: 14px;
        margin: 0px 10px 0px 0px;
      }
    }

    .outofStock__button {
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      font-family: "SSTbold";
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 8px 10px;
      background-color: #a7a7a7;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 7px;
      border: none;

      @media screen and (max-width:580px) {
        width: 200px;
        margin-left: auto;
      }

    }
  }
}


@media(max-width:1199px) {
  .main__recommand__product__block .recommand__product__block .recommand__product__detail__block .see__all__buying__option__button {
    margin-left: 10px;
  }
}

@media(max-width:991px) {
  .main__recommand__product__block .recommand__product__block .recommand__product__detail__block .see__all__buying__option__button {
    margin-left: 0px;
  }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .main__recommand__product__block {
    .recommand__product__block {
      .recommand__product__detail__block {
        .addToCart__button {
          font-family: 'SSTArabicBold';

          @media screen and (max-width:992px) and (min-width:580px) {
            width: auto;
            max-width: max-content;
          }

          @media screen and (max-width:580px) {
            margin-right: auto;
          }

          .addToCart__icon {
            margin: 0px 0px 0px 10px;
            width: 19px;
          }
        }

        .notifyMe__button {
          @media screen and (max-width:580px) {
            margin-right: auto;
          }

          img {
            margin: 0px 0px 0px 10px;
          }
        }

        .outofStock__button {
          @media screen and (max-width:580px) {
            margin-right: auto;
          }
        }
      }
    }


    .view__more__address__block {
      .view__more__address__button {
        svg {
          margin-left: 0px;
          margin-right: 8px;

          @media screen and (max-width:1280px) {
            margin-right: 6px;
          }
        }
      }
    }
  }


}

/*-------------------------- Arabic view End ------------------------*/