 .feed__container {
  .feed__block {
    width: 1420px;
    margin: 0px auto;
    padding: 0px;
    .feed__row {
      .feed {
        .feed__title {
         font-family: 'SSTbold';
font-weight: bold;
          font-size: 36px;
          font-weight: bold;
          color: #363636;
          margin: 0px;
        }
        .feed__image__block {
          overflow: hidden;
          .facebook__feed__image {
            width: 100%;
            height: 385px;
          }
          .feed__image {
            width: auto;
            height: 385px;
          }
        }
        
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .feed__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .feed__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 992px) {
  .feed__block {
    width: 100% !important;
    min-width: 100% !important;
    margin: 0px auto;
  }
}
@media screen and (max-width: 768px) {
  .feed__block {
    width: 100% !important;
  }
  
}
@media screen and (max-width: 576px) {
  .feed__block {
    width: 100% !important;
  }
}
