$mobileBreakpoint: 1200px;
$gap: 29px;
$gap2: 13px;

.wrapper {
  margin: 20px 0 8px;
  padding: 0 20px;

  &.isFullWidth {
    margin-top: 0;
    margin-bottom: 8px;
    padding: 0;
  }

  .sliderBanner {
    min-width: 100%;

    img {
      height: auto;
      min-height: 80px;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: $mobileBreakpoint;

    html[dir="ltr"] &>* {
      margin-right: $gap2;
    }

    html[dir="rtl"] &>* {
      margin-left: $gap2;
    }

    html[dir="ltr"] &>*:last-child {
      margin-right: 0;
    }

    html[dir="rtl"] &>*:last-child {
      margin-left: 0;
    }

    html[dir="ltr"] &:after {
      margin-left: $gap2;
    }

    html[dir="rtl"] &:after {
      margin-right: $gap2;
    }

    html[dir="ltr"] &:before {
      margin-right: $gap2;
    }

    html[dir="rtl"] &:before {
      margin-left: $gap2;
    }

    overflow-x: auto;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (min-width: $mobileBreakpoint) {
    // margin: 51.5px 0 44.5px;
    padding: 0;
    overflow-x: hidden;

    .sliderBanner {
      img {
        height: 100%;
        min-height: 317px;
      }
    }

    .inner {
      margin: 0 auto;

      html[dir="ltr"] &>* {
        margin-right: $gap;
      }

      html[dir="rtl"] &>* {
        margin-left: $gap;
      }

      html[dir="ltr"] &>*:last-child {
        margin-right: 0;
      }

      html[dir="rtl"] &>*:last-child {
        margin-left: 0;
      }

      html[dir="ltr"] &:after {
        margin-left: $gap;
      }

      html[dir="rtl"] &:after {
        margin-right: $gap;
      }

      html[dir="ltr"] &:before {
        margin-right: $gap;
      }

      html[dir="rtl"] &:before {
        margin-left: $gap;
      }
    }
  }
}