
.bundle_prod_wrapper {
  display: grid;
  grid-template-columns: 7% 50% 40%;
  gap: 10px;
  // justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  width: 100%;
  font-size: 16px !important;
  .addressType__block {
    display: flex;
    align-items: center;
    padding: 0px 14px 0px;
    .delivery__input__check {
      accent-color: #ff4f04;
      height: 16px;
      width: 16px;
      border-radius: 10px;
      margin: 0px 10px 0px 0px;
      // border: 16px solid #303030;
      text-transform: uppercase;
    }
    .delivery__selection__text {
      margin: 0px 20px 0px 0px;
    }
  }
  .radio_block{
    display: flex;
  }

  input[type="checkbox"] {
    accent-color: #dc3a1a;
  }

  .pricingCart {
    display: flex;
    justify-content: end;

    html[dir="rtl"] & {
      justify-content: start;
    }

    span {
      font-weight: 500;
      color: #dc3a1a;

      html[dir="rtl"] & {
        font-weight: bold;
      }
    }
  }

  .pricing {
    display: flex;
    padding: 0px 0px 10px 0px;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    font-size: 16px;
    transform: translate(0px, 14px);

    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      color: #dc3a1a;
      height: 22px;
      overflow: hidden;
    }

    div {
      display: flex;
      justify-content: end;

      html[dir="rtl"] & {
        justify-content: start;
      }
    }

    span {
      font-weight: 500;
      color: #dc3a1a;
      font-size: 15px !important;
      transform: translate(0px, 6px);

      html[dir="rtl"] & {
        font-weight: bold;
      }
    }
  }

  .title {
    display: grid;
    grid-template-columns: 10% 90%;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    span {
      width: 90%;
      height: 20px;
      overflow: hidden;
      word-break: break-all;
    }

    .prodImg {
      width: 20px;
      height: 20px;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .checkbox {
    margin: auto;
    position: relative;
    width: 19px;
    height: 17px;

    .label {
      display: block;
      width: 19px;
      height: 17px;
      background: #fff;
      border: 1px solid #dc3a1a;
      border-radius: 3px;
      cursor: pointer;
      position: relative;

      &.checked {
        &::before {
          content: "";
          position: absolute;
          left: 9%;
          top: 8%;
          width: 85%;
          height: 85%;
          background-color: #dc3a1a;
          border-radius: 3px;

          html[dir="rtl"] & {
            right: 9%;
            left: unset;
          }
        }
      }
    }

    input {
      visibility: hidden !important;
    }
  }
}
