.sign_in_up_socialBox{
    padding: 15px 0px 5px;
    h3 {
        font-size: 15px;
        color: #000000;
        font-family: "SSTbold";
        font-weight: bold;
    }
    ul{
        margin: 0px;
        padding: 0px;
        display: flex;
        justify-content: center;
        li{
            list-style-type: none;
            margin-right: 25px;
            &:last-child{
                margin-right: 0px;
            }
            button{
                border: none;    
                width: 45px;
                height: 45px;
                background-color: #ffffff;
                border-radius: 50%;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.09);
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: auto;
                    height: 22px;
                }
            }
        }
    }
}