.videoLogs__container {
  margin: 50px 0px;
  .videoLogs__block {
    width: 1420px;
    margin: 0px auto;
    padding: 0px;
    .section__title__block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .section__title {
       font-family: 'SSTbold';
font-weight: bold;
        font-size: 40px;
      }
      .viewall__button {
        background: #ffffff00 0% 0% no-repeat padding-box;
        border: 2px solid #000000;
        border-radius: 3px;
        outline: none;
        color: #000000;
        text-align: center;
        font-size: 16px;
        width: 125px;
        height: 50px;
        text-transform: uppercase;
       font-family: 'SSTbold';
font-weight: bold;
      }
    }
    .videoLogs__row {
      .videoLogs {
          margin: 0px 0px 10px;
          height: 100%;
        .videoLogs__image__block {
          width: 100%;
          height: auto;
        }
        .videoLogs__title {
          margin: 0px 0px 10px;
          font-size: 18px;
         font-family: 'SSTbold';
font-weight: bold;
        }
        
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .videoLogs__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .videoLogs__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 1200px) {
  .videoLogs__block {
    width: 100% !important;
    min-width: 100% !important;
    margin: 0px auto;
  }
  .videoLogs__image__block {
    width: 100% !important;
    height: 50% !important;
  }
}
@media screen and (max-width: 992px) {
  .videoLogs__block {
    width: 100% !important;
    min-width: 100% !important;
    margin: 0px auto;
  }
  .videoLogs__image__block {
    width: 100% !important;
    height: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .videoLogs__block {
    width: 100% !important;
  }
}
@media screen and (max-width: 576px) {
  .videoLogs__block {
    width: 100% !important;
  }
}
