.dashboard__page__container {
  padding: 0px 12px;
  background-color: #f5f5f5;
  .dashboard__page__block {
    padding: 20px 0px;
    width: 1420px;
    margin: 0px auto;
    .dashboard__page__inner__block {
      // margin: 0px auto;
      .dash__block {
        // padding: 20px 16px;
        text-decoration: none;
        color: #000000;
        margin: 12px 0px;
        .dash__inner__block {
          box-shadow: 0px 3px 6px #0000000a;
          border: 1px solid #f1f1f1;
          border-radius: 10px;
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 30px;
          @media screen and (max-width: 992px) {
            padding: 20px 10px;
          }
          .dash__icon {
            width: fit-content;
            height: 60px;
            margin-bottom: 20px;
            @media screen and (max-width: 992px) {
              height: 35px;
            }
          }
          &:hover{
            box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);            
            transition: all 0.4s ease-in-out;
            -webkit-transition: all 0.4s ease-in-out;
            background-color: #dc3a1a;
            border-color: #dc3a1a;
            color: #ffffff;

            .dash__icon {
              filter: brightness(0) invert(1);
            }
          }
          .heading6{
            @media screen and (max-width: 992px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .dashboard__page__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .dashboard__page__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 992px) {
  .dashboard__page__block {
    width: 100% !important;
    max-width: 100% !important;
  }
}
