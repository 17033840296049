.sony__store__page__container {
    padding: 0px 12px 20px;
    background-color: #f5f5f5;
    .sony__store__page__block {
        padding: 0px 0px;
        width: 1420px;
        margin: 0px auto;
        .sony__store__page__banner__block {
            .sony__store__page__image {
                width: 100%;
            }
        }
        .location__search__block {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #0000000a;
            border: 1px solid #f1f1f1;
            padding: 20px 20px;
            margin: 20px 0px;
            display: flex;
            align-items: flex-end;
            input{
                &:focus{
                    border-color: #000 !important;
                }
            }
            .css-1okebmr-indicatorSeparator{
                display: none;
            }
            @media (max-width:992px) { padding: 10px 0px; }

            .select__field__block {
                margin: 0px 0px 0px;
                @media (max-width:567px) {
                    margin-bottom: 10px;
                }
                .select__field {
                    width: 100%;
                    border-radius: 4px;
                    padding: 20px 30px;
                    border: 1px solid #c8c8c8;
                }
                .select__field:focus {
                    border: 1px solid #c8c8c8;
                }
                .css-319lph-ValueContainer {
                    @media (max-width:992px) { font-size: 14px; }
                }
            }
            .search__button {
                width: 100%;
                border-radius: 5px;
                padding: 7px 20px;
                text-align: center;
                color: #ffffff;
                font-size: 16px;
                font-family: 'SSTmedium';
                font-weight: 500;
                background-color: #dc3a1a;
                border: none;
                outline: none;
                letter-spacing: 0.8px;
                text-transform: uppercase;
                &:hover {
                    box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);                    
                    transition: all 0.3s ease-in-out;
                    -webkit-transition: all 0.3s ease-in-out;
                    opacity: 0.8;
                }

                @media (max-width:580px) {
                    margin-top: 10px;
                }
            }
        }
        .sony__store__page__map__block {
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #0000000a;
            border: 1px solid #f1f1f1;
            padding: 30px 20px;
            @media (max-width:992px) { padding: 10px 0px; }

            .inner__sony__store__page__map__block {
                // background-image: url("./../assets//SonyStore/map_image.jpg");
                margin: 0px;
                .map__location__list__block {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;                   
                    position: relative;
                    overflow-y: scroll;                    

                    @media screen and (max-width:767px) {
                        margin-bottom: 10px;
                        // max-height: 350px;
                        // margin-bottom: 15px;
                    }

                    .location__block {
                        width: 100%;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        background: #ffffff 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #0000000a;
                        border: 1px solid #f1f1f1;
                        padding: 15px;
                        margin-bottom: 15px;

                        img{
                            @media (max-width:580px) {
                                width: 15px;
                            }
                        }
                        .location__info__block {
                            margin: 0px 0px 0px 15px;
                            width: 100%;
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            .location__info__text {
                                width: 86%;
                                .footer__mobile{
                                    text-decoration: none;
                                    color: #000;
                                    font-size: 14px;
                                    &:hover{
                                        color: #dc3a1a;
                                    }
                                }
                            }
                            .store_find_location{
                                cursor: pointer;
                                width: 40px;
                                border-radius: 50%;
                                transition: all 0.3s ease-in-out;

                                &:hover{
                                    box-shadow: 0px 1px 3px #333333a3;
                                }
                            }
                        }
                    }
                }
                .map__location__area{
                    width: 100%;
                    max-width: 100%;

                    &>div{
                        width: 100%;
                        max-width: 100%;
                        overflow: hidden;
                    }
                    .gm-style-iw-c { 
                        width: 345px;
                        max-width: 90%; 
                        .gm-style-iw-d{
                            width: 100%;
                            margin: 0px;
                            div{
                                width: 100%;
                                max-width: 100%;
                            }
                        }
                        .gm-ui-hover-effect {
                            top: -1px !important;
                            right: 3px !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1450px) {
    .sony__store__page__block {
        width: 90% !important;
        max-width: 90% !important;
    }
}

@media screen and (max-width: 1200px) {
    .checkout__right__block {
        padding: 0px !important;
        margin: 10px 0px;
    }
}

@media screen and (max-width: 992px) {
    .sony__store__page__block {
        width: 100% !important;
        max-width: 100% !important;
    }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

    .sony__store__page__container {
        .sony__store__page__block {
            .sony__store__page__map__block {
                .inner__sony__store__page__map__block {
                    .map__location__list__block {
                        .location__block {
                            .location__info__block {
                                margin: 0px 15px 0px 0px;
                            }
                        }
                    }
                }
            }
            .location__search__block {
                .search__button {
                    font-family: 'SSTArabicMedium';
                }
            }
        }
    }
    
}

/*-------------------------- Arabic view End ------------------------*/