.search__page__input__container {
  padding: 0px 12px 0px;
  background-color: #afafaf;
  .search__page__input__block {
    padding: 20px 0px;
    width: 1420px;
    margin: 0px auto;
    .main__search__input__block {
      margin: 0px;
      padding-left: 15px;
      .search__input__block {
        display: flex;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #ffffff;
        border-radius: 5px;
        align-items: center;
        padding: 0px;
        .search__input {
          outline: none;
          border: none;
          width: 100%;
          font-size: 17px;
          // font-family: 'SSTbold';
          font-weight: 500;
          color: #000000;
          padding: 2px 15px;
        }
        .search__input:focus {
          outline: none;
          background: none;
        }
        .cancel__button {
            cursor: pointer;
        }
      }
      .search__button__block {
        .search__button {
          width: 100%;
          height: 100%;
          outline: none;
          border: none;
          border-radius: 5px;
          color: #ffffff;
          font-size: 16px;
          font-family: 'SSTbold';
          font-weight: bold;
          letter-spacing: 0.85px;
          padding: 10px 10px;
          background-color: #dc3a1a;
          text-align: center;
          text-transform: uppercase;
          transition: all 0.3s ease-in-out;
          -webkit-transition: all 0.3s ease-in-out;
          -moz-transition: all 0.3s ease-in-out;

          &:hover{
            box-shadow: 0px 1px 5px #cdcdcd;
          }
        }
      }
    }
  }
}
.search__page__content__container {
  padding: 0px 12px;
  background-color: #f5f5f5;
  .search__page__content__block {
    padding: 20px 0px;
    width: 1420px;
    margin: 0px auto;
    .result__text {
      font-size: 20px;
      font-family: "SSTregular";
      margin: 0px 0px 0px 10px;
    }
  }
  .search__product__container {
    margin: 0px;
    .main__search__product__block {
      padding: 0px 20px 0px 0px;
      .heading4{
        // @media screen and (max-width: 992px) {
        //   font-size:16px;
        // }        
      }
    }
    .search__link__block {
      // width: 100%;
      height: fit-content;
      padding: 15px;
      margin: 0px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #0000000a;
      border: 1px solid #f1f1f1;
      margin: 20px 0px;
      .search__link {
        border-bottom: 1px solid #f1f1f1;
        padding: 0px 0px 10px;
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .search__page__input__block,
  .search__page__content__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .search__page__input__block,
  .search__page__content__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 1200px) {
  .search__page__input__block,
  .search__page__content__block {
    // padding: 0px !important;
    margin: 10px 0px;
  }
}
@media screen and (max-width: 992px) {
  .search__page__input__block,
  .search__page__content__block {
    width: 100% !important;
    max-width: 100% !important;
  }
}
@media screen and (max-width: 580px) {
  .search__page__input__block,
  .search__page__content__block {
    width: 100% !important;
    max-width: 100% !important;
    
  }
  .search__page__input__block{
    .main__search__input__block{
      padding-right: 15px;
      .search__input__block{
        .search__input{
          padding:8px 10px !important;
        }
      }      
      .search__button__block {
        padding: 0px;
        margin-top:15px;
        .search__button{
          padding: 8px 12px !important;
          font-size: 15px !important;
        }
      }
    }
  }
  .search__page__content__container{
    .search__product__container{
      .main__search__product__block {
          padding: 0px 0px 0px 0px;
      }
      .search__product__block{
        padding: 15px;
      }
    } 
  } 

}



/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {

  .search__page__content__container{
    .search__page__content__block {
      .result__text{
        margin-left: 0px;
        margin-right: 10px;
      }
      .search__product__container{
        .main__search__product__block{
          padding:0px 0px 0px 20px;
          .search__product__block{
            .search__product__image__block{
              padding:0px 10px 0px 10px;
            }
            .search__product__detail__block{
              padding: 0px 20px 0px 0px;
            }
          }
        }
      } 
    }    
  } 
  
  

}
/*-------------------------- Arabic view End ------------------------*/