.mainEliteWrapper {
  background-image: url('../assets/Background/GamersEliteBg3.jpg');
  background-size: cover;
  overflow-x: hidden;

  .headerBarElite {
    display: flex;
    padding: 0 100px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.4);


    .logoElite {
      margin-left: -30px;

      html[dir="rtl"] & {
        margin-left: 0;
        margin-right: -30px;
      }
    }
  }

  .eliteWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 80px);

    .containerElite {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;


      .sectionA {
        flex: 2;

      }

      .sectionB {
        flex: 1;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .mainEliteWrapper {
    .headerBarElite {
      padding: 0 20px;
    }

    .eliteWrapper {
      .containerElite {
        flex-direction: column;

        .sectionA {
          flex: 1;
          margin-top: 280px;
        }

        .sectionB {
          flex: 1;
          margin: 0 30px;
          border-radius: 10px;
        }
      }
    }
  }

}

@media screen and (min-width: 768px) and (max-width: 1200px) {

  .mainEliteWrapper {
    .eliteWrapper {
      .containerElite {
        flex-direction: column;

        .sectionA {
          flex: 1;
          margin-top: 280px;

        }

        .sectionB {
          flex: 1;
          margin: 0 100px;
          border-radius: 10px;
          justify-content: center;

        }
      }
    }
  }


}