.mb__filter__related__popup {
    .heading5 {
        margin-bottom: 0px !important;
    }

    ul {
        align-items: center;
        flex-wrap: wrap;
        padding-left: 0;

        li {
            width: 48%;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            &:nth-child(even){
               margin-left: 10px;
            }
            input[type=checkbox] {
                width: 20px;
                height: 20px;
                accent-color: black;
            }

            label {
                font-size: 16px;
            }
        }

        &.mb__start__rating__block {
            .mb__rating__text {
                color: #DC3A1A;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
                font-weight: bold;
                margin-top: 2px;
            }
        }
    }
    .mb__static__btn__group{
        flex-wrap: nowrap;
        padding: 0;

        button{
            width: 50%;
            margin: 0 !important;
            border-radius: 0;
            text-transform: uppercase;
            @media screen and (max-width:992px) {
                padding: 10px 10px !important;
            }
            @media screen and (max-width:580px) {
                padding: 8px 10px !important;
            }
        }
    }
}
@media(max-width:576px){
    .mb__filter__related__popup ul li label{
        font-size: 13px;
    }
}

/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {
   
    
    .mb__filter__related__popup {
        .modal-content{
            .modal-header{
                .btn-close{
                    margin-left: 0px;
                }
            }
            .modal-body{
                ul.list-unstyled{
                    padding-right: 0px;
                    li{

                        &:nth-child(even){
                            margin-left: 0px;
                            margin-right: 10px;
                        }
                        label{
                            margin-left: 0px;
                            margin-right: 10px;
                        }
                    }
                }
            }
            .mb__static__btn__group {
                button{
                    font-family: 'SSTArabic';
                    font-size: 14px;
                }
            }
                        
        }
    }

}

/*-------------------------- Arabic view End ------------------------*/