/* loader css start */
.loader-mainbox {
    position: fixed;
    width: 100%;
    height: 100vh;
    // background-color: #0a0a0a85;
    z-index: 10;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .loader-box {
        position: absolute;
        width: 100%;
        height: 3px;
        left: 0px;
        top: 0px;
        // transform: translate(-50%,-50%);
        // padding: 2px 4px;  
        background-color: transparent;        
        animation: shine 1.5s ease-in-out infinite;

        @media screen and (max-width:580px) {
            // height: 80px;
            // width: 80px;
        }
        @media screen and (max-width:420px) {
            // height: 70px;
            // width: 70px;
        }
        h3{
            font-size: 2.5rem;
            font-weight: 600;                    
            line-height: 1;  
            margin: 0px;
            color: #000000;
            // background: linear-gradient(to right, #54148d 20%, #fecc49 30%, #d93a35 70%, #54148d 80%);
            // background: -webkit-linear-gradient(to right, #54148d 20%, #fecc49 30%, #d93a35 70%, #54148d 80%);
            // background: linear-gradient(to right, #dc3a1a 20%, #f7e869 30%, #ecb51c 70%, #dc3a1a 80%);
            // background: -webkit-linear-gradient(to right, #dc3a1a 20%, #f7e869 30%, #ecb51c 70%, #dc3a1a 80%);
            // background-size: 200% auto;
            // -webkit-background-clip: text;
            // -webkit-text-fill-color: transparent;
            // animation: shine 1.2s ease-in-out infinite;

            @media screen and (max-width:1200px) { font-size: 2rem; }

            @media screen and (max-width:580px) { font-size: 1.8rem; }

            @media screen and (max-width:420px) { font-size: 1.5rem; }
            
            @media screen and (max-width:320px) { font-size: 1.2rem; }
        }
        .loading_line{            
            height: 5px;
            width: auto;
            border-radius: 6px;
            margin-top: 10px;
            background: linear-gradient(to right,#cccfcf 50%, #425cf0 50%);
            background: -webkit-linear-gradient(to right, #cccfcf 50%, #425cf0 50%);
            background-size: 200% auto;
            animation: shine 1.2s ease-in-out infinite;
        }
    }
}
@keyframes shine {
    0%{
        background-color: #dc3a1a;
    }
    25%{
        background-color: #21c74a;
    }
    50%{
        background-color: #cccf07;
    }
    75%{
        background-color: #941bda;
    }
    100%{
        background-color: #188edd;
    }
    // to {
    //     background-position: -200% center;
    // }
}
/*loader css end */