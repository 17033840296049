.searchProduct {
  text-decoration: none;
}

.search__product__block {
  width: 100%;
  padding: 20px;
  margin: 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000a;
  border: 1px solid #f1f1f1;
  margin: 20px 0px;
  text-decoration: none;
  color: #000000;
  position: relative;

  @media screen and (max-width:580px) {
    padding: 15px;
  }

  .search__pro__favouritebox {
    position: absolute;
    right: 7px;
    top: 3px;
    width: 24px;
    max-width: 24px;
    height: auto;
    padding: 0px;
    cursor: pointer;
  }

  .search__product__image__block {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;

    @media screen and (max-width:567px) {
      height: auto;
    }

    span {
      img {
        @media screen and (max-width:567px) {
          margin: 0px auto;
          display: block;
        }
      }
    }

    .search__product__image {
      width: 90%;
      height: 90%;
      object-fit: contain;
    }
  }

  .search__product__detail__block {
    padding: 0px 0px 0px 20px;
    margin-top: 10px;

    @media screen and (max-width: 767px) {
      padding: 0px 0px 0px 0px;
    }

    .searchProduct__name {
      text-decoration: none;
      color: #000000;

      .heading6 {
        @media screen and (max-width: 580px) {
          font-size: 16px !important;
        }
      }
    }

    .text3 {
      font-size: 15px !important;
    }

    .search__product__hightlight__link {
      .search__product__link {
        text-decoration: none;
        color: #dc3a1a;
        font-size: 15px;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;

        &:hover {
          text-decoration: underline;
          text-decoration-color: #000000;
        }

        @media screen and (max-width: 1200px) {
          font-size: 14px;
        }

        @media screen and (max-width: 992px) {
          font-size: 13px;
        }
      }
    }

    .addToCart__button {
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      font-family: 'SSTbold';
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 6px 10px;
      background-color: #DC3A1A;
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0px;
      width: fit-content;
      min-width: 150px;

      .addToCart__icon {
        margin: 0px 10px 0px 0px;
        width: 19px;

        @media screen and (max-width:1420px) {
          width: 18px;
        }

        @media screen and (max-width:1280px) {
          width: 16px;
          margin: 0px 8px 0px 0px;
        }

        @media screen and (max-width:580px) {
          margin: 0px 8px 0px 0px;
          width: 15px;
        }        
      }

      .spinner-border {
        margin-left: 10px !important;

        @media screen and (max-width:1420px) {
          margin-left: 8px !important;
          width: 0.8rem;
          height: 0.8rem;
        }

        @media screen and (max-width:1280px) {
          margin-left: 6px !important;
          width: 0.7rem;
          height: 0.7rem;
        }
      }

      @media screen and (max-width:1366px) {
        font-size: 14px;
      }

      @media screen and (max-width:580px) {
        padding: 8px 10px;
        font-size: 13px;
      }

      &:hover,
      &:focus {
        -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
        transform: translateY(-2px);
        -webkit-transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        opacity: 0.8;
      }

      // &:focus {
      //   -webkit-box-shadow: 0 5px 5px -4.8px rgb(32 32 32 / 60%);
      //   box-shadow: 0 5px 5px -4.8px rgb(32 32 32 / 60%);      
      //   transform: translateY(0px);
      //   -webkit-transform: translateY(0px);
      //   transition: all 0.2s ease-in-out;
      //   -webkit-transition: all 0.2s ease-in-out;
      // }
      .btn__spinner{
        min-height: 21px;
        @media screen and (max-width:580px) {
          min-height: 19.5px;
        }
      }
    }

    .outofStock__button {
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      font-family: 'SSTbold';
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 0.85px;
      padding: 6px 12px;
      background-color: #a7a7a7;
      text-transform: uppercase;
      cursor: default;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0px;
      width: fit-content;

      @media screen and (max-width:1366px) {
        font-size: 14px;
      }

      @media screen and (max-width:580px) {
        padding: 8px 10px;
        font-size: 13px;
      }
    }


    .notifyMe__button {
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      font-family: 'SSTbold';
      text-transform: uppercase;
      letter-spacing: 0.85px;
      padding: 6px 15px;
      line-height: 1.5;
      background-color: #FE8B21;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0px;
      width: fit-content;

      &:hover,
      &:focus {
        -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        opacity: 0.8;
      }

      @media screen and (max-width:1366px) {
        font-size: 14px;
      }

      @media screen and (max-width:580px) {
        padding: 8px 10px;
        font-size: 13px;
      }

      // @media screen and (max-width:480px) {
      //   font-size: 13px;
      //   padding: 5px 10px;
      //   line-height: 1;
      //   letter-spacing: 0.5px;
      // }

      img {
        margin: 0px 8px 0px 0px;
        height: 14px;
        width: auto;
        vertical-align: sub;

        @media screen and (max-width:480px) {
          margin: 0px 4px 0px 0px;
          height: 13px;
        }
      }

    }

  }
}
.size__button__block {
  // margin: 0px 0px 5px 0px;
  min-height: 50px;

  .size__button__listing {
    // border-top: 1px solid #d9d9d9;
    // border-bottom: 1px solid #d9d9d9;
    // padding: 10px 0px;
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    align-items: center;
    span{
      display: block;
      margin: 0px 8px 0px 0px;
    }
    button{
      position: relative;
      &:before{
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000000;
        border-radius: 3px;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 50%;
        transform-origin: 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }
    }

    .size__button__active {
      font-size: 12px;
      font-family: 'SSTmedium';
      border-radius: 3px;
      color: #ffffff;
      padding: 6px 8px;
      // line-height: 1.26;
      // background-color: #000000;
      border: 1px solid #000000;
      outline: none;
      border: none;
      margin: 0px 8px 0px 0px;
      z-index: 2;
      position: relative;
      &:before{
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        z-index: -1;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
    .size__button {
      font-size: 12px;
      border: 1px solid #707070;
      border-radius: 3px;
      padding: 6px 8px;
      background-color: #ffffff;
      outline: none;
      margin: 0px 8px 0px 0px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }      
}
.search__product__detail__block .addToCart__button,.search__product__detail__block .outofStock__button{
  display: inline-block !important;
}
.pd__total__price__block{
  float: right;
}
.inner_price_section .text3{
  color: rgb(68, 68, 68) !important;
  display: inline-block;
  font-weight: 700;
  position: relative;
  width: max-content;
  right: 10px;
  top: 4px;
}
body[dir="rtl"] .inner_price_section .text3{
  right: auto;
  left: 4px;
}
.save-amount-box{
  background-color: rgb(255, 157, 115);
  color: rgb(255, 255, 255);
  border-width: 1.3px;
  border-style: solid;
  border-color: rgb(254, 99, 33);
  border-image: initial;
  border-radius: 20px;
  margin: 0px 10px 0px 0px;
  padding: 6px 35px 0px;
}
.inner_price_section .text3::after{
  background-color: rgb(102, 102, 102);
  bottom: 0px;
  content: " ";
  display: block;
  height: 2px;
  left: 0px;
  position: absolute;
  transform: translateY(-10px) rotate(-5deg);
  width: 100%;
}
.pd__product__price__offer{width: 100%;}
.search__product__detail__block  .heading4{
  display: inline-block;
  // float: right;
}
/*-------------------------- Arabic view start ------------------------*/


body[dir="rtl"].arabicFonts {

  .search__product__block {
    .search__pro__favouritebox {
      right: auto;
      left: 7px;
    }

    .search__product__detail__block {
      padding: 0px !important;
      .addToCart__button,
      .outofStock__button,
      .notifyMe__button,
      .pre-order {
        font-family: 'SSTArabicBold';
        font-weight: bold;
        font-size: 14px;
      }

      .addToCart__button {        

        .addToCart__icon {
          margin: 0px 0px 0px 10px;

          @media screen and (max-width:480px) {
            margin: 0px 0px 0px 6px;
          }
        }
      }

      .notifyMe__button,
      .outofStock__button {        

        @media screen and (max-width:1200px) {
          font-size: 14px;
        }

        img {
          margin: 0px 0px 0px 8px;

          @media screen and (max-width:480px) {
            margin: 0px 0px 0px 6px;
          }
        }
      }
    }
  }

}


/*-------------------------- Arabic view end ------------------------*/