@import "./../../variables";

.bundleProdWrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  // justify-content: space-between;
  align-items: center;
  padding: 2px 20px;
  width: 100%;
  font-size: 16px !important;

    @media screen and (max-width:480px) {
        padding: 2px 10px;
      }


  .pricingCart {
    display: flex;
    justify-content: end;

    html[dir="rtl"] & {
      justify-content: start;
    }

    span {
      font-weight: 500;
      color: #dc3a1a;

      html[dir="rtl"] & {
        font-weight: bold;
      }
    }
  }

  .pricing {
    display: flex;
    padding: 0px 6px 10px 6px;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    font-size: 16px;
    transform: translate(0px, 14px);

    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      color: $textDiscount;
      height: 18px;
      overflow: hidden;
    }

    div {
      display: flex;
      justify-content: end;

      html[dir="rtl"] & {
        justify-content: start;
      }
    }

    span {
      font-weight: 500;
      color: $textDiscount;
      font-size: 15px !important;
      transform: translate(0px, 6px);

      html[dir="rtl"] & {
        font-weight: bold;
      }
    }
  }

  .title {
    display: grid;
    grid-template-columns: 10% 90%;
    gap: 10px;
    align-items: center;
    cursor: pointer;

    span {
      width: 90%;
      height: 20px;
      overflow: hidden;
      word-break: break-all;
    }

    .prodImg {
      width: 20px;
      height: 20px;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .checkbox {
    margin: auto;
    position: relative;
    width: 19px;
    height: 17px;

    .label {
      display: block;
      width: 19px;
      height: 17px;
      background: #FFF;
      border: 1px solid #dc3a1a;
      border-radius: 3px;
      cursor: pointer;
      position: relative;

      &.checked {
        &::before {
          content: "";
          position: absolute;
          left: 9%;
          top: 8%;
          width: 85%;
          height: 85%;
          background-color: #dc3a1a;
          border-radius: 3px;

          html[dir="rtl"] & {
            right: 9%;
            left: unset;
          }
        }
      }
    }

    input {
      visibility: hidden !important;
    }
  }
}