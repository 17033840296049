.productTwelve__block {
  width: 100%;
  background-color: #ffffff;
  // margin: 0px 0px 24px 0px;
  border-radius: 0px 5px 5px;
  min-height: 100%;
  
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 9px #d9d9d9;
  }
  .productTwelve__header {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px 0px 0px;
    align-items: flex-start;
    .productOne__favourite__icon {
      display: block;
      margin: 7px 0px 0px;
      cursor: pointer;
      width: 18px;
      &.empty_icon{
        opacity: 0.5;
      }
    }
    .productOne__favourite__icon__disable {
      display: none;
    }
    .productTwelve__new__sticker__block {
      // width: 85px;
      // height: 30px;
      background: #ff9b04;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      padding: 5px 9px 6px 6px;
      border-bottom-right-radius: 15px;
      .productTwelve__new__sticker__text {
        margin: auto;
        font-family: 'SSTbold';
        font-weight: bold;
        font-size: 13px;
        color: #ffffff;
        line-height: 1;
      }
    }
    .quality__favourite__block {
      display: flex;
      align-items: center;
      padding: 12px 12px 0px 0px;
      .quality__icon {
        width: 36px;
        margin: 0px 10px 0px 0px;
      }
      .favourite__icon {
        display: block;
      }
      .favourite__icon__disable {
        display: none;
      }
    }
  }
  .productTwelve__content {
    padding: 15px;
    .productTwelve__image__slider{
      width: 85%;
      margin: 0px auto;
      min-height: 160px;
      max-height: 160px;
      .slick-prev:before, .slick-next:before{
        color: #c7c7c7;
      }
      .slick-next {
        background-repeat: no-repeat;
        width: 8px;
        height: 20px;
        background-size: contain !important;
        background: url(./../../assets/Icon/grey_right_arrow.svg);
        background-repeat: no-repeat;
        &:before{
          display: none;
        }
    }
    .slick-prev {
      background-repeat: no-repeat;
      width: 8px;
      height: 20px;
      background-size: contain !important;
      background: url(./../../assets/Icon/grey_right_arrow.svg);
      background-repeat: no-repeat;
      transform: rotate(175deg) translate(0, 0%);
      top:41%;
      &:before{
        display: none;
      }
  }
      .slick-slider{

        // .slick-arrow{ display: none !important; }
        .slick-list {          
          .slick-track{
            .slick-slide{

              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
                max-height: 160px;
                margin: 0px auto;
              }
            }
          }       
        }
        .slick-dots{ 
          bottom: 6px;
          background-color: #f7f7f75e;
          display: flex !important;
          max-width: max-content;
          padding: 2px 5px;
          height: 17px;
          align-items: center;
          border-radius: 7px;
          margin: 0px auto;
          left: 50%;
          transform: translateX(-50%);

          li{
            width: 10px;
            height: 10px;
            margin: 0px 2px;

            button{
              padding: 0px;
              height: 10px;
              width: 10px;
              &:before{
                line-height: 10px;
                width: 10px;
                height: 10px;
              }
            }
          }
        }
      }
    }
    .plp__mezza__reward{
      img[alt="tabby"] { width: 70px; }
    }
    .productTwelve__name__link{
      text-decoration: none;
      color: #000000;  
      margin: 0px;
      .productTwelve__name{
       font-family: 'SSTbold';
        font-weight: bold;
        margin: 0px 0px 10px;
        font-size: 15px;
        line-height: 1.4;
        // min-height: 55px;        
        min-height: 90px;        
      }
    }
    .productTwelve__image__slider {
      .awssld__content {
        background-color: inherit;
      }
      .awssld__bullets {
        position: unset;
        background-color: none;
        //   border: 1px solid #000000;
      }
      .awssld__bullets button {
        background: none;
        border: 1px solid #000000;
        transform: scale(0.7);
        margin: 0px 1px 5px;
        width: 10px;
        height: 10px;
      }
      .awssld__bullets .awssld__bullets--active {
        font-size: 0px;
        transform: scale(0.7);
        background-color: #000000;
        border: 1px solid #000000;
      }
      .awssld__bullets .awssld__bullets--loading {
        //   transform: scale(0.6);
      }
      .awssld__controls {
        visibility: hidden;
      }
      // .awssld__controls__arrow-right {
      //   visibility: hidden;
      // }
    }
     .size__button__block {
      margin: 0px 0px 10px 0px;
      min-height: 54px;

      .size__button__listing {
        padding: 10px 0px;
        display: flex;
        align-items: center;      
        border-top: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        flex: 0 0 100%;
        max-width: 100%;

        button{
          position: relative;
          &:before{
            content: "";
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: #000000;
            border-radius: 3px;
            -webkit-transform: scaleX(0);
            transform: scaleX(0);
            -webkit-transform-origin: 50%;
            transform-origin: 50%;
            -webkit-transition-property: transform;
            transition-property: transform;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
          }
        }
  
        .size__button__active {
          font-size: 12px;
          font-family: 'SSTmedium';
          border-radius: 3px;
          color: #ffffff;
          padding: 6px 8px;
          // line-height: 1.26;
          // background-color: #000000;
          border: 1px solid #000000;
          outline: none;
          border: none;
          margin: 0px 8px 0px 0px;
          z-index: 2;
          position: relative;
          &:before{
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
            z-index: -1;
          }
          &:last-child {
            margin-right: 0px;
          }
        }
        .size__button {
          font-size: 12px;
          border: 1px solid #707070;
          border-radius: 3px;
          padding: 6px 8px;
          background-color: #ffffff;
          outline: none;
          margin: 0px 8px 0px 0px;
          &:last-child {
            margin-right: 0px;
          }
        }
      }
      
    }
    .delivery-time-area{
      margin-bottom: 8px;
      .text4,.heading7 {
        font-size: 12px;
        line-height: 1;
        margin-bottom: 0px;
      }
    }
    .productTwelve__feature__block {
      padding: 0px 0px 5px;
      border-bottom: 1px solid #d9d9d9;
      margin: 0px 0px 2px;
      .feature__text__block {
        display: flex;
        align-items: center;
        margin: 0px 0px 10px;
        .feature__bullet {
          width: 6px;
          height: 6px;
          border-radius: 10px;
          margin: 0px 10px 0px 0px;
          background-color: #c8c8c8;
        }
      }
      &:empty{ display: none; }
    }
    .addToCart__button {
      border-radius: 5px;
      color: #ffffff;
      font-size: 14px;
      font-family: 'SSTbold';
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 8px 10px;
      background-color: #dc3a1a;
      // box-shadow: 0px 1px 3px 0px #928d8dde;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
      display:flex;
      align-items: center;
      justify-content: center;      

      .addToCart__icon {
        margin: 0px 10px 0px 0px;
        width: 16px;
      }
      &:hover,&:focus {
        -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        opacity: 0.8;
      }
    }
    .outofStock__button{
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      font-family: 'SSTbold';
      font-weight: bold;
      letter-spacing: 0.85px;
      padding: 8px 15px;
      background-color: #a7a7a7;    
      text-transform: uppercase;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 6px;
    }
    .notifyMe__button{
      border-radius: 5px;
      color: #ffffff;
      font-size: 15px;
      font-family: 'SSTbold';  
      text-transform: uppercase;
      letter-spacing: 0.85px;
      padding: 8px 15px;
      line-height: 1.5;                
      background-color: #FE8B21;        
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0px;

      &:hover,&:focus {
        -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
        box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        opacity: 0.8;
      }

      img {
        margin: 0px 8px 0px 0px;
        height: 14px;
        width: auto;
        vertical-align: sub;     
        
        @media screen and (max-width:480px) {
          margin: 0px 6px 0px 0px;
          height: 13px;
        }
      }
      // @media screen and (max-width:992px) {
      //   font-size: 14px;
      //   padding: 6px 10px;
      //   line-height: 1.1;
      // }
      // @media screen and (max-width:480px) {
      //   font-size: 13px;
      //   padding: 6px 10px;
      // }
    }
    // .notifyMe__button{
    //   border-radius: 5px;
    //   color: #ffffff;
    //   font-size: 14px;
    //   font-family: 'SSTbold';
    //   font-weight: bold;
    //   letter-spacing: 0.85px;
    //   padding: 8px 10px;
    //   background-color: #ff8800;
    //   // box-shadow: 0px 1px 3px 0px #928d8dde;
    //   text-align: center;
    //   text-transform: uppercase;
    //   text-decoration: none;
    //   cursor: pointer;
    //   display:flex;
    //   align-items: center;
    //   justify-content: center;
    // }
    .productTwelve__quickView__compare__block{
        margin: 8px 0px 0px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .productTwelve__button__block{
            border: none;
            outline: none;
            background: none;
            display: flex;
            align-items: center;
            .productTwelve__button__icon{
                margin: 0px 8px 0px 0px;
            }
            p{ font-size: 14px; }
            svg{
              margin-right: 3px;
            }
            &:hover {
              color: #dc3a1a;
              transition: all 0.2s ease-in-out;
              -webkit-transition: all 0.2s ease-in-out;
              p{ 
                text-decoration: underline; 
                text-decoration-color: #cdcdcd;
                color: #dc3a1a;
                
              }
            }
        }
    }
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .productTwelve__block{
    .productTwelve__header{ 
      padding: 0px 0px 0px 10px;  
      .productTwelve__new__sticker__block{
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 0px;
        .productTwelve__new__sticker__text{
          font-family: 'SSTArabicBold';
        }
      }
      
    }
    .productTwelve__content{

      .addToCart__button,.outofStock__button,
      .notifyMe__button,.pre-order {
        font-family: 'SSTArabicBold';
        font-weight: bold;
        font-size: 14px;
      }
      .addToCart__button {
        .addToCart__icon{
          margin: 0px 0px 0px 10px;
        }
      }
      .notifyMe__button{

        @media screen and (max-width:1200px) {
          font-size: 14px;
        }
        img{
          margin: 0px 0px 0px 8px;
          @media screen and (max-width:1200px) {
            margin: 0px 0px 0px 6px;
          }
          @media screen and (max-width:480px){
            margin: 0px 0px 0px 4px;
          }
        }                  
      }
      .productTwelve__quickView__compare__block{
        .productTwelve__button__block{
          svg{
            margin-right: 0px;
            margin-left: 3px;
          } 
        } 
      } 
      .size__button__block {
        .size__button__listing {
          .size__button{
            margin: 0px 0px 0px 8px;
            &:last-child{
              margin: 0px;
            }
          }
        }
      }
    } 
  } 
  
}

/*-------------------------- Arabic view End ------------------------*/