.margin-alert{
  width: 100%!important;
  padding-left: 37px;
  padding-right: 37px;


}
.green-alert-info{
  &::before {
    display: flex;
    position: absolute;
    content: "i";
    left: 10px;
    width: 22px;
    height: 22px;
    background-color: #275527;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
.red-alert-info{
  &::before {
    display: flex;
    position: absolute;
    content: "i";
    left: 10px;
    width: 22px;
    height: 22px;
    background-color: #842029;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
.shopping__cart__page__container {
  padding: 0px 12px;
  .breadcrumbs__container{
    background: #fff;
  }
  .shopping__cart__page__block {
    padding: 10px 0px;
    width: 1420px;
    margin: 0px auto;
    .cart__notice__area{
      padding: 25px 15px;
      background-color: #fcfcfc;
      margin-bottom: 8px;
      .text3{
        width: 75%;
        margin: 0px auto !important;

        @media screen and (max-width:768px) {
          width: 80%;
        }
        @media screen and (max-width:480px) {
          width: 90%;
        }
        .read-or-hide{
          color: #337dff;
          cursor: pointer;
        }
      }
    }
    .sc__page__title {
      padding: 0px;
      margin: 0px 0px 10px;
    }
    .continue_shopping_btn{
      padding: 9px 12px;
      display: inline-block;
      background-color: #dc3a1a;
      color: #ffffff;
      font-size: 15px;
      text-align: center;
      text-decoration: none;
      border-radius: 5px;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgb(0 0 0 / 0%);
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-property: box-shadow, transform;
      transition-property: box-shadow, transform;

      &:hover{
        box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
    .sc__page__description {
      padding: 0px;
      margin: 0px 0px 20px 0px;
    }
    .shopping__cart__page__inner__block {
      .pickupWarning {
        display: flex;
        width: 100%;
        min-height: 64px;
        border: 1px solid #EEA540;
        border-radius: 4px;
        align-items: center;
        padding: 10px 20px 10px 45px;        
        margin-top: 20px;
        background-color: rgba(255, 227, 149, 0.5);
        position: relative;
        font-size: 16px;
        @media screen and (max-width:768px) {
          padding: 15px 15px 15px 40px;
        }
        @media screen and (max-width:992px) {
          margin-bottom: 15px;
        }
        &::before {
          display: flex;
          position: absolute;
          content: "i";
          left: 10px;
          width: 22px;
          height: 22px;
          background-color: #EEA540;
          border-radius: 100%;
          align-items: center;
          justify-content: center;
          color: white;
        }
      
        span {
          font-weight: 500;
          margin-left: 5px;
        }
      }
      padding: 0px 12px;
      align-items: flex-start;
      .shopping__cart__left__block {
        padding: 0px;
        margin: 0px 0px 50px 0px;
        @media screen and (max-width:992px) {
          margin: 0px 0px 20px 0px;
        }
        // box-shadow: 0px 0px 9px #d9d9d9;
        .sc__product__block {
          background-color: #ffffff;
          width: 100%;
          height: fit-content;
          padding: 20px 0px;
          margin: 0px 0px 15px;
          position: relative;
          .sc__product__left__block {
            padding: 0px 20px 0px;

            .sc__product__image__block {
              width: 100%;
              height: 132px;
              padding: 10px;

              // background-color: #e9f3f3;
              // border: 1px solid #c8c8c8;
              .sc__product__image {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
          .cart__pg__loading{
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;     
            left: 0px;
            top: 0px;
            background-color: #ffffff47;     
          }
          .sc__product__middle__block {
            padding: 0px 20px 0px;
            .pd__mb__product__name {
              text-decoration: none;
              color: #000000;
              display: block;
              margin-bottom: 10px;
              .heading5{
                font-size: 17px;
              }
            }
            .pd__mb__cashback {
              display: flex;
              margin: 10px 0px;
              font-size: 14px;
              line-height: 17px;
              letter-spacing: -0.768px;
              color: #168F00;
              font-weight: 500;

              strong {
                margin: 0px 5px;
                font-weight: 500;
              }            
            }
            .sc__product__company__logo {
              margin: 0px 0px 16px;
            }
            .sc__product__productName {
            }
            @media screen and (max-width:480px) {
              padding: 0px 15px 0px;
            }
          }
          .sc__product__right__block {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            padding: 0px 20px;
            @media screen and (max-width:1366px) {
              padding: 0px 15px;
            }
            .text3{
              text-align: right;
            }
            .heading3 {
              @media screen and (max-width:1100px) {
                font-size: 20px;
              }
              @media screen and (max-width:580px) {
                font-size: 17px;
              }
            }
          }
        }
        .sc__page__button__block__title {
          margin: 30px 0px 10px;
          padding: 0px 20px 0px;
        }
        .sc__page__horizontal__line {
          border-bottom: 1px solid #acacac;
          margin: 0px;
        }
        .sc__page__category__button__block {
          padding: 30px 20px;

          .sc__page__button__main__block {
            width: 100%;
            position: relative;
            margin: 0px auto;

            .arrow__block {
              z-index: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 99%;
              position: absolute;
              top: 50%;
              transform: translateY(-60%);
            }
            .arrow {
              filter: invert(1);
              cursor: pointer;
            }
            .left__arrow {
              position: absolute;
              top: 50%;
              transform: translateY(-70%);
              // transform: translateX(-100%);
            }
            .right__arrow {
              position: absolute;
              top: 50%;
              right: 1%;
              transform: translateY(-70%);
            }
            .sc__page__button__block {
              z-index: 5;
              display: flex;
              align-items: center;
              overflow-x: hidden;
              scroll-behavior: smooth;
              width: 96%;
              .sc__page__button__active {
                text-align: center;
                font-size: 14px;
                font-family: 'SSTbold';
                font-weight: bold;
                border-radius: 3px;
                min-width: fit-content;
                color: #ffffff;
                padding: 10px 15px;
                background-color: #000000;
                border: 1px solid #000000;
                margin: 0px 12px 10px 0px;
                outline: none;
                border: none;
                letter-spacing: 0.3px;
                cursor: pointer;
              }
              .sc__page__button {
                cursor: pointer;
                text-align: center;
                min-width: fit-content;
                font-size: 14px;
               font-family: 'SSTbold';
font-weight: bold;
                border: 1px solid #707070;
                border-radius: 3px;
                padding: 10px 15px;
                color: #808080;
                background-color: #ffffff;
                margin: 0px 12px 10px 0px;
                outline: none;
                letter-spacing: 0.3px;
              }
            }
          }
        }

        .shopping__cart__emptyBox{
          margin: 20px;
          padding: 2.5rem 1.2rem;
          background-color: #f9f9f9;
          text-align: center;
          img { max-width: 80%; }
          .text3{
            font-size: 16px;
            font-family: 'SSTbold';
            color: #000000;
            margin: 12px 0px !important;
          }
          .continue_shopping_btn{
            padding: 9px 12px;
            display: inline-block;
            background-color: #dc3a1a;
            color: #ffffff;
            font-size: 15px;
            text-align: center;
            text-decoration: none;
            border-radius: 5px;
            -webkit-transform: perspective(1px) translateZ(0);
            transform: perspective(1px) translateZ(0);
            box-shadow: 0 0 1px rgb(0 0 0 / 0%);
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;
            -webkit-transition-property: box-shadow, transform;
            transition-property: box-shadow, transform;

            &:hover{
              box-shadow: 0 10px 10px -10px rgb(0 0 0 / 50%);
              -webkit-transform: scale(1.1);
              transform: scale(1.1);
            }
          }
        }
      }
      .sc__newArrival__block {
        margin: 20px 0px 0px;
        width: 100%;
        padding: 30px 15px 30px;
        background-color: #ffffff;
        .heading4{
          text-align: center;
        }
      }
      .shopping__cart__right__block {
        padding: 0px 0px 0px 20px;
        
  .product__offer__block {
    display: flex;
    align-items: center;
    margin: 10px 0px 10px 0px;
    flex: 0 0 100%;
    .product__offer__icon {
      width: 20px;
      margin: 0px 12px 0px 0px;
    }

    .product__tamara_offer__icon {
      width: 20px;
      margin: 0px 12px 0px 0px;
      height: 15px;
      width: 50px;
      margin-left: 30px;
    }

    .tamara-product-widget {
      line-height: 1.4 !important;
      font-size: 13px !important;
      padding: 16px 80px 16px 16px !important;

      &:empty {
        display: none;
      }

      @media screen and (max-width: 1280px) {
        font-size: 12px !important;
        padding: 16px 60px 16px 16px !important;
      }

      @media screen and (max-width: 992px) {
        padding: 16px 110px 16px 16px !important;
      }

      @media screen and (max-width: 480px) {
        font-size: 13px !important;
        font-weight: normal;
        padding: 16px 85px 16px 16px !important;
      }

      .tamara-logo {
        width: 40px !important;
        height: auto !important;

        @media screen and (max-width: 992px) {
          width: 70px !important;
        }

        @media screen and (max-width: 480px) {
          width: 52px !important;
        }
      }
    }

    .installment__offer__box {
      width: 100%;
    }
    #TabbyPromo {
      @media screen and (max-width:992px) {
        width: 100%;
      }

      .styles__tabby-promo-snippet_font_inherit--b7b35 {}

      .styles__tabby-promo-snippet__content--88401 {
        .styles__tabby-promo-snippet__text--f30af {
          span {
            line-height: 1.4 !important;
            font-size: 13px !important;
          }
        }
      }
    }

    .styles__tabby-promo-snippet--54518.styles__tabby-promo-snippet_font_inherit--b7b35 .product__offer__text__box {
      display: flex;
      align-items: center;

      .product__offertype {
        margin: 0px;
        font-size: 14px;
        font-family: 'SSTbold';
        font-weight: bold;

        .product__offerText {
          margin: 0px 10px 0px 0px;
          font-size: 14px;
          font-family: "SSTregular" !important;
          font-weight: 400;
        }
      }

      .product__termsAndConditions__link {
        margin: 0px;
        font-size: 14px;
        font-family: 'SSTbold';
        font-weight: bold;
        text-decoration: none;
        color: #003cb4;
      }
    }
  }
        .sc__package__summary__block {
          // box-shadow: 0px 0px 9px #d9d9d9;
          background-color: #ffffff;

          .sc__ps__title {
            font-size: 24px;
            font-family: 'SSTbold';
            font-weight: bold;
            padding: 20px;
            border-bottom: 1px solid rgb(224, 224, 224);
            margin: 0px;
          }
          .sc__ps__detail__block {
            padding: 10px 20px 20px;
            @media screen and (max-width:1480px) and (min-width:1200px) {
              padding: 10px 13px 20px;
            }
            .sc__ps__detail__inner__block {
              display: flex;
              justify-content: space-between;
              align-items: center;
              border-bottom: 1px solid rgb(224, 224, 224);
              padding: 14px 0px;

              .sc__ps__detail__title {
                margin: 0px;
                font-size: 16px;
                font-family: "SSTregular";
              }
              .sc__ps__detail__price {
                margin: 0px;
                font-size: 16px;
               font-family: 'SSTbold';
                font-weight: bold;
              }            
              &.discount_info_row{
                position: relative;
                .discount_remove_spinner {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0px;
                  top: 0px;
                  background-color: #ffffffb8;
                  text-align: center;
                  z-index: 2;
                }
                button{
                  &:focus{
                    box-shadow: unset;
                  }
                }
              }
            }
            .checkout__coupon__block{
              .cart_coupon_code_inputbox{
                position: relative;
                margin-bottom: 15px;
                display: flex;
                flex: 0 0 100%;
                align-items: center;

                .cart_coupon_spinnerbox{
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  left: 0px;
                  top: 0px;
                  background-color: #ffffffb8;
                  z-index: 10;
                }
                .remove_coupen_code_btn{
                  img {
                    cursor: pointer;
                  }
                }
              }
            }
            ul{
              margin: 0px;
              padding: 0px;
              li.d-flex {
                margin-top: 10px;
                img {
                  width: auto;
                  max-height: 28px;
                  max-width: 80px;
                }
              }
            }            

            .checkout__coupon__block {
              .input-group {
                position: relative;
                width: 90%;
                button#button-addon2 {
                  background: transparent;
                  border: 0;
                  position: absolute;
                  right: 10px;
                  top: 50%;
                 
                  transform: translateY(-50%);
                  color: #000;
                  font-weight: 600;
                  z-index: 9;
                }
          
                input.form-control {
                  background: #FFDCA7;
                  border: 1px solid #1B26303B;
                  border-radius: 0;
                  padding-right: 60px;
                  &:focus {
                    background: #d8d8d7;
                    box-shadow: none;
                    border-color: #000;
                  }
                }
              }
          
            }
            .sc__ps__detail__total__order__block {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 30px 0px;
              .sc__ps__detail__total__order {
                font-size: 18px;
               font-family: 'SSTbold';
font-weight: bold;
                margin: 0px;
              }
              .heading5{
                @media screen and (max-width:580px) {
                  font-size: 18px;
                }
              }
            }
          }
        }
        .checkout__button__link {
          text-decoration: none !important;
          .sc__addToCart__button__block {
            width: 100%;
            padding: 0px;
            margin: 20px 0px;
            .sc__addToCart__button {
              text-decoration: none;
              width: 100%;
              border-radius: 5px;
              color: #ffffff;
              font-size: 16px;
             font-family: 'SSTbold';
font-weight: bold;
              letter-spacing: 0.85px;
              padding: 11px 18px;
              background-color: #dc3a1a;
              text-align: center;
              text-transform: uppercase;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              .sc__addToCart__icon {
                // margin: 0px 0px 0px 60px;
              }
              &:hover,&:focus {
                -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);      
                transform: translateY(-2px);
                -webkit-transform: translateY(-2px);
                transition: all 0.2s ease-in-out;
                -webkit-transition: all 0.2s ease-in-out;
              }
              @media screen and (max-width:480px) {
                font-size: 15px;
              }
            }
            &:disabled{
              .sc__addToCart__button{
                background-color: #707070;
                cursor: not-allowed;
                .btn__spinner{
                  min-height: 24px;
                }
              }
            }
          }
        }
        .signup__button {
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000005;
          border: 2px solid #000000;
          border-radius: 5px;
          color: black;
          outline: none;
          width: 100%;
          font-size: 16px;
          font-family: 'SSTbold';
          font-weight: bold;
          padding: 7px 10px;
          margin: 0px 0px 15px;
          &:hover,&:focus {
            -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
            box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);      
            transform: translateY(-2px);
            -webkit-transform: translateY(-2px);
            transition: all 0.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;
          }
        }
        .main__recommand__product__block {
          .heading5{
            font-size: 19px !important;
          }
          .recommand__product__detail__block{
            .addToCart__button,.outofStock__button,
            .notifyMe__button,.pre-order {
              min-width: 200px;
              max-width: max-content;
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}
.sum__points{
  color: #22D400;
    font-size: 14px;
    font-weight: 600;
}
@media screen and (max-width: 1450px) {
  .shopping__cart__page__block {
    width: 90% !important;
  }
}
@media screen and (max-width: 1200px) {
  .shopping__cart__page__block {
    width: 100% !important;
  }
  .shopping__cart__right__block {    
    margin: 10px 0px;
  }
}
@media screen and (max-width: 992px) {
  .shopping__cart__page__block {
    width: 100% !important;
  }
  .shopping__cart__right__block {    
    padding: 0px !important;
  }
}



/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {
  
  
  .sc__counter__block{
    .sc__counter__box{
      margin-right: 0px;
      .counter__decrease__block{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      .counter__increase__block{
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
    .sc__fav__sec{
      margin-right: 0px !important;
      margin-left: 10px;
    }
  }
  
  
  .shopping__cart__page__container {
    .shopping__cart__page__block {
      .shopping__cart__page__inner__block {
        .shopping__cart__left__block {
          .sc__product__block {
            .sc__product__right__block {
              .text3{
                text-align: left;
              }
            }
          }
        }
        .shopping__cart__right__block {
          padding: 0px 20px 0px 0px;
          .product__offer__block {
            .tamara-product-widget {
              padding: 16px 16px 16px 80px !important;
      
              @media screen and (max-width: 1280px) {
                padding: 16px 16px 16px 60px !important;
              }
      
              @media screen and (max-width: 992px) {
                padding: 16px 16px 16px 110px !important;
              }
      
              @media screen and (max-width: 480px) {
                padding: 16px 16px 16px 85px !important;
              }
            }
          }      
          .sc__package__summary__block {
            .sc__ps__detail__block {
              .checkout__coupon__block {
                .input-group {
                  input.form-control{                    
                    padding-right: .75em;
                    padding-left: 60px;
                  }
                  button#button-addon2 {
                    right:auto;
                    left: 10px;
                  }
                }                  
              }                 
            }        
          }        
          .checkout__button__link{
            .sc__addToCart__button__block {
              &:disabled{
                .sc__addToCart__button{
                  font-family: 'SSTArabicBold';                  
                }
              }
              .sc__addToCart__button {
                font-family: 'SSTArabicBold';
                img {
                  -webkit-transform: rotateY(180deg);
                  transform: rotateY(180deg);
                }
              }
            }            
          } 
          .main__recommand__product__block {
            
            .recommand__product__detail__block{
              .addToCart__button,.outofStock__button,
              .notifyMe__button,.pre-order {
                min-width: 200px;
                max-width: max-content;
                margin-left: 0px;
                margin-right: auto;
              }
            }
            .view__more__address__block{
              .view__more__address__button{
                width: 150px;
                .heading6 {
                  font-size: 17px;
                }
              }
            }
          }
           
          .signup__button{
            font-family: 'SSTArabicBold';
          }
        }        
      }    
    }    
  }

  
  

}

/*-------------------------- Arabic view End ------------------------*/