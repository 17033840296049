.product__delivery__option__area{
    display: flex;
    flex-direction: row;
    margin: 0px 0px 5px;    
    max-width: 100%;    
    min-height: 29px;

    @media screen and (max-width:1200px) {
        min-height: 23px;
    }

    .product__delivery__itembox {        
        margin: 0px 6px 0px 0px;        
        padding: 5px 5px;
        text-align: center;
        // font-size: 13px;
        background-color: #f5f4f4;
        color: #000000;
        font-weight: 500;
        font-family: 'SSTmedium';
        border-radius: 4px;
        width: max-content;
        display: flex;
        align-items: center;
        @media screen and (max-width:767px) {
            padding: 5px 6px;
        }
        @media screen and (max-width:380px) {
            padding: 5px 4px;
            margin: 0px 5px 0px 0px;
        }
        img{
            width: auto;
            height: 10px;
            margin-right: 5px;
            @media screen and (max-width:1200px) {
                display: none;
            }
        }
        span{
            font-size: 10px;
            color: #000000;
            font-weight: 500;    
            font-family: 'SSTmedium';        
            @media screen and (max-width:1200px) {
                font-size: 9px;
            }
            @media screen and (max-width:320px) {
                font-size: 8px;
            }
        }
        &:last-child{
            margin: 0px;
        }
        &.active{
            background-color: #042426;
            border: none !important;
            span{
                color: #ffffff !important;
            }
            img{
                filter: grayscale(1) invert(1); filter: grayscale(1) invert(1);
                -webkit-filter: grayscale(1) invert(1); filter: grayscale(1) invert(1);
                -moz-filter:  grayscale(1) invert(1); filter: grayscale(1) invert(1);
            }
        }
        &.disabled{
            background-color: #f5f4f4;
            border: none !important;
        }
    }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {
  
    .product__delivery__option__area {
        .product__delivery__itembox {
            margin-right: 0px;
            img{
                margin-left: 5px;
                margin-right: 0px;
            }
            &:last-child{
                margin-right: 0px;
            }
            &:nth-child(2){
                margin-right: 6px;
            }
            span{
                font-family: 'SSTArabicMedium';
            }
        }
    }

}

/*-------------------------- Arabic view start ------------------------*/
