.richTextWrapper {
    width: 1420px;
    padding: 0px;
    margin: 0px auto;
    margin-top: 30px;    

    @media screen and (max-width: 1440px) {
        width: 90% !important;
        max-width: 90%;
    }
    @media screen and (max-width: 580px) {
        width: 98% !important;
        max-width: 98%;
    }

    div[class="col-md-12"] {
        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .rich_text-container{
        .row{
            align-items: stretch;
            &:last-child{
                @media screen and (max-width:576px) {
                    flex-direction: column-reverse;
                }
            }
            .col-md-6,div {
                display: flex;
                flex-direction: column;
                justify-content: center;

                h3,p {
                    color: #000000;
                }
                h3{
                    @media screen and (max-width:992px) and (min-width:600px){
                        font-size: 18px;
                    }
                    @media screen and (max-width:576px){
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {

    .richTextWrapper {
        .rich_text-container{
            h3{
                font-family: 'SSTbold';  
            }            
        }
    }

}

/*-------------------------- Arabic view End ------------------------*/