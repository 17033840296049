.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.formContainer {
  max-width: 1200px;
}

.braviaTitle {
  font-size: 38px;
  font-weight: 600;
  color: #000;
  margin-bottom: 20px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .braviaTitle {
    font-size: 22px;
  }

}