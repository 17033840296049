.mb__product__list__detail__block {
    box-shadow: 0px 3px 6px #0000000a;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    margin: inherit;
    margin-bottom: 10px;
    background: #fff;

    .productTen__left__block {
        padding: 0px;

        .productTen__header {
            display: flex;
            justify-content: space-between;

            .productTen__new__sticker__block {
                width: auto;
                height: 30px;
                padding: 5px 10px 5px 5px;
                background: #ff9b04;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                border-bottom-right-radius: 15px;
                text-transform: uppercase;
                max-width: 115px;
                overflow: hidden;

                &.new__sticker__block__green {
                    background: #08BB5D;
                }

                .productTen__new__sticker__text {
                    margin: auto;
                    font-family: 'SSTbold';
                    font-weight: bold;
                    font-size: 12px;
                    color: #ffffff;
                }
            }

            .quality__icon {
                width: 36px;
                // margin: 0px 10px 0px 0px;
            }
        }

        .product__pics__area {
            position: relative;

            .productTen__image__slider {
                margin-top: 7px;
                min-height: 150px;
                max-height: 150px;
                padding: 0px 20px !important;

                .slick-next {
                    background-repeat: no-repeat;
                    width: 6px;
                    height: 12px;
                    background-size: contain !important;
                    background: url(./../../assets/Icon/grey_right_arrow.svg);
                    background-repeat: no-repeat;
                    top: 50%;
                    left: auto;
                    right: -6px;

                    &:before {
                        display: none;
                    }
                }

                .slick-prev {
                    background-repeat: no-repeat;
                    width: 6px;
                    height: 12px;
                    background-size: contain !important;
                    background: url(./../../assets/Icon/grey_right_arrow.svg);
                    background-repeat: no-repeat;
                    transform: rotate(-185deg) matrix(1, 0, 0, 1, 0, 7);
                    top: 50%;
                    left: -6px;
                    right: auto;

                    &:before {
                        display: none;
                    }
                }

                @media (max-width:480px) {
                    min-height: 100px;
                    max-height: 100px;
                }

                .slick-slider {

                    // .slick-arrow{ display: none !important; }
                    .slick-list {
                        .slick-track {
                            .slick-slide {

                                img {
                                    height: 100%;
                                    width: 100%;
                                    object-fit: contain;
                                    max-height: 150px;
                                    margin: 0px auto;

                                    @media (max-width:480px) {
                                        max-height: 100px;
                                    }
                                }
                            }
                        }
                    }

                    .slick-dots {
                        bottom: 6px;
                        background-color: #f7f7f75e;
                        display: flex !important;
                        flex-wrap: wrap;
                        justify-content: center;
                        max-width: max-content;
                        padding: 2px 5px;
                        //   height: 17px;
                        align-items: center;
                        border-radius: 7px;
                        margin: 0px auto;
                        left: 50%;
                        transform: translateX(-50%);

                        li {
                            width: 8px;
                            height: 8px;
                            margin: 0px 2px 2px;

                            @media (max-width:480px) {
                                width: 7px;
                                height: 7px;
                            }

                            button {
                                padding: 0px;
                                height: 8px;
                                width: 10px;

                                &:before {
                                    line-height: 10px;
                                    width: 8px;
                                    height: 8px;
                                }
                            }
                        }
                    }
                }

                .awssld {
                    height: 160px;
                }

                .awssld__content {
                    background-color: inherit;
                }

                .awssld__bullets {
                    position: unset;
                    background-color: none;
                    //   border: 1px solid #000000;
                }

                .awssld__bullets button {
                    background: none;
                    border: 1px solid #000000;
                    transform: scale(0.7);
                    margin: 0px 1px 5px;
                }

                .awssld__bullets .awssld__bullets--active {
                    transform: scale(0.7);
                    background-color: #000000;
                    border: 1px solid #000000;
                }

                .awssld__bullets .awssld__bullets--loading {
                    //   transform: scale(0.6);
                }

                .awssld__controls {
                    visibility: hidden;
                }

                // .awssld__controls__arrow-right {
                //   visibility: hidden;
                // }
            }
            .soldOutLable {
                position: absolute;
                width: 100%;
                background: hsla(0,0%,85.1%,.76);
                height: 30px;
                top: 35%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0px 0px 0px 5px;
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 21px;
                color: rgba(0,0,0,.72);
                text-transform: uppercase;
                @media screen and (max-width:480px){
                    height: 22px;
                    top: 26%;
                }
            }
            .cardLabel {
                display: flex;
                position: absolute;
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
                z-index: 2;
                img {
                    border-radius: 0 !important;
                    max-width: 25% !important;
                    max-height: 25% !important;
                }

                &.left_top {
                    justify-content: flex-start;
                    align-items: flex-start;
                }

                &.center_top {
                    justify-content: center;
                    align-items: flex-start;
                }

                &.right_top {
                    justify-content: flex-end;
                    align-items: flex-start;
                }

                &.left_middle {
                    justify-content: flex-start;
                    align-items: center;
                }

                &.center_middle {
                    justify-content: center;
                    align-items: center;

                    img {
                        max-height: 50% !important;
                        max-width: 50% !important;
                    }
                }

                &.right_middle {
                    justify-content: flex-end;
                    align-items: center;
                }

                &.left_bottom {
                    justify-content: flex-start;
                    align-items: flex-end;
                }

                &.center_bottom {
                    justify-content: center;
                    align-items: flex-end;
                }

                &.right_bottom {
                    justify-content: flex-end;
                    align-items: flex-end;
                }
            }
        }

        .productNine__quickView__compare__block {
            margin: 10px 0px 0px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 0px 20px 0px;

            .productNine__button__block {
                border: none;
                outline: none;
                background: none;
                display: flex;
                align-items: center;

                .productNine__button__icon {
                    margin: 0px 10px 0px 0px;
                }
            }
        }
    }

    .productTen__middle__block {
        padding: 20px;

        .quality__favourite__block {
            display: flex;
            align-items: center;
            padding: 0;
            justify-content: end;

            .quality__icon {
                width: 36px;
                margin: 0px 10px 0px 0px;
            }

            .favourite__icon {
                display: block;
                max-width: 17px;
            }

            .favourite__icon__disable {
                display: none;
            }
        }

        .productNine__name__link {
            text-decoration: none;
            color: #000000;
            margin: 0px;

            .productNine__name {
                font-family: 'SSTbold';
                font-weight: bold;
                margin: 0px 0px 10px;
                font-size: 15px;
                line-height: 1.4;
                min-height: 80px;

                @media screen and (max-width:420px) {
                    font-size: 13px;
                    min-height: 73px;
                }
            }
        }

        .plp__tamara__box {
            display: inline-flex;
            width: 100%;
            flex: 0 0;
            max-width: 100%;
            justify-content: flex-start;
        }

        .plp__mezza__reward {
            img {
                width: auto;
                max-height: 26px;
                max-width: 80px;
                @media screen and (max-width:480px) {                    
                    max-width: 60px;
                    max-height: 20px;
                }
            }
            div{
                &:last-child{
                    text-align: right;
                }
            }
        }

        .mb__product-list-view-pricebox {
            margin-bottom: 8px;
            display: flex;
            flex-direction: column;
            min-height: 32px;
            align-items: flex-start;
            justify-content: flex-end;

            p {
                display: inline-block;

                // &:first-child{
                //     margin-right:5px !important;
                // }
                &.text4 {
                    position: relative;
                    width: fit-content;
                    line-height: 1.2;

                    &::after {
                        content: " ";
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        background-color: #666666;
                        -webkit-transform: translateY(-8px) rotate(-6deg);
                        transform: translateY(-8px) rotate(-6deg);
                        bottom: 0;
                        left: 0;
                    }

                    @media screen and (max-width:420px) {
                        font-size: 13px;
                    }
                }

                &.heading6 {
                    @media screen and (max-width:420px) {
                        font-size: 13px;
                    }
                }
            }
        }

        .productNine__feature__block {
            padding: 10px 0px;

            &:empty {
                display: none;
            }

            .feature__text__block {
                display: flex;
                align-items: flex-start;
                margin: 0px 0px 5px;

                .feature__bullet {
                    width: 6px;
                    height: 6px;
                    border-radius: 100%;
                    margin: 0px 10px 0px 0px;
                    background-color: #c8c8c8;
                    min-width: 6px;
                    min-height: 6px;
                    margin-top: 7px;
                }

                p {
                    word-break: break-all;
                }
            }
        }

        .mb__delivery__text {
            display: block;
            line-height: 1;
            margin-bottom: 6px;

            @media screen and (max-width:420px) {
                min-height: 30px;
            }

            .text4,
            .heading7 {
                @media screen and (max-width:420px) {
                    font-size: 12px;
                    line-height: 1.1;
                }

            }
        }

        .mb_cart_com_btn {
            display: flex;
            justify-content: space-between;

            .addToCart__button {
                display: flex;
                cursor: pointer;
                margin-left: auto;
                padding: 7px 10px;
                border-radius: 5px;
                font-size: 14px;
                font-family: "SSTbold";
                text-transform: uppercase;
                line-height: 0.95;
                background-color: #dc3a1a;
                color: #ffffff;
                min-width: 132px;
                min-height: 28px;
                align-items: center;

                @media screen and (max-width:480px) {
                    font-size: 12px;
                    min-width: 117px;
                    min-height: 26px;
                }
                @media screen and (max-width:380px) {

                }
                img {
                    width: 14px;
                    margin-right: 6px;
                    @media screen and (max-width:480px) {
                        height: 11px;
                        width: auto;
                    }
                }
                .spinner-border{
                    margin-left: 5px !important;
                    height: 0.7rem !important;
                    width: 0.7rem !important;
                }
                .btn__spinner{
                    width: 111.23px;
                    min-height: 13.3px;                        
                    @media screen and (max-width:480px) { width: 98.2px; min-height: 11.39px; }
                    @media screen and (max-width:380px) {  width: 98.21px; min-height: 11.39px; }
                    
                }
                &.pre-order{
                    .btn__spinner{
                        width: 98px;
                        min-height: 14px; 
                        @media screen and (max-width:480px) { min-height: 12px;  }
                        @media screen and (max-width:380px) { min-height: 11px; }
                        @media screen and (max-width:321px) { min-height: 11px; }
                    }
                }
            }
            .notifyMe__button{
                display: flex;
                cursor: pointer;
                margin-left: auto;
                padding: 7px 10px;
                border-radius: 5px;
                background-color: #FE8B21;
                color: #ffffff;
                font-size: 14px;
                line-height: 0.95;
                text-transform: uppercase;
                @media screen and (max-width:480px) {
                    font-size: 12px;
                }
                img {
                    width: 14px;
                    margin-right: 6px;
                    @media screen and (max-width:480px) {
                        height: 11px;
                        width: auto;
                    }
                }
                .spinner-border{
                    margin-left: 5px !important;
                    height: 0.7rem !important;
                    width: 0.7rem !important;
                }
            }
            .outofStock__button {
                padding: 7px 10px;
                border-radius: 5px;
                background-color: #a7a7a7;
                color: #ffffff;
                font-size: 14px;
                font-family: 'SSTbold';  
                text-transform: uppercase;
                line-height: 0.95;
                margin-left: auto;
                @media screen and (max-width:480px) {
                    font-size: 12px;
                }
                img {
                    width: 14px;
                    margin-right: 6px;
                    @media screen and (max-width:480px) {
                        height: 11px;
                    }
                }
                .spinner-border{
                    margin-left: 5px !important;
                    height: 0.7rem !important;
                    width: 0.7rem !important;
                }
            }
            .productNine__button__block {
                border: none;
                outline: none;
                display: flex;
                background: none;
                color: #dc3b2f;
            }
            .pre-order-mbl {
                font-size: 14px;
                color: white;
                border-radius: 5px;
                font-family: 'SSTbold';  
                padding: 7px 10px;
                line-height: 1;
                text-transform: uppercase;
                @media screen and (max-width:480px) {
                    font-size: 12px;
                }
                img {
                    width: 14px;
                    margin-right: 6px;
                    @media screen and (max-width:480px) {
                        height: 11px;
                        width: auto;
                    }
                }
                @media screen and (max-width:380px) {
                    font-size: 13px;
                }
            }
        }
    }

    .productTen__right__block {
        padding: 20px;

        .addToCart__button {
            cursor: pointer;
            display: block;
            text-align: right;

            .addToCart__icon {
                margin: 0px 20px 0px 0px;
            }
        }        
    }
}

@media(max-width:576px) {

    .mb__product__list__detail__block .productTen__middle__block .heading6 {
        font-size: 14px;
    }

    .mb__product__list__detail__block .main__rating__block {
        padding-bottom: 0;
    }

    .mb__product__list__detail__block .productTen__middle__block .productNine__feature__block .feature__text__block p {
        font-size: 12px;
    }

    .mb__product__list__detail__block .productTen__left__block .productNine__image__slider .awssld {
        height: auto;
    }

    .mb__product__list__detail__block .productTen__left__block .productNine__image__slider .awssld__bullets button {
        height: 10px;
        width: 10px;
    }

    .mb__product__list__detail__block .productTen__left__block .productTen__header .quality__icon {
        width: 24px;
        margin-left: 10px;
    }

    .mb__product__list__detail__block .productTen__middle__block {
        padding: 10px;
        position: relative;
    }

    .mb__product__list__detail__block .productTen__middle__block .quality__favourite__block+.heading6 {
        margin-top: 10px !important;
    }
}

/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {

    .mb__product__list__detail__block {
        .productTen__left__block {
            .product__pics__area {
                .productTen__image__slider {
                    padding-left: 0px !important;
                    padding-right: 12px !important;

                    .slick-arrow {
                        z-index: 1;
                        left: -7px;
                        right: auto;
                    }

                    .slick-next {
                        left: auto;
                        right: -7px;
                    }
                }
            }

            .productTen__header {
                .productTen__new__sticker__block {
                    border-bottom-right-radius: 0px;
                    border-bottom-left-radius: 15px;
                    padding: 5px 7px 5px 10px;
                }
            }
        }
        
        .productTen__middle__block {
            .productNine__name__link {
                .productNine__name { 
                    font-family: 'SSTArabicBold';
                }
            }
            .mb__product-list-view-pricebox {
                p {
                    display: inline-block;

                    &:first-child {
                        margin-right: 0px !important;
                        margin-left: 5px !important;
                    }
                }
            }

            .mb_cart_com_btn {
                .addToCart__button,.outofStock__button,
                .notifyMe__button,.pre-order {
                font-family: 'SSTArabicBold';
                font-weight: bold;      
                }

                .addToCart__button{
                    margin-left: unset;
                    margin-right: auto;
                    
                    img{
                        margin-right: 0px;
                        margin-left: 6px;
                    }
                    .btn__spinner{
                        width: 108.08px;
                        min-height: 13.3px;                        
                        @media screen and (max-width:480px) { width: 93.83px; min-height: 12px; }
                        @media screen and (max-width:380px) {  width: 82.52px; min-height: 11px; }
                        
                    }
                    &.pre-order{
                        .btn__spinner{
                            width: 90.91px;
                            min-height: 13.3px; 
                            @media screen and (max-width:480px) { width: 79.11px; min-height: 11px;  }
                            @media screen and (max-width:380px) { min-height: 11px; }
                            @media screen and (max-width:321px) { min-height: 11px; }
                        }
                    }
                }
                .notifyMe__button{
                    margin-left: unset;
                    margin-right: auto;
                    @media screen and (max-width:1200px) {
                      font-size: 14px;
                    }
                    img{
                      margin: 0px 0px 0px 10px;
                      @media screen and (max-width:1200px) {
                        margin: 0px 0px 0px 8px;
                      }
                      @media screen and (max-width:480px){
                        margin: 0px 0px 0px 6px;
                      }
                    }                  
                  }
            }
        }
    }



}

/*-------------------------- Arabic view End ------------------------*/