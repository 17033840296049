.warranty__block {
    margin: 10px 0px;
    background-color: #F2F2F2;
    padding: 10px;
    border-radius: 5px;
  .warranty__icon {
    width: 24px;
    margin: 0px 16px 0px 0px;
  }
}
