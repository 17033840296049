.plp__product__popup__block {
  // margin: 100px 0px 0px 0px;
  // width: 900px;
  // max-width: 90%;
  padding: 25px 20px 20px; 
  background-color: #ffffff;
  border-radius: 5px;
  position: relative;
  @media screen and (max-width:580px) {
    padding: 25px 15px 15px; 
  }
  .plp__product__popup__header {
    padding: 0px;
    position: absolute;
    width: 22px;
    height: 23px;
    top: 3px;
    right: 6px;
    .cancel__button{
      cursor: pointer;
    }
  }
  .plpProduct__popup__left__block {
    padding: 0px 10px 0px 0px;
    .productNine__image__slider {

      .slick-next {
        background-repeat: no-repeat;
        width: 6px;
        height: 12px;
        background-size: contain !important;
        background: url(./../../assets/Icon/grey_right_arrow.svg);
        background-repeat: no-repeat;
        top: 50%;
        right: -11px;
        &:before{
          display: none;
        }
      }
      .slick-prev {
          background-repeat: no-repeat;
          width: 6px;
          height: 12px;
          background-size: contain !important;
          background: url(./../../assets/Icon/grey_right_arrow.svg);
          background-repeat: no-repeat;
          transform: rotate(-185deg) matrix(1, 0, 0, 1, 0, 7);
          top: 50%;
          left: -11px;
          z-index: 2;
          &:before{
              display: none;
          }
      }
      .slick-list{
        .slick-slide {          
          img{
            max-height: 250px;
            margin: 0px auto;
            @media screen and (max-width:767px) {
              max-height: 120px;
            }
          }
        }
      }
      
      .awssld__content {
        background-color: inherit;
      }
      .awssld__bullets {
        position: unset;
        background-color: none;
        //   border: 1px solid #000000;
      }
      .awssld__bullets button {
        background: none;
        border: 1px solid #000000;
        transform: scale(0.7);
        margin: 0px 1px 5px;
      }
      .awssld__bullets .awssld__bullets--active {
        transform: scale(0.7);
        background-color: #000000;
        border: 1px solid #000000;
      }
      .awssld__bullets .awssld__bullets--loading {
        //   transform: scale(0.6);
      }
      .awssld__controls {
        visibility: hidden;
      }
      // .awssld__controls__arrow-right {
      //   visibility: hidden;
      // }
    }
  }
  .plpProduct__popup__right__block {
    padding: 0px 10px 0px 15px;
    @media screen and (max-width:767px) {
      padding: 0px;
    }

    .plpProduct__popup__titlebox{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-top: 5px;

      a{
        text-decoration: none;
        color: #000000;
        width: 85%;
        max-width: calc(100% - 35px);
        .heading6{
          color: #000000;
          line-height: 1.2;
          @media screen and (max-width:580px) {
            font-size: 16px;
          }
        }
      }
      .pd__category__favourite__button__block {
        // margin: 14px 0px 5px 0px;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
  
        .pd__category__button {
          padding: 8px 15px;
          margin: 0px 14px 10px 0px;
          font-size: 15px;
          line-height: 1;
          font-family: "SSTregular";
          letter-spacing: 0.18px;
          border: 1px solid #707070;
          border-radius: 3px;
          background-color: #ffffff;
          transition: all 0.2s;
          -webkit-transition: all 0.2s;
          &:hover{
            background-color: #dc3a1a;
            border: 1px solid #dc3a1a;
            color: #ffffff;
          }
          &.active{
           background-color: #dc3a1a;
           border: 1px solid #dc3a1a;
           color: #ffffff;
           -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
           box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6); 
          }
        }
        .pd__favourite__button {
          padding: 0px;
          margin-top: 3px;
          font-size: 16px;
          font-family: "SSTregular";
          letter-spacing: 0.18px;
          border: none;
          border-radius: 3px;
          background-color: #ffffff;
          .pd__favourite__icon {
            display: block;
            width: 24px;
            @media screen and (max-width:580px) {
              width: 21px;
            }
          }
          .pd__favourite__icon__disable {
            display: none;
          }
        }
      }
    }
    
    .plp_quickPopup_pricebox{
      margin-bottom: 15px;
      min-height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      span {        
       
        &.text3{
          margin-bottom: 0px !important;
          position: relative;
          width: fit-content;
          line-height: 1.2;
          &::after{
            content: " ";
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #666666;
            -webkit-transform: translateY(-8px) rotate(-6deg);
            transform: translateY(-8px) rotate(-6deg);            
            bottom: 0;
            left: 0;
          }
        }
      }
    }
    .size__button__block {
      padding: 10px 0px;
      display: flex;
      align-items: center;
      margin: 0px 0px 10px 0px;

      button{
        position: relative;
        &:before{
          content: "";
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #000000;
          border-radius: 3px;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: 50%;
          transform-origin: 50%;
          -webkit-transition-property: transform;
          transition-property: transform;
          -webkit-transition-duration: 0.3s;
          transition-duration: 0.3s;
          -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out;
        }
      }
      .size__button__active {
        font-size: 12px;
        border-radius: 3px;
        color: #ffffff;
        padding: 6px 8px;
        line-height: 1.26;
        // background-color: #000000;
        border: 1px solid #000000;
        outline: none;        
        margin: 0px 10px 0px 0px;
        z-index: 2;
        position: relative;
        &:before{
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
          z-index: -1;
        }
      }
      .size__button {
        font-size: 12px;
        border: 1px solid #707070;
        border-radius: 3px;
        padding: 6px 8px;
        line-height: 1.26;
        background-color: #ffffff;
        outline: none;
        margin: 0px 10px 0px 0px;
      }
    }
    .addToCart__button {
        border-radius: 5px;
        color: #ffffff;
        font-size: 14px;
        font-family: 'SSTbold';
        font-weight: bold;
        letter-spacing: 0.85px;
        padding: 8px 10px;
        background-color: #dc3a1a;
        box-shadow: 0px 1px 3px 0px rgb(146 141 141 / 87%);    
        text-align: center;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 59%;
        max-width: 180px;
        cursor: pointer;
        .addToCart__icon {
          margin: 0px 10px 0px 0px;
          width: 16px;
        }
        &:hover,&:focus {
          -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
          box-shadow: 0 6px 6px -4.8px rgba(32,32,32,.6);      
          transform: translateY(-2px);
          -webkit-transform: translateY(-2px);
          transition: all 0.2s ease-in-out;
          -webkit-transition: all 0.2s ease-in-out;
        }
        .spinner-border{
          margin-left: 10px;
        }
        .btn__spinner{
          min-height: 21px;
          @media screen and (max-width:1366px) {}
          @media screen and (max-width:580px) {}
        }
      }
      .notifyMe__button {
          border-radius: 5px;
          color: #ffffff;
          font-size: 14px;
          font-family: "SSTbold";
          letter-spacing: 0.85px;
          padding: 8px 15px;
          line-height: 1.5;
          background-color: #FE8B21;
          text-transform: uppercase;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 0px;
          max-width: 180px;
          img {
            margin: 0px 10px 0px 0px;
            width: 13px;
          }
      }
      .outofStock__button{
        border-radius: 5px;
        color: #ffffff;
        font-size: 14px;
        font-family: 'SSTbold';
        font-weight: bold;
        letter-spacing: 0.85px;
        padding: 8px 10px;
        background-color: #a7a7a7;
        box-shadow: 0px 1px 3px 0px rgb(146 141 141 / 87%);    
        text-align: center;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        flex: 0 0 59%;
        max-width: 170px;
        cursor: pointer;
      }      
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .plp__product__popup__block {

    .plp__product__popup__header{
      top: 3px;
      right: auto;
      left: 6px;
    }
    .plpProduct__popup__left__block{
      padding-left: 15px;
      .productNine__image__slider {
        .slick-next{
          left: auto;
          right: -11px;
        }
      }
    } 

     

    .plpProduct__popup__right__block {
      padding: 0px 15px 0px 10px;

      .pd__category__favourite__button__block { 
        .pd__category__button {
          margin: 0px 0px 10px 14px;
        }
      }
      .addToCart__button,.outofStock__button,
      .notifyMe__button,.pre-order {
        font-family: 'SSTArabicBold';
        font-weight: bold;        
      }
      .addToCart__button {
        .addToCart__icon{
          margin: 0px 0px 0px 10px;
        }
      }
      .notifyMe__button {
        img {
          margin: 0px 0px 0px 10px;
        }
      }
      .size__button__block {
        .size__button__listing {
          .size__button{
            margin: 0px 0px 0px 8px;
            &:last-child{
              margin: 0px;
            }
          }
        }
      }
    }
  }

}

/*-------------------------- Arabic view End ------------------------*/
