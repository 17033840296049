.product__protect__block {
  padding: 5px 0px;
  .product__title__block {
  
    .product__block__title__span {
      font-size: 14px;
    }
  }
  
  .product__protect__selection__block {
    .product__protection__selection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0px 0px 0px;
      .product__protection__form__block {
        display: flex;
        align-items: flex-start;
        .product__input__check {
          accent-color: #000000;
          height: 15px;
          width: 15px;
          border-radius: 10px;
          margin: 5px 12px 0px 0px;
          // border: 15px solid #303030;
          text-transform: uppercase;
        }
        .product__protection__text {
          width: 60%;
          margin: 0px;
          color: #3b3b3b;
          font-size: 15px;
        }
      }
      .product__protection__price__block {
        width: 34%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .product__protection__price {
          text-align: right;
          margin: 0px;
        }
        .product__protection__monthly__price {
                    margin: 0px;
          text-align: right;
        }
      }
    }
  }
}
