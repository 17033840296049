.mo__product__block {
  background-color: #ffffff;
  width: 100%;
  height: fit-content;
  padding: 30px 0px;
  margin: 0px;
  border-bottom: 2px solid #dbdbdb;
  .mo__product__left__block {
    padding: 0px 20px 0px;

    .mo__product__image__block {
      width: 100%;
      height: 172px;
      padding: 10px;

      background-color: #e9f3f3;
      border: 1px solid #c8c8c8;
      .mo__product__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .mo__product__middle__block {
    padding: 0px 20px 0px;
    .mo__product__company__logo {
      margin: 0px 0px 16px;
    }
    .mo__order__details__row {
      margin-bottom: 10px;
    }
  }

  .mo__product__right__block {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    padding: 0px 20px;
    .mo__order__detail__button {
      text-align: center;
      font-size: 14px;
     font-family: 'SSTbold';
font-weight: bold;
      border-radius: 3px;
      min-width: fit-content;
      color: #ffffff;
      padding: 10px 15px;
      background-color: #dc3a1a;
      margin: 0px 0px 10px 0px;
      outline: none;
      border: none;
      letter-spacing: 0.3px;
      cursor: pointer;
    }
  }
  .write_Review__link {
    color: #000000;
    margin: 0px 0px 0px 10px;
  }
}
