.footer__container {
  background-color: #000000;
  padding: 30px 12px;
  @media (max-width:1200px) { padding: 20px 12px; }
  .footer__block {
    width: 1420px;
    margin: 0px auto;
    padding: 0px;
    .footer__row {
      padding: 0px 12px 20px;
      border-bottom: 1px solid #202020;
      .logo__social__row {
        padding: 0px;
        .footer__logo___link {
          .footer__logo {
            margin: 0px 0px 50px;
          }
        }
        .social__group {
          a{
            display: inline-block;
            margin-right: 12px;
            border-radius: 50%;
            @media (max-width: 992px) { margin-right: 8px; }

            &:hover {
              box-shadow: 0px 0px 15px #ccc;
              transition: all 0.3s ease-in-out;
              -webkit-transition: all 0.3s ease-in-out;
            }
            .social__icon {
              margin: 0px;
              @media (max-width: 992px) { width: 30px; }
              @media (max-width: 767px) { width: 30px; }
            }
          }
        }
      }
      .footer__navbar__row {
        .footer__link__block {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .footer__link__title {
           font-family: 'SSTbold';
            font-weight: bold;
            font-size: 18px;
            color: #ffffff;
            margin: 0px 0px 20px 0px;
          }
          .footer___link {
            font-family: "SSTregular";
            font-size: 14px;
            color: #ffffff;
            text-decoration: none;
            margin: 0px 0px 10px 0px;
            &:hover{
              color: #ff6421;
              transition: all 0.3s ease-in-out;
              -webkit-transition: all 0.3s ease-in-out;
            }
          }
          .footer__email__title {
            font-family: "SSTregular";
            font-size: 16px;
            color: #ffffff;
            margin: 0px 0px 4px 0px;
          }
          .footer__email {
            font-family: "SSTmedium";
            font-size: 16px;
            color: #ffffff;
            text-decoration: none;
            margin: 0px 0px 20px 0px;
            word-break: break-all;
            &:hover{
              color: #ff6421;
              text-shadow: 0px 0px 15px #fb6e31;
              transition: all 0.3s ease-in-out;
              -webkit-transition: all 0.3s ease-in-out;
            }
          }
          .footer__mobile__title {
            font-family: "SSTregular";
            font-size: 16px;
            color: #ffffff;
            text-decoration: none;
            margin: 0px 0px 4px 0px;
          }
          .footer__mobile {
            font-family: "SSTmedium";
            font-size: 16px;
            color: #ffffff;
            margin: 0px;
            text-decoration: none;
            &:hover{
              color: #ff6421;
              text-shadow: 0px 0px 15px #fb6e31;
              transition: all 0.3s ease-in-out;
              -webkit-transition: all 0.3s ease-in-out;
            }
          }
        }
      }
    }
    .card__and__live__row {
      padding: 30px 0px 0px;
      display: flex;
      align-items: flex-start;
      @media (max-width:1200px) { padding: 20px 0px 0px; }
      .card__block {
        display: flex;
        .card {
          object-fit: cover;
          // width: 59px;
          height: 27px;
          // width: 100%;
          // height: 100%;
          margin: 0px 10px 0px 0px;
          background-color: transparent;
        }
        @media screen and (max-width:992px) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;      
          .card { 
            margin-bottom: 10px; 
            height: 23px;
          }    
        }
      }
      .sony__expert__block {
        display: flex;
        justify-content: flex-end;
        padding: 0px;
        .expert__button {
          background: none;
          border: none;
          outline: none;
        }
      }
    }
  }
}
.footer__bottom__container {
  background-color: #ffffff;
  padding: 12px 12px 12px;
  .footer__bottom__block {
    width: 1420px;
    margin: 0px auto;
    padding: 0px;
    .inner__footer__bottom__block {
      .copyright__text__block {
        .copyright__text {
          margin-bottom: 4px;
          .text4,.heading7,.footer__bottom__link{
            font-size: 13px;
            margin-bottom: 0px;
            line-height: 1;
          }
          .footer__bottom__link {
            text-decoration: underline;
            color: #000000;
          }
        }
        .terms-policy-area {
          line-height: 1;          
          a{
            text-decoration: none;
            color: #000000;
            .text4{
              font-size: 12px;
              color: #000000;
              font-family: "SSTmedium";
            }
            &:hover {              
              .text4{
                color: #3b3b3b;
              }
            }
          }
        }
      }
      .mestores__footer__logo {
        object-fit: contain;
        width: 160px;
        margin-left: auto;
        align-self: flex-start;
        @media (max-width:1200px) {
          width: 145px;
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .footer__block {
    width: 93% !important;
    max-width: 93% !important;
  }
  .footer__bottom__block {
    width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .footer__block {
    width: 90% !important;
    max-width: 90% !important;
  }
  .footer__bottom__block {
    width: 90% !important;
  }
}
@media screen and (max-width: 992px) {
  .footer__block {
    width: 100% !important;
    min-width: 100% !important;
    margin: 0px auto;
  }
  .footer__bottom__block {
    width: 100% !important;
    min-width: 100% !important;
    margin: 0px auto;
  }
  .card__and__live__row {
    padding: 30px 0px !important;
  }
  .footer__container .footer__block .footer__row .footer__navbar__row .footer__link__block .footer___link{
    margin: 0px 10px 10px 0px;
  }
}
@media screen and (max-width: 768px) {
  .footer__block {
    width: 100% !important;
  }
  .logo__social__row {
    margin: 0px 0px 40px 0px;
    .footer__logo {
      margin: 0px 0px 20px !important;
    }
  }
  .footer__link__block {
    padding: 0px 6px !important;
    margin: 0px 0px 30px 0px;
    .footer__link__title {
      margin: 0px 0px 30px 0px !important;
    }
  }
  .copyright__text__block {
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    .copyright__text {
      margin: 0px 0px 10px 0px !important;
    }
    .copyright__small__text {
      margin: 0px !important;
    }
  }
}
@media screen and (max-width: 576px) {
  .footer__block {
    width: 100% !important;
  }
  .sony__expert__block {
    display: flex;
    justify-content: flex-end;
    padding: 30px 0px 0px !important;
    .expert__button {
      width: 100% !important;
      padding: 5px 20px;
    }
  }
}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .footer__container {
    .footer__block {
      .footer__row {
        .footer__navbar__row {
          .footer__link__block {
            .footer__link__title{
              font-family: 'SSTArabicBold';
            }
            .footer___link,.footer__email__title,.footer__mobile__title {
              font-family: 'SSTArabic';
            }
            
          }
        }
      }
    }
  }
  .footer__bottom__container{
    .footer__bottom__block {
      .inner__footer__bottom__block {
        .copyright__text__block {
          .terms-policy-area {
            a {
              .text4 {
                font-family: 'SSTArabic';
              }
              &:last-child{
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}

/*-------------------------- Arabic view End ------------------------*/