.mobile__dealsOfTheDay__container {
  background-color: #FFFBE4;

  .mobile__dealsOfTheDay__block {
    width: 1420px;
    padding: 0px;
    margin: 0px auto;

    .section__title {
      font-family: 'SSTbold';
      font-weight: bold;
      font-size: 20px;
      margin: 10px 0px 0px;
      display: inline-block;
      line-height: 1;
    }

    .inner__mobile__dealsOfTheDay__block {
      display: flex;

      .first__md__product__block {
        width: 50%;
        margin: 20px 0px 20px;
        text-decoration: none;
        color: #000000;

        .first__md__product {
          background: #ffffff;
          height: 100%;
          border-radius: 15px;
          padding: 15px 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;

          .fav_icon {
            position: absolute;
            right: 10px;
            top: 10px;

            .productOne__favourite__icon {
              display: block;
            }

            .productOne__favourite__icon__disable {
              display: none;
            }
          }

          .cart__end__icon {
            position: absolute;
            bottom: 10px;
            right: 10px;
          }

          .deal__of__day__container {
            position: absolute;
            display: flex;
            left: -30px;

            .rectangleLeft {
              position: absolute;
              top: 0px;
              width: 31px;
              height: 77px;
              background-color: #cc8a15;
              border-bottom-left-radius: 30px;
              border-top-left-radius: 30px;
            }

            .upper__rectangle__block {
              position: absolute;
              top: 0px;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .rectangle {
                top: 0px;
                width: 260px;
                height: 40px;
                background: transparent linear-gradient(271deg, #ffdb3b 0%, #ffad2e 100%) 0% 0% no-repeat padding-box;
                font-family: 'SSTbold';
                font-weight: bold;
                font-size: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-left-radius: 15px;
                text-transform: uppercase;
              }
            }

            .triangle-right {
              background: transparent linear-gradient(271deg, #ffdb3b 0%, #ffad2e 100%) 0% 0% no-repeat padding-box;
              //   padding-box;
              // width: 280px;
              // height: 74px;
              // position: relative;

              width: 0;
              height: 0;
              border-top: 20px solid transparent;
              border-left: 15px solid #ffdb3b;
              border-bottom: 20px solid transparent;
            }
          }

          .pro__feature__optionBox {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            position: absolute;

            // top: 11px;
            // right: 50px;
            p {
              margin: 0px 7px 0px 0px;
              min-width: 35px;
              max-width: 90px;
              padding: 3px 6px;
              // background-color: #000000;
              // color: #ffffff;
              // border-radius: 5px;
              text-align: center;
              // line-height: 1;
              font-size: 10px;
              // box-shadow: 0px 1px 2px 0px #868383;
              background-color: #f5f4f4;
              color: #000000;
              font-weight: 600;
              border-radius: 4px;
              line-height: 1.5;

              &:last-child {
                margin-right: 0px;
              }
            }
          }

          .first__md__product__image__block {
            width: 100%;
            // margin: 20px auto 40px;
            background: #ffffff;
            margin-left: 0px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 23px;

            @media(max-width:992px) {
              height: 420px !important;
            }

            @media(max-width:580px) {
              height: 320px !important;
            }

            @media(max-width:320px) {
              height: 280px !important;
            }

            a {
              display: block;
              max-height: 100%;
              height: 100%;

              .first__md__product__image {
                object-fit: contain;
                height: 80%;
                width: 100%;
                margin: 0px auto;

                // width: 100%;
                @media(max-width:992px) {
                  height: 100% !important;
                }
              }
            }

          }

          .plp__tamara__box {
            display: inline-flex;
            width: 100%;
            flex: 0 0;
            max-width: 100%;
          }

          .heading__inner__text {
            margin-left: 0px;
            background: #ffffff;

            .main__rating__block {
              padding: 0px;
              display: flex;
              flex: 0 0 100%;
              max-width: 100%;
              margin-bottom: 4px;
              min-height: 18px;
              margin-top: 0px;
            }

            .md__product__price__block {
              margin-bottom: 8px;
              min-height: 37px;
              display: inline-flex;
              flex-direction: column;
              justify-content: flex-end;

              span {

                &.text4 {
                  margin-bottom: 0px !important;
                  position: relative;
                  display: inline-flex;
                  width: fit-content;
                  line-height: 1.2;
                  color: #444444;

                  &::after {
                    content: " ";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background-color: #666666;
                    -webkit-transform: translateY(-8px) rotate(-6deg);
                    transform: translateY(-8px) rotate(-6deg);
                    bottom: 0;
                    left: 0;
                  }
                }
              }
            }

            .event-btnBox {
              display: inline-block;
              min-width: 155px;
              float: right;

              @media screen and (max-width:380px) {
                min-width: 165px;
              }

              @media screen and (max-width:321px) {
                min-width: 145px;
              }

              .addToCart__button {
                border-radius: 5px;
                color: #ffffff;
                font-size: 14px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 6px 10px;
                background-color: #dc3a1a;
                // box-shadow: 0px 1px 3px 0px #928d8dde;
                text-align: center;
                text-transform: uppercase;
                text-decoration: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                @media screen and (max-width:580px) {
                  font-size: 13px;
                  padding: 8px 8px;
                }

                @media screen and (max-width:380px) {
                  font-size: 12px;
                  padding: 6px 6px;
                }

                @media screen and (max-width:321px) {
                  font-size: 9px;
                  padding: 5px;
                }

                .addToCart__icon {
                  margin: 0px 6px 0px 0px;
                  width: 16px;

                  @media screen and (max-width:580px) {
                    margin: 0px 5px 0px 0px;
                    width: 15px;
                  }

                  @media screen and (max-width:380px) {
                    margin: 0px 5px 0px 0px;
                    width: 15px;
                  }

                  @media screen and (max-width:321px) {
                    width: 11px;
                  }
                }

                .spinner-border,
                .spinner-border.spinner-border-sm {

                  @media screen and (max-width:580px) {
                    margin-left: 0px !important;
                    margin-right: 5px !important;
                    width: 0.8rem;
                    height: 0.8rem;
                  }

                  @media screen and (max-width:321px) {
                    position: absolute;
                    right: 5px;
                    margin-left: 3px !important;
                    width: 0.5rem;
                    height: 0.5rem;
                  }
                }

                &:hover,
                &:focus {
                  -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                  box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
                  -webkit-transform: translateY(-2px);
                  transform: translateY(-2px);
                  transition: all 0.2s ease-in-out;
                  -webkit-transition: all 0.2s ease-in-out;
                  opacity: 0.8;
                }

                .btn__spinner {
                  width: 116px;
                  min-height: 21px;

                  @media screen and (max-width:580px) {
                    min-height: 19.5px;
                  }

                  @media screen and (max-width:380px) {}

                  @media screen and (max-width:321px) {
                    min-height: 13.5px;
                  }
                }

              }

              .outofStock__button {
                border-radius: 5px;
                color: #ffffff;
                font-size: 14px;
                font-family: 'SSTbold';
                font-weight: bold;
                letter-spacing: 0.85px;
                padding: 6px 10px;
                line-height: 1.5;
                background-color: #a7a7a7;
                text-transform: uppercase;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 6px;


                @media screen and (max-width:580px) {
                  font-size: 13px;
                  padding: 8px 10px;
                }

                @media screen and (max-width:380px) {
                  font-size: 12px;
                  padding: 6px 6px;
                }

                @media screen and (max-width:321px) {
                  font-size: 9px;
                  padding: 5px;
                }
              }

              .notifyMe__button {
                border-radius: 5px;
                color: #ffffff;
                font-size: 14px;
                font-family: 'SSTbold';
                letter-spacing: 0.85px;
                padding: 6px 10px;
                line-height: 1.2;
                background-color: #FE8B21;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 0px;

                @media screen and (max-width:992px) {
                  font-size: 14px;
                  padding: 9px 8px;
                  line-height: 1.1;
                }

                @media screen and (max-width:580px) {
                  font-size: 13px;
                  padding: 10.5px 9px;
                }

                @media screen and (max-width:380px) {
                  font-size: 13px;
                  padding: 8px 8px;
                }

                @media screen and (max-width:321px) {
                  font-size: 9px;
                  padding: 5px;
                }

                img {
                  margin: 0px 8px 0px 0px;
                  height: 14px;
                  width: auto;
                  vertical-align: sub;

                  @media screen and (max-width:480px) {
                    margin: 0px 6px 0px 0px;
                    height: 13px;
                  }
                }

                .spinner-border,
                .spinner-border.spinner-border-sm {

                  @media screen and (max-width:580px) {
                    margin-left: 0px !important;
                    margin-right: 5px !important;
                    width: 0.8rem;
                    height: 0.8rem;
                  }

                  @media screen and (max-width:321px) {
                    position: absolute;
                    right: 5px;
                    margin-left: 3px !important;
                    width: 0.5rem;
                    height: 0.5rem;
                  }
                }
              }

              .fav_icon {
                position: absolute;
                right: 10px;
                top: 10px;
                width: 18px;
                height: 18px;

                .productOne__favourite__icon {
                  display: block;
                }

                .productOne__favourite__icon__disable {
                  display: none;
                }
              }
            }
          }
        }
      }

      .second__md__product__block {
        // width: 100%;
        // display: flex;
        // flex: 0 0 100%;
        // max-width: 100%;
        // flex-direction: row;
        // justify-content: space-between;
        padding: 20px 5px 0px;

        .mb__feature__col {
          padding: 0px 6px 20px;

          .md__product {
            background-color: #ffffff;
            padding: 20px 30px 40px;
            // height: 48.5%;
            border-radius: 15px;
            text-decoration: none;
            color: #000000;
            position: relative;
            display: flex;
            flex: 0 0 100%;
            max-width: 100%;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            // &:last-child {
            //   height: -webkit-fill-available;
            //   display: flex;
            //   flex-direction: column;
            //   justify-content: space-between;
            // }
            .product__pics__area {
              position: relative;

              .second__md__product__image__block {
                width: 100%;
                margin: 13px auto 3px;
                overflow: hidden;
                height: 140px;
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (max-width:580px) {
                  max-height: 120px;
                }

                @media screen and (max-width:480px) {
                  max-height: 100px;
                }

                .second__md__product__image {
                  // height: 72px;
                  // margin: 0px auto;
                  // height: auto;
                  // width: 100%;
                  // object-fit: contain;
                  height: auto;
                  width: 100%;
                  max-height: 140px;
                  width: auto !important;

                  @media screen and (max-width:580px) {
                    max-height: 120px;
                  }

                  @media screen and (max-width:480px) {
                    max-height: 100px;
                  }
                }
              }
              .soldOutLable {
                position: absolute;
                width: 100%;
                background: hsla(0, 0%, 85.1%, .76);
                height: 30px;
                top: 45%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-style: normal;
                font-weight: 700;
                font-size: 15px;
                line-height: 21px;
                color: rgba(0, 0, 0, .72);
                text-transform: uppercase;

                @media screen and (max-width:480px) {
                  top: 35%;
                }
              }
            }

            .pro__feature__optionBox {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              position: absolute;
              top: 11px;

              // right: 40px;
              p {
                margin: 0px 7px 0px 0px;
                min-width: 35px;
                max-width: 90px;
                padding: 3px 6px;
                // background-color: #000000;
                // color: #ffffff;
                // border-radius: 5px;
                text-align: center;
                // line-height: 1;
                font-size: 10px;
                // box-shadow: 0px 1px 2px 0px #868383;
                background-color: #f5f4f4;
                color: #000000;
                font-weight: 600;
                border-radius: 4px;
                line-height: 1.5;

                &:last-child {
                  margin-right: 0px;
                }
              }
            }

            .plp__tamara__box {
              display: inline-flex;
              width: 100%;
              flex: 0 0;
              max-width: 100%;
            }

            .second__md__product__name {
              text-decoration: none;
              color: #000000;

              p {
                min-height: 78px;
              }
            }

            .product__delivery__option__area {

              // @media screen and (max-width:767px) { flex-direction: column; } 
              .product__delivery__itembox {
                // @media screen and (max-width:767px) { margin-bottom: 5px; }
              }
            }

          .product__delivery__option__area{
            // @media screen and (max-width:767px) { flex-direction: column; } 
            .product__delivery__itembox {
              // @media screen and (max-width:767px) { margin-bottom: 5px; }
            }
          }

          .md__product__price__block {
            margin-bottom: 8px;
            min-height: 37px;
            display: inline-flex;
            flex-direction: column;
            justify-content: flex-end;

              span {

                &.text3 {
                  margin-bottom: 0px !important;
                  position: relative;
                  display: inline-flex;
                  width: fit-content;
                  line-height: 1.2;
                  color: #444444;

                  &::after {
                    content: " ";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    background-color: #666666;
                    -webkit-transform: translateY(-8px) rotate(-6deg);
                    transform: translateY(-8px) rotate(-6deg);
                    bottom: 0;
                    left: 0;
                  }
                }
              }

            }

            .addToCart__button {
              border-radius: 5px;
              color: #ffffff;
              font-size: 14px;
              font-family: 'SSTbold';
              font-weight: bold;
              letter-spacing: 0.85px;
              padding: 6px 10px;
              background-color: #dc3a1a;
              // box-shadow: 0px 1px 3px 0px #928d8dde;
              text-align: center;
              text-transform: uppercase;
              text-decoration: none;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              @media screen and (max-width:580px) {
                font-size: 13px;
                padding: 8px 8px;
              }

              @media screen and (max-width:380px) {
                font-size: 12px;
                padding: 6px 6px;
              }

              @media screen and (max-width:321px) {
                font-size: 9px;
                padding: 5px;
              }

              .addToCart__icon {
                margin: 0px 6px 0px 0px;
                width: 16px;

                @media screen and (max-width:580px) {
                  margin: 0px 5px 0px 0px;
                  width: 15px;
                }

                @media screen and (max-width:380px) {
                  margin: 0px 5px 0px 0px;
                  width: 13px;
                }

                @media screen and (max-width:321px) {
                  width: 11px;
                }

                @media screen and (max-width:281px) {
                  width: 10px;
                  margin: 0px 2px 0px 0px;
                }
              }

              .spinner-border,
              .spinner-border.spinner-border-sm {

                @media screen and (max-width:580px) {
                  margin-left: 5px !important;
                  width: 0.8rem;
                  height: 0.8rem;
                  margin-right: 5px !important;
                }

                @media screen and (max-width:321px) {
                  position: absolute;
                  right: 5px;
                  margin-left: 3px !important;
                  width: 0.5rem;
                  height: 0.5rem;
                }
              }

              &:hover,
              &:focus {
                -webkit-box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                box-shadow: 0 6px 6px -4.8px rgba(32, 32, 32, .6);
                -webkit-transform: translateY(-2px);
                transform: translateY(-2px);
                transition: all 0.2s ease-in-out;
                -webkit-transition: all 0.2s ease-in-out;
                opacity: 0.8;
              }

              .btn__spinner {
                width: 116px;
                min-height: 21px;

                @media screen and (max-width:580px) {
                  min-height: 19.5px;
                }

                @media screen and (max-width:380px) {
                  min-height: 18px;
                }

                @media screen and (max-width:321px) {
                  min-height: 13.5px;
                }
              }

            }

            .outofStock__button {
              border-radius: 5px;
              color: #ffffff;
              font-size: 14px;
              font-family: 'SSTbold';
              font-weight: bold;
              letter-spacing: 0.85px;
              padding: 6px 10px;
              line-height: 1.5;
              background-color: #a7a7a7;
              text-transform: uppercase;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;

              @media screen and (max-width:580px) {
                font-size: 13px;
                padding: 8px 8px;
              }

              @media screen and (max-width:380px) {
                font-size: 12px;
                padding: 6px 6px;
              }

              @media screen and (max-width:321px) {
                font-size: 9px;
                padding: 5px;
              }

            }

            .notifyMe__button {
              border-radius: 5px;
              color: #ffffff;
              font-size: 14px;
              font-family: 'SSTbold';
              letter-spacing: 0.85px;
              padding: 8px 10px;
              line-height: 1.5;
              background-color: #FE8B21;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 0px;

              @media screen and (max-width:992px) {
                font-size: 14px;
                padding: 6px 8px;
              }

              @media screen and (max-width:580px) {
                font-size: 13px;
                padding: 8px 8px;
              }

              @media screen and (max-width:380px) {
                font-size: 12px;
                padding: 6px 6px;
              }

              @media screen and (max-width:321px) {
                font-size: 9px;
                padding: 5px;
                line-height: 1;
              }

              img {
                margin: 0px 8px 0px 0px;
                height: 14px;
                width: auto;
                vertical-align: sub;

                @media screen and (max-width:992px) {
                  height: 14px;
                }

                @media screen and (max-width:480px) {
                  margin: 0px 4px 0px 0px;
                  height: 12px;
                }

                @media screen and (max-width:321px) {
                  height: 10px;
                }
              }

              .spinner-border,
              .spinner-border.spinner-border-sm {

                @media screen and (max-width:580px) {
                  margin-left: 0px !important;
                  margin-right: 5px !important;
                  width: 0.8rem;
                  height: 0.8rem;
                }

                @media screen and (max-width:321px) {
                  position: absolute;
                  right: 5px;
                  margin-left: 3px !important;
                  width: 0.5rem;
                  height: 0.5rem;
                }
              }
            }

            .fav_icon {
              position: absolute;
              right: 10px;
              top: 10px;
              width: 18px;
              height: 18px;

              .productOne__favourite__icon {
                display: block;
              }

              .productOne__favourite__icon__disable {
                display: none;
              }
            }

            .cart__end__icon {
              position: absolute;
              bottom: 10px;
              right: 10px;
            }

          }
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .mobile__dealsOfTheDay__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}

@media screen and (max-width: 1420px) {
  .mobile__dealsOfTheDay__block {
    width: 90% !important;
    max-width: 90% !important;
  }

  .first__md__product__image {
    width: 100% !important;
  }

  .md__product {
    padding: 15px 20px 15px !important;
  }
}

@media screen and (max-width: 1400px) {
  .second__md__product__image__block {
    width: 100% !important;
  }
}

@media screen and (max-width: 1200px) {
  .first__feature__category__title {
    margin: 0px 0px 0px 100px !important;
  }

  .first__feature__category__subtitle {
    margin: 0px 0px 0px 100px !important;
  }

  .first__md__product__block {
    width: 100% !important;
  }

  .first__md__product__image__block {
    height: 600px !important;
  }
}

@media screen and (max-width: 992px) {
  .md__product {
    padding: 20px 20px 20px !important;
  }

  .mobile__dealsOfTheDay__block {
    min-width: 100% !important;
    margin: 0px auto;
  }

  .feature__category__container {
    background: #fffbe4;
  }

  .second__md__product__image {
    width: 100% !important;
  }

  .deal__of__day__container {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .first__md__product__block {
    width: 100% !important;
  }

  .second__md__product__image {
    width: 90% !important;
  }

  // .second__md__product__block .md__product {
  //   height: auto;
  // }
}

@media screen and (max-width: 576px) {
  .mobile__dealsOfTheDay__block {
    width: 100% !important;
    margin: 0px auto;

    .inner__mobile__dealsOfTheDay__block {
      display: flex;
      // flex-direction: column;

      .first__md__product__block {
        width: 100% !important;
      }

      .first__md__product__image__block {
        height: 250px !important;

        img {
          height: 100% !important;
        }
      }

      .second__md__product__block {
        .md__product {
          padding: 20px 10px 10px !important;
        }

        .heading6,
        .text3 {
          font-size: 14px;
        }

        // width: 100% !important;
        .heading7 {
          font-size: 13px;
          min-height: 110px;
          margin-bottom: 10px !important;
        }

        .main__rating__block .product__totalRating {
          font-size: 13px;
        }
      }
    }
  }

  .first__in__column {
    margin-bottom: 20px !important;
  }

  .second__md__product__image {
    width: 80% !important;
  }

}

@media screen and (max-width: 281px) {

  .second__md__product__block {
    width: 100% !important;
  }

}


/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .mobile__dealsOfTheDay__container {
    .mobile__dealsOfTheDay__block {
      .inner__mobile__dealsOfTheDay__block {
        .first__md__product__block {
          .first__md__product {
            .pro__feature__optionBox {
              left: auto;
              right: 11px;

              @media screen and (max-width:480px) {
                right: 15px;
              }

              p {
                margin: 0px 0px 0px 7px;
              }
            }

            .first__md__product__image__block {
              .fav_icon {
                left: 11px;
                top: 13px;
                right: auto;

                @media screen and (max-width:480px) {
                  left: 15px;
                }
              }
            }

            .heading__inner__text {
              display: flex;
              flex-direction: row-reverse;
              flex-wrap: wrap;
              justify-content: space-between;

              .heading7 {
                display: flex;
              }

              .md__product__price__block {
                margin-right: 15px;
                justify-content: flex-start;
              }

              .event-btnBox {

                .addToCart__button,
                .outofStock__button,
                .notifyMe__button,
                .pre-order {
                  font-family: 'SSTArabicBold';
                  font-weight: bold;
                }

                .notifyMe__button {
                  img {
                    margin: 0px 0px 0px 6px;
                  }
                }

                .addToCart__button {
                  .addToCart__icon {
                    margin: 0px 0px 0px 5px;
                  }

                  .spinner-border.spinner-border-sm {
                    @media screen and (max-width:321px) {
                      position: absolute;
                      right: auto;
                      left: 9px;
                      margin-left: 3px !important;
                      width: 0.5rem;
                      height: 0.5rem;
                    }
                  }

                  .btn__spinner {
                    width: 116px;
                    min-height: 21px;

                    @media screen and (max-width:580px) {
                      min-height: 19.5px;
                    }

                    @media screen and (max-width:380px) {
                      min-height: 18px;
                    }

                    @media screen and (max-width:321px) {
                      min-height: 13.5px;
                    }
                  }

                  &.pre-order {
                    .btn__spinner {
                      width: 116px;
                      min-height: 21px;
                    }
                  }
                }
              }
            }
          }
        }

        .second__md__product__block {
          .mb__feature__col {
            .md__product {

              // .second__md__product__image__block {
              //   .second__md__product__image{
              //     height: max-content;
              //     width: 100%;
              //     max-height: 200px;
              //     width: auto !important;
              //   }                
              // }
              .cart_end_icon {
                text-align: left !important;
              }

              .fav_icon {
                right: auto;
                left: 11px;

                @media screen and (max-width:480px) {
                  left: 8px;
                }
              }

              .pro__feature__optionBox {
                left: auto;
                right: 11px;

                @media screen and (max-width:480px) {
                  right: 8px;
                }

                p {
                  margin: 0px 0px 0px 7px;
                }
              }

              .addToCart__button,
              .outofStock__button,
              .notifyMe__button,
              .pre-order {
                font-family: 'SSTArabicBold';
                font-weight: bold;
              }

              .addToCart__button {
                .addToCart__icon {
                  margin: 0px 0px 0px 5px;
                }

                .spinner-border.spinner-border-sm {
                  @media screen and (max-width:380px) {
                    width: 0.65rem;
                    height: 0.65rem;
                  }

                  @media screen and (max-width:321px) {
                    right: auto;
                    left: 7px;
                    margin-left: 0px !important;
                    margin-right: 3px !important;
                  }
                }

                .btn__spinner {
                  width: 116px;
                  min-height: 19.5px;

                  @media screen and (max-width:580px) {}

                  @media screen and (max-width:380px) {
                    min-height: 18px;
                  }

                  @media screen and (max-width:321px) {
                    min-height: 13.5px;
                  }
                }

                &.pre-order {
                  .btn__spinner {
                    width: 116px;
                    min-height: 19.5px;

                    @media screen and (max-width:380px) {
                      min-height: 18px;
                    }

                    @media screen and (max-width:321px) {
                      min-height: 13.5px;
                    }
                  }
                }
              }

              .notifyMe__button {

                @media screen and (max-width:1200px) {
                  font-size: 14px;
                }

                @media screen and (max-width:580px) {
                  font-size: 13px;
                }

                @media screen and (max-width:480px) {
                  font-size: 12.5px;
                }

                @media screen and (max-width:400px) {
                  font-size: 11.2px;
                  line-height: 1.7;
                }

                @media screen and (max-width:380px) {
                  font-size: 10px;
                }

                img {
                  margin: 0px 0px 0px 8px;

                  @media screen and (max-width:480px) {
                    height: 11px;
                    margin: 0px 0px 0px 6px;
                  }

                  @media screen and (max-width:480px) {}
                }

                .spinner-border,
                .spinner-border.spinner-border-sm {
                  margin-left: 0px !important;
                  margin-right: 5px !important;
                  width: 0.8rem;
                  height: 0.8rem;

                  @media screen and (max-width:450px) {
                    width: 0.75rem;
                    height: 0.75rem;
                  }
                }
              }
            }
          }

        }
      }
    }
  }







}

/*-------------------------- Arabic view End ------------------------*/