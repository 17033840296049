.main_header {
    position: sticky;
    width: 100%;
    height: auto;
    left: 0px;
    top: 0px;
    display: block;
    z-index: 15;

  .toast {
    @media screen and (max-width:992px) {
      width: auto;  
    }    
    .toast-body {
      @media screen and (max-width:992px) {
        padding: 0.35rem;
      }
    }
  }
  .main__navbar__container {
    display: flex;
    flex-direction: column;
    padding: 0px;
    z-index: 10;
    position: relative;
    // -webkit-transition: all 2s ease-in-out;
    // -moz-transition: all 2s ease-in-out;
    // transition: all 2s ease-in-out;
    // overflow-y: scroll;
    width: 100%;
    height: auto;

    &.header_fix {
      position: fixed;
      width: 100%;
      height: auto;
      left: 0px;
      top: 0px;
      -webkit-transition: all .3s ease-in-out;
      -moz-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      // animation: smoothScroll 0.6s forwards;

    }

    .header__container {
      background-color: #000000;
      padding: 2px 10px 0px;
      // height: 80px;
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;

      .header {
        margin: 0px auto;
        width: 1420px;
        padding: 0px;

        @media screen and (max-width: 1500px) {
          max-width: 93% !important;
        }

        @media screen and (max-width: 1450px) {
          max-width: 90% !important;
        }

        @media screen and (max-width: 992px) {
          max-width: 100% !important;
        }

        .header__sidemenu__icon {
          display: none;
          margin-left: 10px;
          @media screen and (max-width: 992px) {
            display: block !important;
            margin-right: 10px;
            margin-left: 0 !important;
          }
        }
        .header__right__text {
          position: relative;
          color: #fff;
          /* right: 7px; */
          font-size: 10px;
          max-width: 100%;  
          /* top: -16px; */
          margin-top: 3px;
          p{
            font-size: 10px;
            letter-spacing: 0.5px;
            margin-bottom: 0px;          
            line-height: 1.6;  
            @media screen and (max-width:350px) {
              font-size:9px;
            }
            @media screen and (max-width:296px) {
              font-size:8px;
            }            
          }
        }
        .header__row {
          width: 100%;
          margin: 0px 0px 18px;
          padding-top: 10px;
          @media screen and (max-width:992px) {
            margin: 0px 0px 0px;
          }
          @media screen and (max-width:560px) {
            padding-top: 5px;
          }

          .header__link {
            display: flex;
            align-items: center;
            padding: 0px;

            .header__logo {
              cursor: pointer;
              // margin-left: 10px;
              @media screen and (max-width:480px) {
                width: 92% !important;
              }
            }
          }

          .search__box__block {
            padding: 0px;
            position: relative;

            .search__box {
              padding: 0px 6px 0px 0px;
              background-color: white;
              // height: 30px;
              width: 100%;
              border-radius: 2px;
              display: flex;
              align-items: center;
              margin: 1px auto 0px;

              .search__input {
                padding: 5px 10px;
                margin: 0px;
                background: none;
                outline: none;
                border: 0px;
                width: 96%;
                font-size: 14px;
              }

              .search__icon__button {
                padding: 0px;
                background: none;
                outline: none;
                border: none;
                display: flex;
                align-items: center;

                .search__icon {
                  cursor: pointer;
                }
              }
            }

            .search__box__result__popup__disable {
              position: absolute;
              z-index: 14;
              top: 30px;
              background: #ffffff 0% 0% no-repeat padding-box;
              margin: 0px;
              padding: 20px;
              border-radius: 2px;
              display: none;
              width: 1000px;
            }

            .search__box__result__popup::-webkit-scrollbar {
              display: none;

              /* transform: translateY(100%); */
            }

            .search__box__result__popup {
              max-height: 540px;
              overflow-y: auto;
              position: absolute;
              width: 710px;
              z-index: 14;
              top: 34px;
              background: #ffffff 0% 0% no-repeat padding-box;
              margin: 0px;
              padding: 5px;
              border-radius: 2px 2px 10px 10px;
              display: flex;
              align-items: flex-start;
              box-shadow: 0px 10px 10px #00000020;

              @media screen and (max-width:992px) {
                top:44px;
              }              
              @media screen and (max-width:580px) {
                  max-height: 64vh !important;
              }
              @media screen and (max-width:380px) {
                top:38px;
              }
              .search__result__product__text {
                .heading7 {
                  font-size: 14px;
                }
                .main__rating__block{
                  margin-top: 2px;
                  padding: 0px;
                }
              }

              .search__result__left__part {
                border-radius: 2px 2px 0px 5px;

                .search__Result__title__link {
                  text-decoration: none;
                  color: #000000;
                  cursor: pointer;
                  font-size: 14px;

                  .search__Result__title__text {
                    font-family: "SSTbold";
                    font-weight: bold;
                    margin: 0px;
                    color: #000000;
                    margin: 0px 0px 20px;
                  }
                }
              }

              .search__result__right__part {
                border-radius: 0px 2px 5px 0px;

                .search__result__product__block {
                  margin: 10px;
                  text-decoration: none;
                  color: #000000;
                  @media screen and (max-width:580px) {
                    margin-top: 8px;
                    margin-left: 0px;
                    margin-right: 0px;
                  }

                  .search__result__product__image__block {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .search__result__product__image {
                      width: 100%;
                      // min-width: 80px;
                    }
                  }

                  .search__result__product__text {}
                }
              }
            }
          }

          .header__icons__linkbox {

            @media screen and (max-width:580px) {
              padding-left: 0px;
              padding-right: 0px;
            }

            .header__icon__block {
              // padding: 0px 50px 0px 0px;
              display: flex;
              justify-content: flex-end;
              margin-right: -10px;
              position: relative;

              @media (max-width:1420px) {
                padding: 0px;
              }
              @media (max-width:576px) {
                margin-right: -3px;
              }
              .header__icon__inner__block {
                // width: 300px;
                display: flex;
                align-items: center;

                // justify-content: space-between;
                .header__language__icon,
                .header__search__icon {
                  display: none;
                }

                .header__icon {
                  margin: 0px 5px 0px;
                  cursor: pointer;
                  height: 24px;
                  width: 24px;
                  vertical-align: top;

                  @media screen and (max-width:580px) {
                    width: 18px;
                    height: 18px;
                    vertical-align: text-bottom;
                  }

                  @media screen and (max-width:280px) {
                    width: 14px;
                    height: 14px;
                    margin: 0px 4px 0px 0px;
                  }
                  .user_avatar {
                    padding: 5px;
                    border-radius: 50%;
                    background: #dc3a1a;
                    color: #fff;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                  }
                }

                .header__user__block {
                  // position: relative;
                  margin-left: 30px;

                  &.user__login__active{
                    img {
                      position: relative;
                      z-index: 2;
                    }
                  }

                  @media (max-width:992px) {
                    margin-left: 3px;
                  }

                  .header__username__block {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    .header__username {
                      color: #ffffff;
                      margin: 0px 0px 0px 10px;

                      @media (max-width:768px) {
                        display: none;
                      }
                    }
                    .user_avatar {
                      padding: 5px;
                      border-radius: 50%;
                      background: #dc3a1a;
                      color: #fff;
                      width: 20px;
                      height: 20px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-size: 12px;
                    }
                    .user__down__arrow__icon {
                      margin: 0px 10px 0px 5px;

                      @media screen and (max-width:580px) {
                        margin: 0px 8px 0px 5px;
                      }

                      @media screen and (max-width:281px) {
                        margin: 0px 6px 0px 0px;
                        width: 15px;
                        height: 14px;
                      }
                    }
                  }

                  .user__dropdown__block {
                    position: absolute;
                    top: 40px;
                    right: 0px;
                    z-index: 2;
                    background-color: #ffffff;
                    width: 180px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    background: #ffffff 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #53535342;
                    border-radius: 3px;

                    .user__dropdown__title__block {
                      width: 100%;
                      background-color: #f1f1f1;
                      padding: 5px 10px;

                      a {
                        color: #000000;
                        text-decoration: none;
                      }
                    }

                    .user__db__menu__block {
                      display: flex;
                      align-items: center;
                      padding: 5px 10px;
                      cursor: pointer;
                      text-decoration: none;
                      color: #000000;
                      -webkit-transition: all 0.3s ease-in-out;
                      transition: all 0.3s ease-in-out;

                      .user__db__icon {
                        margin: 0px 10px 0px 0px;
                        -webkit-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                      }

                      .user__db__menu__title {
                        margin: 0px;
                      }

                      &:hover {
                        color: #dc3a1a;

                        .user__db__icon {
                          filter: invert(27%) sepia(43%) saturate(3274%) hue-rotate(350deg) brightness(102%) contrast(96%);
                        }
                      }
                    }

                    .user__db__signout__button__block {
                      border-top: 1px solid #eeeeee;
                      width: 100%;

                      .logout__button {
                        cursor: pointer;
                        background-color: #ffffff;
                        box-shadow: 0px 3px 6px #00000005;
                        border-radius: 5px;
                        color: #000000;
                        outline: none;
                        border: none;
                        width: 100%;
                        font-size: 16px;
                        font-family: "SSTbold";
                        font-weight: bold;
                        padding: 10px 0px;

                        &:hover {
                          color: red;
                          text-decoration: underline;
                        }
                      }
                    }
                  }

                  .signin__signup__popup__disable {
                    display: none;
                  }
                  .signin__signup__bg__area{
                    position: fixed;
                    background-color: rgba(0,0,0,.769);
                    width: 100vw;
                    height: 100vh;
                    top: 0px;
                    left: 0px;
                    z-index: 1;
                  }
                  .signin__signup__popup {
                    position: absolute;
                    top: 40px;
                    right: -50px;
                    z-index: 2;
                    background-color: #ffffff;
                    width: 420px;
                    max-width: 420px;
                    padding: 20px 18px;
                    // box-shadow: 0px 0px 9px #d9d9d9;
                    border-radius: 13px;
                    z-index: 2;
                    @media screen and (max-width:992px) {
                      right: 0px;
                      width: 390px;
                      max-width: 390px;
                    }
                    @media screen and (max-width:480px) {
                        max-width: 95vw;
                        margin: 0px 2vw;
                    }
                    .signin__button {
                      background-color: #dc3a1a;
                      // box-shadow: 0px 3px 6px #00000005;
                      border-radius: 6px;
                      color: #ffffff;
                      outline: none;
                      border: none;
                      width: 100%;
                      font-size: 16px;
                      font-family: "SSTbold";
                      font-weight: bold;
                      padding: 13px 10px;
                      margin: 0px 0px 0px;

                      &:hover,
                      &:focus {
                        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                        transform: translateY(-2px);
                        -webkit-transform: translateY(-2px);
                        transition: all 0.2s ease-in-out;
                        -webkit-transition: all 0.2s ease-in-out;
                        opacity: 0.8;
                      }
                      .btn__spinner{
                        min-height: 16px;
                        @media screen and (max-width:1366px) { }
                        @media screen and (max-width:580px) { }
                      }
                    }

                    .signup__button {
                      background: #dc3a1a;
                      // box-shadow: 0px 3px 6px #00000005;
                      // border: 2px solid #000000;
                      border-radius: 6px;
                      color: #ffffff;
                      outline: none;
                      width: 100%;
                      font-size: 16px;
                      font-family: "SSTbold";
                      font-weight: bold;
                      padding: 13px 10px;
                      margin: 0px;

                      &:hover,
                      &:focus {
                        box-shadow: 0 6px 6px -4.8px rgb(32 32 32 / 60%);
                        transform: translateY(-2px);
                        -webkit-transform: translateY(-2px);
                        transition: all 0.2s ease-in-out;
                        -webkit-transition: all 0.2s ease-in-out;
                        opacity: 0.8;
                      }
                      .btn__spinner{
                        min-height: 16px;
                        @media screen and (max-width:1366px) { }
                        @media screen and (max-width:580px) { }
                      }
                    }

                    .signin__signup__popup__closeBtn{
                      display: flex;
                      width: 20px;
                      height: 20px;
                      padding: 4px;
                      color: #ffffff;
                      justify-content: center;
                      align-items: center;
                      background-color: #d1d1d1;
                      border-radius: 10px;
                      margin: -10px 0px 8px -7px;
                      border: none;

                      svg {
                        width: 100%;
                        height: auto;
                      }
                    }
                    .nav-tabs{
                      padding: 3px;
                      background-color: #000000;
                      border-radius: 8px;
                      border: none;

                      .nav-item{
                        width: 50%;
                        .nav-link{
                          margin-bottom: 0px;
                          border: none;
                          border-radius: 6px;
                          width: 100%;                        
                          font-size: 15px;
                          font-family: "SSTmedium";
                          color: #ffffff;
                          line-height: 1.2;
                          &.active{
                            background-color: #ffffff;
                            color: #000000;
                          }
                        }
                      }
                    }
                    .tab-content{
                          max-height: 74vh;
                          overflow: scroll;
                          overflow-x: hidden;
                       @media screen and (max-width:767px) {
                          max-height: 72vh;
                          overflow: scroll;
                          overflow-x: hidden;
                       }
                      .tab-pane{
                        &.active{
                          border: none !important;
                        }
                      } 
                    }
                  }
                }

                .main__language__selection {
                  // margin: 0px 5px 0px;
                  margin-right: 30px;
                  margin-left: 0.3rem;
                  position: relative;
                  cursor: pointer;
                  background: #ffffff00 0% 0% no-repeat padding-box;
                  // border: 2px solid #ffffff;
                  border-radius: 3px;
                  padding: 2px 10px 2px 10px;
                  display: flex;
                  justify-content: center;
                  font-size: 16px;
                  text-align: center;

                  @media screen and (max-width: 1075px) {
                    margin-right: 20px;
                  }

                  @media (max-width:992px) {
                    margin-right: 0px;
                  }

                  .language {
                    text-align: center;
                    color: #ffffff;
                    margin: 0px 0px 0px 0px;

                    @media screen and (max-width:580px) {
                      font-size: 14px;
                    }

                    @media screen and (max-width:321px) {
                      font-size: 12px;
                    }
                  }

                  .language__option__dropdown {
                    z-index: 1;
                    position: absolute;
                    background-color: black;
                    border-radius: 3px;
                    width: 120px;
                    left: -2px;
                    top: 35px;
                    padding: 5px;
                    border: 2px solid #ffffff;

                    .language__option {
                      color: white;
                      margin: 0px 0px 5px;
                    }

                    .language__option:hover {
                      cursor: pointer;
                    }
                  }

                  .language__option__dropdown__disable {
                    display: none;
                  }
                }

                .wishlist__icon__block {
                  // margin-right: 10px;
                  position: relative;
                  margin-left: 30px;

                  @media screen and (max-width: 1075px) {
                    margin-left: 20px;
                  }

                  @media screen and (max-width: 992px) {
                    margin-left: 10px;
                  }

                  @media screen and (max-width:580px) {
                    margin-left: 5px;
                  }

                  .wishlist__item__count {
                    // display: none;
                    position: absolute;
                    background-color: #818181;
                    width: fit-content;
                    top: -20%;
                    right: -18%;
                    color: white;
                    margin: 0px;
                    border-radius: 20px;
                    padding: 2px 5px;
                    font-size: 9px;
                    text-align: center;
                    min-width: 20px;

                    @media screen and (max-width:580px) {
                      top: -12%;
                      right: 0%;
                      padding: 1px 3px;
                      min-width: 16px;
                    }
                  }
                }

                .cart__icon__block {
                  // margin-right: 10px;
                  position: relative;
                  margin-left: 30px;

                  @media screen and (max-width: 1075px) {
                    margin-left: 20px;
                  }

                  @media (max-width:992px) {
                    margin-left: 5px;
                  }

                  .cart__item__count {
                    // display: none;
                    position: absolute;
                    background-color: #818181;
                    width: fit-content;
                    top: -30%;
                    right: -30%;
                    color: white;
                    margin: 0px;
                    border-radius: 20px;
                    padding: 2px 5px;
                    font-size: 9px;
                    text-align: center;
                    min-width: 20px;

                    @media screen and (min-width:992px) and (max-width:1130px) {
                      top: -30%;
                      right: -60%;
                    }

                    @media screen and (max-width:580px) {
                      top: -20%;
                      right: 0%;
                      padding: 1px 3px;
                      min-width: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .navbar__container {
      background-color: #000000;
      padding: 0px 10px;

      .navbar__row {
        // padding: 0px 10px;
        margin: 0px auto;
        max-width: 1420px;

        @media screen and (max-width: 1500px) {
          max-width: 93% !important;
        }

        @media screen and (max-width: 1450px) {
          max-width: 90% !important;
        }

        @media screen and (max-width: 992px) {
          max-width: 100% !important;
        }

        .navbar {
          // width: 1420px;
          display: flex;
          // justify-content: center;
          align-items: center;
          padding: 0px;
          // height: 47px;
          background-color: #000000;
          // margin: 0px 0px 0px 250px;

          // @media (min-width: 1200px) {
          //   justify-content: flex-start;
          //   margin-left: 14.66666667%;
          // }
          // @media (min-width: 1700px) {
          //   margin-left: 14.3%;
          // }
          .navbar__link__block {
            display: flex;
            // align-items: center;
            padding: 0px 0px 0px;

            // margin: 0px auto;
            // margin-left: 66px;
            .navbar__allCategories {
              font-family: "SSTbold";
              font-weight: bold;
              color: white;
              margin: 0px 90px 0px 0px;
              display: flex;
              align-items: center;
              cursor: pointer;
              transition: all 0.2s;

              @media screen and (max-width: 1450px) {
                margin: 0px 75px 0px 0px;
              }

              &:hover {

                color: #ff6421;

                // svg{
                //   path {
                //     fill: #dc3a1a;
                //     transition: all 0.8s ease-in-out;
                //     -webkit-transition: all 0.8s ease-in-out;
                //   }                
                // }
                .all__categoriy__navabar__link {
                  color: #ff6421 !important;
                  text-shadow: 0px 0px 20px #fb6e31;
                  // transition: all 0.8s ease-in-out;
                  // -webkit-transition: all 0.8s ease-in-out;                
                }
              }

              svg {
                height: 23px;
                width: 23px;
                line-height: 1;
              }

              .all__categoriy__navabar__link {
                margin: 0px 10px 0px;
              }

              .navbar__menu__icon {
                margin: 0px 0px 0px 0px;
                transition: all 0.5s;
              }

              .navbar__menu__icon__rotate {
                transform: rotate(90deg);
                transition: all 0.5s;
              }
            }

            .navbar__link {
              padding: 6px 0px 8px 0px;
              margin: 0px 47px 0px 0px;
              text-decoration: none;
              color: #fff;
              cursor: pointer;
              font-size: 15px;
              position: relative;

              @media screen and (max-width: 1450px) {
                margin: 0px 35px 0px 0px;
              }

              @media screen and (max-width: 580px) {
                font-size: 14px;
                padding: 10px 0px !important;
                margin: 0px 0px 0px 15px !important;
              }

              @media screen and (max-width: 321px) {
                font-size: 13px;
              }

              .down__arrow__icon {
                width: 20px;
                height: 18px;
                margin-left: 5px;
                vertical-align: bottom;
              }

              &:hover {
                color: #dc3a1a !important;
                transition: all 0.2s ease-in-out;
                -webkit-transition: all 0.2s ease-in-out;

                // width: 0%;
                &::after {
                  position: absolute;
                  content: "";
                  width: 100%;
                  height: 1px;
                  background: #dc3a1a;
                  bottom: 6px;
                  left: 50%;
                  transform: translateX(-50%);
                  transition: all 0.2s ease-in-out;
                  -webkit-transition: all 0.2s ease-in-out;
                }

                .down__arrow__icon {}
              }
            }

            .navbar__link:hover {
              color: white;
            }

            .active__navbar__link {
              margin: 0px 47px 0px 0px;
              color: white;
              text-decoration: none;
              cursor: pointer;
            }
          }

          .icon__block {
            display: none;
            height: 100%;
            padding: 5px;

            .menu__icon {}
          }
        }
      }
    }

    .mobile__navbar {
      height: 100vh;
      max-height: 100vh;
      // max-height: 80vh;
      overflow: scroll;
      overflow-y: scroll;
      overflow-x: hidden;
      background-color: #ffffff;
      top: 0;
      position: fixed;
      left: 0;
      display: none;
      width: 97%;
      z-index: 15;

      @media screen and (max-width:992px) {
        width: 280px;
      }
      @media screen and (max-width:576px) {
        width: 97%;
      }

      // padding: 10px;
      .mobile_top_header {
        background-color: #ff3d2b;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 0px;

        .mobile_user_name span {
          display: inline-block;
          background: #fff;
          height: 30px;
          width: 30px;
          padding: 5px;
          border-radius: 100%;
          line-height: 15px;
          text-align: center;
          vertical-align: middle;
          margin-right: 10px;
        }

        .mobile_close_icon {
          padding: 0px 15px;

          img {
            vertical-align: text-top;
          }
        }

        a {
          color: #fff !important;
        }
      }

      .mobile__navbar__link__nested__block__disable {
        display: none;
      }

      .mobile__navbar__link__nested__block {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        padding: 20px;

        @media (max-width:992px) {
          .mobile__category__block {
            a {
              font-size: 17px;
              color: #3B3B3B;
              font-family: "SSTregular";
              text-decoration: none;
              margin-bottom: 2.1rem;

              &:hover {
                color: #dc3a1a;
              }
              @media screen and (max-width:420px) {
                font-size: 15px;              
              }
            }
          }
        }
      }

      .mobile__navbar__link__block__disable {
        display: none;
      }

      .mobile__navbar__link__block {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 0 0 100%;
        max-width: 100%;
        border: none;
        position: relative;
        z-index: 10;

        .mobile__navbar__link,
        a, span {
          padding: 7px 10px 5px;
          margin: 0px 0px 5px 0px;
          text-decoration: none;
          color: #3b3b3b;
          display: flex;
          justify-content: space-between;
          padding: 10px 15px;
          align-items: center;
          position: relative;
          z-index: 5;

          @media screen and (max-width:420px) {
            font-size: 15px;
          }
          &.active__mobile__navbar__link {
            background-color: #000000;
            color: #fff;
            font-family: "SSTbold";
            font-weight: bold;

            .down__arrow__icon {
              // margin-left: 10px;
              filter: none;
              // transform: rotate(-90deg);
            }
          }

          .down__arrow__icon {
            // margin-left: 10px;
            transform: rotate(-90deg);
            filter: invert(37%) sepia(0%) saturate(1815%) hue-rotate(171deg) brightness(97%) contrast(81%);
          }
        }

        .active__mobile__navbar__link {
          margin: 0px;
          color: #fff;
          text-decoration: none;
          border-bottom: 1px solid #fff;
          justify-content: space-between;
          display: flex;
        }
      }
    }

    .mobile__navbar::-webkit-scrollbar {
      display: none;
    }

    .mobile__navbar__disable {
      display: none;
    }

    .category__popup__container__disable {
      min-height: 0%;
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 118px;
      left: 0px;
      opacity: 0;
      z-index: 9;
      background-color: #00000091;
      visibility: hidden;

      // display: block;
      // transform: ;
      // transition: all 0.2s;
      a {
        color: #000000 !important;
      }
    }

    .category__popup__block__disable {
      height: 0;
      opacity: 0;

    }

    .category__popup__container {
      // background: #ffffff 0% 0% no-repeat padding-box;
      z-index: 9;
      min-height: 100%;
      overflow-y: scroll;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 116px;
      left: 0px;
      opacity: 1;
      background-color: #00000091;
      transition: all 0.1s ease-in;
      -webkit-transition: all 0.1s ease-in;

      .category__popup__block {
        opacity: 1;
        // width: 900px;
        height: 560px;
        // background: #ffffff 0% 0% no-repeat padding-box;
        // box-shadow: 0px 3px 6px #00000029;
        overflow: hidden;
        // margin-left: 16.67%;
        // transition: all 0.2s;
        margin: 0px auto;
        max-width: 1420px;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;

        @media screen and (max-width: 1450px) {
          max-width: 90% !important;
        }

        .category__popup__left__block {
          padding: 30px 20px 0px 20px;
          background: #f1f1f1 0% 0% no-repeat padding-box;
          height: 100%;
          overflow-y: scroll;

          .mainCategory__block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0px 0px 15px;
            text-decoration: none;
            color: #000000;
            cursor: pointer;
            position: relative;

            .mainCategory__left__block {
              text-decoration: none !important;
              margin: 0px;
              font-size: 17px;
              // font-family: "SSTbold";
              font-weight: 500;
              width: 240px;
              transition: all 0.2s;

              @media screen and (max-width:1360px) {
                width: 205px;
                max-width: 80%;
              }
            }

            .popup__right__arrow {
              opacity: 0;
              position: absolute;
              right: 10px;
            }
          }

          .selected__mainCategory__block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0px 0px 15px;
            text-decoration: none;
            color: #000000 !important;
            position: relative;
            cursor: pointer;

            .mainCategory__left__block {
              text-decoration: none !important;
              color: #ff4f04 !important;
              margin: 0px;
              font-size: 17px;
              font-family: "SSTbold";
              font-weight: bold;
              width: 240px;
              font-family: "SSTbold";
              font-weight: bold;
              transition: all 0.3s;

              @media screen and (max-width:1360px) {
                width: 205px;
                max-width: 80%;
              }
            }

            .popup__right__arrow {
              opacity: 1;
              position: absolute;
              right: 0px;
              transition: all 0.3s ease-in;
            }
          }
        }

        .category__popup__left__block::-webkit-scrollbar {
          width: 0px !important;
        }

        .category__popup__right__block {
          padding: 0px 20px 0px;
          background: #ffffff 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #00000029;
          border: 1px solid #d1d1d1;
          height: 100%;
          overflow-y: scroll;

          .mainCategory__title__block {
            padding: 10px 0px;
            border-bottom: 1px solid #818181;

            .mainCategory__right__block {
              margin: 0px;
              font-size: 20px;
              font-family: "SSTbold";
              font-weight: bold;
            }
          }

          .subCategory__block {
            padding: 20px 0px;

            // opacity: 0;
            .subcategory {
              // opacity: 1;
              color: #000000 !important;
              text-decoration: none;
              cursor: pointer;
              margin: 0px 0px 20px;
              font-size: 16px;
              font-family: "SSTregular";
              transition: all 0.1s ease-in;
              -webkit-transition: all 0.1s ease-in;
              display: block;

              p {
                position: relative;
                display: inline-block;
                margin: 0;

                &:after {
                  position: absolute;
                  width: 0px;
                  content: "";
                  opacity: 0;
                  background: #dc3a1a;
                  height: 1px;
                  left: 0;
                  bottom: -5px;
                  transition: all .3s ease-in;
                }
              }

              &:hover {
                color: #dc3a1a !important;

                p {
                  &:after {
                    opacity: 1;
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        .category__popup__right__block::-webkit-scrollbar {
          width: 0px !important;
        }
      }
    }

    .login__popup__container {
      position: absolute;
      top: 0px;
      margin: 0px;
      width: 100%;
      z-index: 1;
      height: 100vh;
      overflow-y: auto;
      background-color: #000000c4;
      display: flex;
      // align-items: center;
      justify-content: center;
      padding: 10px 0px;
    }

    .login__popup__container__disable {
      display: none;
    }
  }
}
.cart__popup__container__disable {
  display: none;
}
.cart__popup__container {
  position: fixed;
  top: 0px;
  margin: 0px;
  width: 100%;
  z-index: 100;
  height: 100vh;
  overflow-y: hidden;
  background-color: #02020250;
  padding: 10px 0px;

  @media screen and (max-width:576px) {
    z-index: 110;
  }
}

.main_wrapper{
  // margin-top: 116px;
  display: block;
  width: 100%;

  // @media screen and (max-width:992px) {
  //   margin-top: 154px;
  // }
  // @media screen and (max-width:576px) {
  //   margin-top: 143px;
  // }
  // @media screen and (max-width:480px) {
  //   margin-top: 143px;
  // }
  // @media screen and (max-width:380px) {
  //   margin-top: 137px;
  // }
}

.main_wrapper_hidden{
  margin-top: 116px;
  display: none;
  width: 100%;

  @media screen and (max-width:992px) {
    margin-top: 154px;
  }
  @media screen and (max-width:576px) {
    margin-top: 143px;
  }
  @media screen and (max-width:480px) {
    margin-top: 143px;
  }
  @media screen and (max-width:380px) {
    margin-top: 137px;
  }
}

@media screen and (max-width: 1500px) {
  // .header {
  //   width: 90% !important;
  // }
  // .navbar {
  //   width: 90% !important;
  // }
  .product__popup__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  // .header {
  //   width: 90% !important;
  // }
  // .navbar {
  //   width: 90% !important;
  // }
  .product__popup__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}
@media screen and (max-width: 1280px) {
  .popup__product__block {
    margin: 0px 12px 12px 0px !important;
    width: 30% !important;
  }
}
@media screen and (max-width: 1200px) {
  .navbar {
    padding: 0px 0px 0px 8px !important;
  }
  .search__box {
    width: 100% !important;
  }
  .search__box__result__popup {
    width: 600px !important;
  }
  .main__navbar__container
    .header__container
    .header
    .header__row
    .search__box__block
    .search__box__result__popup
    .search__result__right__part
    .search__result__product__block {
    margin: 0;
  }
  .main__navbar__container
    .header__container
    .header
    .header__row
    .search__box__block
    .search__box__result__popup
    .search__result__right__part
    .search__result__product__block
    .search__result__product__image__block {
    padding-left: 0;
  }
  .main__navbar__container .category__popup__container .category__popup__block {
    width: 1020px;
    margin: 0px auto;
  }
}
@media screen and (max-width: 992px) {
  .back__mobile__icon {
    background: transparent;
    border: none;
    display: inline;
    width: 22px;
    margin-bottom: 20px;
    padding: 0;
    margin-left: 0;    
  }
  .search__result__left__part p span {
    font-size: 18px !important;
  }
  .search__result__product__text p {
    font-size: 12px !important;
  }
  .search__box__result__popup {
    width: 100% !important;
  }
  .main__navbar__container {
    min-width: 100% !important;
    top: 0;
    .location__icon__block,
    .wishlist__icon__block {
      display: block;
    }
    .navbar__row {
      overflow-x: auto;
      overflow-y: hidden;
    }
    .navbar__link__block {
      margin-left: 0 !important;
      flex-wrap: nowrap;
      width: 100%;
      .navbar__allCategories {
        margin: 0px 10px 0px 0px !important;
        white-space: nowrap;
        display: none !important;
      }
      .navbar__link {
        white-space: nowrap;
        padding: 10px !important;
        margin: 0 !important;
      }
    }
  }
  .main__navbar__container .header__container {
    // height: 120px;
    padding: 24px 12px 0 12px;
  }
  .header {
    width: 100% !important;
    margin: 0px auto;
  }
  .search__box__block {
    // display: none !important;
    order: 2;
    margin-top: 9px;
  }
  .search__box {
    height: 42px !important;
    border-radius: 5px !important;
    @media screen and (max-width:380px) {
      height: 36px !important;
    }
  }
  .header__icon__block {
    padding: 0px 0px 0px 0px !important;
    justify-content: space-between !important;    
  }
  .header__language__icon {
    // display: block !important;
  }
  .language__selction {
    display: none !important;
  }
  .navbar__container {
    padding: 0px !important;
    // display: none !important;
  }
  .navbar {
    min-width: 100% !important;
    margin: 0px auto;
    padding: 0px 0px 0px !important;
  }

  .mobile__navbar {
    display: block !important;
  }
  .product__popup__block {
    min-width: 100% !important;
    margin: 0px auto;
    // padding: 7px 10px 0px !important;
    .inner__product__popup__block {
      padding: 0px 14px;
      .category__block {
        .category__title {
          font-size: 14px !important;
        }
      }
      .productList__block {
        padding: 0px 0px 0px 12px !important;
        .popup__product__block {
          margin: 0px 12px 12px 0px !important;

          .product__image {
            margin: 0px 0px 10px 0px !important;
            width: 100%;
          }
          .product__title {
            font-size: 13px;
          }
        }
      }
      .adsBanner__block {
        padding: 0px !important;
        .adBanner__image {
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .main__navbar__container {
    width: 100% !important;
  }
  .product__popup__container {
    display: none !important;
  }

  .navbar {
    width: 100% !important;
    align-items: flex-end;
    justify-content: flex-end !important;
  }
  .header {
    width: 100% !important;
  }

  // .icon__block {
  //   display: none !important;
  // }
  .productList__block {
    justify-content: space-between;
    padding: 0px 12px 0px 12px !important;
  }
  .adsBanner__block {
    margin-top: 5px !important;
    display: flex;
    justify-content: space-between !important;
    align-items: flex-start;
    flex-direction: row !important;
    .adBanner__image {
      width: 32% !important;
    }
  }
}
@media screen and (max-width: 576px) {
  .main__navbar__container {
    width: 100% !important;
  }
  .navbar {
    width: 100% !important;
  }
  .header {
    width: 100% !important;
  }
  .header__sidemenu__icon {
    transform: scale(80%);
    margin-left: 0px !important;
    width: 23px;
  }
  .header__logo {
    width: 100% !important;
  }
  .header__icon {
    // margin: 0px !important;
    // transform: scale(80%) !important;
  }
  .cart__icon__block {
    margin: 0px !important;
  }
}
@media screen and (max-width:280px) {
  .main__navbar__container .header__container {
    padding: 24px 10px 0px 10px;
  }
}

/*-------------------------- Arabic view start ------------------------*/
body[dir="rtl"].arabicFonts {

  .main_header {
    .main__navbar__container {
      .header__container {
        .header {
          .header__right__text.text-end {
            // right: auto;
            // left: 3px;
            // top: -20px;

            @media screen and (max-width:992px) {
              // top: -22px;
            }

            p {
              text-align: left;
              @media screen and (max-width:330px) {
                font-size: 8px;
              }
              @media screen and (max-width:281px) {
                font-size: 7px;
              }
            }
          }
          
          .header__row {
            .header__icons__linkbox {
              padding-left: 0px;

              .header__icon__block {
                position: sticky;
                .header__icon__inner__block {
                  .header__icon {
                    @media screen and (max-width:321px) {
                      width: 16px;
                      height: 16px;
                      margin: 0px 5px 0px 0px;
                    }
                  }

                  .header__user__block {
                    .user__down__arrow__icon {
                      margin: 0px 5px 0px 2px;
                      width: 16px;
                      height: 16px;
                    }
                    .user__dropdown__block{
                      right: auto;
                      left: 0px;
                    }
                  }

                  .cart__icon__block {
                    margin-left: 0px;
                  }

                  .location__icon__block {
                    margin-left: 10px;

                    @media screen and (max-width:480px) {
                      margin-left: 4px;
                    }
                  }

                  .main__language__selection {
                    margin-right: 0px;
                    margin-left: 8px;

                    @media screen and (max-width:480px) {
                      margin-left: 0px;
                      padding-left: 4px;
                    }
                  }

                  .header__user__block {
                    .signin__signup__popup {
                      right: auto;
                      left: 0px;
                      @media screen and (max-width:992px) {
                          z-index: 5;
                      }
                      @media screen and (max-width:480px) {
                        left: -2.5vw;
                      }
                      .nav-tabs {
                        .nav-item {
                          .nav-link{
                            font-family: 'SSTArabicMedium';
                          }
                        }
                      }
                      .signin__button,.signup__button,
                      .signin__signup__popup__closeBtn {
                        font-family: 'SSTArabicBold';
                      }
                    }

                    .header__username__block {
                      .header__username {
                        margin: 0px;
                      }
                    }

                    .user__dropdown__block {
                      @media screen and (max-width:1200px) {
                        width: 185px;
                        right: auto;
                        left: 0px;
                      }

                      .user__db__menu__block {
                        .user__db__icon {
                          margin: 0px 0px 0px 10px;
                        }
                      }
                    } 
                  }              
                }
              }            
            }

            .search__box__block {
              .search__box {
                .search__input {
                  width: 94%;

                  @media screen and (max-width:380px) {
                    width: 92%;
                  }
                }
              }
            }
          }

          .header__sidemenu__icon {
            @media screen and (max-width:992px) {
              margin-right: 0px;
              margin-left: 10px !important;
              transform: scaleX(-1);
              position: relative;
              z-index: 0;
            }
          }
        }
      }
      
      .navbar__container {
        position: relative;
        z-index: -1;
        .navbar__row {
          .navbar {
            .navbar__link__block {
              .navbar__allCategories {
                margin: 0px 0px 0px 90px;
                font-family: 'SSTArabicBold';
                font-size: 15px;
              }
              .navbar__link{
                font-size: 14px;
                @media screen and (max-width:580px) {
                  margin: 0px 15px 0px 0px !important;
                }
              }
            }
          }
        }
      }
      
      .mobile__navbar {
        .mobile_top_header {
          .mobile_close_icon {
            margin-left: 0px;

            img {
              vertical-align: text-top;
            }
          }

          .mobile_user_name {
            padding-right: 15px;
          }
        }

        .mobile__navbar__link__block {

          .mobile__navbar__link,
          a {
            .down__arrow__icon {
              @media screen and (max-width:992px) {
                transform: rotate(90deg);
                filter: invert(37%) sepia(0%) saturate(1815%) hue-rotate(171deg) brightness(97%) contrast(81%);
              }
            }
            &.active__mobile__navbar__link {
              font-family: 'SSTArabicBold';
            }
          }
        }
        .mobile__navbar__link__nested__block {
          .mobile__category__block {
            a{
              font-family: 'SSTArabic';
            }
          }
        }
      }
      .category__popup__container {
        .category__popup__block {
          .category__popup__left__block {
            .selected__mainCategory__block {
              .mainCategory__left__block{
                font-family: 'SSTArabicBold';
              }
              .popup__right__arrow {
                right: auto;
                left: 0;
                transform: rotateY(155deg);
                transition: unset;
              }
            }
          }
          .category__popup__right__block{
            .mainCategory__title__block{
              .mainCategory__right__block{
                font-family: 'SSTArabicBold';
              }
            }
            .subCategory__block{
              .subcategory{
                p{
                  font-family: 'SSTArabic';
                }
              }
            }
          }
        }
      }
    }

    .main__navbar__container {
      .mobile__navbar {
        .mobile_top_header {
          padding-left: 0px;

          .mobile_user_name {
            span {
              @media screen and (max-width:992px) {
                margin-right: 0px;
                margin-left: 10px;
              }

            }
          }
        } 
      } 
    }
  }
  .slick-slide {
    float: left;
  }

  .spinner-border{
    margin-left: 0px;
    margin-right: 10px;
  }
  
}

/*-------------------------- Arabic view End ------------------------*/

.main__navbar__container .header__container .header .header__row {
    padding-top: 10px;
}