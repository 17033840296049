.carousel__container {
  margin: 0px 0px 0px;
  position: relative;

  .less__width__carousel__block {
    width: 100%;
    padding: 0px;
    position: relative;
    margin: 0px auto;
  }

  .pc__block {
    width: 1420px;
    padding: 0px;
    position: relative;
    margin: 40px auto 0px;

    @media screen and (max-width:992px) {
      margin: 20px auto 20px;
    }
    // @media screen and (min-width:580px) and (max-width:992px) {
    //   padding: 0px 20px;
    // }
  }

  .section__title {
    font-family: 'SSTbold';
    font-weight: bold;
    font-size: 24px;
    line-height: 1.2;

    @media screen and (max-width:580px) { font-size: 20px !important; }

  }

  // .arrow__block {
  //   z-index: 2;
  //   display: flex;
  //   justify-content: space-between;
  //   align-items: center;
  //   width: 103%;
  //   position: absolute;
  //   top: 50%;
  //   transform: translateY(-50%);
  // }

  .arrow { // plp landing
    // filter: invert(0);
    cursor: pointer;
    padding: 8px 12px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 1;
    display: flex;
    align-items: center;

      @media screen and (max-width:1360px) {
        padding: 6px 9px;
      }

      @media screen and (max-width:580px) {
        padding: 6px 9px;
      }

      img {
        width: 14px;

        @media screen and (max-width:1360px) {
          width: 11px;
        }

        @media screen and (max-width:580px) {
          width: 10px;
        }
      }

      &:hover {
        background-color: #dc3a1a;
        transition: all 0.8s ease-in-out;
        -webkit-transition: all 0.8s ease-in-out;

        img {
          filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(12deg) brightness(106%) contrast(103%);
        }
      }

      &:focus {
        box-shadow: 0px 3px 4px rgb(0 0 0 / 16%);
      }
    }

    .arrow__disable {
      visibility: hidden;
    }

    .left__arrow {
      position: absolute;
      top: 50%;
      left: -1.5%;

      // transform: translateY(-50%);
      // transform: translateX(-100%);
      @media screen and (max-width:992px) {
        left: -1%;
      }
      @media screen and (max-width:580px) {
        left: -1.5%;
      }
    }

    .right__arrow {
      position: absolute;
      top: 50%;
      right: -1.5%;

      // transform: translateY(-70%);
      @media screen and (max-width:992px) {
        right: -1%;
      }
      @media screen and (max-width:580px) {
        right: -1.5%;
      }
    }


    .slick-list {
      
    }

  

  .main__product__container__block {
    background-color: #ffffff;
    border-radius: 10px;

    @media screen and (max-width:992px) { padding: 0px; }
    
    .slick-slider {
      padding: 20px;

      @media screen and (max-width:992px) { padding: 0px; }

      // @media screen and (max-width:580px) { padding: 0px 15px !important; }
      
      .arrow { //homepage 
        // filter: invert(0);
        cursor: pointer;
        padding: 8px 12px;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        z-index: 1;
        display: flex;
        align-items: center;

        @media screen and (max-width:1360px) {
          padding: 6px 9px;
        }

        @media screen and (max-width:580px) {
          padding: 6px 9px;
        }

        img {
          width: 14px;

          @media screen and (max-width:1360px) {
            width: 11px;
          }

          @media screen and (max-width:580px) {
            width: 10px;
          }
        }

        &:hover {
          background-color: #dc3a1a;
          transition: all 0.8s ease-in-out;
          -webkit-transition: all 0.8s ease-in-out;

          img {
            filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(12deg) brightness(106%) contrast(103%);
          }
        }

        &:focus {
          box-shadow: 0px 3px 4px rgb(0 0 0 / 16%);
        }
      }

      .arrow__disable {
        visibility: hidden;
      }

      .left__arrow {
        position: absolute;
        top: 50%;
        left: -1.5%;

        // transform: translateY(-50%);
        // transform: translateX(-100%);
        @media screen and (max-width:992px) {
          left: -2.5%;
        }
        @media screen and (max-width:580px) {
          left: -1.5%;
        }
      }

      .right__arrow {
        position: absolute;
        top: 50%;
        right: -1.5%;

        // transform: translateY(-70%);
        @media screen and (max-width:992px) {
          right: -2.5%;
        }
        @media screen and (max-width:580px) {
          right: -1.5%;
        }
      }


      .slick-list {
        
      }

    }

    .new__arrival__pc__block {
      display: flex;
      align-items: center;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }

    .top__trending__pc__block {
      // position: relative;
      display: flex;
      align-items: center;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }

    .top__rated__pc__block {
      display: flex;
      align-items: center;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }

    .new__arrival__pc__block_mob {
      display: flex;
      align-items: center;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }

    .top__trending__pc__block_mob {
      // position: relative;
      display: flex;
      align-items: center;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }

    .top__rated__pc__block_mob {
      display: flex;
      align-items: center;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }
  }

  .bestSellers__block {
    display: flex;
    align-items: flex-start;
    overflow-x: hidden;
    scroll-behavior: smooth;
    position: relative;
  }

  .budgetTV__block {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    scroll-behavior: smooth;
    position: relative;
  }

  .productListingPage__similarproduct__block {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    scroll-behavior: smooth;
    position: relative;
  }

  .people__also__bought__block {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    scroll-behavior: smooth;
    position: relative;
  }

  .pd__similar__products__block {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    scroll-behavior: smooth;
    position: relative;
  }
}

@media screen and (max-width: 1500px) {
  .pc__block {
    width: 93% !important;
    max-width: 93% !important;
  }
}
@media screen and (max-width: 1450px) {
  .pc__block {
    width: 90% !important;
    max-width: 90% !important;
  }
}

@media screen and (max-width: 1400px) {
  .arrow__block {
    width: 103% !important;

    .left__arrow {}

    .right__arrow {}
  }
}

@media screen and (max-width: 1200px) {
  // .main__product__container__block {
  //   padding: 20px !important;
  // }

  .arrow__block {
    width: 100% !important;
  }

  .left__arrow {
    transform: translateX(0%) !important;
  }

  .right__arrow {
    transform: translateX(0%) !important;
  }
}

@media screen and (max-width: 992px) {
  .pc__block {
    min-width: 100% !important;
    margin: 0px auto;
  }

  .arrow__block {
    width: 100% !important;
  }

  .arrow {
    padding: 10px;
    background-color: #81818150;
  }
}

@media screen and (max-width: 768px) {
  .pc__block {
    width: 100% !important;
  }

  .arrow__block {
    width: 100% !important;
  }
}

@media screen and (max-width: 576px) {
  .pc__block {
    width: 100% !important;
  }

  .arrow__block {
    width: 100% !important;
  }

  .main__product__container__block {
    padding: 20px 2px !important;
  }
}

.top__trending__section,.top__ratedProduct__section {
  .main__product__container__block{

    .slick-slider{
      padding: 0px;
      .arrow{
         @media screen and (max-width:580px) {
          padding: 6px 9px;
         }
      }
      
      .left__arrow{
        position: absolute;
        top: 50%;
        left: -2.8%;
        @media screen and (max-width:992px) {
          left: -2.5%;
        }
        @media screen and (max-width:580px) {
          left: -6%;
        }
      }
      .right__arrow {
        position: absolute;
        top: 50%;
        right: -2.8%;
        @media screen and (max-width:992px) {
          right: -2.5%;
        }
        @media screen and (max-width:580px) {
          right: -6%;
        }
      }
    }
  }
}