.category__banner__container {
  // display: flex;
  // justify-content: center;
  //   align-items: center;
  // padding: 0px;
  padding: 0px 15px;
  margin-bottom: 49px;
  .category__banner__block {
    // width: 1420px;
    // padding: 0px;
    // margin: 0px auto;
    .banner__image__block {
      width: 100%;
      .banner__image {
        width: 100%;
        // height: 100%;
        // object-fit: cover;
      }
    }
  }
}
