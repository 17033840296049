
 


.mobile__home__page {
 
  .adImage__container {
    padding: 0 !important;
    img {
      width: 100%;
    }
    @media screen and (max-width: 992px) {
      padding: 0px 10px 20px !important;      
    }
  }
  
  .mobile__home__feature__categories__container {
    .section__title {
     font-family: 'SSTbold';
      font-weight: bold;
      font-size: 20px;
      padding: 0px !important;
      margin: 10px 0px 16px;
    }
    .mobile__home__feature__categories__card {
      box-shadow: 0px 3px 6px #0000000a;
      border: 1px solid #d1d1d1;
      border-radius: 5px;
      padding: 12px;
      height: 170px;
      .mobile__home__feature__categories__card__link{
        text-decoration: none;
        color: #000000;
        text-align: center;
      }
      p{
        font-size: 14px;
        line-height: 1.3;
      }
    }
  }
}

@media (max-width: 767px) {
  .home__page__category__block {
    // padding-top:170px;
  }
}
