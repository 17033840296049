body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body.modal_back_stop{
  max-height: 100vh;
  overflow: hidden;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 4px !important;

  /* transform: translateY(100%); */
}

.main__navbar__container .navbar__row::-webkit-scrollbar {
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;
      border-radius: 10px; */
  /* transform: translateY(100%); */
  z-index: 10;
  opacity: 0;
  background: white;
}

::-webkit-scrollbar-track:hover {
  /* box-shadow: inset 0 0 5px grey;
    border-radius: 10px; */
  opacity: 1;
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  opacity: 0;
  background-color: #818181;
  /* background: red; */
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #303030;
}

.main_header {}

.main_wrapper {
  /* margin-top: 112px; */
  min-height: 70vh;
}

.cart__popup__container__disable {
  display: none;
}

.cart__popup__container {
  position: fixed;
  top: 0px;
  margin: 0px;
  width: 100%;
  z-index: 12;
  height: 100vh;
  overflow-y: hidden;
  background-color: #02020250;
  padding: 10px 0px;
}

.plp__compare__popup__container__disable {
  display: none;
}
.plp__compare__popup__container {
  position: fixed;
  top: 70px;
  margin: 0px;
  width: 100%;
  z-index: 2;
  height: 94vh;
  overflow-y: hidden;
  background-color: #02020250;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}

img {
  max-width: 100%;
}

.text-orange {
  color: #FF4F04;
}

.text-grey {
  color: #808080;
}

.btn__border__black {
  border: 1px solid #000000;
  background: transparent;
  color: #000000;
  font-size: 16px;
  font-family: 'SSTbold';
  font-weight: bold;
  padding: 20px 20px;
  transition: all 0.3s;
}

.btn__primary__orange {
  background: #DF4E32;
  color: #fff;
  font-size: 16px;
  font-family: 'SSTbold';
  font-weight: bold;
  padding: 20px 20px;
  transition: all 0.3s;
  border-color: #DF4E32;
}

.btn__primary__black {
  background: #000;
  color: #fff !important;
  font-size: 16px;
  font-family: 'SSTbold';
  font-weight: bold;
  padding: 20px 20px;
  transition: all 0.3s;
  border-color: #000000;
}

.btn__primary__white {
  background: #fff;
  color: #DF4E32 !important;
  font-size: 16px;
  font-family: 'SSTbold';
  font-weight: bold;
  padding: 10px 20px;
  transition: all 0.3s;
  border-color: #fff;
  text-transform: uppercase;
}

.btn__primary__white:hover, .btn__primary__white:focus {
  outline: none;
  box-shadow: none;
}

.btn__primary__black:hover, .btn__primary__black:focus {
  box-shadow: none;
}

.btn__primary__orange:hover, .btn__primary__orange:focus, .btn__primary__orange:active:focus {
  background: #fff;
  color: #DF4E32;
  border-color: #DF4E32;
  box-shadow: none;
}

.btn__grey__solid {
  width: 50%;
  margin: 0 !important;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'SSTbold';
  font-weight: bold;
  padding: 20px 20px;
  background: #636363;
  color: #fff;
}

/* input[type='radio']:hover {
  accent-color: #dc3a1a;
  cursor: pointer;
  border-color: #dc3a1a;
} */
input[type='radio']{
  position: relative;
}
input[type='radio']::after {
  content: '';
  display: block;
  width: 55%;
  height: 55%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  padding: 10%; /* Increase the inner area for pushing the border out of the circle */
  border-radius: 50%;
  transition: 0.3s; /* Move your transition to here */
}

input[type='radio']:hover::after {
  /* box-shadow: 0px 0px 0px 12px rgba(80, 80, 200, 0.2); */
  background-color: #dc3a1a;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
}
input[type='radio']:checked:hover:after {
  display: none;
}

.custom__checkbox {
  position: relative;
}

.custom__checkbox .form-check-input {
  opacity: 0;
  display: none;
}

.custom__checkbox .form-check-input[type=checkbox]:checked+label {
  border-color: #000000;
}

.custom__checkbox label.custom__checkmark {
  width: 100%;
  border: 2px solid #D1D1D1;
  box-shadow: 0px 3px 6px #00000029;
  padding-top: 60px !important;
}

.counter__decrease__block {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #D1D1D1;
  border-left: 1px solid #D1D1D1;
  font-size: 20px;
  padding: 0px;
}
.counter input {
  width: 100%;
  border-left: none;
  border-right: none;
  max-height: 30px;
  text-align: center;
  font-size: 15px;
  border: none;
  height: 30px;
}

.counter__increase__block {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.row.sc__counter__box {
  border: 1px solid #D1D1D1;
  border-radius: 3px;
  margin-right: 20px;
  margin-left: 3px;
  width: 105px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.common__favourite__button {
  border: none;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 0;
}

.common__favourite__button .pd__favourite__icon {
  display: block;
}

.common__favourite__button .pd__favourite__icon__disable {
  display: none;
}

.toast .bg-success {
  background-color: #27743e !important;
}

.toast .bg-danger {
  background-color: #d32f2f !important;
}
.pre-order{
  background: #F3A100 !important;
}
.pre-order::before{
  background: #F3A100 !important;

}

.form-select:focus {
  outline: none;
  box-shadow: none;
}

.invalid_err_message {
  color: red;
}
.custom__pagination{
  padding: 20px;
}
.custom__pagination .page-item.active .page-link{
  z-index: 3;
    color: #fff;
    background-color: #000000;
    border-color: #000000;
}
.custom__pagination .page-link {
  border: 1px solid #707070;
  color: #707070;
  border-radius: 100% !important;
  margin: 0 10px;
  height: 25px;
  width: 25px;
  font-size: 12px;
  line-height: 23px;
  padding: 0;
  text-align: center;
}
.custom__pagination li.page-item.active{
  border:none
}

.active_compare_btn{
  color: #dc3a1a;
}

/* ======= 18-9-2022 */

.product__offer__block.my_reward_icon img {
  /* width: 25px; */
  width: 20px;
  float: left;
  margin-right: 13px;
}
.sc__counter__box .col-4.counter__decrease__block, 
.sc__counter__box .col-4.counter__increase__block {
  padding: 6px !important;
    width: 30%;
    height: 30px;
    border-radius: 0px;
}
.sc__counter__box .col-4.counter__decrease__block:hover, 
.sc__counter__box .col-4.counter__increase__block:hover {
  background-color: #cccccc94;
}
.sc__counter__box input[type="number"] {
  width: 40%;
  padding: 0px 4px;
  font-size: 14px;
  text-align: center;
  border: 1px solid #d1d1d1;
  border-top: none;
  border-bottom: none;
  border-radius: 0px;
}
.sc__counter__box input[type="number"]::-webkit-inner-spin-button,
.sc__counter__box input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.sc__counter__box input[type="number"]:focus{                
  outline: none;
}


/* .col-8.col-sm-8.col-md-8.pd__total__price__block p.heading4 {
  font-size: 27px;
}
.col-8.col-sm-8.col-md-8.pd__total__price__block p.heading4 {
  width: auto !important;
  float: left;
} */

/* .row.pdp__sticky__counter__block {
  padding-bottom: 0px;
  padding-left: 0px;
} */

.product__avilable__offers__block.product__common__main__block {
  padding-bottom: 6px;
}

.product__offer__block.my_reward_icon {
  padding-top: 13px;
}
   

/* .inner_price_section {
  width: 100%;
  float: left;
} */

.pd__product__price__block .pd__total__price__block .pd__save__price__button {
  float: left;
}

.rating_block_pdp {
  float: left;
  margin-top: 5px;
  margin-left: 5px;
}

.plp__mezza__reward.d-flex.justify-content-between.align-items-center.mb-2 {
  min-height: 43px;
  margin-top: 12px;
  margin-bottom: 12px !important;
}


/* .productNine__content span.text3 {
  float: right;
} */

/* .productNine__content .heading6 {
  float: left;
} */


.productNine__block .productNine__content .productNine__feature__block {
  display: flex;
  width: 100%;
}

.cms_container {
  max-width: 1420px;
  margin: 0px auto;
  padding-top: 25px;
  padding-bottom: 25px;  
  
} 
.col-4.counter__decrease__block img {
  width: 18px;
}  
.col-4.counter__increase__block img {
  width: 15px;
} 
/* .row.pd__product__price__block.earn_loyalty {
  padding-left: 40px;
} */
.selected__plp__category__block img.plp__category__image {
  filter: invert(41%) sepia(90%) saturate(3401%) hue-rotate(356deg) brightness(97%) contrast(105%);
} 

body.arabicFonts .main__navbar__container .navbar__container .navbar__row .navbar .navbar__link__block .navbar__allCategories svg {
  transform: rotate(179deg);
}

.feature__category__container .feature__category__block .inner__feature__category__block .second__feature__category__block .feature__category .second__feature__category__image__block {
  min-height: 200px;
}

.store__pickup__mainarea {
  width: 90% !important; 
}

.store__pickup__options__box {
  margin-top: 25px;
} 

.checkout__page__container .checkout__page__block .checkout__page__inner__block .checkout__left__block .user__delivery__address__block .store__pickup__mainarea .store__pickup__options__box .store__pickup__itembox .pickup__store__infobox {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
  padding-bottom: 15px;
  border-radius: 0 !important;
  box-shadow: none !important;
} 

.pickup__store__infobox p.product__store__status {
  width: 80%;
  float: left;
} 

.pickup__store__infobox .pickup__store__location {
  float: left;
}

.cursor_not_allowed {
  cursor: not-allowed! important;
}

.main__form__field__block .heading7{ display: inline-block; }
.main__form__field__block .inputs_validation{
  font-size: 13px;
  color: red;  
}
.field__block .heading7{ display: inline-block; }
.field__block .inputs_validation{
  color: red;
  font-size: 13px;
}
a.product__comparison__name p {
  min-height: 100px;
}

.col-sm-3.specification__subcategory__title p.text5 {
  font-size: 16px;
}

.col-sm-3.specification__subcategory__title p.text4 {
  padding-top: 7px;
}
.toast:not(.showing):not(.show){
  opacity: 1 !important;
}
.main__rating__block{
  display: none !important;
}
.main__navbar__container .header__container .header{
  position: relative;
}
.header__right__text.text-end {
  
}
#popover-basic{
  padding: 5px;
  background: #dc3a1a;
}
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after, .bs-popover-bottom>.popover-arrow::after{
  border-bottom-color:#dc3a1a;
}
#popover-basic span{
  color: #fff;
}
.popover .popover-arrow {
  /* background: #dc3a1a; */
}

.loyalty__points__block input:hover {
  cursor: pointer;
}

.loyalty__points__block .sum__points{
  text-align:right;
}

@media(max-width:1400px){
  .header__right__text.text-end{
    /* right: 20px; */
  }
}


@media screen and (max-width:992px){
  .cms_container {  width:94%; }
  .header__right__text.text-end{
    /* right: 0px;
    top:-19px; */
  }
  .header__right__text.text-end p {
    margin-bottom: 0px;
  }
  .main_wrapper {
    min-height: 16vh;
  }
}

@media screen and (max-width:580px){
  .tamara__logobox {
    width: 50px;
    height: 17px;
    max-height: 17px;
    margin-bottom: 5px;
  }
  .tamara__logobox img {
    display: inline-block;
    vertical-align: top;
    max-width: 100%;
  }
  .main_wrapper {
    min-height: 15vh;
  }
  
}
@media screen and (max-width:280px){  
}
/* 09-10-2022 */

.plp__tamara__box.mb-2 {
  min-height: 21px;
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  justify-content: center;
}
.plp__tamara__box.mb-2 img {
  vertical-align: text-top;
  max-height: 17px;
}
.tamara__logobox{
  max-width: 60px;
  height: 21px;
}
.tamara__logobox img {
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
.is_disabled_product_qty {
  pointer-events: none;
  opacity: 0.7;
}

/* --------------- animation start ---------------*/

@keyframes smoothScroll {
	0% {
		transform: translateY(-80px);
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes smoothAccodion {
	0% {
		transform: translateY(-65px);
	}
	100% {
		transform: translateY(0px);
	}
}
.lazy-load-image-background{
  display: inline !important;
}

/* --------------- animation end ---------------*/

/* .playStation_bottom_banner {
  padding: 0px 10px 0px 14px !important;
} */

/* .outofStock__button, .pd__outofStock__button {
  display: none !important;
} */

.plp__category__image {
  display: none !important;
}