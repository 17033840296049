.carouselTypeTwo__container {
  margin: 30px 0px 0px;
  padding: 0px;
  .section__title {
    font-family: 'SSTbold';
    font-weight: bold;
    font-size: 24px;
    line-height: 1;
  }
  .arrow__block {
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .arrow {
      // filter: invert(1);
      cursor: pointer;
      padding: 6px 12px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      position: absolute;
      @media screen and (max-width:580px) {
        display: inline-flex;
        padding: 6px 11px;        
      }
      img {
        width: 14px;
        vertical-align: top;
        @media screen and (max-width:580px) {
          width: 10px;
        }
      }
      &:hover {
        background-color: #dc3a1a;    
        transition: all 0.8s ease-in-out;
        -webkit-transition: all 0.8s ease-in-out;   
        img{
          filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(12deg) brightness(106%) contrast(103%);
        }  
      }
      &:focus{
        box-shadow: 0px 3px 4px rgb(0 0 0 / 16%);
      }
    }
    .arrow__disable {
      visibility: hidden;
    }
    .left__arrow {
      // transform: translateX(-50%) !important;
      left: -2%;
      padding: 6px 10px;
      // @media screen and (max-width:992px) {
      //   left: -0.2%;
      // }
      // @media screen and (max-width:580px) {
      //   left: -6%;
      // }
    }
    .right__arrow {
      // transform: translateX(5%);
      right: -2%;
      padding: 6px 10px;
      // @media screen and (max-width:992px) {
      //   right: -0.2%;
      // }
      // @media screen and (max-width:580px) {
      //   right: 6%;
      // }
    }
  }
  .carouselTypeTwo__inner__block {
    // position: relative;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    scroll-behavior: smooth;
    // transition: all 0.2s ease-in;
  }
  .carouselTypeTwo__block {
    width: 100%;
    padding: 0px;
    position: relative;
    margin: 0px auto;
    @media screen and (max-width:767px) {
      padding: 10px 0px;
    }
    // @media screen and (max-width:992px) {
    //   padding: 0px 0px !important;
    // }
    // @media screen and (max-width:580px) {
    //   padding: 15px 20px !important;
    // }
  }
  .rv__inner__mobile__screen {
    // position: relative;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    scroll-behavior: smooth;
    // transition: all 0.2s ease-in;
    @media screen and (max-width:992px) {
      padding: 15px 20px;
    }
    @media screen and (max-width:580px) {
      padding: 15px 0px;
    }
    // @media screen and (max-width:580px) {
    //   padding: 15px 0px;
    // }
  }
 
  .top__trending__block {
    // position: relative;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    scroll-behavior: smooth;
    // transition: all 0.2s ease-in;
  }
  .plp__youCanAlsoPurchase__block {
    // position: relative;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    scroll-behavior: smooth;
    // transition: all 0.2s ease-in;
  }
  .cart__popup__frequentlyBoughtTogether__block {
    // position: relative;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    scroll-behavior: smooth;
    // transition: all 0.2s ease-in;
  }
}
.pd__newArrival__block {
  .carouselTypeTwo__container {
    margin: 20px 0px 0px;
    .section__title{ font-size: 24px; }
  }
}
@media screen and (max-width: 1450px) {
  .carouselTypeTwo__block {
    width: 100% !important;
  }
}
@media screen and (max-width: 1400px) {
  .arrow__block {
    width: 103% !important;

    // .left__arrow {
    // }
    // .right__arrow {
    // }
  }
}
@media screen and (max-width: 1200px) {
  .carouselTypeTwo__block {
    min-width: 100% !important;
    margin: 0px auto;
  }
  .arrow__block {
    width: 100% !important;

    .left__arrow {
      // transform: translateX(-50%) !important;
    }
    .right__arrow {
      // transform: translateX(50%) !important;
    }
  }
}
@media screen and (max-width: 992px) {
  .carouselTypeTwo__block {
    min-width: 100% !important;
    margin: 0px auto;
  }
  .arrow__block {
    width: 100% !important;
    .left__arrow {
      // transform: translateX(-10%) !important;
    }
    .right__arrow {
      // transform: translateX(20%) !important;
    }
  }
}
@media screen and (max-width: 768px) {
  .carouselTypeTwo__block {
    width: 100% !important;
  }
  .arrow__block {
    width: 100% !important;
  }
}
@media screen and (max-width: 576px) {
  .carouselTypeTwo__block {
    width: 100% !important;
  }
  .arrow__block {
    width: 100% !important;
  }
}

/*-------------------------- Arabic view start ------------------------*/

body[dir="rtl"].arabicFonts {

  .carouselTypeTwo__container{
    .plp__youCanAlsoPurchase__block {
      .pd__productOne__block{
        .addToCart__button{
          flex-direction: row;
        }
      }
      
    }
    .arrow__block {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    
  } 
}

/*-------------------------- Arabic view End ------------------------*/
